import React, { useState, useEffect, useRef } from "react";

const ResponsiveMobileTab = (props) => {
  const [viewDropdown, setViewDropdown] = useState(false);
  const [value, setValue] = useState("");
  const [key, setKey] = useState("");
  const dropdownRef = useRef(null);

  useEffect(() => {
    let menuItem =
      props.menuItems.find((item) => item.key === props.param) || {};
    setKey(menuItem.key);
    setValue(menuItem.label);
  }, [props.menuItems, props.param]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current) {
        if (!dropdownRef.current.contains(e.target) && viewDropdown) {
          setViewDropdown(false);
        } else if (dropdownRef.current.contains(e.target) && !viewDropdown) {
          setViewDropdown(true);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [viewDropdown]);

  return window.innerWidth > 600 ? (
    props.menuItems.map((menuItem, index) => {
      let style =
        props.param === menuItem.key
          ? {
              borderBottom: `4px solid ${props.primaryColor}`,
            }
          : {};
      if (index > 0) {
        style.marginLeft = 24;
      }
      return (
        <li
          className="nav-item"
          onClick={props.changeTab}
          style={style}
          key={menuItem.key}
        >
          <a
            name={menuItem.key}
            style={{
              height: "100%",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            {menuItem.label}
          </a>
        </li>
      );
    })
  ) : (
    <div
      className="nav-item"
      ref={dropdownRef}
      style={{
        borderBottom: `4px solid ${props.primaryColor}`,
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
    >
      <a
        style={{
          height: "100%",
          fontWeight: "bold",
          fontSize: "22px",
          marginRight: 30,
        }}
      >
        {value}
      </a>
      <i
        className={`fa fa-caret-${viewDropdown ? "up" : "down"}`}
        aria-hidden="true"
      ></i>
      <select
        style={{
          border: "0px none transparent",
          background: "transparent",
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          zIndex: 1,
          opacity: 0,
          cursor: "pointer",
        }}
        value={key}
        tabIndex={props.tabIndex || 0}
        onChange={(e) => {
          let selectedItem =
            props.menuItems.find((item) => item.key === e.target.value) || {};
          props.changeTab({
            target: { name: e.target.value },
            persist: () => {},
          });
          setKey(e.target.value);
          setValue(selectedItem.label);
          setViewDropdown(false);
        }}
      >
        {props.menuItems.map((item) => {
          return (
            <option key={item.key} value={item.key}>
              {item.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default ResponsiveMobileTab;
