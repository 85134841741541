import React from "react";
import { Select, Tooltip } from "antd";
import logger from "../../../utils/logger";

export function FSelect(props) {
  const { Option } = Select;

  // const handleChange = (details) => {
  //   const syntheticEvent = {
  //     target: {
  //       name: props.name,
  //       label: details.label,
  //       value: details.value,
  //     },
  //   };

  //   props.onChange(syntheticEvent);
  // };

  const handleChange = (details) => {
    if (!details) {
      return;
    }

    let processedValue = details.value;

    try {
      if (props.dataList?.length > 0) {
        const firstItem = props.dataList[0];

        const dataType =
          typeof firstItem === "object" && props.dataKey
            ? typeof firstItem[props.dataKey]
            : typeof firstItem;

        if (dataType === "string") {
          processedValue = String(details.value);
        } else if (dataType === "number") {
          const num = Number(details.value);
          processedValue = Number.isNaN(num) ? details.value : num;
        }
      }

      const syntheticEvent = {
        target: {
          name: props.name,
          label: details.label,
          value: processedValue,
        },
      };

      props.onChange(syntheticEvent);
    } catch (error) {
      logger.error("Error processing change:", error);
    }
  };

  return (
    <div
      className={`form-group  ${props.display || "row"} ${props.containerClassname}`}
    >
      <div
        style={{ width: props.width, display: props.flex ? "flex" : undefined }}
      >
        {props.label && (
          <div className={props.display !== "col" ? "col-4" : "row"}>
            <label
              className={`${props.textCenter ? "text-center" : ""}`}
              style={{ marginBottom: 0 }}
            >
              {props.label}
              {props.required && <span style={{ color: "red" }}>*</span>}
              {props.tooltip && (
                <Tooltip
                  placement="topLeft"
                  title={() => (
                    <div
                      style={{
                        height: "fit-content",
                        width: "300px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {props.tooltip}
                    </div>
                  )}
                >
                  <span className="border-0">
                    &nbsp;<i className="fa fa-question-circle-o"></i>
                  </span>
                </Tooltip>
              )}
            </label>
          </div>
        )}
        <div style={{ width: "100%" }}>
          <Select
            labelInValue
            disabled={props.disabled}
            required={props.required}
            value={props.value}
            name={props.name}
            onChange={handleChange}
            tabIndex={props.tabIndex || 0}
            className={`${props.display !== "col" && props.label && "col-8"}`}
            style={{ width: "100%", ...props.style } || {}}
            defaultValue={props.defaultValue}
            placeholder={props.placeholder}
          >
            {props.placeholder && (
              <Option key="placeholder" value="">
                {props.placeholder}
              </Option>
            )}
            {props.dataList.length > 0 &&
              props.dataList.map((data) => {
                if (props.listBuilder) {
                  return props.listBuilder(data);
                } else {
                  const value = props.dataOnlyValue
                    ? data
                    : data[props.dataKey];
                  const displayValue = props.dataOnlyValue
                    ? data
                    : data[props.dataValue];
                  return (
                    <Option key={value} value={value}>
                      {displayValue}
                    </Option>
                  );
                }
              })}
          </Select>
          {props.bottomHint && (
            <div>
              <div style={{ display: "flex", fontSize: 13, gap: "10px" }}>
                {props.bottomHint}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <Select
        defaultValue={props.defaultValue}
        className={`form-control form-control-sm ${props.display !== "col" && props.label && "col-8"}`}
        style={props.style || {}}
        disabled={props.disabled}
        required={props.required}
        value={props.value}
        name={props.name}
        // onChange={props.onChange}
        tabIndex={props.tabIndex || 0}
        options={options}
        onChange={(_, event) => props.onChange(event)}
      /> */}
    </div>
  );
}
