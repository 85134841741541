import { devtools } from "zustand/middleware";
import createMembersStore from "./members";
import createPackagesStore from "./packages";
import createFormLayoutStore from "./formLayout";
import { create } from "zustand";
import createAppStore from "./app";

const useGlobalStore = create(
  devtools(
    (set, get) => ({
      members: createMembersStore(set),
      packages: createPackagesStore(set),
      formLayout: createFormLayoutStore(set),
      app: createAppStore(set),
      resetAllStores: () => {
        const { members, packages, formLayout } = get();
        members.reset();
        packages.reset();
        formLayout.reset();
      },
    }),
    {
      name: "Zustand",
    }
  )
);

export default useGlobalStore;
