import React, { useState } from "react";
import axios from "axios";
import Axios from "../../../../services/apiFootballService/connection";
import logger from "../../../../utils/logger";
import { useSelector } from "react-redux";
import { selectCurrentGroupData } from "../../../../redux/selectors/adminData";
import Onboard from "../Onboard";
import { getPrimaryColor } from "../../../../utils/helper";

const OnboardOptions = () => {
  const [chips, setChips] = useState([]);
  const [runningService, setRunningService] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [csvFileText, setCsvFileText] = useState("");
  const groupData = useSelector(selectCurrentGroupData);

  const uploadCSV = (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      let file = e.target.files[0];
      setCsvFile(file);
      let reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        setCsvFileText(reader.result);
      };
    } else {
      setCsvFile(null);
      setCsvFileText("");
    }
  };

  const uploadCSVToFirebase = async () => {
    if (csvFile === null && chips.length === 0) {
      alert(
        "Please click on choose file button or add emails in text entry box"
      );
    } else {
      setRunningService(true);
      if (csvFile === null) {
        var url = `https://us-central1-chant2019.cloudfunctions.net/addEmailsForRegistartion`;
        var functionBody = {};
        functionBody.emailsList = chips;
        functionBody.groupDocId = groupData.id;
        await Axios.post(url, functionBody)
          .then((_) => {
            alert("Successfully updated");
            setCsvFile(null);
            document.getElementById("uploadCSVFileId").value = "";
            setChips([]);
            setCsvFileText("");
          })
          .catch((err) => {
            logger.error(`Error in updating ${err} `);
            alert("Something went wrong, try again after sometime");
          });
      } else {
        const lines = csvFileText.split("\n");
        var emails = [];
        var temp = lines[0].split(",");
        if (temp[0].trim().toLowerCase() === "email") {
          for (let i = 0; i < lines.length; i++) {
            const currentline = lines[i].split(",");
            const headers = ["Email"];
            const columns = [0];
            var email = "";
            for (let j = 0; j < headers.length; j++) {
              email = currentline[columns[j]];
            }
            if (email.trim().toLowerCase() !== "")
              emails.push(email.trim().toLowerCase());
          }
          emails.shift();

          var functionUrl = `https://us-central1-chant2019.cloudfunctions.net/addEmailsForRegistartion`;
          var body = {};
          body.emailsList = emails;
          body.groupDocId = groupData.id;
          await Axios.post(functionUrl, body)
            .then((_) => {
              alert("Successfully updated");
              setCsvFile(null);
              document.getElementById("uploadCSVFileId").value = "";
              setCsvFileText("");
              setChips([]);
            })
            .catch((err) => {
              logger.error(`Error in updating ${err} `);
              alert("Something went wrong, try again after sometime");
            });
        } else {
          alert("CSV file is not in the same structure as mentioned above.");
        }
      }

      setRunningService(false);
      //   this.getPendingMembers();
    }
  };

  const addChip = (value) => {
    const chipsTemp = chips.slice();
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var validEmail = re.test(String(value).toLowerCase());
    if (validEmail) {
      chipsTemp.push(value.toLowerCase());
      setChips(chipsTemp);
    } else {
      alert("Invalid Email");
    }
  };

  const removeChip = (index) => {
    const chipsTemp = chips.slice();
    chipsTemp.splice(index, 1);
    setChips(chipsTemp);
  };

  let primaryColor = "";
  if (groupData.configuration && groupData.configuration.primaryColor) {
    primaryColor = getPrimaryColor(groupData.configuration.primaryColor);
  }

  return (
    <div style={{ padding: "0rem 2rem" }}>
      <Onboard
        chips={chips}
        addChip={addChip}
        removeChip={removeChip}
        // downloading={downloading}
        uploadCSVToFirebase={uploadCSVToFirebase}
        uploadCSV={uploadCSV}
        groupName={groupData.groupName}
        currentGroup={groupData.id}
        registrationCode={groupData.registrationCode}
        registrationLink={groupData.registrationLink}
        backgroundColor={primaryColor}
        csvFile={csvFile}
        runningService={runningService}
        fanJoin={groupData.fanJoin}
        regCodeRole={groupData.regCodeRole}
        groupLogo={groupData.configuration.logo}
        isFanPermissionSet={
          groupData.groupPermission &&
          typeof groupData.groupPermission === "object"
        }
      />
    </div>
  );
};

export default OnboardOptions;
