import { collection, getDocs, query, where } from "@firebase/firestore";
import React from "react";
import { db } from "../../../services/firebaseService/connection";
import moment from "moment";
import { Table } from "antd";
import logger from "../../../utils/logger";

class StatusReport extends React.Component {
  state = {
    tableData: [],
  };
  render() {
    const columns = [
      {
        title: "Match Id",
        dataIndex: "matchId",
        key: "matchId",
        className: "header-white",
      },
      {
        title: "Away Team",
        dataIndex: "awayTeam",
        key: "awayTeam",
        className: "header-white",
        render: (data) => (data ? data.name : "-"),
      },
      {
        title: "Home Team",
        dataIndex: "homeTeam",
        key: "homeTeam",
        className: "header-white",
        render: (data) => (data ? data.name : "-"),
      },
      {
        title: "League Id",
        dataIndex: "lId",
        key: "lId",
        className: "header-white",
      },
      {
        title: "Failed Tasks",
        dataIndex: "report",
        key: "status",
        className: "header-white",
        render: (data) => {
          if (data && typeof data === "object") {
            let failedTasks = Object.keys(data).filter(
              (key) => data[key] === "fail"
            );
            return (
              <div>
                <ul>
                  {failedTasks.map((taskName) => {
                    return <li>{taskName}</li>;
                  })}
                </ul>
              </div>
            );
          }
        },
      },
    ];
    return (
      <div className="container p-5">
        <Table
          columns={columns}
          bordered
          dataSource={this.state.tableData}
          bodyStyle={{
            backgroundColor: "#ffffff",
          }}
          scroll={{ x: true }}
          pagination={{
            position: ["bottomLeft"],
            defaultPageSize: 50,
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 50, 100],
          }}
          className="table-backdrop mb-3"
        />
      </div>
    );
  }

  changeHeaderColor = () => {
    let elements = document.querySelectorAll("th");
    elements.forEach((elem) => {
      elem.style.backgroundColor = "#000000";
    });
  };

  fetchData = () => {
    let timestamp = moment().unix() * 1000 - 7 * 24 * 24 * 60 * 1000;
    let dbQuery = query(
      collection(db, "leaderBoardStatus"),
      where("timestamp", ">", timestamp.toString())
    );
    getDocs(dbQuery).then((snap) => {
      let tableData = [];
      for (let doc of snap.docs) {
        let data = doc.data();
        for (let key in data.matches) {
          if (key && !isNaN(Number(key))) {
            let matchData = data.matches[key];
            matchData["matchId"] = key;
            matchData["timestamp"] = data.timestamp || doc.id;
            tableData.push(matchData);
          }
        }
      }

      this.setState({ tableData });
    });
  };

  componentDidMount() {
    try {
      this.fetchData();
    } catch (e) {
      logger.error(e);
    }
    this.changeHeaderColor();
  }
}

export default StatusReport;
