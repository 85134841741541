import { Spin } from "antd";
import React from "react";
import { connect } from "react-redux";
import { db } from "../../../services/firebaseService/connection";
import { startUpdateUser } from "../../../redux/actions/user";
import { updateDoc, doc } from "@firebase/firestore";
import logger from "../../../utils/logger";

class AppSettings extends React.Component {
  state = {
    matchThreadsInGroupStream: true,
    enableGroupStream: true,
    showChatChannels: false,
    lockMemberSince: false,
    lockMemberName: false,
    enableMemberBarcode: false,
    loading: false,
    pageStatus: "loading",
  };

  handleCheckChange = (e) => {
    this.setState({
      [e.target.id]: e.target.checked,
    });
  };

  save = async () => {
    this.setState({
      loading: true,
    });
    let appSettings = { ...this.state };
    delete appSettings.loading;
    delete appSettings.pageStatus;
    await updateDoc(
      doc(db, "group", this.props.currentGroup),
      appSettings
    ).then(() => {
      this.setState({
        loading: false,
      });
    });
    this.props.dispatch(startUpdateUser());
  };

  handleInputChange = (e) => {
    this.setState({
      rewards: {
        ...this.state.rewards,
        [e.target.name]: e.target.value,
      },
    });
  };

  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <div style={{ width: "100%", height: "100%", padding: "30px 26px" }}>
        <ul className="nav nav-tabs">
          <li
            className="nav-item"
            style={{
              fontSize: 22,
              fontWeight: "bold",
              borderBottom: `4px solid ${primaryColor}`,
              color: "black",
            }}
          >
            <a>App</a>
          </li>
        </ul>
        <form
          className="mb-4 p-3 px-md-5 py-md-4 mx-md-auto"
          style={{
            maxWidth: "1400px",
            width: "100%",
            border: "1px solid #dee2e6",
            borderRadius: "0.25rem",
            marginTop: 30,
            backgroundColor: "#ffffff",
            // boxShadow: "0px 3px 10px 1px",
          }}
        >
          <div className="row">
            <span>
              Apply global app settings to be reflected for all members. These
              settings are, primarily, meant to be set for the life of the
              group.
            </span>
          </div>

          {/*chat settings */}
          <div className="row mt-4">
            <span style={{ fontSize: 20, fontWeight: "bold" }}> Chat </span>
          </div>
          {/* <div className='row'>
                        <div className='col-md-4'>
                            <div className='row'>
                                <div className='col-md-8'>
                                    Create match threads in Group Stream?
                                </div>
                                <div className='col-md-4'>
                                    <input
                                        style={{ height: "20px", width: "20px" }}
                                        type="checkbox"
                                        className="form-control-input cursor-pointer"
                                        id="matchThreadsInGroupStream"
                                        checked={this.state.matchThreadsInGroupStream}
                                        onChange={this.handleCheckChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8'>
                            Chant automatically creates a match thread in the Chat Stream before each match as a way to create a focal point for discussion.
                        </div>
                    </div> */}

          {/* <div className='row'>
                        <div className='col-md-4'>
                            <div className='row'>
                                <div className='col-md-8'>
                                    Enable Group Stream?
                                </div>
                                <div className='col-md-4'>
                                    <input
                                        style={{ height: "20px", width: "20px" }}
                                        type="checkbox"
                                        className="form-control-input cursor-pointer"
                                        id="enableGroupStream"
                                        checked={this.state.enableGroupStream}
                                        onChange={this.handleCheckChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8'>
                            Allows members to post in the Chat Stream for group-only. Groups that are part of a global community may choose to focus discussion in the Global Stream.
                        </div>
                    </div> */}

          <div className="row">
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-8">Enable Chat Channels?</div>
                <div className="col-md-4">
                  <input
                    style={{ height: "20px", width: "20px" }}
                    type="checkbox"
                    className="form-control-input cursor-pointer"
                    id="showChatChannels"
                    checked={this.state.showChatChannels}
                    onChange={this.handleCheckChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-8">
              Channels require in app setup by admin. Allows focused discussion
              by topic (ie. Slack channels). Typically used by larger groups
              with committees.
            </div>
          </div>

          {/*member since settings */}
          <div className="row mt-4">
            <span style={{ fontSize: 20, fontWeight: "bold" }}>
              {" "}
              Member Since{" "}
            </span>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-8">
                  Restrict members from editing member since year?
                </div>
                <div className="col-md-4">
                  <input
                    style={{ height: "20px", width: "20px" }}
                    type="checkbox"
                    className="form-control-input cursor-pointer"
                    id="lockMemberSince"
                    checked={this.state.lockMemberSince}
                    onChange={this.handleCheckChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-8">
              Restricting this field requires admin to set the ‘member since’
              year for each member. Members will see the year in their profile
              but will not be able to edit.
            </div>
          </div>

          <div className="row mt-4">
            <span style={{ fontSize: 20, fontWeight: "bold" }}>
              {" "}
              Member Name{" "}
            </span>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-8">
                  Restrict members from editing member name?
                </div>
                <div className="col-md-4">
                  <input
                    style={{ height: "20px", width: "20px" }}
                    type="checkbox"
                    className="form-control-input cursor-pointer"
                    id="lockMemberName"
                    checked={this.state.lockMemberName}
                    onChange={this.handleCheckChange}
                  />
                </div>
              </div>
            </div>
            {/* <div className='col-md-8'>
                            Restricting this field requires admin to set the ‘member since’ year for each member. Members will see the year in their profile but will not be able to edit.
                        </div> */}
          </div>

          {/*check-in settings */}
          {/* <div className='row mt-4'>
                        <span style={{ fontSize: 20, fontWeight: 'bold' }}> Check-in </span>
                    </div>

                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='row'>
                                <div className='col-md-8'>
                                    Enable member barcodes?
                                </div>
                                <div className='col-md-4'>
                                    <input
                                        style={{ height: "20px", width: "20px" }}
                                        type="checkbox"
                                        className="form-control-input cursor-pointer"
                                        id="enableMemberBarcode"
                                        checked={this.state.enableMemberBarcode}
                                        onChange={this.handleCheckChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8'>
                            Member barcodes allow groups to scan for event check-in. Barcodes appear under the membership card. Barcode scanner appears in the event cart (event must be created to enable event check-in).
                        </div>
                    </div> */}
        </form>

        <div className="my-row justify-content-center mt-4">
          <button
            className="btn btn-success default-text-color"
            style={{ backgroundColor: primaryColor, borderColor: primaryColor }}
            tabIndex="14"
            onClick={this.save}
          >
            {this.state.loading ? (
              <Spin size="large" spinning={true} />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (state.pageStatus === "loading") {
      let groupData = props.adminData.data;
      let appSettings = {
        matchThreadsInGroupStream: groupData.matchThreadsInGroupStream || true,
        enableGroupStream: groupData.enableGroupStream || true,
        showChatChannels: groupData.showChatChannels || false,
        lockMemberSince: groupData.lockMemberSince || false,
        enableMemberBarcode: groupData.enableMemberBarcode || false,
        lockMemberName: groupData.lockMemberName || false,
        rewards: {
          eventLeaderboard: (groupData.rewards || {}).eventLeaderboard || "",
          scoreLeaderboard: (groupData.rewards || {}).scoreLeaderboard || "",
          statsLeaderboard: (groupData.rewards || {}).statsLeaderboard || "",
        },
        loading: false,
      };
      logger.log("App Settings: ", appSettings);
      return { ...appSettings, pageStatus: "done" };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentGroup !== prevProps.currentGroup) {
      let groupData = this.props.adminData.data;
      let appSettings = {
        matchThreadsInGroupStream: groupData.matchThreadsInGroupStream || true,
        enableGroupStream: groupData.enableGroupStream || true,
        showChatChannels: groupData.showChatChannels || false,
        lockMemberSince: groupData.lockMemberSince || false,
        lockMemberName: groupData.lockMemberName || false,
        enableMemberBarcode: groupData.enableMemberBarcode || false,
        rewards: {
          eventLeaderboard: (groupData.rewards || {}).eventLeaderboard || "",
          scoreLeaderboard: (groupData.rewards || {}).scoreLeaderboard || "",
          statsLeaderboard: (groupData.rewards || {}).statsLeaderboard || "",
        },
        loading: false,
      };
      this.setState({ ...appSettings, pageStatus: "done" });
    }
  }
}

const mapStateToProps = (state) => {
  return {
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    currentGroup: state.adminData.currentGroup,
  };
};

export default connect(mapStateToProps)(AppSettings);
