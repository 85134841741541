import React from "react";
import { Spin } from "antd";
import "./IconContainer.css";

const IconContainer = ({ loading, icon, onClick }) => {
  return (
    <div
      className="icon-container"
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      {loading ? (
        <Spin style={{ fontSize: "20px" }} />
      ) : (
        <img src={icon} alt="icon" style={{ fontSize: "20px" }} />
      )}
    </div>
  );
};

export default IconContainer;
