import { Spin } from "antd";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../../utils/helper";

class OurChantsSettings extends React.Component {
  state = {
    isChantsPublished: false,
    joinLink: "",
    isSaving: false,
  };

  redirectToStadium = () => {
    this.props.router.navigate("/admin/stadium/setup");
  };

  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <div
        className="border rounded"
        style={{
          backgroundColor: "#ffffff",
          // boxShadow: "0px 3px 10px 1px",
          marginTop: 30,
          padding: 15,
        }}
      >
        <div className="col-md-9">
          <p style={{ fontSize: "20", fontWeight: "bold", marginBottom: 0 }}>
            Chants
          </p>
          <p>
            Publish your chants to a web page and share with fans around the
            stadium who are not part of your group (and not on the Chant app).
          </p>
          <p>
            Your capo can press/hold any chant from the app to send a
            notification and dynamically (in real-time) flip that chant card
            over to show the lyrics. This works for both the app and web.
          </p>
          <p>
            When the web page is accessed via a mobile device we also show a
            card with a link to ‘Join’ your group. Set the URL to point to your
            chant registration form or your group’s website.
          </p>
          <p>
            Chant reserves the right to add Chant and Sponsorship details to
            this page.
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              Web URL (when published):
            </span>
            {` https://group.chant.fan/${(this.props.adminData.data.groupName + "").toLowerCase().split(" ").join("")}/chants`}
          </p>
          {/* <div className='mt-5'>
                        <div className='row'>
                            <div className='col-md-4'>Publish your ‘Chants’ web page</div>
                            <Checkbox
                                checked={this.state.isChantsPublished}
                                onChange={(e) => {
                                    this.setState({
                                        isChantsPublished: e.target.checked
                                    })
                                }}
                                value="secondary"
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                tabIndex="2"
                            />
                        </div>
                        <FInput
                            label={"Add URL for ‘Join’ link"}
                            value={this.state.joinLink}
                            onChange={(e) => {
                                this.setState({
                                    joinLink: e.target.value
                                })
                            }}
                            name="joinLink"
                            display="row"
                            placeholder="Enter url of your registration form, group website or social"
                            tabIndex="2"
                        />
                    </div> */}
          <div className="align-items-center">
            <span style={{ fontWeight: "bold" }}>
              Setup in the Stadium section of the admin dashboard.
            </span>
          </div>
        </div>
        <div className="d-flex mt-5" style={{ justifyContent: "center" }}>
          <button
            style={{
              backgroundColor: primaryColor,
              borderRadius: 5,
              border: "0px none",
              boxShadow: "transparent 0px 0px 0px",
              outline: "none",
              padding: "5px 10px",
              width: "200px",
            }}
            className="default-text-color"
            // disabled={this.state.isSaving}
            onClick={this.redirectToStadium}
          >
            {this.state.isSaving ? (
              <Spin size="small" spinning={true} />
            ) : (
              "Stadium Setup"
            )}
          </button>
        </div>
      </div>
    );
  }

  componentDidMount() {
    let groupConfig = this.props.adminData.data.configuration;
    let isChantsPublished = groupConfig.isChantsPublished || false;
    let joinLink = groupConfig.joinLink || "";
    this.setState({
      isChantsPublished,
      joinLink,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentGroup !== this.props.currentGroup) {
      let groupConfig = this.props.adminData.data.configuration;
      let isChantsPublished = groupConfig.isChantsPublished;
      let joinLink = groupConfig.joinLink;
      this.setState({
        isChantsPublished,
        joinLink,
      });
    }
  }
}

const mapStateToProps = (state) => {
  return {
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    currentGroup: state.adminData.currentGroup,
  };
};

export default withRouter(
  connect(mapStateToProps)(withRouter(OurChantsSettings))
);
