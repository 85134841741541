import React from "react";
import FanPermission from "../../groupConfig/FanPermission";
import { useNavigate } from "react-router-dom";

const FanPermissions = () => {
  const navigate = useNavigate();

  const goToPath = (path) => {
    navigate(path, { replace: true });
  };

  return <FanPermission goToPath={goToPath} />;
};

export default FanPermissions;
