import { Table } from "antd";
import React from "react";
import { fbGetClubAdminMembers } from "../../../services/firebaseService/endPoints/clubAdmin/members";
import CustomDropDown from "../../commons/CustomDropDown";
import Search from "../../commons/Search";
import { continents, countries } from "countries-list";
import { connect } from "react-redux";
import { withRouter } from "../../../utils/helper";

let countryCodes = {};
Object.keys(countries).forEach((code) => {
  countryCodes[code] = countries[code].name;
});

class ListMembers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      filteredDataSource: [],
      groupSpecific: !!props.router.params.id,
    };
  }

  fetchMembers = () => {
    fbGetClubAdminMembers(
      this.state.groupSpecific ? this.props.router.params.id : null
    ).then((members) => {
      this.setState({
        dataSource: members || [],
        filteredDataSource: members || [],
      });
    });
  };

  handleSearchParamChange = (list) => {
    let dataSource = [];
    list.forEach((param) => {
      if (Object.values(continents).includes(param)) {
        param = Object.keys(continents).filter(
          (key) => continents[key] === param
        )[0];
      }
      if (Object.values(countryCodes).includes(param)) {
        param = Object.keys(countryCodes).filter(
          (key) => countryCodes[key] === param
        )[0];
      }
      dataSource = [
        ...dataSource,
        ...this.state.dataSource.filter(
          (member) =>
            (member.groupName.toLowerCase() === param.toLowerCase() ||
              member.name.toLowerCase() === param.toLowerCase() ||
              member.userRole.toLowerCase() === param.toLowerCase() ||
              member.country.toLowerCase() === param.toLowerCase() ||
              member.region.toLowerCase() === param.toLowerCase()) &&
            !dataSource.includes(member)
        ),
      ];
    });

    this.setState((prevState) => ({
      filteredDataSource: list.length > 0 ? dataSource : prevState.dataSource,
    }));
  };

  render() {
    const columns = [
      {
        title: "Role",
        width: 200,
        className: "header-white",
        render: (data, index) => {
          return (
            <CustomDropDown
              value={data.userRole}
              myName={this.props.myName}
              editMemberInLocalDb={this.props.editMemberInLocalDb}
              userName={data.name}
              userId={data.id}
              disabled
              groupData={this.props.groupData}
              name="userRole"
              className="custom-disabled"
              dataList={[
                "admin",
                "member",
                "ticket-scanner",
                "capo",
                "leader",
                "club-admin",
              ]}
              placeholder="--Select Role--"
              display="col"
              tabIndex="1"
              required
            />
          );
        },
      },
      {
        title: "Name",
        width: 200,
        key: "name",
        className: "header-white",
        dataIndex: "name",
        sorter: true,
        render: (name) => <span style={{ fontWeight: "bold" }}>{name}</span>,
      },
      {
        title: "Email",
        className: "header-white",
        key: "email",
        dataIndex: "email",
        render: (email) => <span style={{ fontWeight: "bold" }}>{email}</span>,
      },
      {
        title: "Group",
        width: 200,
        key: "groupName",
        className: "header-white",
        dataIndex: "groupName",
        render: (groupName) => (
          <span style={{ fontWeight: "bold" }}>{groupName}</span>
        ),
      },
      {
        title: "Member since",
        width: 200,
        key: "memberSince",
        className: "header-white",
        dataIndex: "memberSince",
        sorter: true,
        render: (memberSince) => (
          <span style={{ fontWeight: "bold" }}>{memberSince}</span>
        ),
      },
      {
        title: "Polls",
        className: "header-white",
        width: window.screen.width > 500 ? 80 : 30,
        render: () => (
          // <Tag color='#ffcccc'>Lock</Tag>
          <></>
        ),
      },
      {
        title: "Giveaways",
        className: "header-white",
        render: () => (
          // <Tag color='#ffcccc'>Lock</Tag>
          <></>
        ),
      },
      {
        title: "POM",
        className: "header-white",
        width: window.screen.width > 500 ? 80 : 30,
        render: () => (
          // <Tag color='#ffcccc'>Lock</Tag>
          <></>
        ),
      },
      {
        title: "Predictions",
        className: "header-white",
        render: () => (
          // <Tag color='#ffcccc'>Lock</Tag>
          <></>
        ),
      },
      {
        title: "VAR",
        className: "header-white",
        width: window.screen.width > 500 ? 80 : 30,
        render: () => (
          // <Tag color='#ffcccc'>Lock</Tag>
          <></>
        ),
      },
      {
        title: "Cards",
        className: "header-white",
        width: window.screen.width > 500 ? 80 : 30,
        render: () => (
          // <Tag color='#ffcccc'>Lock</Tag>
          <></>
        ),
      },
    ];

    let primaryColor = "";
    if (
      this.props.groupData.data.configuration &&
      this.props.groupData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.groupData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    return (
      <>
        <div
          className="mx-auto"
          style={{
            width: "95%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              paddingTop: 30,
            }}
          >
            <div className="">
              <ul
                className="nav nav-tabs"
                style={{
                  fontSize: "15px",
                  color: "black",
                  flexWrap: "nowrap",
                  whiteSpace: "nowrap",
                }}
              >
                <li
                  className="nav-item"
                  onClick={this.changeTab}
                  style={{
                    borderBottom: `4px solid ${primaryColor}`,
                  }}
                >
                  <a
                    name="all"
                    style={{
                      height: "100%",
                      fontWeight: "bold",
                      fontSize: "22px",
                    }}
                  >
                    Members
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row" style={{ width: "100%" }}>
            <div
              style={{
                backgroundColor: "white",
                padding: "12px",
                marginTop: "12px",
              }}
            >
              <Search
                options={[
                  ...Array.from(
                    new Set(this.state.dataSource.map((row) => row.groupName))
                  ),
                  ...Array.from(
                    new Set(this.state.dataSource.map((row) => row.name))
                  ),
                  ...Array.from(
                    new Set(this.state.dataSource.map((row) => row.userRole))
                  ),
                  ...Array.from(
                    new Set(this.state.dataSource.map((row) => row.country))
                  ).map((code) => countryCodes[code]),
                  ...Array.from(
                    new Set(this.state.dataSource.map((row) => row.region))
                  ).map((code) => continents[code]),
                ].filter((option) => option !== undefined && option !== null)}
                onSearchParamsChange={this.handleSearchParamChange}
                placeholder="Search by group name/ name/ role/ country/ region"
              />
            </div>
          </div>
          <div style={{ width: "100%", overflow: "scroll" }}>
            <Table
              columns={columns}
              dataSource={this.state.filteredDataSource}
              scroll={{ x: "max-content" }}
              bordered
              className={`table-backdrop mt-3 mb-3`}
              bodyStyle={{
                fontSize: window.screen.width > 500 ? "18px" : "12px",
                backgroundColor: "#ffffff",
                // boxShadow: "0px 3px 10px 1px",
              }}
            />
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    this.fetchMembers();
  }
}

const mapStateToProps = (state) => {
  return {
    adminData: state.adminData,
    groupData: state.adminData.allGroups[state.adminData.currentGroup],
    user: state.user,
  };
};

export default connect(mapStateToProps)(withRouter(ListMembers));
