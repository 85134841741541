import useSideNavLinks from "./useSideNavLinks";

const useSideNav = () => {
  const { adminLinks, clubAdminLinks } = useSideNavLinks();

  const getStyles = (groupData) => {
    let backgroundColor = "#ff4040";
    let color = "#ffffff";
    let style = null;

    const primaryColor = groupData?.configuration?.primaryColor;

    if (primaryColor) {
      const { r, g, b } = primaryColor;
      backgroundColor = `rgb(${r}, ${g}, ${b})`;

      const isWhite = [r, g, b].every((value) => value === 255);
      if (isWhite) {
        color = `rgb(0, 0, 0)`;
        style = {
          boxShadow: "0px 1px 3px 0px",
        };
      }
    }

    return { backgroundColor, color, style };
  };

  return { getStyles, adminLinks, clubAdminLinks };
};

export default useSideNav;
