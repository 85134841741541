import React, { Component } from "react";
import { FSelect } from "../../commons/formFields/FSelect";
import { FInput } from "../../commons/formFields/FInput";
import moment from "moment";

export default class DirectoryGeneral extends Component {
  render() {
    const { general, handleOptionChange, handleInputChange } = this.props;

    return (
      <div>
        <div className="row mt-4">
          <div className="col">
            <h5 style={{ fontWeight: "bold" }}>General</h5>
          </div>
        </div>
        <div style={{ marginLeft: "16px" }}>
          <div className="row">
            <div className="form-group col-md-6">
              <FSelect
                flex
                label="Status with Club"
                name="general.clubStatus"
                display="row"
                tabIndex="2"
                value={general.clubStatus}
                onChange={handleOptionChange}
                placeholder={"Select"}
                dataList={["Official", "Unofficial"]}
                dataOnlyValue={true}
              />
            </div>
            <div className="col-md-6">
              <p style={{ fontSize: "14px", width: "400px" }}>
                Select if club recognizes 'Official' groups
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row" style={{ flexWrap: "wrap" }}>
              <div className="form-group col-md-12">
                <FSelect
                  flex
                  label="Group Size"
                  name="general.groupSize"
                  display="row"
                  tabIndex="2"
                  value={general.groupSize}
                  onChange={handleOptionChange}
                  placeholder={"Select"}
                  dataList={["1-99", "100-249", "250-499", "500-999", "1000+"]}
                  dataOnlyValue={true}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="form-group col-md-12">
                <FSelect
                  flex
                  label="Founded"
                  name="general.founded"
                  display="row"
                  tabIndex="2"
                  value={general.founded}
                  onChange={handleOptionChange}
                  placeholder={"Select"}
                  dataList={Array.from(
                    { length: moment().year() - 1949 },
                    (_, index) => (1950 + index).toString()
                  ).reverse()}
                  dataOnlyValue={true}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <FInput
                label="Membership Fee?"
                name="general.membershipFee"
                display="row"
                placeholder="Example: $25 or Free"
                className="placeholder-regular"
                maxLength={4}
                onChange={handleInputChange}
                value={general.membershipFee}
              />
            </div>
            <div className="col-md-6">
              <p style={{ fontSize: "14px", width: "400px" }}>
                Include the currency symbol if adding a membership fee
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
