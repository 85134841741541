import React from "react";
import { FInput } from "../../commons/formFields/FInput";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Input, Checkbox } from "antd";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { connect } from "react-redux";
import {
  fbCreateChant,
  fbUpdateChant,
  fbGetChant,
} from "../../../services/firebaseService/endPoints/admin/ourChants";
import LoadingModal from "../../commons/LoadingModal";
import "./ourChants.css";
import { applicationError } from "../../../redux/actions/error";
import { withRouter } from "../../../utils/helper";
import { postUpdateCDNUtil } from "../../../helperFunctions/util";

class CreateChant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lyrics: "",
      noteEditorState: EditorState.createEmpty(),
      title: "",
      videoLink: "",
      isLoading: true,
      editMode: false,
      checked: false,
    };
  }

  onEditorStateChange = (editorState, name) => {
    this.setState({
      [name]: editorState,
      // html: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    });
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.checked === true) {
      const result = {
        title: this.state.title,
        //   lyrics: draftToHtml(
        //     convertToRaw(this.state.lyricsEditorState.getCurrentContent())
        //   ),
        lyrics: this.state.lyrics,
        specialNote: draftToHtml(
          convertToRaw(this.state.noteEditorState.getCurrentContent())
        ),
        videoLink: this.state.videoLink,
      };

      this.setState({ isLoading: true });
      let promise;

      if (this.state.editMode) {
        promise = fbUpdateChant(
          this.props.currentGroup,
          this.props.router.params.id,
          result
        );
      } else {
        promise = fbCreateChant(this.props.currentGroup, result);
      }

      promise.then(() => {
        this.setState({ isLoading: false });
        this.props.router.navigate("/admin/chants", { replace: true });
      });

      postUpdateCDNUtil(this.props.currentGroup);
    } else {
      alert("Accept the Terms & Conditions");
    }
  };

  handleChange = (event) => {
    this.setState({
      checked: event.target.checked,
    });
  };

  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <div className="col">
        <div style={{ padding: "30px 16px" }}>
          <ul className="nav nav-tabs">
            <li
              className="nav-item"
              style={{
                fontSize: 22,
                fontWeight: "bold",
                borderBottom: `4px solid ${primaryColor}`,
                color: "#ffffff",
              }}
            >
              Create Chant
              <a></a>
            </li>
          </ul>
          <LoadingModal loading={this.state.isLoading} />
          <div className="md-5 p-2 border-box">
            <form
              onSubmit={this.handleSubmit}
              className="border rounded mt-4 p-3 px-3"
              style={{
                backgroundColor: "#ffffff",
                // boxShadow: "0px 3px 10px 1px",
              }}
            >
              <div className="col-md-6" style={{ paddingBottom: "10px" }}>
                <FInput
                  label="Title"
                  name="title"
                  display="row"
                  placeholder="Enter Title"
                  onChange={this.handleInputChange}
                  value={this.state.title}
                  required
                />
              </div>

              {/* <div className="col">
              <div className="form-group col">
                <div>
                  <label>Lyrics</label>
                </div> */}
              {/* <Editor
                  editorState={this.state.lyricsEditorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="border rounded p-3 mt-0"
                  editorClassName="editorClassName"
                  editorStyle={{ minHeight: "200px" }}
                  placeholder={`Enter Lyrics`}
                  onEditorStateChange={editorState => {
                    this.onEditorStateChange(editorState, "lyricsEditorState");
                  }}
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "list",
                      "colorPicker",
                      "link"
                    ],
                    inline: {
                      options: ["bold", "italic", "underline", "strikethrough"]
                    },
                    blockType: {
                      options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"]
                    },
                    link: {
                      defaultTargetOption: "_blank",
                      dropdownClassName: "custom-link-dropdown"
                    }
                  }}
                  required
                  stripPastedStyles={true}
                /> */}

              <div className="col">
                <div
                  style={{ display: "flex", flexWrap: "wrap" }}
                  className="form-group"
                >
                  <label className="col-md-2">Lyrics</label>
                  {/* <input className="form-control form-control-sm" placeholder="Join us for a season kickoff party at Fado!"></input> */}
                  <div className="col-md-10" style={{ marginLeft: "-4px" }}>
                    <Input.TextArea
                      //   tabIndex="4"
                      style={{ fontSize: `18px` }}
                      placeholder="Enter Lyrics"
                      value={this.state.lyrics}
                      name="lyrics"
                      onChange={this.handleInputChange}
                      rows={8}
                      //   autosize={true}
                    />
                  </div>
                </div>
              </div>

              <div className="col">
                <div
                  style={{ display: "flex", flexWrap: "wrap" }}
                  className="form-group"
                >
                  <div className="col-md-2">
                    <label>Special Note</label>
                  </div>
                  <div style={{ marginLeft: "-4px" }}>
                    <Editor
                      editorState={this.state.noteEditorState}
                      // toolbarClassName="toolbarClassName"
                      wrapperClassName="border rounded p-3 mt-0 flex-1"
                      editorClassName="height-fit-content"
                      placeholder={`Enter special note`}
                      onEditorStateChange={(editorState) => {
                        this.onEditorStateChange(
                          editorState,
                          "noteEditorState"
                        );
                      }}
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "list",
                          "colorPicker",
                          "link",
                        ],
                        inline: {
                          options: [
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                          ],
                        },
                        blockType: {
                          options: [
                            "Normal",
                            "H1",
                            "H2",
                            "H3",
                            "H4",
                            "H5",
                            "H6",
                          ],
                        },
                        link: { defaultTargetOption: "_blank" },
                      }}
                      stripPastedStyles={true}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6" style={{ paddingBottom: "10px" }}>
                <FInput
                  label="Video Link"
                  name="videoLink"
                  display="row"
                  placeholder="Enter YouTube link"
                  onChange={this.handleInputChange}
                  value={this.state.videoLink}
                />
              </div>

              <div
                className="col"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                }}
              >
                <Checkbox
                  style={{ marginLeft: "18px", marginBottom: "16px" }}
                  checked={this.state.checked}
                  onChange={this.handleChange}
                  value="secondary"
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <span
                  style={{
                    marginLeft: "15px",
                    fontsize: "50px",
                    color: "#FF0000",
                  }}
                >
                  *{" "}
                </span>
                <p style={{ marginLeft: "5px" }}>
                  I confirm that this chant does not violate the code of conduct
                  of my club, the league or the terms of Chant. This chant is in
                  no way racist, homophobic, misogynistic, bigoted or otherwise
                  aggressive.
                </p>
              </div>

              <div className="d-flex justify-content-center mt-2">
                {this.state.checked === true && this.state.title !== "" && (
                  <button
                    className="btn btn-success"
                    tabIndex="14"
                    type="success"
                    style={{ backgroundColor: primaryColor }}
                  >
                    Submit
                  </button>
                )}
                {(this.state.checked === false || this.state.title === "") && (
                  <button
                    className="btn btn-secondary"
                    tabIndex="14"
                    type="success"
                    style={{ backgroundColor: primaryColor }}
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  handleEditMode = () => {
    fbGetChant(
      this.props.router.params.group,
      this.props.router.params.id
    ).then((data) => {
      // TODO: current group issues on refresh
      if (data) {
        //   const contentBlockLyrics = htmlToDraft(data.lyrics);
        const contentBlockNote = htmlToDraft(data.specialNote);

        let result = {};
        //   if (contentBlockLyrics) {
        //     const contentState = ContentState.createFromBlockArray(
        //       contentBlockLyrics.contentBlocks
        //     );
        //     result.lyricsEditorState = EditorState.createWithContent(
        //       contentState
        //     );
        //   }

        if (contentBlockNote) {
          const contentState = ContentState.createFromBlockArray(
            contentBlockNote.contentBlocks
          );
          result.noteEditorState = EditorState.createWithContent(contentState);
        }

        this.setState({
          title: data.title,
          lyrics: data.lyrics,
          videoLink: data.videoLink,
          isLoading: false,
          editMode: true,
          ...result,
        });
      } else {
        this.props.dispatch(applicationError({ err: {}, errorCode: "404" }));
        return;
      }
    });
  };

  componentDidMount() {
    if (this.props.router.params.id) {
      if (this.props.currentGroup === this.props.router.params.group) {
        this.handleEditMode();
      }
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.currentGroup !== prevProps.currentGroup &&
      this.props.router.params.id &&
      this.state.isLoading
    ) {
      this.handleEditMode();
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentGroup: state.adminData.currentGroup,
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
  };
};

export default connect(mapStateToProps)(withRouter(CreateChant));
