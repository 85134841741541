import React from "react";

const NAV_ITEMS = [
  // {
  //     label: "Home",
  //     name: "home",
  //     comment: "Anchors to top of page (as does your logo)."
  // },
  {
    label: "News",
    name: "news",
    comment:
      "Appears just below ‘welcome’ text near top of site. If checked, ensure you have news or will show ‘No current news.’",
  },
  {
    label: "Events",
    name: "events",
    comment:
      "Recommended. Populate your events for the season and section will remain populated with next x events.",
  },
  {
    label: "Where we meet",
    name: "whereWeMeet",
    comment: "",
  },
  {
    label: "About",
    name: "about",
    comment: "Recommended. Anchors to top of ‘About’ section.",
  },
  {
    label: "Tickets",
    name: "ticket",
    comment: "",
  },
  // {
  //     label: "Code of Conduct",
  //     name: "codeOfConduct",
  //     comment: "Might not be needed in navigation if you anchor to ‘About’ as visitors can scroll to view."
  // },
  {
    label: "Membership",
    name: "membership",
    comment:
      "Recommended. Can show an invitation to join you at the next event or a full member registration form.",
  },
  {
    label: "Leadership",
    name: "leadership",
    comment: "",
  },
  // {
  //     label: "Contact",
  //     name: "contact",
  //     comment: ""
  // },
  {
    label: "Community",
    name: "community",
    comment: "",
  },
  {
    label: "Store",
    name: "store",
    comment:
      "Recommended if you will have merchandise in your store throughout the season.",
  },
  {
    label: "Gallery",
    name: "gallery",
    comment: "",
  },
];

class NavigationSetup extends React.Component {
  state = {
    home: false,
    news: false,
    events: false,
    whereWeMeet: false,
    about: false,
    mission: false,
    codeOfConduct: false,
    membership: false,
    leadership: false,
    contact: false,
    community: false,
    gallery: false,
    ticket: false,
  };

  render() {
    return (
      <div className="mt-5" style={{ width: "100%", padding: "0px 15px" }}>
        <div className="row">
          <span style={{ fontSize: "20", fontWeight: "bold", marginBottom: 0 }}>
            Navigation
          </span>
        </div>
        <div className="row">
          <p style={{ fontSize: 15 }}>
            Select up to 8 navigation items. Sections do not need navigation in
            header for content to appear in the site. For example, showing
            ‘About’ in navigation will anchor to the beginning of that section
            where visitor can then scroll to see Mission, Membership and
            Leadership content.
          </p>
        </div>
        {NAV_ITEMS.map(({ label, name, comment }) => {
          if (window.innerWidth <= 600) {
            return (
              <div style={{ margin: "0 auto", width: "100%", maxWidth: "90%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "1rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: "17px", flexGrow: 1 }}>
                      {label}
                    </span>
                    <input
                      type="checkbox"
                      name={name}
                      checked={this.state[name]}
                      style={{
                        height: "20px",
                        width: "20px",
                        borderRadius: "5px",
                      }}
                      onChange={(e) => {
                        let count = Object.values(this.state).filter(
                          (element) => element
                        ).length;
                        if (count < 8 || !e.target.checked) {
                          this.setState({
                            [e.target.name]: e.target.checked,
                          });
                        } else {
                          alert("Can't select more than 8!");
                        }
                      }}
                    />
                  </div>
                  <div>
                    <span style={{ fontSize: "14px", color: "#676767" }}>
                      {comment}
                    </span>
                  </div>
                </div>
              </div>
            );
          }
          return (
            <div style={{ margin: "0 auto", width: "90%" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <div
                  style={{
                    width: "20%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "6px",
                  }}
                >
                  <span style={{ fontSize: "17px" }}>{label}</span>
                  <input
                    type="checkbox"
                    name={name}
                    checked={this.state[name]}
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "5px",
                    }}
                    onChange={(e) => {
                      let count = Object.values(this.state).filter(
                        (element) => element
                      ).length;
                      if (count < 8 || !e.target.checked) {
                        this.setState({
                          [e.target.name]: e.target.checked,
                        });
                      } else {
                        alert("Can't select more than 8!");
                      }
                    }}
                  />
                </div>
                <div style={{ marginLeft: "2rem" }}>
                  <span style={{ fontSize: "14px", color: "#676767" }}>
                    {comment}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  static getDerivedStateFromProps(props) {
    let websiteConfig = props.adminData.data.websiteConfig;
    if (props.updated) {
      if (websiteConfig && websiteConfig.navigation) {
        return websiteConfig.navigation;
      }
      return {
        home: false,
        news: false,
        events: false,
        whereWeMeet: false,
        about: false,
        mission: false,
        codeOfConduct: false,
        membership: false,
        leadership: false,
        contact: false,
        community: false,
        gallery: false,
        ticket: false,
      };
    }
    return null;
  }

  componentDidUpdate() {
    if (this.props.save && !this.props.isUpdated) {
      this.props.onSave({
        navigation: { ...this.state },
      });
    }
  }
}

export default NavigationSetup;
