// 3rd party design libraries
import {
  Alert,
  DatePicker,
  Input,
  TimePicker,
  Checkbox,
  Popconfirm,
  Tooltip,
} from "antd";

// api end points
import Axios from "../../../services/apiFootballService/connection";
//
import { FInput } from "../../commons/formFields/FInput";
import { FSelect } from "../../commons/formFields/FSelect";
// common components
import LoadingModal from "../../commons/LoadingModal";
import React from "react";
import Ticket from "../../../models/admin/Ticket";
import axios from "axios";
import { connect } from "react-redux";
// import { fbCheckChatChannelName } from "../../../services/firebaseService/endPoints/admin/content";
import { fbGetGroupPaymentDetails } from "../../../services/firebaseService/endPoints/admin/groupConfig";
import {
  fbGetTickets,
  uploadImage,
} from "../../../services/firebaseService/endPoints/admin/tickets";
// 3rd party helper libraries
import { db, storage } from "../../../services/firebaseService/connection";
import FUpload from "../../commons/formFields/FUpload";
import { getCurrencyUnicode } from "../../../helperFunctions/util";
import { getMetadata, ref } from "firebase/storage";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { getLocations } from "../../../services/firebaseService/endPoints/admin/content";
import { verifyStripeAccountId } from "../../../utils/errors";
import { withRouter } from "../../../utils/helper";
import dayjs, { utc } from "dayjs";

// constants
const timeFormat = "HH:mm";
const options = [
  {
    value: "on submit",
    label: "On Submit",
  },
  {
    value: "day of event",
    label: "Day of Event",
  },
  {
    value: "1 day before event",
    label: "1 day before event",
  },
];
for (let i = 2; i <= 28; i++) {
  options.push({
    value: `${i} day before event`,
    label: `${i} day's before event`,
  });
}

const nameEmailOptions = [
  "Not required",
  "Require name",
  "Require name & email",
];

const ticketDetailsTemplate = {
  name: "",
  quantity: "",
  memberPrice: "",
  nonMemberPrice: "",
  limitPerPerson: "",
  soldTickets: 0,
  ticketLimit: [],
  noOfCheckins: 0,
  subTicketType: "",
  generateQRCode: null,
  requireNameEmail: "",
};

dayjs.extend(utc);

class CreateTicket extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "",
      location: {},
      matchId: "",
      title: "",
      eventDate: null,
      saleEndDate: null,
      eventDescription: "",
      lastDownloadOn: 0,
      checkMatchRequired: true,
      previewVisible: false,
      previewImage: "",
      section: "",
      quantity: null,
      // generateBarCode: null,
      showRequireMembers: true,
      price: null,
      limit: null,
      passLimit: null,
      email: false,
      barcode: false,
      requireMember: false,
      stripeAddress: "",
      notifyOnPublish: true,
      errorInForm: {
        isError: false,
        visible: false,
        message: "",
      },
      noOfCheckins: 0,
      soldTickets: 0,
      loadingDocument: !!props.router.params.id,
      editMode: !!props.router.params.id,
      editConfiguration: {
        imageChanged: false,
      },

      ticketTypes: [
        "Group Match",
        "Bus",
        "Tailgate",
        "Special Event",
        "Parking",
        "Screening",
        // "Tailgate Season Pass"
      ],
      processingFeeTypes: [
        "Group pays (Fees absorbed by group)",
        "Member pays (Fees added to total)",
        "Ask Member to pay (Request member pay fee but not required)",
      ],
      ticketLimit: [],
      matchList: [],
      acceptedTerms: false,
      match: null,
      clubId: props.clubId,
      groupId: props.currentGroup,
      isLoading: false,
      loadingMatches: true,
      ticketId: props.router.params.id,
      eventName: "",
      oldTicketsCount: 0,

      row: "TBD",
      seats: "TBD",
      eventCode: "abcdef",
      collectedAmount: 0,
      soldTickets: 0,
      remainingTickets: 0,
      broughtBy: [],
      errorQuantity: false,
      imageFile: undefined,
      imageList: [],
      editConfiguration: {
        imageChanged: false,
      },
      tickets: [JSON.parse(JSON.stringify(ticketDetailsTemplate))],
      showForFans: false,
      showOnWeb: false,
      showForMembers: true,
      processingFee: "",
      groupTerms: "",
      receiptDescription: "",
      allLocations: [],
      ticketDeliveryMethodApp: "",
      ticketDeliveryMethodWeb: "",
      contactEmail: "",
      refundTerms: "",
      ticketLink: "",
    };
  }

  uploadAction = (file) => {
    this.setState({
      imageFile: file,
    });
  };

  handleImageChange = ({ fileList }) => {
    let result = {};
    if (fileList.length === 0) {
      result.imageFile = {};
    } else {
      result.imageFile = fileList[0].originFileObj;
    }

    if (this.state.editMode && !this.state.editConfiguration.imageChanged) {
      this.setState((prevState) => {
        let editConfiguration = Object.assign({}, prevState.editConfiguration);
        editConfiguration.imageChanged = true;
        return {
          editConfiguration,
        };
      });
    }

    result.imageList = fileList;

    this.setState(() => result);
  };

  handleSubmit = async (e, publishStatus) => {
    e.preventDefault();

    if (this.state.acceptedTerms === true) {
      const totalPrice = this.state?.tickets?.reduce((acc, curr) => {
        return (acc =
          acc + Number(curr?.memberPrice) + Number(curr?.nonMemberPrice));
      }, 0);
      if (
        totalPrice > 0 &&
        !verifyStripeAccountId(
          this.props?.adminData?.data?.paymentDetails?.stripeAccountId
        )
      ) {
        return;
      }

      this.setState({
        isLoading: true,
      });

      if (!this?.state?.matchId || this?.state?.matchId?.length === 0) {
        if (
          !(
            (Array.isArray(this.state.imageList) &&
              this.state.imageList.length !== 0) ||
            this.state.imageFile !== undefined
          )
        ) {
          window.scrollTo(0, 0);
          this.setState({
            errorInForm: {
              isError: true,
              visible: true,
              message: "Image is required",
            },
            isLoading: false,
          });
          window.scrollTo(0, 0);
          setTimeout(this.handleErrorMessageClose, 5000);
          return;
        }
      }

      let data = new Ticket();
      data.toDataSet(
        {
          ...this.state,
          eventDate: dayjs(this.state.eventDate).unix(),
          eventTime: dayjs(this.state.eventDate).unix(),
          saleEndDate: dayjs(this.state.saleEndDate).unix(),
          saleEndTime: dayjs(this.state.saleEndDate).unix(),
        },
        publishStatus || "saved"
      );

      data = JSON.parse(JSON.stringify(data));

      if (data.hasError) {
        window.scrollTo(0, 0);
        this.setState({
          errorInForm: {
            isError: true,
            visible: true,
            message: data.hasError.message,
          },
          isLoading: false,
        });
        window.scrollTo(0, 0);
        setTimeout(this.handleErrorMessageClose, 5000);
      } else {
        this.setState({
          isLoading: true,
          loadingPercentage: 10,
        });

        if (!this.state.editMode) {
          data.groupTicket.addedOn = Math.round(new Date().getTime() / 1000);

          if (
            ["Tailgate Season Pass", "Special Event"].includes(
              this.state.type
            ) &&
            !this.state.matchId
          ) {
            const { downloadUrl } = await uploadImage(
              this.state.imageFile,
              data.match.groupId
            );
            data.groupTicket.image = downloadUrl;
          }
          let obj = {
            groupTicket: data.groupTicket,
            match: data.match,
            ticketType: data.groupTicket.ticketType,
            groupId: data.match.groupId,
            notifyOnPublish: this.state.notifyOnPublish,
          };

          axios({
            method: "post",
            url: "https://us-central1-chant2019.cloudfunctions.net/addNewGroupTicket",
            data: obj,
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((res) => {
              // this.setState({
              //   loading: false
              // });
              const that = this;
              setTimeout(function () {
                that.props.router.navigate("/admin/tickets/all", {
                  replace: true,
                });
              }, 5000);
            })
            .catch((err) => {
              this.setState({
                errorInForm: {
                  isError: true,
                  visible: true,
                  message: err,
                },
                isLoading: false,
              });
            });
        } else {
          //update

          let allTicketsValid = this.state.tickets.every(
            (ticket) => ticket.quantity >= ticket.soldTickets
          );

          if (allTicketsValid) {
            let obj = {
              groupTicket: data.groupTicket,
              match: data.match,
              ticketId: data.ticketId,
              groupId: data.match.groupId,
              oldTicketsCount: this.state.oldTicketsCount,
              notifyOnPublish: this.state.notifyOnPublish,
            };
            if (
              ["Tailgate Season Pass", "Special Event"].includes(
                this.state.type
              ) &&
              !this.state.matchId
            ) {
              if (this.state.editConfiguration.imageChanged) {
                const { downloadUrl } = await uploadImage(
                  this.state.imageFile,
                  data.match.groupId,
                  this.state.oldImageName ? this.state.oldImageName : undefined
                );
                data.groupTicket.image = downloadUrl;
              } else {
                data.groupTicket.image = this.state.imageList[0].url;
              }
            }

            axios({
              method: "post",
              url: "https://us-central1-chant2019.cloudfunctions.net/editSingleGroupTicket",
              data: obj,
              headers: {
                "Content-Type": "application/json",
              },
            })
              .then((res) => {
                // this.setState({
                //   loading: false
                // });
                const that = this;
                setTimeout(function () {
                  that.props.router.navigate("/admin/tickets/all", {
                    replace: true,
                  });
                }, 5000);
              })
              .catch((err) => {
                this.setState({
                  errorInForm: {
                    isError: true,
                    visible: true,
                    message: err,
                  },
                  isLoading: false,
                });
              });
          } else {
            window.scrollTo(0, 0);
            this.setState({
              errorInForm: {
                isError: true,
                visible: true,
                message: `Quantity can not be set below the number of tickets already sold`,
              },
              isLoading: false,
            });
            window.scrollTo(0, 0);
            setTimeout(this.handleErrorMessageClose, 5000);
          }
        }
      }
    } else {
      alert("Accept the Terms & Conditions");
    }
  };

  getPaymentDetails() {
    fbGetGroupPaymentDetails(this.props.currentGroup).then((paymentDetails) => {
      if (paymentDetails) {
        this.setState({
          stripeAddress: paymentDetails.stripeAccountId,
        });
      } else {
        this.setState({
          stripeAddress: "",
        });
      }
    });
  }
  updateLimitDropdown = (value) => {
    let obj = [];
    if (this.state.type === "Tailgate Season Pass") {
      obj = Array.from(Array(50), (x, index) => index + 1);
    } else if (value > 0) {
      if (value > 20) {
        obj = Array.from(Array(20), (x, index) => index + 1);
      } else {
        let size = Number(value);
        obj = Array.from(Array(size), (x, index) => index + 1);
      }
    }
    obj.unshift(0);
    this.setState({
      ticketLimit: obj,
    });
  };

  handleInputChange = (e) => {
    if (e.target.name === "quantity") {
      this.updateLimitDropdown(e.target.value);
    }
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  // Called on form select options changes
  handleOptionChange = (e) => {
    if (e.target.name === "matchId") {
      this.getMatch(e.target.value);
    }

    let value = null;
    value = e.target.value;
    if (e.target.name === "limit") {
      if (e.target.value === "NA") {
        value = 0;
      }
    }

    if (
      e.target.name === "requireMember" ||
      e.target.name === "email" ||
      e.target.name === "barcode"
    ) {
      if (String(e.target.value) === "false") {
        this.setState({
          [e.target.name]: false,
        });
      } else {
        this.setState({
          [e.target.name]: true,
        });
      }
    } else {
      this.setState({
        [e.target.name]: value,
      });
    }

    if (e.target.name === "type") {
      if (e.target.value === "Bus" || e.target.value === "Tailgate") {
        this.setState({
          checkMatchRequired: true,
          // generateBarCode: true
        });
      } else if (
        e.target.value === "Group Match" ||
        e.target.value === "Parking"
      ) {
        this.setState({
          checkMatchRequired: true,
          // generateBarCode: false,
          email: true,
        });
      } else if (e.target.value === "Tailgate Season Pass") {
        this.setState({
          showRequireMembers: false,
          checkMatchRequired: false,
          // generateBarCode: true
        });
      } else {
        this.setState({
          checkMatchRequired: false,
          // generateBarCode: true
        });
      }

      if (
        e.target.value === "Tailgate Season Pass" ||
        e.target.value === "Special Events"
      ) {
        this.syncSpecialTicketCount();
      }
    }
  };

  handleDateChange = (name, value) => {
    if (!Array.isArray(value)) {
      value = dayjs.isDayjs(value)
        ? value
        : dayjs(value).isValid()
          ? dayjs(value)
          : "";
    }

    if (name === "eventDate" && !value) {
      this.setState({
        eventDate: null,
      });
    } else if (name.includes("Time")) {
      let date = this.state[name.replace("Time", "Date")];
      if (date) {
        let currentHours = 0;
        let currentMinutes = 0;
        let seconds = 0;

        if (value) {
          if (dayjs.isDayjs(value)) {
            currentHours = value.hour();
            currentMinutes = value.minute();
          } else if (
            typeof value === "object" &&
            value.hours &&
            value.minutes
          ) {
            currentHours = value.hours();
            currentMinutes = value.minutes();
          }
        }

        let time = date
          .set("hour", currentHours)
          .set("minute", currentMinutes)
          .set("second", seconds);

        if (
          name === "saleEndDate" ||
          name.replace("Time", "Date") === "saleEndDate"
        ) {
          if (
            this.state.eventDate &&
            time.isAfter(this.state.eventDate, "day")
          ) {
            alert("Sale end date cannot be after Event date.");
            return;
          }
        }
        this.setState({
          [name.replace("Time", "Date")]: time,
        });
      }
    } else if (name === "showOnHomePage") {
      let showOnHomePageStart = "",
        showOnHomePageEnd = "";
      if (value.length === 2) {
        showOnHomePageStart = dayjs(value[0]).startOf("day").unix();
        showOnHomePageEnd = dayjs(value[1]).endOf("day").unix();
      }
      this.setState({
        showOnHomePageStart,
        showOnHomePageEnd,
      });
    } else {
      if (value) {
        this.setState({
          [name]: value,
        });
      } else {
        this.setState({
          [name]: "",
        });
      }
    }
  };

  handleErrorMessageClose = () => {
    this.setState((prevState) => {
      const errorInForm = {
        ...prevState.errorInForm,
      };
      errorInForm.visible = false;
      return {
        errorInForm,
      };
    });
  };

  checkError = () => {
    if (document.getElementById("quantity_error")) {
      if (this.state.quantity < this.state.soldTickets) {
        document.getElementById("quantity_error").style.visibility = "visible";
        if (this.state.quantity !== null) {
          this.setState({
            quantity: null,
          });
        }
      } else {
        document.getElementById("quantity_error").style.visibility = "hidden";
      }
    }
  };

  verifiedChange = (e) => {
    this.setState({
      acceptedTerms: e.target.checked,
    });
  };

  handleTicketDetailInputChange = (e, index) => {
    if (e.target.name === "quantity") {
      let tickets = this.state.tickets;
      tickets[index].ticketLimit = Array.from(
        { length: e.target.value < 50 ? e.target.value : 50 },
        (_, index) => index + 1
      );
      this.setState({ tickets });
    }
    let name = e.target.name;
    let value = e.target.value;

    let tickets = this.state.tickets;
    tickets[index][name] = value;
    this.setState({ tickets });
  };

  addTicket = () => {
    let tickets = this.state.tickets;
    tickets.push(JSON.parse(JSON.stringify(ticketDetailsTemplate)));
    this.setState({ tickets });
  };

  handleTicketDetailOptionChange = (e, index) => {
    let tickets = this.state.tickets;
    if (e.target.name === "limitPerPerson") {
      tickets[index][e.target.name] = parseInt(e.target.value)
        ? parseInt(e.target.value)
        : 0;
    } else {
      tickets[index][e.target.name] = e.target.value;
    }
    this.setState({ tickets });
  };

  checkChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  render() {
    if (this.state.type === "Group Match") {
      this.state.email = true;
    }

    if (this.state.type === "Tailgate Season Pass") {
      // this.state.eventDate = (Math.round(Date.now() / 1000) + (100 * 24 * 60 * 60));
      this.state.eventDate =
        Array.isArray(this.state.matchList) && this.state.matchList.length === 0
          ? Math.round(Date.now() / 1000) + 100 * 24 * 60 * 60
          : this.state.matchList[this.state.matchList.length - 1]
              .event_timestamp;
    }

    this.checkError();

    const layout2 = this.state.type === "News" || this.state.type === "Update";
    let loadingTitle = "",
      loadingMessage = "";
    if (this.state.loadingDocument) {
      loadingTitle = "Loading Document";
      loadingMessage = "Fetching data";
    } else if (this.state.loadingMatches) {
      loadingTitle = "Loading Matches";
      loadingMessage = "Fetching match details";
    } else if (this.state.isLoading) {
      loadingTitle = this.state.editMode
        ? "Updating Content"
        : "Creating Content";
      loadingMessage = this.state.editMode
        ? "Updating the ticket"
        : "Creating a new ticket";
    }

    let tickets = this.state.tickets;
    if (tickets.length > 0) {
      tickets.forEach((ticket) => {
        if (ticket.quantity > 0) {
          ticket.ticketLimit = Array.from(
            { length: ticket.quantity < 50 ? ticket.quantity : 50 },
            (_, index) => index + 1
          );
        }
      });
    }

    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    let groupCurrencyISO = this.props.adminData.data.paymentDetails
      ? this.props.adminData.data.paymentDetails.currency || "USD"
      : "USD";
    let groupCurrency = getCurrencyUnicode(groupCurrencyISO);

    return (
      <div>
        <LoadingModal
          title={loadingTitle}
          message={loadingMessage}
          loading={
            this.state.loadingDocument ||
            this.state.loadingMatches ||
            this.state.isLoading
          }
        />
        {this.state.errorInForm.isError && this.state.errorInForm.visible && (
          <div className="mx-5 mt-2">
            <Alert
              message="Error"
              description={this.state.errorInForm.message}
              type="error"
              closable
              afterClose={this.handleErrorMessageClose}
            />
          </div>
        )}
        <div className="mx-md-5 p-2 border-box" style={{ width: "95%" }}>
          <ul className="nav nav-tabs">
            <li
              className="nav-item"
              style={{
                fontSize: 22,
                fontWeight: "bold",
                borderBottom: `4px solid ${primaryColor}`,
                color: "black",
              }}
            >
              <a>Create Group Ticket</a>
            </li>
          </ul>
          <p style={{ margin: "15px 0px 30px", fontSize: 16 }}>
            Create group tickets by selecting a ticket type and match (if
            applicable). Generate the ticket ‘items’ (away match + bus, food +
            drink, etc) including quantity, price. Tickets can be generated with
            a scannable QR code (except away match tickets). Note in the event
            description how tickets are to be distributed. See Help: Tickets for
            more info.
          </p>
          <div
            className="border rounded p-3 px-3"
            style={{
              backgroundColor: "#ffffff",
              // boxShadow: "0px 3px 10px 1px",
              marginBottom: 20,
            }}
          >
            <div className="my-row">
              <div className="col-md-12">
                <div
                  className={`form-group col-md-8
                      }`}
                >
                  <FSelect
                    flex
                    label="Ticket Type"
                    value={this.state.type}
                    onChange={this.handleOptionChange}
                    name="type"
                    dataList={this.state.ticketTypes || []}
                    dataOnlyValue
                    placeholder="--Select Ticket Type--"
                    // display="my-row"
                    tabIndex="1"
                    required={
                      this.props.currentGroup !== this.props.router.params.group
                    }
                    disabled={
                      this.props.currentGroup === this.props.router.params.group
                    }
                  />
                </div>
                <div className={`form-group col-md-8`}>
                  {this.state.checkMatchRequired && (
                    <FSelect
                      flex
                      label={`Match`}
                      name="matchId"
                      // display="my-row"
                      tabIndex="2"
                      tooltip={
                        this.props.currentGroup ==
                        this.props.router.params.group ? (
                          <div style={{ width: 400, fontSize: 16 }}>
                            {" "}
                            If changed, then u have to notify all the people who
                            bought the ticket.
                          </div>
                        ) : null
                      }
                      value={this.state.matchId}
                      onChange={this.handleOptionChange}
                      placeholder={
                        this.state.loadingMatches
                          ? "Loading Matches..."
                          : this.state.matchList.length === 0
                            ? "No Matches"
                            : "--Select Match--"
                      }
                      dataList={this.state.matchList || []}
                      // required
                      required={
                        !this.state.editMode ||
                        (this.state.editMode &&
                          this.state.quantity === this.state.remainingTickets)
                      }
                      disabled={
                        this.state.editMode &&
                        Number(this.state.quantity) !==
                          Number(this.state.remainingTickets)
                      }
                      listBuilder={(match, index) => {
                        if (new Date(match.event_date).getTime() > Date.now()) {
                          return (
                            <option key={index} value={match.fixture_id}>
                              {match.homeTeam.team_name + " "}
                              vs {match.awayTeam.team_name}{" "}
                              {`(${dayjs(match.event_date).format(
                                "Do-MMM-YY"
                              )})`}{" "}
                            </option>
                          );
                        }
                      }}
                    />
                  )}
                  {!this.state.checkMatchRequired &&
                    this.state.type !== "Tailgate Season Pass" && (
                      <FSelect
                        flex
                        label="Match (Optional)"
                        name="matchId"
                        display="row"
                        tabIndex="2"
                        // tooltip="If selected, event will appear in Match Day immediately upon submission and not expire. Opponent logo will appear on content card."
                        value={this.state.matchId}
                        onChange={this.handleOptionChange}
                        placeholder={
                          this.state.loadingMatches
                            ? "Loading Matches..."
                            : this.state.matchList.length === 0
                              ? "No Matches"
                              : "--Select Match--"
                        }
                        dataList={this.state.matchList || []}
                        disabled={
                          this.state.editMode &&
                          this.state.quantity !== this.state.remainingTickets
                        }
                        listBuilder={(match, index) => {
                          if (
                            new Date(match.event_date).getTime() > Date.now()
                          ) {
                            return (
                              <option key={index} value={match.fixture_id}>
                                {" "}
                                {match.homeTeam.team_name + " "}
                                vs {match.awayTeam.team_name}{" "}
                                {`(${dayjs(match.event_date).format(
                                  "Do-MMM-YY"
                                )})`}{" "}
                              </option>
                            );
                          }
                        }}
                      />
                    )}
                </div>
                {this.state.type !== "Group Match" && (
                  <div className="form-group col-md-8">
                    <FInput
                      label="Event Name"
                      value={this.state.eventName}
                      onChange={this.handleInputChange}
                      name="eventName"
                      display="my-row"
                      placeholder="Enter event name"
                      tabIndex="2"
                      required={this.state.type !== "Group Match"}
                    />
                  </div>
                )}
                {this.state.checkMatchRequired &&
                  this.state.type === "Group Match" && (
                    <div className="form-group col-md-8">
                      <FInput
                        label="Event name"
                        value={this.state.section}
                        onChange={this.handleInputChange}
                        name="section"
                        display="my-row"
                        tooltip={
                          <div style={{ width: 400, fontSize: 16 }}>
                            Enter single section or range. If sections have
                            different prices enter each as a separate item for
                            sale.
                          </div>
                        }
                        placeholder="Ex. Match ticket or Section 204"
                        tabIndex="2"
                        required={
                          this.props.currentGroup !==
                          this.props.router.params.group
                        }
                        // disabled={
                        //   this.props.currentGroup === this.props.router.params.group
                        // }
                      />
                    </div>
                  )}

                <div className="form-group col-md-8">
                  {this.state.type !== "Tailgate Season Pass" &&
                    this.state.type !== "Group Match" && (
                      // <FInput
                      //   label="Event Location"
                      //   value={this.state.location}
                      //   onChange={this.handleInputChange}
                      //   name="location"
                      //   display="my-row"
                      //   tooltip={<div style={{ width: 400, fontSize: 16 }}>List location of event (or city)</div>}
                      //   placeholder="Enter event location"
                      //   tabIndex="2"
                      // />
                      <FSelect
                        flex
                        label="Event Location"
                        value={this.state.location.id}
                        onChange={(e) => {
                          if (e.target.value) {
                            let selectedLocation = this.state.allLocations.find(
                              (loc) => loc.id === e.target.value
                            );
                            this.setState({
                              location: selectedLocation,
                            });
                          } else {
                            this.setState({
                              location: {},
                            });
                          }
                        }}
                        name="location"
                        dataList={this.state.allLocations}
                        tooltip={
                          <div style={{ width: 400, fontSize: 16 }}>
                            List location of event (or city)
                          </div>
                        }
                        placeholder="--Select--"
                        tabIndex="2"
                        dataKey="id"
                        dataValue="name"
                      />
                    )}
                </div>
                {this.state.type !== "Tailgate Season Pass" &&
                  this.state.type !== "Group Match" && (
                    <div className="form-group col-md-8">
                      <div style={{ display: "flex" }}>
                        <label className="col-md-4">
                          Event Date{" "}
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            {" "}
                            *{" "}
                          </span>{" "}
                        </label>{" "}
                        <div className="col-md-8">
                          <DatePicker
                            tabIndex="2"
                            value={this.state.eventDate}
                            onChange={(value) => {
                              this.handleDateChange("eventDate", value);
                            }}
                            disabledDate={(current) => {
                              return (
                                current && current < dayjs().startOf("day")
                              );
                            }}
                            className="form-control form-control-sm col-md-8"
                            size={10}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                {this.state.type !== "Tailgate Season Pass" &&
                  this.state.type !== "Group Match" && (
                    <div className="form-group col-md-8">
                      <div style={{ display: "flex" }}>
                        <label className="col-md-4"> Event Time </label>
                        <div className="col-md-8">
                          <TimePicker
                            tabIndex="2"
                            className="form-control form-control-sm col-md-8 input-height"
                            value={this.state.eventDate}
                            defaultOpenValue={dayjs().set({
                              hour: 0,
                              minute: 0,
                            })}
                            onChange={(value) => {
                              this.handleDateChange("eventTime", value);
                            }}
                            format={timeFormat}
                          />
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="form-group col-md-8">
              <div style={{ display: "flex" }} className="form-group">
                <label className="col-md-4">
                  Sale End Date
                  <span style={{ fontsize: "50px", color: "#FF0000" }}>* </span>
                  <Tooltip
                    placement="topLeft"
                    title={
                      <div style={{ width: 400, fontSize: 16 }}>
                        (Optional) Use as a way to end ticket sales before the
                        event date.
                      </div>
                    }
                  >
                    <span className="border-0">
                      &nbsp;<i class="fa fa-question-circle-o"></i>
                    </span>
                  </Tooltip>
                </label>{" "}
                <div className="col-md-8">
                  <DatePicker
                    tabIndex="2"
                    value={this.state.saleEndDate}
                    onChange={(value) => {
                      this.handleDateChange("saleEndDate", value);
                    }}
                    disabledDate={(current) => {
                      return current && current < dayjs().startOf("day");
                    }}
                    className="form-control form-control-sm col-md-8"
                    size={10}
                  />
                </div>
              </div>
            </div>
            <div className="form-group col-md-8">
              <div style={{ display: "flex" }} className="form-group">
                <label className="col-md-4">
                  Sale End Time
                  <span style={{ fontsize: "50px", color: "#FF0000" }}>* </span>
                  {/* <Tooltip placement="topLeft" title={'(Optional) Use as a way to end ticket sales before the event date.'}>
                      <span className="border-0">
                        &nbsp;<i class="fa fa-question-circle-o"></i>
                      </span>
                    </Tooltip> */}
                </label>{" "}
                <div className="col-md-8">
                  <TimePicker
                    tabIndex="2"
                    className="form-control form-control-sm col-md-8 input-height"
                    value={this.state.saleEndDate}
                    defaultOpenValue={dayjs().set({
                      hour: 0,
                      minute: 0,
                    })}
                    onChange={(value) => {
                      this.handleDateChange("saleEndTime", value);
                    }}
                    format={timeFormat}
                  />
                </div>
              </div>
            </div>
            <div className="form-group col-md-8">
              <div className="my-row">
                <label className="col-md-4"> Event Description </label>{" "}
                <Input.TextArea
                  tabIndex="2"
                  className="col-md-8"
                  style={{
                    fontSize: `18px`,
                  }}
                  placeholder={
                    "Add event description and ticket distribution details."
                  }
                  value={this.state.eventDescription}
                  name="eventDescription"
                  onChange={this.handleInputChange}
                  my-rows={layout2 ? 13 : 3}
                />{" "}
              </div>{" "}
            </div>
            <div className="form-group col-md-8">
              <div className="my-row">
                <label className="col-md-4"> Description (for receipt) </label>{" "}
                <Input.TextArea
                  tabIndex="2"
                  className="col-md-8"
                  style={{
                    fontSize: `18px`,
                  }}
                  placeholder={
                    "(optional) description included in stripe receipt."
                  }
                  value={this.state.receiptDescription}
                  name="receiptDescription"
                  maxLength={500}
                  onChange={this.handleInputChange}
                  my-rows={layout2 ? 13 : 3}
                />{" "}
              </div>{" "}
            </div>
            {(!this?.state?.matchId || this?.state?.matchId?.length === 0) && (
              <div className="form-group col-md-8">
                <FUpload
                  action={this.uploadAction}
                  fileList={this.state.imageList}
                  onChange={this.handleImageChange}
                  display="my-row"
                  isAvatar="false"
                  label="Image"
                  aspectRatio={1}
                  tooltip={() => (
                    <div style={{ width: 400, fontSize: 16 }}>
                      <span>
                        Image required (1:1 ratio) for special events and
                        tailgate season passes.
                        <br /> Not displayed on ticket but on callouts/shortcuts
                        to ticket.
                        <br /> For events not associated with a match the image
                        is used in place of the opponent logo.
                        <br /> Image not required for events associated with a
                        match.
                      </span>
                    </div>
                  )}
                  required
                />
              </div>
            )}
            <div className="form-group col-md-8">
              <FInput
                label="Ticket Delivery Method (App)"
                value={this.state.ticketDeliveryMethodApp}
                onChange={this.handleInputChange}
                name="ticketDeliveryMethodApp"
                display="my-row"
                tooltip="Share ticket delivery method when tickets are purchased in the app (ie, QR code, via email, show ticket at the door, etc)."
                placeholder=""
                maxLength="150"
                tabIndex="2"
              />
            </div>
            <div className="form-group col-md-8">
              <FInput
                label="Ticket Delivery Method(Web)"
                value={this.state.ticketDeliveryMethodWeb}
                onChange={this.handleInputChange}
                name="ticketDeliveryMethodWeb"
                display="my-row"
                tooltip="Share ticket delivery method when tickets are purchased via web. Note that QR codes are generated in the email receipt. Web delivery is likely to be stated as 'Email receipt will serve as your ticket.'. You can use manual check-in in the ticket as members show their receipt."
                placeholder=""
                maxLength="150"
                tabIndex="2"
              />
            </div>
            <div className="form-group col-md-8">
              <FInput
                label="Refund Terms"
                value={this.state.refundTerms}
                onChange={this.handleInputChange}
                name="refundTerms"
                display="my-row"
                placeholder=""
                maxLength="150"
                tabIndex="2"
              />
            </div>
            <div className="form-group col-md-8">
              <FInput
                label="Contact Email"
                value={this.state.contactEmail}
                onChange={this.handleInputChange}
                name="contactEmail"
                display="my-row"
                placeholder=""
                tabIndex="2"
                required
              />
            </div>
            <div className="form-group col-md-8">
              <FInput
                label="Group Terms"
                value={this.state.groupTerms}
                onChange={this.handleInputChange}
                name="groupTerms"
                display="my-row"
                placeholder="(optional) add URL if group terms must be accepted"
                tabIndex="2"
              />
            </div>
            <div className="form-group col-md-8">
              <FInput
                label="Stripe Merchant Account"
                value={
                  this.state.stripeAddress
                    ? "Active"
                    : "Requires setup. Contact support@chant.fan."
                }
                name="paymentDetail"
                display="my-row"
                tooltip={
                  <div style={{ width: 400, fontSize: 16 }}>
                    <p>
                      A Stripe account enables transactions via Apple Pay,
                      Google Pay and credit card.{" "}
                    </p>
                    <p>
                      Contact{" "}
                      <a
                        href="mailto:support@chant.fan"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        support@chant.fan
                      </a>{" "}
                      for a link to create your account.
                    </p>
                  </div>
                }
                placeholder="Set Stripe Payment Details"
                tabIndex="5"
                disabled
              />
            </div>{" "}
            <div className="form-group col-md-8">
              <FSelect
                flex
                label="Processing Fee"
                name="processingFee"
                // display="my-row"
                tabIndex="5"
                bottomHint="Stripe (2.9% + $.030) + Chant (3%). See ‘?’ for additional details."
                tooltip={
                  <div>
                    <p style={{ fontWeight: "bold" }}>Fees</p>
                    <p style={{ marginBottom: 0 }}>
                      Stripe card processing fee: 2.9% + $0.30 (can vary)
                    </p>
                    <p>Chant fee: 3%</p>

                    <p style={{ fontWeight: "bold" }}>Member Pay</p>
                    <p>
                      Stripe collects the fee on the entire purchase amount. So,
                      if a member covers fees that is added to the total charged
                      ($10 item + $0.59 fee paid by member for $10.59) stripe
                      will collect a fee on the entire $10.59.
                    </p>

                    <p>
                      To account for this we add 3% + $.30 (.1% more) to the
                      original item price for Stripe so your net is close to the
                      item price - it may be off very slightly.
                    </p>

                    <p>
                      Stripe fees can vary based on member payment method but
                      are not known until after the transaction is complete
                      (foreign card, etc). Your net proceeds may not cover the
                      full item amount but should be close.
                    </p>

                    <p style={{ fontWeight: "bold" }}>
                      Member Pay (legal Limitations)
                    </p>
                    <p>
                      Not all states/countries allow the fee to be passed to the
                      member. Chant is not responsible for determining your
                      local laws. Please confirm this for your location.
                    </p>
                  </div>
                }
                required
                value={this.state.processingFee}
                onChange={this.handleOptionChange}
                placeholder="--Select--"
                dataOnlyValue
                dataList={this.state.processingFeeTypes || []}
                // disabled={
                //   this.state.editMode
                // }
              />
            </div>
            <div
              className="col-md-8 py-2"
              style={{ backgroundColor: "#eeeeee", padding: "12px 16px" }}
            >
              <span style={{ fontSize: 20, fontWeight: "bold" }}>Display</span>
              <div className="form-group">
                <div className="my-row">
                  <label className="col-md-4 d-flex" style={{ gap: "6px" }}>
                    <div>Show to members (in app)?</div>
                    <Tooltip
                      placement="topLeft"
                      title={() => (
                        <div
                          style={{
                            height: "fit-content",
                            width: 400,
                            display: "flex",
                            flexDirection: "column",
                            fontSize: 16,
                          }}
                        >
                          <p>
                            (Optional) If group uses the non-member (Fan) role
                            in the app and/or has a Chant website you can choose
                            to display tickets on the web.{" "}
                          </p>
                          <p>
                            {" "}
                            You can set separate non-member pricing for the web
                            and app.{" "}
                          </p>
                          <p>
                            {" "}
                            Note that 'max quantity per person' can not be
                            controlled on the web -- guests can purchase, then
                            purchase again. Web tickets should not be used for
                            tickets where a max quantity is important, or should
                            be first offered to members.
                          </p>
                        </div>
                      )}
                    >
                      <span className="border-0">
                        <i className="fa fa-question-circle-o" />
                      </span>
                    </Tooltip>
                  </label>
                  <div className="col-md-1">
                    <Checkbox
                      style={{ marginTop: "1px" }}
                      checked={this.state.showForMembers}
                      onChange={this.checkChange}
                      value="secondary"
                      color="primary"
                      name="showForMembers"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      tabIndex="5"
                    />
                  </div>
                  {/* <div>
                        Web URL (if published): <a href={`https://group.chant.fan/${this.props.groupName.toLowerCase().replaceAll(' ', '')}/`}
                  </div> */}
                </div>
              </div>
              <div>
                <div className="form-group">
                  <div className="my-row">
                    <label className="col-md-4 d-flex" style={{ gap: "6px" }}>
                      <div>Show to fans (in app)?</div>
                      <Tooltip
                        placement="topLeft"
                        title={() => (
                          <div
                            style={{
                              height: "fit-content",
                              width: 400,
                              display: "flex",
                              flexDirection: "column",
                              fontSize: 16,
                            }}
                          >
                            <p>
                              (Optional) If group uses the non-member (Fan) role
                              in the app and/or has a Chant website you can
                              choose to display tickets on the web.{" "}
                            </p>
                            <p>
                              {" "}
                              You can set separate non-member pricing for the
                              web and app.{" "}
                            </p>
                            <p>
                              {" "}
                              Note that 'max quantity per person' can not be
                              controlled on the web -- guests can purchase, then
                              purchase again. Web tickets should not be used for
                              tickets where a max quantity is important, or
                              should be first offered to members.
                            </p>
                          </div>
                        )}
                      >
                        <span className="border-0">
                          <i className="fa fa-question-circle-o" />
                        </span>
                      </Tooltip>
                    </label>
                    <div className="col-md-1">
                      <Checkbox
                        style={{ marginTop: "1px" }}
                        checked={this.state.showForFans}
                        onChange={this.checkChange}
                        value="secondary"
                        color="primary"
                        name="showForFans"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        tabIndex="5"
                      />
                    </div>
                    <div className="col-md" style={{ fontSize: 12 }}>
                      If you allow fans (non-members) in app you can show the
                      ticket and use non-member pricing. Note: If you want to
                      manage ticket inventory separately for members and
                      non-members, create a separate ticket.
                    </div>
                    {/* <div>
                        Web URL (if published): <a href={`https://group.chant.fan/${this.props.groupName.toLowerCase().replaceAll(' ', '')}/`}
                  </div> */}
                  </div>
                </div>
                <div className="form-group">
                  <div className="my-row">
                    <label className="col-md-4 d-flex" style={{ gap: "6px" }}>
                      <div>Publish to web?</div>
                      <Tooltip
                        placement="topLeft"
                        title={() => (
                          <div
                            style={{
                              height: "fit-content",
                              width: 400,
                              display: "flex",
                              flexDirection: "column",
                              fontSize: 16,
                            }}
                          >
                            <p>
                              (Optional) If group uses the non-member (Fan) role
                              in the app and/or has a Chant website you can
                              choose to display tickets on the web.{" "}
                            </p>
                            <p>
                              {" "}
                              You can set separate non-member pricing for the
                              web and app.{" "}
                            </p>
                            <p>
                              {" "}
                              Note that 'max quantity per person' can not be
                              controlled on the web -- guests can purchase, then
                              purchase again. Web tickets should not be used for
                              tickets where a max quantity is important, or
                              should be first offered to members.
                            </p>
                          </div>
                        )}
                      >
                        <span className="border-0">
                          <i className="fa fa-question-circle-o" />
                        </span>
                      </Tooltip>
                    </label>
                    <div className="col-md-1">
                      <Checkbox
                        style={{ marginTop: "1px" }}
                        checked={this.state.showOnWeb}
                        onChange={this.checkChange}
                        value="secondary"
                        color="primary"
                        name="showOnWeb"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        tabIndex="5"
                      />
                    </div>
                    <div className="col-md" style={{ fontSize: 12 }}>
                      If checked a web URL will be generated. Web tickets are
                      openly available to anyone who access the webpage.. Limit
                      per person is controlled per transaction but anyone can
                      purchase again with no restrictions.
                    </div>
                    {/* <div>
                        Web URL (if published): <a href={`https://group.chant.fan/${this.props.groupName.toLowerCase().replaceAll(' ', '')}/`}
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="pl-2" id="quantity_error">
              Wrong value
            </div>
            {this.state.type !== "Tailgate Season Pass" ? (
              <div className="mb-2">
                {this.state.tickets.map((ticketDetail, index) => {
                  return (
                    <div className="col-md-8">
                      <div
                        className="my-row mb-1"
                        style={{ justifyContent: "space-between" }}
                      >
                        <span
                          style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                        >
                          Item #{index + 1}
                          <Tooltip
                            placement="topLeft"
                            title={
                              <div style={{ width: 400, fontSize: 16 }}>
                                <p>
                                  A Group Ticket can have multiple ticket
                                  options. Quantities are set separately for
                                  each option so do not offer a limited quantity
                                  ticket across two options. Each option can
                                  have it’s own limit per member, offer a
                                  barcode (except match tickets) and require
                                  name/email.
                                </p>
                                Examples: Food Drink Food and Drink Tailgate and
                                Parking
                              </div>
                            }
                          >
                            <span className="border-0">
                              &nbsp;<i class="fa fa-question-circle-o"></i>
                            </span>
                          </Tooltip>
                        </span>
                        <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            let items = this.state.tickets;
                            items.splice(index, 1);
                            this.setState({
                              tickets: items,
                            });
                          }}
                        >
                          Delete
                        </span>
                      </div>
                      <div className="form-group">
                        <FInput
                          label="Ticket"
                          value={ticketDetail.name}
                          type="text"
                          onChange={(e) =>
                            this.handleTicketDetailInputChange(e, index)
                          }
                          name="name"
                          display="my-row"
                          // If not populated, no limit will appear on ticket card.
                          placeholder={
                            this.state.type === "Group Match"
                              ? "Ex. Match ticket or Match + Bus"
                              : "Ex. Food & Drink"
                          }
                          tabIndex="5"
                          required
                          maxLength={25}
                        />
                      </div>
                      <div className="form-group">
                        <FInput
                          label="Quantity"
                          value={ticketDetail.quantity}
                          type="number"
                          onChange={({ target }) => {
                            let { name, value } = target;
                            value = Number(value).toFixed(0);
                            this.handleTicketDetailInputChange(
                              { target: { name, value } },
                              index
                            );
                          }}
                          name="quantity"
                          display="my-row"
                          tooltip={
                            <div style={{ width: 400, fontSize: 16 }}>
                              Set number of available tickets for sale.
                            </div>
                          }
                          // If not populated, no limit will appear on ticket card.
                          placeholder="0"
                          tabIndex="5"
                          required
                          min={
                            this.state.soldTickets !== 0
                              ? this.state.soldTickets
                              : 1
                          }
                        />
                      </div>
                      <div className="form-group">
                        <FInput
                          label={`Member Price(${groupCurrency})`}
                          value={ticketDetail.memberPrice}
                          type="number"
                          onChange={({ target }) => {
                            let { name, value } = target;
                            value =
                              value.indexOf(".") > 0
                                ? value.substr(0, value.indexOf(".") + 3)
                                : value;
                            this.handleTicketDetailInputChange(
                              { target: { name, value } },
                              index
                            );
                          }}
                          name="memberPrice"
                          display="my-row"
                          step="0.01"
                          tooltip={
                            <div style={{ width: 400, fontSize: 16 }}>
                              Enter price per ticket for a member. If ticket is
                              free enter '0' and member can 'Reserve' tickets
                            </div>
                          }
                          // If not populated, no limit will appear on ticket card.
                          placeholder="0"
                          tabIndex="5"
                          required
                          min={0}
                        />
                      </div>
                      <div className="form-group">
                        <FInput
                          label="Non member price"
                          value={ticketDetail.nonMemberPrice}
                          type="number"
                          onChange={({ target }) => {
                            let { name, value } = target;
                            value =
                              value.indexOf(".") > 0
                                ? value.substr(0, value.indexOf(".") + 3)
                                : value;
                            this.handleTicketDetailInputChange(
                              { target: { name, value } },
                              index
                            );
                          }}
                          name="nonMemberPrice"
                          display="my-row"
                          step="0.01"
                          tooltip="Enter price per ticket for a member for a non-member. If ticket is free enter '0' and member can 'Reserve' tickets"
                          // If not populated, no limit will appear on ticket card.
                          placeholder="0"
                          tabIndex="5"
                          min={0}
                        />
                      </div>
                      <div className="form-group">
                        <FSelect
                          flex
                          label="Limit per person"
                          value={ticketDetail.limitPerPerson}
                          onChange={(e) =>
                            this.handleTicketDetailOptionChange(e, index)
                          }
                          name="limitPerPerson"
                          tooltip={
                            this.state.type !== "Tailgate Season Pass" ? (
                              <div style={{ width: 400, fontSize: 16 }}>
                                If no ticket limit, select N/A. Note that 'limit
                                per person' can not be controlled on the web --
                                guests can purchase the max, then purchase
                                again. Web tickets should not be used for
                                tickets where a max quantity is important, or
                                should be first offered to members.
                              </div>
                            ) : (
                              <div style={{ width: 400, fontSize: 16 }}>
                                Set number of tickets in pass. This value will
                                not be editable for member.
                              </div>
                            )
                          }
                          dataList={ticketDetail.ticketLimit || []}
                          listBuilder={(limit, index) => {
                            if (Number(limit) === 0) {
                              return (
                                <option
                                  key={index}
                                  value={
                                    (ticketDetail.ticketLimit.length || 1) - 1
                                  }
                                >
                                  {"NA"}
                                </option>
                              );
                            } else {
                              return (
                                <option key={index} value={limit}>
                                  {limit}
                                </option>
                              );
                            }
                          }}
                          dataOnlyValue
                          placeholder="--Select Ticket Limit--"
                          // display="my-row"
                          tabIndex="5"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <FSelect
                          flex
                          label="Subticket Type"
                          value={ticketDetail.subTicketType}
                          onChange={(e) =>
                            this.handleTicketDetailOptionChange(e, index)
                          }
                          name="subTicketType"
                          dataList={this.state.ticketTypes}
                          dataOnlyValue
                          placeholder="--Select--"
                          tooltip={
                            <div style={{ width: 400, fontWeight: 16 }}>
                              <p>
                                Each ticket type provides the option of
                                requiring name or name/email.
                              </p>
                              <p>
                                Match tickets do not allow a QR code as the
                                actual ticket will be distributed by the group
                                outside of Chant. This avoids member confusion.
                                Manual check-in (in the ticket check-in section)
                                is available to the admin.
                              </p>
                              <p>
                                Other ticket types do allow a QR code to be
                                displayed providing groups the option of
                                scanning tickets upon event check-in. Only add
                                QR codes for tickets you control.
                              </p>
                            </div>
                          }
                          // display="my-row"
                          tabIndex="5"
                          required
                        />
                      </div>
                      {ticketDetail.subTicketType && (
                        <div className="form-group">
                          <FSelect
                            flex
                            label="Require name/email per ticket"
                            value={ticketDetail.requireNameEmail}
                            onChange={(e) =>
                              this.handleTicketDetailOptionChange(e, index)
                            }
                            name="requireNameEmail"
                            dataList={nameEmailOptions}
                            dataOnlyValue
                            placeholder="--Select--"
                            tabIndex="5"
                            required
                          />
                        </div>
                      )}
                      {ticketDetail.subTicketType &&
                        ticketDetail.subTicketType.length > 0 &&
                        ticketDetail.subTicketType !== "Group Match" && (
                          <div className="form-group">
                            <FSelect
                              flex
                              label="Enable check-in?"
                              value={ticketDetail.generateQRCode}
                              onChange={(e) =>
                                this.handleTicketDetailOptionChange(e, index)
                              }
                              name="generateQRCode"
                              dataList={["Yes", "No"]}
                              dataOnlyValue
                              placeholder="--Select--"
                              bottomHint="QR codes are not generated for web tickets. Use
                            receipt for entry."
                              tooltip={
                                <div style={{ width: 400, fontSize: 16 }}>
                                  <p>
                                    Only add QR codes for tickets you control
                                    (tailgate, bus, special event, etc). Do not
                                    show for match tickets as those will be
                                    distributed by you outside of Chant.
                                  </p>
                                  <p>
                                    Chant provides the option to have each
                                    non-match ticket appear with a QR code.
                                    Group leaders (and ticket scanner role) can
                                    scan tickets by clicking on the QR scanner
                                    icon associated with the ticket they intend
                                    to scan.{" "}
                                  </p>
                                  <p>
                                    QR codes change from green to red when
                                    scanned.
                                  </p>
                                </div>
                              }
                              tabIndex="5"
                              required
                            />
                          </div>
                        )}
                    </div>
                  );
                })}
                <div className="my-row mt-1">
                  <button
                    type="button"
                    class="btn btn-link"
                    onClick={this.addTicket}
                  >
                    <i className="fa fa-plus"></i> Add Ticket
                  </button>
                </div>
              </div>
            ) : (
              <div className="my-row mb-2">
                <br />
                <div className="form-group col-md-4">
                  <FInput
                    label={
                      this.state.type !== "Tailgate Season Pass"
                        ? "Total Ticket Quantity"
                        : "Total Number of Season Passes"
                    }
                    value={this.state.quantity}
                    type="number"
                    onChange={this.handleInputChange}
                    name="quantity"
                    display="col"
                    tooltip={
                      <div style={{ width: 400, fontSize: 16 }}>
                        Set number of available tickets for sale.
                      </div>
                    }
                    // If not populated, no limit will appear on ticket card.
                    placeholder="0"
                    tabIndex="5"
                    required
                    min={
                      this.state.soldTickets !== 0 ? this.state.soldTickets : 1
                    }
                  />
                </div>{" "}
                <div className="form-group col-md-4">
                  <FInput
                    label={
                      this.state.type !== "Tailgate Season Pass"
                        ? "Price per Ticket"
                        : "Price per Season Pass"
                    }
                    value={this.state.price}
                    type="number"
                    step="0.01"
                    onChange={this.handleInputChange}
                    name="price"
                    display="col"
                    tooltip={
                      <div style={{ width: 400, fontSize: 16 }}>
                        Enter price per ticket. If ticket is free enter '0' and
                        member can 'Reserve' tickets
                      </div>
                    }
                    placeholder="0"
                    tabIndex="5"
                    min={0}
                    required
                  />
                </div>{" "}
                <div className="form-group col-md-4">
                  <FSelect
                    flex
                    label={
                      this.state.type !== "Tailgate Season Pass"
                        ? "Ticket Limit Per member"
                        : "Number of Tickets in Season Pass"
                    }
                    value={this.state.limit}
                    onChange={this.handleOptionChange}
                    name="limit"
                    tooltip={
                      this.state.type !== "Tailgate Season Pass" ? (
                        <div style={{ width: 400, fontSize: 16 }}>
                          If no ticket limit, select N/A
                        </div>
                      ) : (
                        <div style={{ width: 400, fontSize: 16 }}>
                          Set number of tickets in pass. This value will not be
                          editable for member.
                        </div>
                      )
                    }
                    dataList={this.state.ticketLimit}
                    listBuilder={(limit, index) => {
                      if (limit === 0) {
                        return (
                          <option key={index} value={limit}>
                            {"NA"}
                          </option>
                        );
                      } else {
                        return (
                          <option key={index} value={limit}>
                            {limit}
                          </option>
                        );
                      }
                    }}
                    dataOnlyValue
                    placeholder="--Select Ticket Limit--"
                    display="col"
                    tabIndex="5"
                    required
                  />
                </div>{" "}
              </div>
            )}
            <div className="my-row mb-2">
              {/* {this.state.generateBarCode && (
                <div className="form-group col-md-6">
                  <FSelect
                    label="Generate barcode on ticket?"
                    value={this.state.barcode}
                    onChange={this.handleOptionChange}
                    name="barcode"
                    tooltip="Enables use of barcode for check-in. Not available for group match tickets."
                    dataList={yesNoDropdown}
                    listBuilder={(match, index) => {
                      return (
                        <option key={index} value={match.id}>
                          {match.title}
                        </option>
                      );
                    }}
                    placeholder="--Yes/No--"
                    display="my-row"
                    tabIndex="5"
                    required={
                      this.props.currentGroup !==this.props.router.params.group
                    }
                    disabled={
                      this.props.currentGroup===this.props.router.params.group
                    }
                  />
                </div>
              )} */}
              {/* {this.state.showRequireMembers && <div className="form-group col-md-4">
                <FSelect
                  label="Require member to enter names of those using tickets?"
                  value={this.state.requireMember}
                  onChange={this.handleOptionChange}
                  name="requireMember"
                  dataList={yesNoDropdown}
                  listBuilder={(match, index) => {
                    return (
                      <option key={index} value={match.id}>
                        {match.title}
                      </option>
                    );
                  }}
                  placeholder="--Yes/No--"
                  display="col"
                  tooltip=
                  // {this.props.currentGroup===this.props.router.params.group
                  //   ? "If changed, then u have to notify all the people who bought the ticket."
                  //   : null }
                  "If selected, member will be required to include names of those who will use tickets."
                />
              </div>} */}
              {this.state.type === "Tailgate Season Pass" && (
                <div className="form-group col-md-6">
                  <FSelect
                    flex
                    label="Passes Limit Per member"
                    value={this.state.passLimit}
                    onChange={this.handleOptionChange}
                    name="passLimit"
                    tooltip={
                      <div style={{ width: 400, fontSize: 16 }}>
                        Set number of passes limit per person.
                      </div>
                    }
                    dataList={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                    listBuilder={(limit, index) => {
                      return (
                        <option key={index} value={limit}>
                          {limit}
                        </option>
                      );
                    }}
                    dataOnlyValue
                    placeholder="--Select Passes Limit--"
                    display="my-row"
                    tabIndex="5"
                    required
                  />
                </div>
              )}
            </div>
            {/* {this.state.type === "Group Match" && <div className="my-row mb-2">
              <div className="form-group col-md-6">
                <FSelect
                  label="Will tickets be emailed to member?"
                  value={this.state.email}
                  onChange={this.handleOptionChange}
                  name="email"
                  disabled
                  dataList={yesNoDropdown}
                  tooltip={<div style={{ width: 400, fontSize: 16 }}>Required for Match Tickets. Message on ticket will read: *Note: Leadership will send tickets via email.</div>}
                  listBuilder={(match, index) => {
                    return (
                      <option key={index} value={Boolean(match.id)}>
                        {match.title}
                      </option>
                    );
                  }}
                  placeholder="--Yes/No--"
                  display="my-row"
                  tabIndex="5"
                  required
                />
              </div>
            </div>} */}
            <div
              className="col"
              style={{ display: "flex", flexDirection: "my-row" }}
            >
              <Checkbox
                style={{ marginLeft: "18px", marginTop: "1px" }}
                checked={this.state.acceptedTerms}
                onChange={this.verifiedChange}
                value="secondary"
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
                tabIndex="5"
              />
              <span
                style={{
                  marginLeft: "15px",
                  fontsize: "50px",
                  color: "#FF0000",
                }}
              >
                *{" "}
              </span>
              <p style={{ marginLeft: "5px" }}>
                I am authorized to create this ticket offer and understand it is
                between the group and our members. I understand and accept the{" "}
                <a href="https://www.chant.fan/terms">Chant terms</a> and
                transaction fee.
              </p>
            </div>
            {/* Show on home screen */}
            <div className="d-flex justify-content-center mt-2">
              <div>
                <div className="d-flex" style={{ gap: "10px" }}>
                  <button
                    className="btn btn-outline-primary mr-2"
                    style={{ borderColor: primaryColor }}
                    tabIndex="5"
                    onClick={(e) => this.handleSubmit(e, "saved")}
                  >
                    Save for Later
                  </button>
                  <Popconfirm
                    placement="topRight"
                    title={
                      <div>
                        <p>Submitting will post the tickets to Chant.</p>
                        <div
                          className="my-row"
                          style={{
                            flexWrap: "nowrap",
                            gap: 20,
                            marginRight: "12px",
                          }}
                        >
                          <label>Send notification?</label>

                          <Checkbox
                            style={{ marginTop: "1px" }}
                            checked={this.state.notifyOnPublish}
                            onChange={this.checkChange}
                            value="secondary"
                            color="primary"
                            name="notifyOnPublish"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            tabIndex="5"
                          />
                        </div>
                      </div>
                    }
                    onConfirm={(e) => this.handleSubmit(e, "live")}
                    onCancel={() => {}}
                    okText="Ok"
                    cancelText="Cancel"
                  >
                    <button
                      className="btn btn-success"
                      tabIndex="5"
                      type="success"
                      style={{
                        backgroundColor: primaryColor,
                        borderColor: primaryColor,
                      }}
                    >
                      Publish{" "}
                    </button>{" "}
                  </Popconfirm>
                </div>
                <div className="d-flex justify-content-center">
                  <Popconfirm
                    title="Are you sure? Will move to past tickets."
                    onConfirm={(e) => this.handleSubmit(e, "archived")}
                    onCancel={() => {}}
                    okText="Ok"
                    cancelText="Cancel"
                  >
                    <span
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                    >
                      Archive
                    </span>
                  </Popconfirm>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>{" "}
      </div>
    );
  }

  // ? Life cycle methods and helper functions

  handleEditMode = async () => {
    let doc = await fbGetTickets(
      this.props.router.params.id,
      this.props.router.params.group
    );

    document.getElementById("quantity_error").style.visibility = "hidden";

    const data = new Ticket();
    data.fromDataSet(doc.data.body.data);
    if (Array.isArray(data.imageList) && data.imageList.length > 0) {
      data.oldImageName = await getMetadata(
        ref(storage, data.imageList[0].url)
      ).then((metadata) => {
        return metadata.name;
      });
    }
    let matchList = [];
    if (this.props.clubId && this.state.matchList.length === 0) {
      await Axios.get(`fixtures?team=${this.props.clubId}&next=99`).then(
        (response) => {
          let data = response.data.response;
          let res = [];
          data.forEach((fixtureData) => {
            res.push({
              fixture_id: fixtureData.fixture.id,
              league_id: fixtureData.league.id,
              league: fixtureData.league,
              event_date: fixtureData.fixture.date,
              event_timestamp: fixtureData.fixture.timestamp,
              firstHalfStart: fixtureData.fixture.periods.first,
              secondHalfStart: fixtureData.fixture.periods.second,
              round: fixtureData.league.round,
              status: fixtureData.fixture.status.long,
              statusShort: fixtureData.fixture.status.short,
              elapsed: fixtureData.fixture.status.elapsed,
              venue: fixtureData.fixture.venue.name,
              referee: fixtureData.fixture.referee,
              homeTeam: {
                team_id: fixtureData.teams.home.id,
                team_name: fixtureData.teams.home.name,
                logo: fixtureData.teams.home.logo,
              },
              awayTeam: {
                team_id: fixtureData.teams.away.id,
                team_name: fixtureData.teams.away.name,
                logo: fixtureData.teams.away.logo,
              },
              goalsHomeTeam: fixtureData.goals.home,
              goalsAwayTeam: fixtureData.goals.away,
              score: {
                halftime: fixtureData.score.halftime.home
                  ? `${fixtureData.score.halftime.home}-${fixtureData.score.halftime.away}`
                  : null,
                fulltime: fixtureData.score.fulltime.home
                  ? `${fixtureData.score.fulltime.home}-${fixtureData.score.fulltime.away}`
                  : null,
                extratime: fixtureData.score.extratime.home
                  ? `${fixtureData.score.extratime.home}-${fixtureData.score.extratime.away}`
                  : null,
                penalty: fixtureData.score.penalty.home
                  ? `${fixtureData.score.penalty.home}-${fixtureData.score.penalty.away}`
                  : null,
              },
            });
          });
          matchList = res;
        }
      );
    } else {
      matchList = this.state.matchList;
    }

    let matchObj = null;
    matchList.map((match) => {
      if (String(match.fixture_id) === String(data.matchId)) {
        matchObj = match;
      }
    });

    let obj = [];
    if (data.type === "Tailgate Season Pass") {
      obj = Array.from(Array(50), (x, index) => index + 1);
    } else if (data.quantity > 0) {
      if (data.quantity > 20) {
        obj = Array.from(Array(20), (x, index) => index + 1);
      } else {
        let size = Number(data.quantity);
        obj = Array.from(Array(size), (x, index) => index + 1);
      }
    }
    obj.unshift(0);

    if (data.tickets) {
      data.tickets.forEach((ticket) => {
        ticket.ticketLimit = Array.from(
          { length: ticket.quantity },
          (_, index) => index + 1
        );
      });
    }

    let remaining =
      doc.data.body.data.groupTicket.totalTickets -
      doc.data.body.data.groupTicket.remainingTickets;

    if (data.type) {
      if (data.type === "Bus" || data.type === "Tailgate") {
        this.setState({
          ...data,
          eventTime: data.eventDate ? dayjs(data.eventDate * 1000) : null,
          eventDate: data.eventDate ? dayjs(data.eventDate * 1000) : null,
          saleEndDate: data.saleEndDate ? dayjs(data.saleEndDate * 1000) : null,
          saleEndTime: data.saleEndTime ? dayjs(data.saleEndTime * 1000) : null,
          loadingDocument: false,
          // generateBarCode: true,
          checkMatchRequired: false,
          oldTicketsCount: data.oldTicketsCount,
          soldTickets: remaining,
          matchList: matchList,
          match: matchObj,
          ticketLimit: obj,
        });
      } else if (data.type === "Group Match") {
        this.setState({
          ...data,
          eventTime: data.eventDate ? dayjs(data.eventDate * 1000) : null,
          eventDate: data.eventDate ? dayjs(data.eventDate * 1000) : null,
          saleEndDate: data.saleEndDate ? dayjs(data.saleEndDate * 1000) : null,
          saleEndTime: data.saleEndTime ? dayjs(data.saleEndTime * 1000) : null,
          loadingDocument: false,
          // generateBarCode: false,
          checkMatchRequired: true,
          oldTicketsCount: data.oldTicketsCount,
          soldTickets: remaining,
          matchList: matchList,
          match: matchObj,
          ticketLimit: obj,
        });
      } else {
        this.setState({
          ...data,
          eventTime: data.eventDate ? dayjs(data.eventDate * 1000) : null,
          eventDate: data.eventDate ? dayjs(data.eventDate * 1000) : null,
          saleEndDate: data.saleEndDate ? dayjs(data.saleEndDate * 1000) : null,
          saleEndTime: data.saleEndTime ? dayjs(data.saleEndTime * 1000) : null,
          loadingDocument: false,
          // generateBarCode: true,
          checkMatchRequired: false,
          oldTicketsCount: data.oldTicketsCount,
          soldTickets: remaining,
          matchList: matchList,
          match: matchObj,
          ticketLimit: obj,
        });
      }
    } else {
      this.setState({
        ...data,
        eventTime: data.eventDate ? dayjs(data.eventDate * 1000) : null,
        eventDate: data.eventDate ? dayjs(data.eventDate * 1000) : null,
        saleEndDate: data.saleEndDate ? dayjs(data.saleEndDate * 1000) : null,
        saleEndTime: data.saleEndTime ? dayjs(data.saleEndTime * 1000) : null,
        loadingDocument: false,
        oldTicketsCount: data.oldTicketsCount,
        soldTickets: remaining,
        matchList: matchList,
        match: matchObj,
        ticketLimit: obj,
      });
    }
  };

  getMatch = (id) => {
    let hours = 0,
      minutes = 0,
      seconds = 59;

    let date = null;
    let matchObj = null;
    let loc = "";
    this.state.matchList.map((match) => {
      if (String(match.fixture_id) === String(id)) {
        date = match.event_date;
        loc = { address: match.venue };
        matchObj = match;
      }
    });

    if (date) {
      if (this.state.type === "Group Match" || this.state.type === "Parking") {
        this.setState({
          saleEndDate: dayjs(date),
          saleEndTime: dayjs(date),
          eventDate: dayjs(date),
          eventTime: dayjs(date),
          location: loc,
          match: matchObj,
        });
      } else {
        this.setState({
          saleEndTime: dayjs(date).set({
            h: hours,
            m: minutes,
            second: seconds,
          }),
          saleEndDate: dayjs(date).set({
            h: hours,
            m: minutes,
            second: seconds,
          }),
          eventDate: dayjs(date).set({
            h: hours,
            m: minutes,
            second: seconds,
          }),
          eventTime: dayjs(date).set({
            h: hours,
            m: minutes,
            second: seconds,
          }),
          match: matchObj,
        });
      }
    }
  };

  getMatchList = (prevProps = this.props) => {
    if (
      this.props.clubId &&
      (this.props.currentGroup !== prevProps.currentGroup ||
        this.state.matchList.length === 0)
    ) {
      Axios.get(`fixtures?team=${this.props.clubId}&next=99`).then(
        (response) => {
          let data = response.data.response;
          let res = [];
          data.forEach((fixtureData) => {
            res.push({
              fixture_id: fixtureData.fixture.id,
              league_id: fixtureData.league.id,
              league: fixtureData.league,
              event_date: fixtureData.fixture.date,
              event_timestamp: fixtureData.fixture.timestamp,
              firstHalfStart: fixtureData.fixture.periods.first,
              secondHalfStart: fixtureData.fixture.periods.second,
              round: fixtureData.league.round,
              status: fixtureData.fixture.status.long,
              statusShort: fixtureData.fixture.status.short,
              elapsed: fixtureData.fixture.status.elapsed,
              venue: fixtureData.fixture.venue.name,
              referee: fixtureData.fixture.referee,
              homeTeam: {
                team_id: fixtureData.teams.home.id,
                team_name: fixtureData.teams.home.name,
                logo: fixtureData.teams.home.logo,
              },
              awayTeam: {
                team_id: fixtureData.teams.away.id,
                team_name: fixtureData.teams.away.name,
                logo: fixtureData.teams.away.logo,
              },
              goalsHomeTeam: fixtureData.goals.home,
              goalsAwayTeam: fixtureData.goals.away,
              score: {
                halftime: fixtureData.score.halftime.home
                  ? `${fixtureData.score.halftime.home}-${fixtureData.score.halftime.away}`
                  : null,
                fulltime: fixtureData.score.fulltime.home
                  ? `${fixtureData.score.fulltime.home}-${fixtureData.score.fulltime.away}`
                  : null,
                extratime: fixtureData.score.extratime.home
                  ? `${fixtureData.score.extratime.home}-${fixtureData.score.extratime.away}`
                  : null,
                penalty: fixtureData.score.penalty.home
                  ? `${fixtureData.score.penalty.home}-${fixtureData.score.penalty.away}`
                  : null,
              },
            });
          });
          this.setState({
            matchList: res,
            loadingMatches: false,
          });
        }
      );
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (state.clubId !== props.clubId || state.groupId !== props.currentGroup) {
      return {
        clubId: props.clubId,
        groupId: props.currentGroup,
      };
    }
  }

  syncSpecialTicketCount = () => {
    let groupId = this.props.currentGroup;
    return new Promise(async (resolve, rej) => {
      try {
        var timezone = "America/Panama";
        var now = dayjs().tz(timezone).unix();
        var ticketsDocs = await getDocs(
          query(
            collection(db, "tickets", groupId, "ticket"),
            where("match.fixtureId", "==", 0),
            where("groupTicket.eventDate", ">=", now)
          )
        );
        var count = 0;
        // if(ticketsDocs.docs.length>0){
        ticketsDocs.docs.forEach((ticket) => {
          count += ticket.data().groupTicket.remainingTickets;
        });
        await setDoc(doc(db, "matchDay", groupId, "matches", "0"), {
          ticketCount: count,
        });
        resolve();
      } catch (error) {
        resolve();
      }
    });
  };

  componentDidMount() {
    if (this.props.router.params.id) {
      if (this.props.currentGroup === this.props.router.params.group) {
        this.handleEditMode();
      }
    } else {
      this.setState({
        loadingDocument: false,
      });
    }
    /*
     * If we navigate from some other page to this screen we will already have
     * club id and match list will be fetched, if we refresh the page componentdidupdate
     * will do the same
     */
    this.getPaymentDetails();
    this.getMatchList();
    getLocations(this.props.currentGroup).then((locations) => {
      this.setState({
        allLocations: locations,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentGroup !== prevProps.currentGroup) {
      if (this.props.router.params.id && this.state.loadingDocument) {
        this.handleEditMode();
      }
      this.getPaymentDetails();
      this.getMatchList(prevProps);
      getLocations(this.props.currentGroup).then((locations) => {
        this.setState({
          allLocations: locations,
        });
      });
    }
  }
}

const mapStateToProps = (state) => {
  let currentGroup = state.adminData.currentGroup;
  let { clubId } = state.adminData.allGroups[currentGroup].data;
  return {
    user: state.user,
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    groupName:
      state.adminData.allGroups[state.adminData.currentGroup].data.groupName,
    currentGroup,
    clubId,
  };
};

export default connect(mapStateToProps)(withRouter(CreateTicket));
