import React from "react";
import { Button, Popconfirm, Spin } from "antd";
import "./MemberActions.css";

import { getMyId } from "../../../../../services/firebaseService/endPoints/admin/members";
import useMemberActions from "./useMemberActions";
import { AddIcon, DownloadIcon, ReloadIcon } from "../../../../../utils/icons";
import IconContainer from "../../../../../CommonComponents/IconContainer";

const MemberActions = ({ tableData }) => {
  const {
    groupData,
    showAddMember,
    downloading,
    handleMemberRefresh,
    handleDownloadCsv,
  } = useMemberActions(tableData);

  return (
    <div className="members-actions-container">
      <div>
        <h3
          style={{
            // color: "var(--primary-background)",
            marginBottom: "0px",
            display: "flex",
            gap: "6px",
          }}
        >
          <span>Members </span>
          {tableData && tableData.length >= 0 && (
            <div>
              <span
                style={{ fontSize: "18px", color: "gray", fontWeight: "400" }}
              >{`(${tableData.length})`}</span>
            </div>
          )}
        </h3>
      </div>

      <div
        className="members-actions-search-container"
        style={{ display: "flex", gap: "12px" }}
      >
        <div className="member-actions-buttons">
          <Popconfirm
            title="Download all members list?"
            onConfirm={handleDownloadCsv}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
            trigger="click" // Explicitly set trigger type
          >
            <div className="icon-container" style={{ cursor: "pointer" }}>
              {downloading ? (
                <Spin style={{ fontSize: "20px" }} />
              ) : (
                <img
                  src={DownloadIcon}
                  alt="icon"
                  style={{ fontSize: "20px" }}
                />
              )}
            </div>
          </Popconfirm>

          <Popconfirm
            title="Refresh Member List?"
            onConfirm={handleMemberRefresh}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <div className="icon-container" style={{ cursor: "pointer" }}>
              {downloading ? (
                <Spin style={{ fontSize: "20px" }} />
              ) : (
                <img src={ReloadIcon} alt="icon" style={{ fontSize: "20px" }} />
              )}
            </div>
          </Popconfirm>
          {showAddMember && (
            <Popconfirm
              title="This allows you to register a member while bypassing the payment process. Continue?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                let url = `https://group.chant.fan/${groupData?.groupName.replace(/ /g, "").replaceAll("#", "%23").toLowerCase()}/join/addMember?id=${getMyId()}`;
                window.open(url);
              }}
              onCancel={() => {}}
            >
              <Button
                style={{
                  backgroundColor: "var(--primary-background)",
                  color: "var(--primary-text-color)",
                }}
                icon={
                  <img
                    src={AddIcon}
                    alt="add-member"
                    style={{ fontSize: "18px", color: "white" }}
                  />
                }
              >
                Add Member
              </Button>
            </Popconfirm>
          )}
        </div>
      </div>
    </div>
  );
};

export default MemberActions;
