import React from "react";
import MembershipPackageTable from "./MembershipPackageTable";
import { selectCurrentGroupData } from "../../../../../redux/selectors/adminData";
import { useSelector } from "react-redux";
import { fetchPackages } from "../../../../../services/api/packages";
import useMembers from "../../Members/useMembers";
import "./Packages.css";

const PackagesTable = () => {
  const groupData = useSelector(selectCurrentGroupData);

  const { allMembers, membersLoading } = useMembers();

  const packageAutoRenewCountMap = allMembers?.reduce((acc, obj) => {
    const { package: pkgId, subscriptionDetail } = obj;

    if (subscriptionDetail?.status === "active") {
      acc[pkgId] = (acc[pkgId] || 0) + 1;
    }

    return acc;
  }, {});

  const onPackageModalClose = () => {
    fetchPackages(groupData.id);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "0",
      }}
      className="packages-table-container"
    >
      <div>
        <div style={{ padding: "0px 1rem" }}>
          <p>
            Sell membership packages, merchandise (add through the store) and
            collect donations. Stripe account required. Active’ packages will
            appear on the web registration page.
          </p>
          <p>
            Members who purchase a package are automatically pre-registered for
            Chant.
          </p>
          <p>
            You can offer packages and donations subscriptions (auto-renewal).
            Members can cancel subscriptions from their profile (in Chant) or
            via the Cancel link from the subscription email receipt.
          </p>
          <p>
            If package includes merchandise (scarf, shirt, etc) first add
            product to Store then select item in the ‘add package’ form below.
            Orders will then appear in the ‘Store: Orders’ report to track
            fulfillment.
          </p>
        </div>

        <MembershipPackageTable
          onCloseModal={onPackageModalClose}
          packageAutoRenewCountMap={packageAutoRenewCountMap}
          membersLoading={membersLoading}
        />
      </div>
    </div>
  );
};

export default PackagesTable;
