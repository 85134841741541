import { Table } from "antd";
import moment from "moment";
import React from "react";
import {
  fbGetAllGroupDetails,
  fetchTransactions,
} from "../../../services/firebaseService/endPoints/group";

class TransactionReport extends React.Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      loading: false,
    };
  }

  fetchGroups = () => {
    this.setState({ loading: true });
    fbGetAllGroupDetails().then((groups) => {
      groups.forEach((group) => {
        let groupDetails = {};
        groupDetails.groupName = group.groupName;
        groupDetails.groupId = group.id;
        groupDetails.stripeId =
          (group.paymentDetails || {}).stripeAccountId || "";

        fetchTransactions(group.id, groupDetails).then((data) => {
          this.setState((prevState) => {
            let newTableData = [
              ...(data || []),
              ...(prevState.tableData || []),
            ];

            newTableData.sort((a, b) => b.timestamp - a.timestamp);
            return {
              tableData: newTableData,
              loading: false,
            };
          });
        });
      });
    });
  };

  changeHeaderColor = () => {
    let elements = document.querySelectorAll("th");
    elements.forEach((elem) => {
      elem.style.backgroundColor = "#000000";
    });
  };

  render() {
    const columns = [
      {
        title: "Id",
        dataIndex: "groupId",
        key: "groupId",
        className: "header-white",
        width: 150,
      },
      {
        title: "Group Name",
        dataIndex: "groupName",
        width: 250,
        key: "groupName",
        className: "header-white",
      },
      {
        title: "Payment Intent Id",
        dataIndex: "paymentIntentId",
        key: "paymentIntentId",
        className: "header-white",
        width: 250,
        render: (data, record) => {
          let url = `https://dashboard.stripe.com/connect/accounts/${record.stripeId}/payments/${data}`;
          return (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {data}
            </a>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        className: "header-white",
        width: 50,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        className: "header-white",
        width: 250,
      },

      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        className: "header-white",
        width: 200,
      },
      {
        title: "Date",
        width: 200,
        dataIndex: "timestamp",
        key: "timestamp",
        className: "header-white",
        render: (data) => {
          let dateTime = moment.unix(
            parseInt(Number(`${data || 0}`.toString()) / 1000)
          );
          if (data) {
            return <span>{dateTime.format("MMM DD YYYY")}</span>;
          }
          return <></>;
        },
      },
      {
        title: "Time",
        width: 100,

        dataIndex: "timestamp",
        key: "timestamp",
        className: "header-white",
        render: (data) => {
          let dateTime = moment.unix(Number(`${data || 0}`.toString()));
          if (data) {
            return <span>{dateTime.format("HH:mm:ss")}</span>;
          }
          return <></>;
        },
      },
      {
        title: "Total amount",
        width: 100,

        dataIndex: "totalAmount",
        key: "totalAmount",
        className: "header-white",
      },
      {
        title: "Payment Type",
        width: 100,

        dataIndex: "paymentType",
        key: "paymentType",
        className: "header-white",
      },
    ];

    return (
      <div className="container p-5">
        <Table
          loading={this.state.loading}
          columns={columns}
          bordered
          dataSource={this.state.tableData}
          bodyStyle={{
            backgroundColor: "#ffffff",
          }}
          scroll={{ x: 1550 }}
          pagination={{
            position: ["bottomLeft"],
            defaultPageSize: 50,
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 50, 100],
          }}
          className="table-backdrop mb-3"
        />
      </div>
    );
  }

  componentDidMount() {
    this.fetchGroups();
    this.changeHeaderColor();
  }
}

export default TransactionReport;
