import React from "react";
import { Editor } from "react-draft-wysiwyg";
import ImageUploadButton from "./ImageUploaderButton";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Radio } from "antd";
import {
  AngularStripes,
  GroupNameAndStripes,
  SolidHeader,
  StripeHeader,
} from "../../../images/websiteHeader";
import { rgbToHex } from "../../../helperFunctions/util";
import CircularGrad from "../../../images/circlegradient_texture.png";
import FUpload from "../../commons/formFields/FUpload";

function getFontColor({ r, g, b, a }) {
  return `rgba(${r},${g},${b},${a})`;
}

const HEADER_OPTIONS = [
  {
    option: "Solid",
    value: "solid",
    image: (color, width, height) => SolidHeader(color, width, height),
  },
  {
    option: "Solid + two stripes",
    value: "solid-two-stripes",
    image: (color, width, height, groupName, textColor, secondaryColor) =>
      StripeHeader(color, width, height, secondaryColor),
  },
  {
    option: "Angle Stripes",
    value: "angle-stripes",
    image: (color, width, height) => AngularStripes(color, width, height),
  },
  {
    option: "Group Name + Slash marks",
    value: "group-name-slash",
    image: (color, width, height, groupName, textColor) => (
      <div style={{ height: height, width: width, position: "relative" }}>
        {GroupNameAndStripes(color, width, height)}
        <div
          style={{
            position: "absolute",
            right: 10,
            top: "50%",
            transform: "translate(0%,-50%)",
          }}
        >
          <span
            style={{
              color: textColor,
              fontSize: 24,
              fontWeight: 700,
              letterSpacing: "-0.04em",
              fontFamily: "Open Sans",
              filter: "brightness(80%)",
            }}
          >
            {groupName.toUpperCase()}
          </span>
        </div>
      </div>
    ),
  },
  // {
  //     option: 'Dot Pattern',
  //     value: 'dot-pattern',
  //     image: (color, width, height) => {
  //         return (
  //             <div style={{ height: height, width: width, backgroundColor: color, position: 'relative' }}>
  //                 <div style={{ position: 'absolute', height: '100%', width: '100%', backgroundImage: `url(${CircularGrad})`, top: 0, left: 0, backgroundSize: 'cover', opacity: '0.5' }}></div>
  //             </div>
  //         )
  //     }
  // }
];

const WELCOME_IMAGE_OPTIONS = [
  {
    option: "Single Image",
    images: 1,
    value: "single",
    imageHelpTexts: ["Landscape (4.5x1 ratio)"],
    aspectRatios: [4.5],
    wireframe: (
      <div
        style={{
          width: 225,
          height: 50,
          backgroundColor: "#dfdfdf",
          border: "1px solid #000000",
          display: "flex",
        }}
      ></div>
    ),
  },
  {
    option: (
      <div>
        Single Large
        <br /> image/gif + text'
      </div>
    ),
    images: 1,
    value: "singleLarge",
    imageHelpTexts: ["Landscape (2:1 ratio)"],
    aspectRatios: [2],
    textField: "true",
    wireframe: (
      <div
        style={{
          width: 225,
          height: 175,
          backgroundColor: "#dfdfdf",
          border: "1px solid #000000",
          display: "flex",
        }}
      ></div>
    ),
  },
  {
    option: "Two Images",
    images: 2,
    value: "two-images",
    imageHelpTexts: ["Landscape (3:1 ratio)", "Medium Landscape (1.5:1 ratio)"],
    aspectRatios: [3, 1.5],
    wireframe: (
      <div
        style={{
          width: 225,
          height: 50,
          backgroundColor: "#dfdfdf",
          display: "flex",
        }}
      >
        <div style={{ flex: 2, height: 50, border: "1px solid #000000" }}></div>
        <div style={{ flex: 1, height: 50, border: "1px solid #000000" }}></div>
      </div>
    ),
  },
  {
    option: "Four Images",
    images: 4,
    value: "four-images",
    imageHelpTexts: [
      "Landscape (3:1 ratio)",
      "Medium Portrait (3:4 ratio)",
      "Small Landscape (2:1 ratio)",
      "Small Landscape (2:1 ratio)",
    ],
    aspectRatios: [3, 3 / 4, 2, 2],
    wireframe: (
      <div
        style={{
          width: 225,
          height: 50,
          backgroundColor: "#dfdfdf",
          display: "flex",
        }}
      >
        <div style={{ flex: 3, height: 50, border: "1px solid #000000" }}></div>

        <div style={{ flex: 1, height: 50, border: "1px solid #000000" }}></div>

        <div
          style={{
            flex: 1,
            height: 50,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{ flex: 1, width: "100%", border: "1px solid #000000" }}
          ></div>
          <div
            style={{ flex: 1, width: "100%", border: "1px solid #000000" }}
          ></div>
        </div>
      </div>
    ),
  },
];

function getColor({ r, g, b }) {
  return rgbToHex(r, g, b);
}

class IntroSection extends React.Component {
  state = {
    intro: EditorState.createEmpty(),
    welcomeLayoutImages: [],
    welcomeLayout: "",
    welcomeImageLayoverText: "",
    headerOption: "",
    interstetial1: {
      image: null,
      text: "",
    },
    interstetial2: {
      image: null,
      text: "",
    },
    interstetial3: {
      image: null,
      text: "",
    },
    imageFileKeys: [
      "welcomeLayoutImages",
      "interstetial1.image",
      "interstetial2.image",
      "interstetial3.image",
    ],
  };

  handleEditorStateChange = (editorState) => {
    if (editorState instanceof EditorState)
      this.setState({ intro: editorState });
  };

  handleInputChange = (e) => {
    let state = this.state;

    let name = e.target.name;
    if (name.includes(".")) {
      name = name.split(".");
      state[name[0]][name[1]] = e.target.value;
    } else {
      state[name] = e.target.value;
    }
    this.setState({ ...state });
  };

  onHeaderOptionChange = (e) => {
    this.setState({
      headerOption: e.target.value,
    });
  };

  onWelcomeLayoutChange = (e) => {
    this.setState({
      welcomeLayout: e.target.value,
    });
  };

  render() {
    let height = 53;
    let width = 450;
    let color = getColor(this.props.adminData.data.configuration.primaryColor);
    let secondaryColor = getColor(
      this.props.adminData.data.configuration.secondaryColor
    );
    let groupName = this.props.adminData.data.groupName;
    let { r, g, b } = this.props.adminData.data.configuration.primaryColor;

    let textColor = getFontColor({ r, g, b, a: 1 });
    return (
      <div className="mt-5">
        <p style={{ fontSize: "20", fontWeight: "bold", marginBottom: 0 }}>
          Header & Welcome
        </p>
        <div className="mx-auto" style={{ width: "90%" }}>
          <div className="my-row">
            <div style={{ flex: 1 }}>
              <p style={{ marginBottom: -10 }}>Website Header</p>
            </div>
            <div style={{ flex: 3 }}>
              <div className="form-group col">
                <Radio.Group
                  onChange={this.onHeaderOptionChange}
                  style={{ width: "100%" }}
                  value={this.state.headerOption}
                >
                  {HEADER_OPTIONS.map(({ option, value, image }) => (
                    <div className="d-flex mt-2 justify-content-space-between">
                      <div style={{ flex: 0 }}>
                        <Radio value={value}>{/* {option} */}</Radio>
                      </div>
                      <div
                        style={{
                          flex: 3,
                          width: width,
                          height: height,
                          overflow: "hidden",
                        }}
                      >
                        {image(
                          color,
                          width,
                          height,
                          groupName,
                          textColor,
                          secondaryColor
                        )}{" "}
                      </div>
                    </div>
                  ))}
                </Radio.Group>
              </div>
            </div>
          </div>
          <div className="my-row mt-2">
            <div style={{ flex: 1 }}>
              <p style={{ marginBottom: -10 }}>Welcome Images</p>
            </div>
            <div style={{ flex: 3 }}>
              <div className="form-group col">
                <Radio.Group
                  onChange={this.onWelcomeLayoutChange}
                  style={{ width: "100%" }}
                  value={this.state.welcomeLayout}
                >
                  {WELCOME_IMAGE_OPTIONS.map(
                    (
                      {
                        option,
                        value,
                        imageHelpTexts,
                        wireframe,
                        aspectRatios,
                        textField,
                      },
                      index
                    ) => (
                      <div
                        key={value}
                        style={{ display: "flex", marginTop: "1rem" }}
                      >
                        <div style={{ flex: 1 }}>
                          <Radio value={value}>{option}</Radio>
                        </div>
                        <div style={{ flex: 3, marginLeft: "1rem" }}>
                          {imageHelpTexts.map((text, imgIndex) => (
                            <div
                              key={imgIndex}
                              style={{ display: "flex", marginTop: "0.5rem" }}
                            >
                              <ImageUploadButton
                                text="Upload Image"
                                images={[
                                  this.state.welcomeLayoutImages[imgIndex],
                                ]}
                                onSave={(images) => {
                                  const welcomeLayoutImages = [
                                    ...this.state.welcomeLayoutImages,
                                  ];
                                  welcomeLayoutImages[imgIndex] = images[0];
                                  this.setState({ welcomeLayoutImages });
                                }}
                                disabled={this.state.welcomeLayout !== value}
                                aspectRatio={aspectRatios[imgIndex]}
                              />

                              <div style={{ marginLeft: "0.5rem" }}>{text}</div>
                            </div>
                          ))}
                          {textField && (
                            <div
                              style={{ flexGrow: 1, padding: "20px 20px 0 0" }}
                            >
                              <textarea
                                rows={2}
                                style={{
                                  width: "100%",
                                  borderRadius: "5px",
                                  borderColor: "#ced4da",
                                  padding: "5px",
                                }}
                                maxLength={150}
                                value={this.state.welcomeImageLayoverText}
                                onChange={this.handleInputChange}
                                name="welcomeImageLayoverText"
                                placeholder="(optional) Enter text. Max 150 chars"
                                tabIndex="2"
                              />
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "1rem",
                          }}
                        >
                          {wireframe}
                        </div>
                      </div>
                    )
                  )}
                </Radio.Group>
              </div>
            </div>
          </div>
          <div className="my-row mt-2">
            <div style={{ flex: 1 }}>
              <p style={{ marginBottom: -10 }}>Welcome message</p>
              <span style={{ fontSize: 12 }}>(max 500 characters)</span>
            </div>
            <div style={{ flex: 3 }}>
              <div style={{ border: "1px solid #ced4da", borderRadius: 5 }}>
                <Editor
                  editorState={this.state.intro}
                  onEditorStateChange={this.handleEditorStateChange}
                  toolbar={{
                    options: ["inline", "list", "link"],
                    inline: {
                      inDropdown: false,
                      options: ["bold", "italic", "underline"],
                    },
                    list: {
                      inDropdown: false,
                      options: ["unordered", "ordered"],
                    },
                    link: {
                      inDropdown: false,
                      defaultTargetOption: "_self",
                      options: ["link", "unlink"],
                      linkCallback: undefined,
                    },
                  }}
                  stripPastedStyles={true}
                  tabIndex="2"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <div className="my-row">
            <p style={{ fontSize: "20", fontWeight: "bold", marginBottom: 0 }}>
              Section Breaks
            </p>
            <p style={{ fontSize: 15 }}>
              Create up to three page interstitials (visual breaks between site
              sections) by adding image/gifs and very brief text overlays.
              Consider a group motto, portion of a chant, or stance on
              openness/diversity. Ensure images are good resolution. Gifs can be
              generated from a tool like Giphy.
            </p>
            {/* <p style={{ fontSize: 15 }}>Note: You can create a gif from a video or series of images with tools like Giphy, y and z.</p> */}
          </div>
          <div className="mx-auto" style={{ width: "90%" }}>
            <div className="px-5">
              <div className="my-row mt-2">
                <div style={{ flex: 1 }}>
                  <p style={{ marginBottom: -10 }}>Page transitions #1*</p>
                  <span style={{ fontSize: 12 }}>
                    (4:1 aspect ratio, max 150 characters)
                  </span>
                </div>
                <div style={{ flex: 3, display: "flex", alignItems: "center" }}>
                  <ImageUploadButton
                    aspectRatio={2}
                    text="Add image or gif #1"
                    images={[this.state.interstetial1.image]}
                    onSave={(images) => {
                      let interstetial1 = this.state.interstetial1;
                      interstetial1.image = images[0];
                      this.setState({ interstetial1 });
                    }}
                  />

                  <div style={{ flexGrow: 1 }}>
                    <textarea
                      rows={2}
                      style={{
                        marginLeft: 20,
                        width: "100%",
                        borderRadius: 5,
                        borderColor: "#ced4da",
                        padding: 5,
                      }}
                      maxLength={150}
                      value={this.state.interstetial1.text}
                      onChange={this.handleInputChange}
                      name="interstetial1.text"
                      display="col"
                      placeholder="Enter text (optional)"
                      tabIndex="2"
                    />
                  </div>
                </div>
              </div>
              <div className="my-row mt-2">
                <div style={{ flex: 1 }}>
                  <p style={{ marginBottom: -10 }}>Page transitions #2*</p>
                  <span style={{ fontSize: 12 }}>
                    (4:1 aspect ratio, max 150 characters)
                  </span>
                </div>
                <div style={{ flex: 3, display: "flex", alignItems: "center" }}>
                  <ImageUploadButton
                    aspectRatio={2}
                    text="Add image or gif #2"
                    images={[this.state.interstetial2.image]}
                    onSave={(images) => {
                      let interstetial2 = this.state.interstetial2;
                      interstetial2.image = images[0];
                      this.setState({ interstetial2 });
                    }}
                  />
                  <div style={{ flexGrow: 1 }}>
                    <textarea
                      rows={2}
                      style={{
                        marginLeft: 20,
                        width: "100%",
                        borderRadius: 5,
                        borderColor: "#ced4da",
                        padding: 5,
                      }}
                      maxLength={150}
                      value={this.state.interstetial2.text}
                      onChange={this.handleInputChange}
                      name="interstetial2.text"
                      display="col"
                      placeholder="Enter text (optional)"
                      tabIndex="2"
                    />
                  </div>
                </div>
              </div>
              <div className="my-row mt-2">
                <div style={{ flex: 1 }}>
                  <p style={{ marginBottom: -10 }}>Page transitions #3*</p>
                  <span style={{ fontSize: 12 }}>
                    (4:1 aspect ratio, max 150 characters)
                  </span>
                </div>
                <div style={{ flex: 3, display: "flex", alignItems: "center" }}>
                  <ImageUploadButton
                    aspectRatio={2}
                    text="Add image or gif #3"
                    images={[this.state.interstetial3.image]}
                    onSave={(images) => {
                      let interstetial3 = this.state.interstetial3;
                      interstetial3.image = images[0];
                      this.setState({ interstetial3 });
                    }}
                  />
                  <div style={{ flexGrow: 1 }}>
                    <textarea
                      rows={2}
                      style={{
                        marginLeft: 20,
                        width: "100%",
                        borderRadius: 5,
                        borderColor: "#ced4da",
                        padding: 5,
                      }}
                      maxLength={150}
                      value={this.state.interstetial3.text}
                      onChange={this.handleInputChange}
                      name="interstetial3.text"
                      display="col"
                      placeholder="Enter text (optional)"
                      tabIndex="2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  static getDerivedStateFromProps(props) {
    let websiteConfig = props.adminData.data.websiteConfig;
    if (props.updated) {
      if (websiteConfig && websiteConfig.intro) {
        let intro = EditorState.createEmpty();
        if (websiteConfig.intro.intro) {
          let introDraft = htmlToDraft(websiteConfig.intro.intro);
          let introContentState = ContentState.createFromBlockArray(
            introDraft.contentBlocks
          );
          let introEditorState =
            EditorState.createWithContent(introContentState);

          intro = introEditorState;
        }

        return {
          ...websiteConfig.intro,
          intro: intro,
        };
      }
      return {
        intro: EditorState.createEmpty(),
        welcomeLayoutImages: [],
        welcomeLayout: "",
        headerOption: "",
        interstetial1: {
          image: null,
          text: "",
        },
        interstetial2: {
          image: null,
          text: "",
        },
        interstetial3: {
          image: null,
          text: "",
        },
      };
    }
    return null;
  }

  componentDidUpdate() {
    if (this.props.save && !this.props.isUpdated) {
      let intro = this.state;
      intro.intro = draftToHtml(convertToRaw(intro.intro.getCurrentContent()));

      this.props.onSave({
        intro: { ...intro },
      });
    }
  }
}

export default IntroSection;
