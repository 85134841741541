// 3rd party design libraries
import { Checkbox, DatePicker, Input, Spin, TimePicker, Tooltip } from "antd";
// 3rd party helper libraries
import React from "react";
import Content from "../../../models/admin/Content";
// redux store actions
import {
  startAddContent,
  startEditContent,
} from "../../../redux/actions/adminData";
import { applicationError } from "../../../redux/actions/error";
// api end points
import Axios from "../../../services/apiFootballService/connection";
import {
  fbCheckChatChannelName,
  fbCreateContent,
  fbGetChatChannel,
  fbGetContent,
  fbUpdateContent,
  fbUpdateNotification,
  getLocations,
} from "../../../services/firebaseService/endPoints/admin/content";
//
import utc from "dayjs/plugin/utc";
import { connect } from "react-redux";
import { FInput } from "../../commons/formFields/FInput";
import { FSelect } from "../../commons/formFields/FSelect";

// common components
import { BellFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import { getBlob, ref } from "firebase/storage";
import { postUpdateCDNUtil, randomString } from "../../../helperFunctions/util";
import DefaultSettingsImage from "../../../images/Chant_Event_Default_Settings.png";
import { storage } from "../../../services/firebaseService/connection";
import { toDayjs, withRouter } from "../../../utils/helper";
import logger from "../../../utils/logger";
import ImageUploadButton from "../website/ImageUploaderButton";

dayjs.extend(utc);

let defaultDateConfig = {
  "Watch Party": {
    homeFeed: 7,
    notifications: [1, 3],
  },
  Tailgate: {
    homeFeed: 7,
    notifications: [1, 3],
  },
  Travel: {
    homeFeed: 14,
    notifications: [7, 14],
  },
  Volunteer: {
    homeFeed: 14,
    notifications: [1, 7],
  },
  News: {
    homeFeed: -7,
    notifications: [0],
  },
  Update: {
    homeFeed: -7,
    notifications: [0],
  },
  "Special Event": {
    homeFeed: 14,
    notifications: [3, 14],
  },
  "Meet-up": {
    homeFeed: 7,
    notifications: [1, 3],
  },
  "Virtual Event": {
    homeFeed: 7,
    notifications: [1, 3],
  },
  Screening: {
    homeFeed: 7,
    notifications: [1, 3],
  },
};

const timeFormat = "HH:mm";
const { RangePicker } = DatePicker;

const VolunteerTemplate = {
  title: "",
  description: "",
  slotCount: 0,
  startTime: "",
  endTime: "",
  points: 0,
};

class CreateContentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      author: "",
      location: "",
      // locationData: null,
      matchId: "",
      title: "",
      imageFile: "",
      imageList: [],
      eventStartDate: "",
      eventEndDate: "",
      eventStartTime: "",
      eventEndTime: "",
      details: "",
      chatChannelName: "",
      channelArchiveDateStart: "",
      channelArchiveDateEnd: "",
      notifications: [null],
      oldNotifications: [null],
      showOnHomePageStart: null,
      showOnHomePageEnd: null,
      previewVisible: false,
      previewImage: "",
      memberResponseState: 1,
      isLinkTicket: false,
      ticketId: "",
      ticketName: "",
      ticketMatchId: "",
      editMode: !!props.router.params.id,
      copyMode: !!props.currentContentId,
      editConfiguration: {
        imageChanged: false,
      },
      events: [
        "Watch Party",
        "Tailgate",
        "Travel",
        "Volunteer",
        "News",
        "Update",
        "Special Event",
        "Meet-up",
        "Virtual Event",
        "Screening",
      ],
      matchList: [],
      tokenList: [],
      clubId: props.clubId,
      groupId: props.currentGroup,
      // TODO get from props
      viewMode: true,
      setDefaults: false,
      showForFans: false,
      checkIn: {
        validateGeolocation: true,
      },
      allLocations: [],
      volunteerRequests: [],
      locationDataList: [],
      additionalLocations: [undefined],
      videoConferenceLink: "",
      meetingCode: "",
      isLoading: false,
      showOnHomePage: null,
    };
  }

  handleSubmit = async (e) => {
    try {
      e.preventDefault();

      // this.setState({
      //   isLoading: true,
      // });

      this.props.handleChildStateUpdate("isLoading", true);

      if (
        this.state.eventEndDate &&
        this.state.showOnHomePageEnd > this.state.eventEndDate
      ) {
        this.props.handleChildStateUpdate("errorInForm", {
          isError: true,
          visible: true,
          message:
            "Show on home screen end date should not be after event end date",
        });

        this.props.handleChildStateUpdate("isLoading", false);

        window.scrollTo(0, 0);
        return;
      }

      if (
        this.state.isLinkTicket === true &&
        (this.state.ticketId === "" || this.state.ticketName === "")
      ) {
        this.props.handleChildStateUpdate("errorInForm", {
          isError: true,
          visible: true,
          message: "Link proper ticket for member response",
        });

        this.props.handleChildStateUpdate("isLoading", false);

        window.scrollTo(0, 0);
        return;
      }
      let locationCondition =
        this.state.additionalLocations
          .map(
            (locationData) =>
              !locationData ||
              !locationData.locationData ||
              locationData.locationData.placeId === null ||
              locationData.locationData.placeId.trim() === ""
          )
          .filter((condition) => condition).length > 0;
      // let locationCondition = !this.state.locationData || !this.state.locationData.locationData || this.state.locationData.locationData.placeId === null || this.state.locationData.locationData.placeId.trim() === ""
      if (
        this.state.checkIn.validateGeolocation &&
        this.state.checkIn.allowCheckin &&
        locationCondition
      ) {
        this.props.handleChildStateUpdate("errorInForm", {
          isError: true,
          visible: true,
          message: "Google Maps link is required for check-in.",
        });

        this.props.handleChildStateUpdate("isLoading", false);

        window.scrollTo(0, 0);
        return;
      }
      if (this.state.checkIn.allowCheckin && !this.state.checkIn.season) {
        this.props.handleChildStateUpdate("errorInForm", {
          isError: true,
          visible: true,
          message: "Season is required for check-in.",
        });

        this.props.handleChildStateUpdate("isLoading", false);

        window.scrollTo(0, 0);
        return;
      }

      if (
        !this.state.eventStartTime &&
        !["News", "Update"].includes(this.state.type)
      ) {
        this.props.handleChildStateUpdate("errorInForm", {
          isError: true,
          visible: true,
          message: "Event start time is required.",
        });

        this.props.handleChildStateUpdate("isLoading", false);

        window.scrollTo(0, 0);
        return;
      }
      let data = new Content();
      data.toDataSet({
        ...this.state,
        matchId: String(this.state.matchId),
        showOnHomePageEnd: toDayjs(this.state.showOnHomePage?.[0]).unix(),
        showOnHomePageStart: toDayjs(this.state.showOnHomePage?.[1]).unix(),
      });
      data = JSON.parse(JSON.stringify(data));

      if (data.hasError) {
        window.scrollTo(0, 0);

        this.props.handleChildStateUpdate("errorInForm", {
          isError: true,
          visible: true,
          message: data.hasError.message,
        });

        this.props.handleChildStateUpdate("isLoading", false);

        window.scrollTo(0, 0);
        setTimeout(this.handleErrorMessageClose, 5000);
      } else {
        this.setState({
          loadingPercentage: 10,
        });

        this.props.handleChildStateUpdate("isLoading", true);

        let promise;
        // new content

        if (!this.state.editMode && !this.state.copyMode) {
          promise = fbCreateContent(data, this.state.imageFile);
        } else if (this.state.editMode) {
          // edit content
          data.chatChannel.id = this.state.editConfiguration.channelId;
          promise = fbUpdateContent(
            data,
            this.props.router.params.id,
            this.state.editConfiguration.imageChanged
              ? this.state.imageFile
              : undefined,
            this.state.editConfiguration.channelChange &&
              this.state.chatChannelName,
            this.state.editConfiguration.newChannel &&
              this.state.chatChannelName,
            this.state.editConfiguration.imageChanged &&
              !this.state.imageFile.name
          );
        } else if (this.state.copyMode) {
          const storageRef = ref(storage);
          const imagePath = "content/images/";

          const folderRef = ref(
            storageRef,
            `${this.state.groupId}/${imagePath}${this.props.currentContentId}`
          );

          let imageData = undefined;

          if (this.state.imageFile && this.state.imageFile.length > 0) {
            imageData = await getBlob(folderRef);
          }

          fbCreateContent(data, imageData)
            .then((content) => {
              fbUpdateNotification(
                data,
                this.state.oldNotifications,
                this.props.currentGroup,
                content.id
              );
              postUpdateCDNUtil(this.props.currentGroup, "event");

              if (!this.state.editMode) {
                this.props.dispatch(
                  startAddContent(content, this.props.currentGroup)
                );
              } else {
                this.props.dispatch(
                  startEditContent(content, this.props.currentGroup)
                );
              }
              this.setState({
                loading: false,
              });
              this.props.router.navigate("/admin/content", { replace: true });
            })
            .catch((err) => logger.log("Failed to create content: ", err));
        }

        if (!this.state.copyMode) {
          promise.then((content) => {
            fbUpdateNotification(
              data,
              this.state.oldNotifications,
              this.props.currentGroup,
              content.id
            );
            postUpdateCDNUtil(this.props.currentGroup, "event");
            if (!this.state.editMode) {
              this.props.dispatch(
                startAddContent(content, this.props.currentGroup)
              );
            } else {
              this.props.dispatch(
                startEditContent(content, this.props.currentGroup)
              );
            }
            this.setState({
              loading: false,
            });
            this.props.router.navigate("/admin/content", { replace: true });
          });
        }

        this.props.handleModalOpen && this.props.handleModalOpen();
      }
    } catch (err) {
      logger.error("Failed to submit event: ", err);
    }
  };

  locationClear = (e) => {
    this.setState((prevState) => {
      const locationData = {
        ...prevState.locationData,
      };
      locationData.placeId = "";
      locationData.address = "";
      return {
        location: "",
        locationData,
      };
    });
  };
  mapUpdate = (e) => {
    this.setState((prevState) => {
      const locationData = {
        ...prevState.locationData,
      };
      // placeId: e.placeId,
      locationData.address = e.address;
      locationData.placeId = e.placeId;
      locationData.longitude = e.long;
      locationData.latitude = e.lat;
      return { locationData };
    });
  };
  handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
  };
  handleTicketListChange = (e) => {
    let value = e.target.value;
    if (value) {
      const selectedTicket = this.state.tokenList?.find(
        (ticket) => ticket?.code === value
      );

      const ticketId = selectedTicket?.id;
      const ticketMatchId = selectedTicket?.matchId;

      this.setState({
        ticketName: value,
        ticketId: ticketId,
        ticketMatchId: ticketMatchId,
        isLinkTicket: true,
      });
    } else {
      this.setState({
        ticketName: "",
        ticketId: "",
        ticketMatchId: "",
        isLinkTicket: false,
      });
    }
  };

  // Called on form select options changes
  handleOptionChange = (e) => {
    let startDate;
    const { name, value } = e.target;

    if (name === "matchId") {
      const selectedMatch = this.state.matchList.find(
        (match) => Number(match?.fixture_id) === Number(value)
      );

      if (selectedMatch) {
        startDate = selectedMatch?.event_timestamp;
      }

      this.setState((prevState) => ({
        [name]: value,
        eventStartDate: startDate,
        eventStartTime: startDate,
        setDefaults: true,
        checkIn: {
          ...prevState.checkIn,
          season:
            startDate && selectedMatch?.league?.season
              ? selectedMatch.league.season
              : null,
        },
      }));
    } else {
      this.setState({
        [name]: value,
      });
    }
  };
  handleRadioChange = (e) => {
    if (Number(e.target.value) === 2) {
      this.setState({
        memberResponseState: e.target.value,
        isLinkTicket: true,
      });
    } else if (Number(e.target.value) === 1) {
      this.setState({
        ticketName: "",
        ticketId: "",
        ticketMatchId: "",
        memberResponseState: e.target.value,
        isLinkTicket: false,
      });
    }
  };
  handleMapOK = (e) => {
    this.props.handleChildStateUpdate("showModal", !this.props.showModal);
    //setState is called inn below funnction so no need too call above
    this.child.updateFormMapData();
  };
  handleModalChange = (e) => {
    this.props.handleChildStateUpdate("showModal", !this.props.showModal);
  };

  handleDateChange = (name, value) => {
    // checking for chat channel dates change in edit mode.
    let validValue = "";

    if (!Array.isArray(value)) {
      validValue = dayjs(value).isValid() ? value : "";
    } else {
      validValue = value.every((date) => dayjs(date).isValid()) ? value : "";
    }

    let hours = 0,
      minutes = 0,
      seconds = 59;
    if (name.includes("End")) {
      hours = 23;
      minutes = 59;
    }
    if (name === "eventStartTime") {
      this.setState({
        eventStartTime: validValue,
      });
    }

    if (this.state.editMode && name === "channelArchiveDate") {
      this.setState((prevState) => {
        let editConfiguration = {
          ...prevState.editConfiguration,
        };
        editConfiguration.channelChange = true;
        return {
          editConfiguration,
        };
      });
    }

    if (name === "eventStartDate" && !validValue) {
      this.setState({
        eventStartDate: "",
        eventEndDate: "",
      });

      this.setState({
        showOnHomePageStart: "",
        showOnHomePageEnd: "",
        notifications: [null],
      });
    } else if (name.includes("Time")) {
      let date = this.state[name.replace("Time", "Date")];
      if (date) {
        if (validValue) {
          hours = validValue.hour();
          minutes = validValue.minute();
          seconds = "00";
        }
        let time = dayjs
          .unix(date)
          .hour(hours)
          .minute(minutes)
          .second(seconds)
          .unix();

        this.setState({
          [name.replace("Time", "Date")]: time,
          // eventStartTime: validValue
        });
      }
    } else if (name === "showOnHomePage") {
      if (value && value.length === 2) {
        this.setState({
          showOnHomePageStart: value[0],
          showOnHomePageEnd: value[1],
        });
      } else {
        this.setState({
          showOnHomePageStart: null,
          showOnHomePageEnd: null,
        });
      }
    } else if (name === "channelArchiveDate") {
      let channelArchiveDateStart = "",
        channelArchiveDateEnd = "";
      if (validValue.length === 2) {
        channelArchiveDateStart = dayjs(validValue[0])
          .set("hour", hours)
          .set("minute", minutes)
          .unix();

        channelArchiveDateEnd = dayjs(validValue[1])
          .set("hour", hours)
          .set("minute", minutes)
          .unix();
      }
      this.setState({
        channelArchiveDateStart,
        channelArchiveDateEnd,
      });
    } else {
      if (validValue) {
        const date = dayjs(validValue)
          .set("hour", hours)
          .set("minute", minutes)
          .set("second", seconds)
          .unix();

        this.setState(
          {
            [name]: date,
          },
          () => {
            let configuration = defaultDateConfig[this.state.type];
            if (configuration) {
              const data = this.handleDisplayNotificationDates(
                date,
                configuration
              );
              this.setState({
                ...data,
              });
            }
          }
        );
      } else {
        this.setState({
          [name]: "",
        });
      }
    }
  };

  handleChatChannelChange = (e) => {
    const regex = /^[a-z0-9\-]*$/;
    this.setState({
      isChatChannelValid: undefined,
    });

    if (this.state.editMode) {
      if (this.state.editConfiguration.hasChannel) {
        this.setState((prevState) => {
          let editConfiguration = {
            ...prevState.editConfiguration,
          };
          editConfiguration.channelChange = true;
          return {
            editConfiguration,
          };
        });
      } else {
        this.setState((prevState) => {
          let editConfiguration = {
            ...prevState.editConfiguration,
          };
          editConfiguration.newChannel = true;
          return {
            editConfiguration,
          };
        });
      }
    }
    let value = e.target.value.toLowerCase();
    // if (value.length === 1 && value[0] !== '#') {
    //     value = "#" + value
    // }
    let valid = regex.test(value);

    if (e.target.value.length === 0) {
      valid = true;
    }
    if (valid) {
      // if (this.state.editMode && this.state.editConfiguration.hasChannel && e.target.value.length === 1) {
      //     this.setState({ errorInForm: { isError: true, visible: true, message: "Wholla you cant do that !\n Name is required for chat-channel" } })
      // } else {
      this.setState({
        chatChannelName: value,
      });

      this.props.handleChildStateUpdate("errorInForm", {
        isError: false,
        visible: false,
        message: "",
      });

      // }
    } else {
      this.props.handleChildStateUpdate("errorInForm", {
        isError: true,
        visible: true,
        message:
          "Wholla you cant add that !\n Chat channel name should be in this format, #chat-cannel-name",
      });
    }
  };

  handleChatFocusChange = (e) => {
    if (this.state.chatChannelName) {
      const regex = /^[a-z0-9\-]*[a-z0-9]$/;
      let valid = regex.test(this.state.chatChannelName);
      if (valid) {
        fbCheckChatChannelName(
          this.props.currentGroup,
          this.state.chatChannelName,
          this.props.router.params.id
        )
          .then(() => {
            this.setState({
              isChatChannelValid: true,
            });
          })
          .catch(() => {
            this.setState({
              isChatChannelValid: false,
            });
          });
      }
    }
  };

  handleNotificationsChange = (value, index) => {
    if (value)
      value = dayjs(value)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .unix();

    this.setState((prevState) => {
      let notifications = [...prevState.notifications];
      notifications[index] = value;
      return {
        notifications,
      };
    });
  };

  handleErrorMessageClose = () => {
    this.props.handleChildStateUpdate("errorInForm", {
      ...this.props.errorInForm,
      visible: false,
    });
  };

  // Called whenever a new image is uploaded to deleted
  handleImageChange = ({ fileList }) => {
    let result = {};
    if (fileList.length === 0) {
      result.imageFile = {};
    } else {
      result.imageFile = fileList[0].originFileObj;
    }

    if (this.state.editMode && !this.state.editConfiguration.imageChanged) {
      this.setState((prevState) => {
        let editConfiguration = Object.assign({}, prevState.editConfiguration);
        editConfiguration.imageChanged = true;
        return {
          editConfiguration,
        };
      });
    }

    result.imageList = fileList;

    this.setState(() => result);
  };

  uploadAction = (file, fileList) => {
    // fbUploadGroupLogo("testing", file)
    // this.handleImageChange({fileList})
    this.setState(() => ({
      imageFile: file,
    }));

    // return true
  };

  handleAddNotifications = () => {
    this.setState((prevState) => {
      let notifications = [...prevState.notifications];
      notifications.push(null);
      return {
        notifications,
      };
    });
  };

  checkChange = (e) => {
    this.setState({
      showForFans: e.target.checked,
    });
  };

  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    let eventStartDate = this.state.eventStartDate;
    let eventStartTime = this.state.eventStartTime;

    if (this.state.eventStartDate) {
      eventStartDate = dayjs.unix(this.state.eventStartDate);
    }

    if (eventStartDate) {
      eventStartTime =
        dayjs.unix(this.state.eventStartDate).second() !== 59
          ? dayjs(
              `${eventStartDate.hour()}:${eventStartDate.minute()}`,
              timeFormat
            )
          : null;
    }

    const layout2 = this.state.type === "News" || this.state.type === "Update";
    let loadingTitle = "",
      loadingMessage = "";
    if (this.props.loadingDocument) {
      loadingTitle = "Loading Document";
      loadingMessage = "Please wait while we fetch data for you.";
    } else if (this.props.loadingMatches) {
      loadingTitle = "Loading Matches";
      loadingMessage = "Please wait while we fetch match details. ";
    } else if (this.props.isLoading) {
      loadingTitle = this.state.editMode
        ? "Updating Content"
        : "Creating Content";
      loadingMessage = this.state.editMode
        ? "Please wait while we update the content for you"
        : "Please wait while we create a new content for you.";
    }

    return (
      <div
        className="border rounded p-3 px-3 mt-4"
        style={{
          backgroundColor: "#ffffff",
          // boxShadow: "0px 3px 10px 1px",
        }}
      >
        {(this.state.copyMode && this.props.isLoading) ||
        this.props.loadingMatches ||
        this.state.isLoading ||
        this.props.loadingDocument ? (
          <div
            style={{ height: "20vh" }}
            className="d-flex justify-content-center align-items-center mt-2"
          >
            <Spin size="large" />
          </div>
        ) : (
          <div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="col-md-8">
                <div style={{ display: "flex", paddingBottom: "10px" }}>
                  <div className={`col-md-12`}>
                    <FSelect
                      label="Content Type"
                      value={this.state.type}
                      onChange={this.handleOptionChange}
                      name="type"
                      dataList={this.state.events}
                      dataOnlyValue
                      placeholder="--Select Event Type--"
                      display="row"
                      divClassname="row"
                      tabIndex="1"
                      required
                      disabled={this.state.viewMode}
                      flex
                    />
                  </div>
                </div>
                <div style={{ display: "flex", paddingBottom: "10px" }}>
                  <div className="col-md-12">
                    <FSelect
                      flex
                      label="Match"
                      name="matchId"
                      display="row"
                      tabIndex="2"
                      tooltip="If selected, event will appear in Match Day immediately upon submission and not expire. Opponent logo will appear on content card."
                      value={
                        this.state.matchId ? Number(this.state.matchId) : null
                      }
                      onChange={this.handleOptionChange}
                      placeholder={
                        this.props.loadingMatches &&
                        (this.state.matchList.length === 0 ||
                          this.state.matchId === 0)
                          ? "Loading Matches..."
                          : "--Select Match--"
                      }
                      dataList={this.state.matchList}
                      listBuilder={(match, index) => {
                        if (new Date(match.event_date).getTime() > Date.now()) {
                          return (
                            <option key={index} value={match.fixture_id}>
                              {match.homeTeam.team_name + " "}
                              vs {match.awayTeam.team_name}{" "}
                              {`(${dayjs(match.event_date).format("Do-MMM-YY")})`}
                            </option>
                          );
                        }
                      }}
                      disabled={this.state.viewMode}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", padding: "0" }}>
                  <div className={`col-md-12`}>
                    <FInput
                      label="Title"
                      value={this.state.title}
                      onChange={this.handleInputChange}
                      name="title"
                      display="row"
                      tooltip="Not Required"
                      placeholder="Season Kickoff Party"
                      tabIndex="2"
                      disabled={this.state.viewMode}
                    />
                  </div>
                </div>

                {layout2 && (
                  <div style={{ display: "flex" }}>
                    <div className="col-md-12">
                      <FInput
                        label="Author Name"
                        value={this.state.author}
                        onChange={this.handleInputChange}
                        name="author"
                        display="row"
                        placeholder="Lisa Smith"
                        tabIndex="2"
                        disabled={this.state.viewMode}
                        // required
                      />
                    </div>
                  </div>
                )}

                <div style={{ display: "flex" }}>
                  <div className="form-group col-12">
                    <div style={{ display: "flex" }}>
                      <label className="col-md-4">
                        Event Date
                        <span
                          style={{
                            color: "red",
                          }}
                        >
                          {" "}
                          *{" "}
                        </span>
                        <Tooltip placement="topLeft" title="Required">
                          <span className="border-0 m-0 p-0 ml-2">
                            <i className="fa fa-question-circle-o" />
                          </span>
                        </Tooltip>
                      </label>
                      <div className="col-md-8">
                        <DatePicker
                          tabIndex="2"
                          value={eventStartDate}
                          onChange={(value) => {
                            this.handleDateChange("eventStartDate", value);
                          }}
                          disabledDate={(current) => {
                            return current && current < dayjs().startOf("day");
                          }}
                          className="form-control form-control-sm col-8"
                          size={10}
                          disabled={this.state.viewMode}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ display: "flex" }}>
                  <div className="form-group col-md-12">
                    <div style={{ display: "flex" }}>
                      <label className="col-md-4">
                        Event Time
                        {!["News", "Update"].includes(this.state.type) && (
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            {" "}
                            *{" "}
                          </span>
                        )}
                      </label>
                      <div className="col-md-8">
                        <TimePicker
                          tabIndex="2"
                          className="form-control form-control-sm input-height col-8"
                          value={eventStartTime}
                          defaultOpenValue={dayjs()
                            .set("hour", 0)
                            .set("minute", 0)}
                          onChange={(value) => {
                            this.handleDateChange("eventStartTime", value);
                          }}
                          format={timeFormat}
                          disabled={this.state.viewMode}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{ display: "flex", flexWrap: "wrap" }}
                  className=" mb-2"
                >
                  <div className="col-md-12">
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div className="col-md-4">
                        Event Location
                        <Tooltip
                          placement="topLeft"
                          title={() => {
                            return (
                              <div style={{ width: 400 }}>
                                <p>
                                  Locations added in the Content / Locations
                                  section of the dashboard appear here for
                                  selection.
                                </p>
                                <p>
                                  {" "}
                                  If event will take place across more than one
                                  location you can add locations to a single
                                  event or create multiple events. All addresses
                                  will be checked upon member check-in.
                                </p>
                              </div>
                            );
                          }}
                        >
                          <span className="border-0">
                            &nbsp;<i className="fa fa-question-circle-o"></i>
                          </span>
                        </Tooltip>
                      </div>
                      <div className="col-md-8">
                        {this.state.additionalLocations.map(
                          (locationData, index) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  gap: 10,
                                  flexWrap: "nowrap",
                                }}
                              >
                                <div className="col-md-12">
                                  <FSelect
                                    label=""
                                    name="locationData"
                                    display="row"
                                    tooltip="Locations added in the Content / Locations section of the dashboard appear here for selection."
                                    tabIndex="2"
                                    value={
                                      locationData ? locationData.id : null
                                    }
                                    // value={this.state.locationData ? this.state.locationData.id : null}
                                    onChange={(e) => {
                                      let additionalLocations =
                                        this.state.additionalLocations;
                                      if (e.target.value) {
                                        let value = e.target.value;
                                        // let locationIndex = -1;
                                        let location =
                                          this.state.allLocations.find(
                                            (e, index) => {
                                              if (e.id === value) {
                                                e.hidden = true;
                                                return true;
                                              }
                                            }
                                          );

                                        additionalLocations[index] = location;
                                        // this.state.locationDataList.splice(locationIndex, 1)
                                        this.setState({
                                          additionalLocations,
                                          // locationData: location
                                        });
                                      } else {
                                        let location = JSON.parse(
                                          JSON.stringify(
                                            additionalLocations[index]
                                          )
                                        );
                                        additionalLocations[index] = undefined;
                                        // let location=JSON.parse(JSON.stringify(additionalLocations[index]));
                                        // let locationDataList=this.state.locationDataList;
                                        // locationDataList.push(location);
                                        this.state.allLocations.forEach(
                                          (loc) => {
                                            if (loc.id === location.id) {
                                              loc.hidden = false;
                                            }
                                          }
                                        );
                                        this.setState({
                                          additionalLocations,
                                          // locationDataList
                                          // locationData: {}
                                        });
                                      }
                                    }}
                                    placeholder={"Select location"}
                                    dataList={this.state.allLocations}
                                    listBuilder={(location) => {
                                      return (
                                        <option
                                          key={location.name}
                                          value={location.id + ""}
                                          hidden={location.hidden}
                                        >
                                          {location.type}:{location.name}
                                        </option>
                                      );
                                    }}
                                    disabled={this.state.viewMode}
                                  />
                                </div>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    let additionalLocations =
                                      this.state.additionalLocations;
                                    // let locationDataList=this.state.locationDataList;

                                    if (additionalLocations.length === 1) {
                                      additionalLocations = [undefined];
                                      // locationDataList=this.state.allLocations;
                                    } else {
                                      let location = JSON.parse(
                                        JSON.stringify(
                                          additionalLocations[index]
                                        )
                                      );
                                      this.state.allLocations.forEach((loc) => {
                                        if (loc.id === location.id) {
                                          loc.hidden = false;
                                        }
                                      });
                                      // locationDataList.push(location);
                                      additionalLocations.splice(index, 1);
                                    }
                                    this.setState({
                                      additionalLocations,
                                      // locationDataList
                                    });
                                  }}
                                >
                                  x
                                </div>
                              </div>
                            );
                          }
                        )}

                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <div className="col-md-12">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                fontSize: 12,
                              }}
                            >
                              <div>
                                <p>
                                  Add Locations in Content / Locations section
                                  of the dashboard.
                                </p>
                              </div>
                              <div>
                                <p
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                  onClick={() => {
                                    let additionalLocations =
                                      this.state.additionalLocations;
                                    additionalLocations.push(undefined);
                                    this.setState({
                                      additionalLocations,
                                    });
                                  }}
                                >
                                  Create multi-location event?
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.type === "Virtual Event" && (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className={`col-md-12`}>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="col-md-4">
                          Video Conference Link
                          <Tooltip
                            placement="topLeft"
                            title={
                              <div style={{ width: 400 }}>
                                Add a video conference link / meeting code. In
                                the Virtual Event card the standard ‘Check-in’
                                option that appears on the event day will
                                instead show ‘Join’. If offered, points will be
                                awarded for attending.
                              </div>
                            }
                          >
                            <span className="border-0">
                              <i className="fa fa-question-circle-o" />
                            </span>
                          </Tooltip>
                        </div>
                        <div className="col-md-8">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: 30,
                            }}
                          >
                            <div className="col-md-7">
                              <FInput
                                value={this.state.videoConferenceLink}
                                onChange={this.handleInputChange}
                                name="videoConferenceLink"
                                display="row"
                                tooltip="Not Required"
                                placeholder="Add Link"
                                tabIndex="2"
                                disabled={this.state.viewMode}
                              />
                            </div>
                            <div style={{ flexGrow: 1, paddingRight: 15 }}>
                              <FInput
                                value={this.state.meetingCode}
                                onChange={this.handleInputChange}
                                name="meetingCode"
                                display="row"
                                tooltip="Not Required"
                                placeholder="Meeting Code (Optional)"
                                tabIndex="2"
                                disabled={this.state.viewMode}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className="form-group col-md-12">
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <label className="col-md-4"> Details </label>{" "}
                      {/* <input className="form-control form-control-sm" placeholder="Join us for a season kickoff party at Fado!"></input> */}{" "}
                      <Input.TextArea
                        className="col-md-8"
                        tabIndex="2"
                        style={{
                          fontSize: `18px`,
                        }}
                        placeholder={
                          layout2
                            ? "Write an article for the news.."
                            : "Join us for a season kickoff party at Fado! "
                        }
                        value={this.state.details}
                        name="details"
                        onChange={this.handleInputChange}
                        rows={layout2 ? 13 : 3}
                        disabled={this.state.viewMode}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className="form-group col-md-12">
                    <ImageUploadButton
                      label="Group Photo"
                      text="Upload"
                      images={
                        this.state.imageFile ? [this.state.imageFile] : []
                      }
                      tooltip="If no image uploaded, default image will appear. Image dimensions are 800x450 (16:9 ratio)"
                      onSave={(images) => {
                        if (
                          this.state.editMode &&
                          !this.state.editConfiguration.imageChanged
                        ) {
                          this.setState((prevState) => {
                            let editConfiguration = Object.assign(
                              {},
                              prevState.editConfiguration
                            );
                            editConfiguration.imageChanged = true;
                            return {
                              editConfiguration,
                            };
                          });
                        }
                        this.setState({
                          imageFile: images[0],
                        });
                      }}
                    />

                    {/* <FUpload label="Image"
                                aspectRatio={null}
                                action={
                                    this.uploadAction
                                }
                                fileList={
                                    this.state.imageList
                                }
                                onChange={
                                    this.handleImageChange
                                }
                                tabIndex="2"
                                display="row"
                                isAvatar="false"
                                tooltip="If no image uploaded, default image will appear. Image dimensions are 800x450 (16:9 ratio)"
                                viewMode={this.state.viewMode}
                            /> */}
                  </div>
                </div>
              </div>{" "}
              {/* </div> */}
            </div>
            {(this.state.type === "Watch Party" ||
              this.state.type === "Tailgate" ||
              this.state.type === "Travel" ||
              this.state.type === "Volunteer" ||
              this.state.type === "Special Event" ||
              this.state.type === "Meet-up" ||
              this.state.type === "Virtual Event" ||
              this.state.type === "Screening") && [
              <div
                className="mb-2"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                <div className="form-group col-md-8">
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <label className="col-md-4">
                      Link to group ticket?
                      <Tooltip
                        placement="topLeft"
                        title="Select to link the Event card to the event Ticket. You must first create the ticket in 'Group Tickets' of the dashboard."
                      >
                        <span className="border-0">
                          <i className="fa fa-question-circle-o" />
                        </span>
                      </Tooltip>
                    </label>

                    <select
                      style={{
                        width: `285px`,
                        border: "1px solid #d9d9d9",
                        borderRadius: 5,
                        color: "#000000",
                      }}
                      value={this.state.ticketName}
                      // name={this.state.ticket}
                      onChange={
                        !this.state.viewMode
                          ? this.handleTicketListChange
                          : undefined
                      }
                      tabIndex="2"
                    >
                      <option value={""} key={"select"}>
                        Select
                      </option>
                      {this.state.tokenList.map((data) => {
                        return (
                          <option value={data.code} key={data.code}>
                            {data.code} {data.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>,
              <div className="d-flex mb-2">
                <div
                  className="col-md-8"
                  style={{ padding: "1rem 2rem", backgroundColor: "#eeeeee" }}
                >
                  <span style={{ fontSize: 20, fontWeight: "bold" }}>
                    Event Check-in
                    <Tooltip
                      placement="topLeft"
                      title={() => (
                        <div
                          style={{
                            height: "fit-content",
                            width: "600px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            Season (important)
                          </span>
                          <p>
                            Ensure the correct season is added. Chant adds the
                            season automatically when a match is selected,
                            however some matches (cups, for example) might not
                            be for the same season as your primary league. These
                            should be corrected when creating the event to avoid
                            creating a new leaderboard (ex. 2024 might need to
                            be reset to 2023-2024).
                          </p>

                          <span style={{ fontWeight: "bold" }}>
                            Geolocation
                          </span>
                          <p>
                            Event check-in uses geolocation to confirm the
                            member is in attendance. Members will be asked to
                            enable this upon check-in.
                          </p>

                          <p>
                            Chant allows single-location and multi-location
                            events.
                          </p>

                          <p>
                            Chant uses 'medium' accuracy (100 meters) to avoid
                            creating the issues that 'high' accuracy (10 meters)
                            might create for members.
                          </p>

                          <p>
                            Disable geolocation to allow members to check-in
                            from anywhere. See the ‘Virtual’ location in Content
                            / Locations for a running total of these check-ins.
                          </p>

                          <span style={{ fontWeight: "bold" }}>Check-in</span>
                          <p>
                            Members can check in via the event card or, on
                            matchday, via the matchday header.
                          </p>

                          <p>
                            Admin can manually check-in a member from the event
                            card (Attendance section). Geolocation / Date are
                            not checked in this case allowing you to check in a
                            member anytime after the event.
                          </p>

                          <p>
                            Non-members (Fans) can check in and appear in the
                            Fan section of ‘Who’s Here’ but are not assigned
                            points (Message: Must be a member to receive
                            points).
                          </p>

                          <p>
                            If no event is created, members can check-in from
                            the Matchday header and will appear in ‘Who’s Here’
                            but receive the message ‘No points available’.
                          </p>
                        </div>
                      )}
                    >
                      <span className="border-0">
                        <i className="fa fa-question-circle-o" />
                      </span>
                    </Tooltip>
                  </span>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="col">
                      <p>
                        Complete this section to offer event points and add
                        members to the Events Leaderboard. If geolocation will
                        be used during check-in you must add an address above.
                        Note: Non-member role (Fans) not eligible for points.
                      </p>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="col-md-5">
                      <div style={{ display: "flex", flexWrap: "nowrap" }}>
                        <div className="col-md-8">
                          <span>Offer points upon check-in?</span>
                          <Tooltip
                            placement="topLeft"
                            title={() => (
                              <div
                                style={{
                                  height: "fit-content",
                                  width: "400px",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <p>
                                  Check-in button added to event card on event
                                  date.
                                </p>
                                <p>
                                  Requires member be present to check-in. Must
                                  set address (above) using ‘Add Google Maps
                                  Link’.
                                </p>
                              </div>
                            )}
                          >
                            <span className="border-0">
                              <i className="fa fa-question-circle-o" />
                            </span>
                          </Tooltip>
                        </div>

                        <div className="ml-2">
                          <Checkbox
                            style={{ marginTop: "1px" }}
                            checked={this.state.checkIn.allowCheckin}
                            onChange={(e) => {
                              let checkin = this.state.checkIn;
                              checkin.allowCheckin = e.target.checked;
                              this.setState({
                                checkIn: checkin,
                              });
                            }}
                            value="secondary"
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            tabIndex="2"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="ml-2" style={{ fontSize: 13 }}>
                        If not enabled, members can check-in but with no points
                        awarded.
                      </div>
                    </div>
                  </div>
                  {this.state.checkIn.allowCheckin && (
                    <>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="col-md-5">
                          <div style={{ display: "flex", flexWrap: "nowrap" }}>
                            <div className="col-md-8">
                              <span>Check members location?</span>
                            </div>

                            <div className="ml-2">
                              <Checkbox
                                style={{ marginTop: "1px" }}
                                checked={this.state.checkIn.validateGeolocation}
                                onChange={(e) => {
                                  let checkin = this.state.checkIn;
                                  checkin.validateGeolocation =
                                    e.target.checked;
                                  this.setState({
                                    checkIn: checkin,
                                  });
                                }}
                                value="secondary"
                                color="primary"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                                tabIndex="2"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="ml-2" style={{ fontSize: 13 }}>
                            Geolocation used by default. Disable for virtual
                            events.
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="col-md-5">
                          <div style={{ display: "flex", flexWrap: "nowrap" }}>
                            <div className="col-md-8">
                              <span>Set points to be awarded</span>
                              <Tooltip
                                placement="topLeft"
                                title={() => (
                                  <div
                                    style={{
                                      height: "fit-content",
                                      width: "400px",
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <p>
                                      Set points awarded for attending event.
                                      Encourage attendance by awarding more
                                      points for key events (volunteer, travel,
                                      low attendance matches). Consider awards
                                      at the end of each season.
                                    </p>
                                  </div>
                                )}
                              >
                                <span className="border-0">
                                  <i className="fa fa-question-circle-o" />
                                </span>
                              </Tooltip>
                            </div>
                            <div className="ml-2">
                              <input
                                tabIndex="2"
                                style={{
                                  height: 30,
                                  width: 60,
                                  border: "1px solid #ced4da",
                                  boxShadow: "0px 0px",
                                }}
                                type="number"
                                value={this.state.checkIn.points}
                                onChange={(e) => {
                                  let checkin = this.state.checkIn;
                                  checkin.points = e.target.value;
                                  this.setState({
                                    checkIn: checkin,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="ml-2" style={{ fontSize: 13 }}>
                            <p style={{ marginBottom: 0 }}>
                              Examples: Watch Party (100), Volunteer (300),
                              Travel (500)
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", flexWrap: "wrap" }}
                        className="mt-2"
                      >
                        <div className="col-md-5">
                          <div style={{ display: "flex", flexWrap: "nowrap" }}>
                            <div className="col-md-8">
                              <span>Season</span>
                              <Tooltip
                                placement="topLeft"
                                title={() => (
                                  <div
                                    style={{
                                      height: "fit-content",
                                      width: "400px",
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <p>
                                      If no match selected for event, set
                                      ‘season’ to properly assign points.
                                    </p>
                                  </div>
                                )}
                              >
                                <span className="border-0">
                                  <i className="fa fa-question-circle-o" />
                                </span>
                              </Tooltip>
                            </div>
                            <div className="ml-2">
                              <select
                                // style={{ borderColor: '#D3D3D3', borderRadius: '3px', paddingHorizontal: '2px', border: `${this.props.disabled ? 0 : 1}` }}
                                style={{
                                  height: 30,
                                  border: "1px solid #ced4da",
                                  boxShadow: "0px 0px",
                                  outline: "none",
                                  background: "#ffffff",
                                  color: "#000000",
                                }}
                                value={this.state.checkIn.season}
                                onChange={(e) => {
                                  let checkin = this.state.checkIn;
                                  checkin.season = e.target.value;
                                  this.setState({
                                    checkIn: checkin,
                                  });
                                }}
                                tabIndex="2"
                                disabled={this.state.viewMode}
                              >
                                <option key="no-val" value="">
                                  Select
                                </option>
                                {[
                                  { label: "2025", value: 2025 },
                                  { label: "2024", value: 2024 },
                                ].map((data) => {
                                  return (
                                    <option key={data.value} value={data.value}>
                                      {data.label}
                                    </option>
                                  );
                                })}
                              </select>
                              {/* <FSelect
                                            value={
                                                this.state.checkIn.season
                                            }
                                            onChange={
                                                (e) => {
                                                    let checkin = this.state.checkIn;
                                                    checkin.season = e.target.value;
                                                    this.setState({
                                                        checkIn: checkin
                                                    });
                                                }
                                            }
                                            name="type"
                                            dataList={
                                                [2023]
                                            }
                                            dataOnlyValue placeholder="--Select Season--"
                                            display="col"
                                            tabIndex="2"
                                            required={this.state.checkIn.allowCheckin || false}
                                            disabled={this.state.viewMode}
                                        /> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="ml-2" style={{ fontSize: 13 }}>
                            <p style={{ marginBottom: 0 }}>
                              Set automatically if match selected above.
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>,
              <div className="d-flex mb-2">
                <div
                  className="col-md-8"
                  style={{ padding: "1rem 2rem", backgroundColor: "#eeeeee" }}
                >
                  <span style={{ fontSize: 20, fontWeight: "bold" }}>
                    Request Volunteers
                  </span>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="col">
                      <p>
                        To offer volunteer points you must set both event
                        location and season (above). Geolocation used to confirm
                        check-in.
                      </p>
                    </div>
                  </div>
                  {this.state.volunteerRequests.map((request, index) => {
                    let startTime = null,
                      endTime = null;
                    if (request.startTime) {
                      startTime = dayjs.unix(request.startTime);
                    }
                    if (request.endTime) {
                      endTime = dayjs.unix(request.endTime);
                    }
                    return (
                      <div
                        style={{
                          padding: "0px 30px",
                          width: "100%",
                          marginTop: 20,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              let volunteerRequests =
                                this.state.volunteerRequests;
                              volunteerRequests = volunteerRequests.filter(
                                (req) => request.id !== req.id
                              );
                              this.setState({
                                volunteerRequests,
                              });
                            }}
                          >
                            <span style={{ textDecoration: "underline" }}>
                              Delete
                            </span>
                          </div>
                        </div>
                        <FInput
                          label="Title of slot"
                          value={request.title}
                          maxLength={50}
                          onChange={(e) => {
                            let volunteerRequests =
                              this.state.volunteerRequests;
                            volunteerRequests[index].title = e.target.value;
                            this.setState({
                              volunteerRequests,
                            });
                          }}
                          name="title"
                          display="row"
                          placeholder="Max 50 Characters"
                          tabIndex="2"
                          disabled={this.state.viewMode}
                          required
                        />
                        <FInput
                          label="Description"
                          value={request.description}
                          maxLength={100}
                          onChange={(e) => {
                            let volunteerRequests =
                              this.state.volunteerRequests;
                            volunteerRequests[index].description =
                              e.target.value;
                            this.setState({
                              volunteerRequests,
                            });
                          }}
                          name="description"
                          display="row"
                          placeholder="Max 100 Characters"
                          tabIndex="2"
                          disabled={this.state.viewMode}
                          // required
                        />
                        <FInput
                          label="# of slots"
                          value={request.slotCount}
                          type="number"
                          min={0}
                          max={50}
                          onChange={(e) => {
                            let volunteerRequests =
                              this.state.volunteerRequests;
                            volunteerRequests[index].slotCount = e.target.value;
                            this.setState({
                              volunteerRequests,
                            });
                          }}
                          name="slotCount"
                          display="row"
                          tabIndex="2"
                          disabled={this.state.viewMode}
                          required
                        />
                        <div className="form-group row">
                          <div className="col-md-4">Start/End Time</div>
                          <div className="col-md-8 d-flex" style={{ gap: 20 }}>
                            <div className="d-flex" style={{ flex: 1 }}>
                              <TimePicker
                                tabIndex="2"
                                className="form-control form-control-sm input-height col-8"
                                value={startTime}
                                defaultOpenValue={dayjs()
                                  .hour(0)
                                  .minute(0)
                                  .second(0)}
                                onChange={(value) => {
                                  let hh = 0,
                                    mm = 0;
                                  let epochTime = "";
                                  if (value) {
                                    hh = value.hour();
                                    mm = value.minute();
                                    epochTime = dayjs
                                      .unix(this.state.eventStartDate)
                                      .hour(hh)
                                      .minute(mm)
                                      .unix();
                                  }
                                  let volunteerRequests =
                                    this.state.volunteerRequests;
                                  volunteerRequests[index].startTime =
                                    epochTime;
                                  this.setState({
                                    volunteerRequests,
                                  });
                                }}
                                format={timeFormat}
                                disabled={this.state.viewMode}
                              />
                            </div>
                            <div className="d-flex" style={{ flex: 1 }}>
                              <TimePicker
                                tabIndex="2"
                                className="form-control form-control-sm input-height col-8"
                                value={endTime}
                                defaultOpenValue={dayjs()
                                  .set("hour", 0)
                                  .set("minute", 0)}
                                onChange={(value) => {
                                  let hh = 0,
                                    mm = 0;
                                  let epochTime = null;
                                  if (value) {
                                    hh = value.hour();
                                    mm = value.minute();
                                    let epochDay = dayjs
                                      .unix(this.state.eventStartDate)
                                      .hour(hh)
                                      .minute(mm);
                                    epochTime = epochDay.unix();
                                  }

                                  let volunteerRequests =
                                    this.state.volunteerRequests;
                                  volunteerRequests[index].endTime = epochTime;
                                  this.setState({
                                    volunteerRequests,
                                  });
                                }}
                                format={timeFormat}
                                disabled={this.state.viewMode}
                              />
                            </div>
                          </div>
                        </div>
                        <FInput
                          label="Offer volunteer points?"
                          value={request.points}
                          type="number"
                          min={0}
                          onChange={(e) => {
                            let volunteerRequests =
                              this.state.volunteerRequests;
                            volunteerRequests[index].points = e.target.value;
                            this.setState({
                              volunteerRequests,
                            });
                          }}
                          name="offerPoints"
                          display="my-row"
                          tabIndex="2"
                          disabled={this.state.viewMode}
                          postTip={
                            "Offered to volunteers in addition to ‘event points’ (above)"
                          }
                        />
                      </div>
                    );
                  })}
                  <div className="col-12">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        let volunteerRequests = this.state.volunteerRequests;
                        volunteerRequests.push({
                          ...JSON.parse(JSON.stringify(VolunteerTemplate)),
                          id: randomString(),
                        });
                        this.setState({
                          volunteerRequests,
                        });
                      }}
                    >
                      <span style={{ textDecoration: "underline" }}>
                        +add volunteer slot
                      </span>
                    </div>
                  </div>
                </div>
              </div>,
            ]}

            <div
              className="col-md-8 py-2"
              style={{ padding: "1rem 2rem", backgroundColor: "#eeeeee" }}
            >
              <span style={{ fontSize: 20, fontWeight: "bold" }}>
                Display & Notification
              </span>
              <div
                style={{ display: "flex", flexWrap: "wrap" }}
                className="mb-2"
              >
                <div className="col">
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="col-md-4">
                      <label className="">
                        Show on Home Screen &nbsp;
                        <Tooltip
                          placement="topLeft"
                          title={() => (
                            <div
                              style={{
                                height: "fit-content",
                                width: "400px",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              Will show through selected end date
                              <img src={DefaultSettingsImage} alt="" />
                            </div>
                          )}
                        >
                          <span className="border-0">
                            <i className="fa fa-question-circle-o" />
                          </span>
                        </Tooltip>
                      </label>
                    </div>

                    <RangePicker
                      tabIndex="2"
                      value={this.state.showOnHomePage}
                      onChange={(value) => {
                        this.setState({ showOnHomePage: value });
                        this.handleDateChange("showOnHomePage", value);
                      }}
                      disabledDate={(current) => {
                        return (
                          current &&
                          current >
                            dayjs.unix(this.state.eventEndDate || 9999999999)
                        );
                      }}
                      size={10}
                      disabled={this.state.viewMode}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{ display: "flex", flexWrap: "wrap" }}
                className="mb-2"
              >
                <div className="form-group">
                  <div className="col">
                    <div
                      className=" d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <label className="">
                        Show to fans (non-members) in app and website? &nbsp;
                        <Tooltip
                          placement="topLeft"
                          title={() => (
                            <div
                              style={{
                                height: "fit-content",
                                width: "400px",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              Checking option will display this event on your
                              Chant website (if used) and to the ‘fan’ role (if
                              allowed in the app).
                            </div>
                          )}
                        >
                          <span className="border-0">
                            <i className="fa fa-question-circle-o" />
                          </span>
                        </Tooltip>
                      </label>
                      <div className="ml-2">
                        <Checkbox
                          style={{ marginLeft: "18px", marginTop: "1px" }}
                          checked={this.state.showForFans}
                          onChange={this.checkChange}
                          value="secondary"
                          color="primary"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                          tabIndex="2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div className="form-group">
                  <label className="" for="exampleInputEmail1">
                    Notifications &nbsp;
                    <Tooltip
                      placement="topLeft"
                      title={() => (
                        <div
                          style={{
                            height: "fit-content",
                            width: "400px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <p>
                            Important: Will send app notification to all members
                          </p>
                          <p>
                            Notifications scheduled for today are sent upon
                            'submit'. Scheduled notifications are sent at 1:00PM
                            ET.
                          </p>
                          <img src={DefaultSettingsImage} alt="" />
                        </div>
                      )}
                    >
                      <span className="border-0">
                        <i className="fa fa-question-circle-o" />
                      </span>
                    </Tooltip>
                  </label>
                  <div
                    style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}
                    className="form-group"
                  >
                    {" "}
                    {this.state.notifications.map((element, index) => {
                      return (
                        <div className="col-md-3 mr-3">
                          <DatePicker
                            format="YYYY-MM-DD"
                            value={element ? dayjs.unix(element) : null}
                            placeholder="Start"
                            className="form-control form-control-sm"
                            onChange={(value) => {
                              this.handleNotificationsChange(value, index);
                            }}
                            disabled={this.state.viewMode}
                            //   onOpenChange={this.handleStartOpenChange}
                          />
                        </div>
                      );
                    })}{" "}
                    {this.state.notifications.length < 3 &&
                      !this.state.viewMode && (
                        <div className="mb-4 col-md-4 align-self-center">
                          <span
                            className={`btn btn-sm btn-info`}
                            onClick={this.handleAddNotifications}
                          >
                            Add notification <BellFilled />
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>

            {/* Show on home screen */}

            {!this.state.viewMode && (
              <div className="d-flex justify-content-center mt-2">
                <button
                  className="btn btn-success"
                  tabIndex="14"
                  type="success"
                  onClick={this.handleSubmit}
                  style={{
                    backgroundColor: primaryColor,
                    borderColor: primaryColor,
                  }}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  handleEditMode = () => {
    fbGetContent(
      this.props.router.params.id,
      this.props.router.params.group
    ).then(async (doc) => {
      if (!doc) {
        this.props.dispatch(
          applicationError({
            err: {},
            errorCode: "404",
          })
        );
        return;
      }

      const data = new Content();
      data.fromDataSet(doc);

      let chatChannel;
      if (data.chatChannelName) {
        chatChannel = await fbGetChatChannel(
          this.props.router.params.group,
          this.props.router.params.id
        );
      }
      if (chatChannel) {
        data.chatChannelName = chatChannel.channelName;
        data.channelArchiveDateStart = chatChannel.startDate;
        data.channelArchiveDateEnd = chatChannel.endDate;
      }

      let viewMode = this.props.router.location.pathname.includes("view");

      let eventStartDate = dayjs.unix(data.eventStartDate);

      let eventStartTime =
        dayjs.unix(data.eventStartDate).second() !== 59
          ? dayjs(
              `${eventStartDate.hour()}:${eventStartDate.minute()}`,
              timeFormat
            )
          : null;

      this.setState({
        ...data,
        eventStartTime,
        oldNotifications: data.notifications,
        memberResponseState: data.isLinkTicket === true ? 2 : 1,
        viewMode,
        showOnHomePage: [
          toDayjs(data.showOnHomePageStart * 1000),
          toDayjs(data.showOnHomePageEnd * 1000),
        ],
      });

      this.props.handleChildStateUpdate("loadingDocument", false);
    });
  };

  handleCopyMode = () => {
    fbGetContent(this.props.currentContentId, this.props.currentGroup)
      .then(async (doc) => {
        if (!doc) {
          this.props.dispatch(
            applicationError({
              err: {},
              errorCode: "404",
            })
          );
          return;
        }

        const data = new Content();
        data.fromDataSet(doc);

        let chatChannel;
        if (data.chatChannelName) {
          chatChannel = await fbGetChatChannel(
            this.props.router.params.group,
            this.props.router.params.id
          );
        }
        if (chatChannel) {
          data.chatChannelName = chatChannel.channelName;
          data.channelArchiveDateStart = chatChannel.startDate;
          data.channelArchiveDateEnd = chatChannel.endDate;
        }

        let eventStartDate = dayjs.unix(data.eventStartDate);

        let eventStartTime =
          dayjs.unix(data.eventStartDate).second() !== 59
            ? dayjs(
                `${eventStartDate.hour()}:${eventStartDate.minute()}`,
                timeFormat
              )
            : null;

        if (data.showOnHomePageStart && data.showOnHomePageEnd) {
          let showOnHomePage = [];
          if (data.showOnHomePageStart > data.showOnHomePageEnd) {
            showOnHomePage.push(toDayjs(data.showOnHomePageEnd * 1000));
            showOnHomePage.push(toDayjs(data.showOnHomePageStart * 1000));
          } else {
            showOnHomePage.push(toDayjs(data.showOnHomePageStart * 1000));
            showOnHomePage.push(toDayjs(data.showOnHomePageEnd * 1000));
          }
          data.showOnHomePage = showOnHomePage;
        }

        this.setState({
          ...data,
          eventStartTime,
          oldNotifications: data.notifications,
          memberResponseState: data.isLinkTicket === true ? 2 : 1,
          // viewMode,
        });

        this.props.handleChildStateUpdate("loadingDocument", false);
      })
      .catch((err) => logger.error(err));
  };

  getTokenList = async () => {
    if (this.props.currentGroup && this.state.tokenList.length === 0) {
      await Axios.get(
        `https://us-central1-chant2019.cloudfunctions.net/getAllGroupTicket?groupId=${this.props.currentGroup}`
      )
        .then((tickets) => {
          let tempTicket = [];
          tickets = tickets.data.body.filter((ticket) => {
            return (
              dayjs
                .unix(ticket.data.groupTicket.eventDate)
                .utc()
                .local()
                .format("YYYY-MM-DD") >= new Date().toISOString().slice(0, 10)
            );
          });
          tickets.forEach((ticket, index) => {
            tempTicket.push({
              matchId: ticket.data.match.fixtureId,
              id: ticket.id,
              code: ticket.data.groupTicket.eventCode,
              name: ticket.data.groupTicket.eventName,
            });
          });
          this.setState({
            tokenList: tempTicket,
            // pagination: { total: tickets.length }
          });

          this.props.handleChildStateUpdate("isLoading", false);
        })
        .catch((error) => {
          logger.error(error);
        });
    }
  };
  getMatchList = async () => {
    if (this.props.clubId && this.state.matchList.length === 0) {
      await Axios.get(`fixtures?team=${this.props.clubId}&next=99`)
        .then((response) => {
          let data = response.data.response;
          let res = [];
          data.forEach((fixtureData) => {
            res.push({
              fixture_id: fixtureData.fixture.id,
              league_id: fixtureData.league.id,
              league: fixtureData.league,
              event_date: fixtureData.fixture.date,
              event_timestamp: fixtureData.fixture.timestamp,
              firstHalfStart: fixtureData.fixture.periods.first,
              secondHalfStart: fixtureData.fixture.periods.second,
              round: fixtureData.league.round,
              status: fixtureData.fixture.status.long,
              statusShort: fixtureData.fixture.status.short,
              elapsed: fixtureData.fixture.status.elapsed,
              venue: fixtureData.fixture.venue.name,
              referee: fixtureData.fixture.referee,
              homeTeam: {
                team_id: fixtureData.teams.home.id,
                team_name: fixtureData.teams.home.name,
                logo: fixtureData.teams.home.logo,
              },
              awayTeam: {
                team_id: fixtureData.teams.away.id,
                team_name: fixtureData.teams.away.name,
                logo: fixtureData.teams.away.logo,
              },
              goalsHomeTeam: fixtureData.goals.home,
              goalsAwayTeam: fixtureData.goals.away,
              score: {
                halftime: fixtureData.score.halftime.home
                  ? `${fixtureData.score.halftime.home}-${fixtureData.score.halftime.away}`
                  : null,
                fulltime: fixtureData.score.fulltime.home
                  ? `${fixtureData.score.fulltime.home}-${fixtureData.score.fulltime.away}`
                  : null,
                extratime: fixtureData.score.extratime.home
                  ? `${fixtureData.score.extratime.home}-${fixtureData.score.extratime.away}`
                  : null,
                penalty: fixtureData.score.penalty.home
                  ? `${fixtureData.score.penalty.home}-${fixtureData.score.penalty.away}`
                  : null,
              },
            });
          });
          this.setState({
            matchList: res,
          });
        })
        .finally(() => {
          this.props.handleChildStateUpdate("loadingMatches", false);
        });
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (state.clubId !== props.clubId || state.groupId !== props.currentGroup) {
      return {
        clubId: props.clubId,
        groupId: props.currentGroup,
      };
    }
    return null;
  }

  fetchCurrentLocation = () => {
    logger.log(
      "location==========>",
      this.props.isGeolocationAvailable,
      this.props.isGeolocationEnabled,
      this.props
    );
    if (this.props.isGeolocationAvailable) {
      if (this.props.isGeolocationEnabled) {
        if (this.props.coords) {
          if (
            !this.state.locationData ||
            !this.state.locationData.locationData.placeId
          ) {
            this.setState({
              locationData: {
                name: "",
                id: "",
                locationData: {
                  placeId: "",
                  address: "",
                  latitude: this.props.coords.latitude,
                  longitude: this.props.coords.longitude,
                },
              },
            });
          } else {
            navigator.geolocation.getCurrentPosition((pos) => {
              logger.log("navigator location=====>", pos);
              this.setState({
                locationData: {
                  name: "",
                  id: "",
                  locationData: {
                    placeId: "",
                    address: "",
                    latitude: pos.coords.latitude,
                    longitude: pos.coords.longitude,
                  },
                },
              });
            });
          }
        } else {
          logger.log("location denied");
        }
      }
    }
  };

  initState = async () => {
    this.setState({ isLoading: true });

    this.fetchCurrentLocation();

    /*
     * If we navigate from some other page to this screen we will already have
     * club id and match list will be fetched, if we refresh the page componentdidupdate
     * will do the same
     */
    let promises = [];
    promises.push(
      this.getTokenList(),
      this.getMatchList(),
      getLocations(this.props.currentGroup).then((allLocations) => {
        logger.log("allLocations: ", allLocations);
        this.setState({
          allLocations: allLocations,
          locationDataList: allLocations,
        });
      })
    );
    await Promise.all(promises);
    if (this.props.router.params.id) {
      if (this.props.currentGroup === this.props.router.params.group) {
        this.handleEditMode();
      }
    } else {
      this.setState({
        viewMode: false,
      });

      this.props.handleChildStateUpdate("loadingDocument", false);
    }

    if (this.props.currentContentId) {
      this.handleCopyMode();
    }
    this.setState({ isLoading: false });
  };

  componentDidMount() {
    this.initState();
  }

  handleDisplayNotificationDates(eventStartDate, configuration) {
    if (eventStartDate) {
      let startDate = eventStartDate;
      let showOnHomePageStart =
        configuration.homeFeed >= 0
          ? dayjs.unix(startDate).subtract(configuration.homeFeed, "days")
          : dayjs.unix(startDate);
      let showOnHomePageEnd =
        configuration.homeFeed >= 0
          ? dayjs.unix(startDate).hour(23).minute(59).second(59)
          : dayjs.unix(startDate).subtract(configuration.homeFeed, "days");

      const showOnHomePage = [showOnHomePageStart, showOnHomePageEnd];

      let notifications = [];
      configuration.notifications
        .sort((a, b) => b - a)
        .forEach((dateDiff) => {
          let notificationEpoch = dayjs
            .unix(startDate)
            .subtract(dateDiff, "days")
            .unix();
          notifications.push(
            notificationEpoch > dayjs().unix()
              ? notificationEpoch
              : dayjs().unix()
          );
        });
      notifications = Array.from(new Set(notifications));
      return {
        showOnHomePage,
        notifications,
      };
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // this.getMatchList()
    if (this.props.currentGroup !== prevProps.currentGroup) {
      if (this.props.router.params.id && this.props.loadingDocument) {
        this.handleEditMode();
      }
      this.getMatchList();
      getLocations(this.props.currentGroup).then((allLocations) => {
        this.setState({
          allLocations,
        });
      });
    }
    if (this.state.type !== prevState.type) {
      if (this.state.type === "Virtual Event") {
        this.setState(() => {
          let checkIn = this.state.checkIn;
          checkIn.validateGeolocation = false;
          return { checkIn };
        });
      } else if (prevState.type === "Virtual Event") {
        this.setState(() => {
          let checkIn = this.state.checkIn;
          checkIn.validateGeolocation = true;
          return { checkIn };
        });
      }
    }

    if (
      (this.state.eventStartDate !== prevState.eventStartDate ||
        this.state.type !== prevState.type) &&
      this.state.setDefaults
    ) {
      let configuration = defaultDateConfig[this.state.type];
      if (configuration) {
        const data = this.handleDisplayNotificationDates(
          this.state.eventStartDate,
          configuration
        );
        this.setState({
          ...data,
        });
      }
    }
  }
}

const mapStateToProps = (state) => {
  let currentGroup = state.adminData.currentGroup;
  let { clubId } = state.adminData.allGroups[currentGroup].data;
  return {
    user: state.user,
    currentGroup,
    clubId,
    adminData: state.adminData.allGroups[currentGroup],
  };
};

export default connect(mapStateToProps)(withRouter(CreateContentForm));

// export default geolocated({
//     positionOptions: { enableHighAccuracy: true },
//     // userDecisionTimeout: 5000
//   })(connect(mapStateToProps)(CreateContentForm));
