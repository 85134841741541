import { message } from "antd";
import { continents, countries } from "countries-list";
import moment from "moment";
import { store } from "../../..";
import { applicationError } from "../../../redux/actions/error";
import { db, storage } from "../connection";
import { fbGetGroups } from "./group";
import logger from "../../../utils/logger";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

let countryCodes = {};
Object.keys(countries).forEach((code) => {
  countryCodes[code] = countries[code].name;
});

export async function fbGetPolls() {
  let members = await getAllMembers();
  return new Promise(async (resolve, reject) => {
    try {
      const pollCollectionRef = collection(
        db,
        "polls",
        "superadmin_polls",
        "poll"
      );
      const snap = await getDocs(pollCollectionRef);
      let res = [];
      snap.docs.forEach((doc) => {
        let stats = {};
        let optKeys = ["op1", "op2", "op3", "op4", "op5"];
        optKeys
          .filter((key) => doc.data()[key] && doc.data()[key] !== null)
          .forEach((key) => {
            stats[doc.data()[key].name] =
              (doc.data()[key].votes * 100) / doc.data().noOfPolls;
            if (isNaN(stats[doc.data()[key].name])) {
              stats[doc.data()[key].name] = 0;
            }
          });
        let groups = doc.data().groups;
        let targetMembersCount;

        if (groups.includes("global")) {
          targetMembersCount = members.length;
        } else {
          targetMembersCount = members.filter(
            (member) =>
              groups.includes(member.groupId) ||
              groups.includes(members.leagueId) ||
              groups.includes(members.clubId) ||
              groups.includes(continents[members.region]) ||
              groups.includes(countryCodes[members.country])
          ).length;
        }

        res.push({ ...doc.data(), id: doc.id, stats, targetMembersCount });
      });

      let polls = res;
      let filteredPolls = polls.filter((poll) => !poll.isActive);
      polls = [
        ...polls
          .filter((poll) => poll.isActive && moment().unix() < poll.closesOn)
          .sort((a, b) => b.addedOn - a.addedOn),
        ...polls
          .filter((poll) => moment().unix() > poll.closesOn)
          .sort((a, b) => b.addedOn - a.addedOn),
        ...filteredPolls.sort((a, b) => b.addedOn - a.addedOn),
      ];

      resolve(polls);
    } catch (err) {
      let errorMessage = "Error fetching polls";
      store.dispatch(
        applicationError({
          err,
          message: errorMessage,
          show: true,
          intensity: "mid",
        })
      );
      reject({ message: errorMessage });
    }
  });
}

export function fbCreateSuperAdminPoll(data, pollImage, sponsorImage) {
  return new Promise(async (resolve, reject) => {
    const docRef = doc(collection(db, "polls", "superadmin_polls", "poll"));

    try {
      if (pollImage) {
        const pollImageUrl = await fbUploadPollImage(pollImage, docRef);
        data.pollImage = pollImageUrl || "";
      }

      if (sponsorImage) {
        const sponsorImageUrl = await fbUploadSponsorImage(
          sponsorImage,
          docRef
        );
        data.sponsorImage = sponsorImageUrl || "";
      }

      await setDoc(docRef, { ...data }, { merge: true });
      resolve();
    } catch (e) {
      reject({
        message: "Error uploading images or creating the poll, contact admin",
      });
    }
  });
}

export function fbUpdateSuperAdminPoll(data, pollImage, sponsorImage, id) {
  return new Promise(async (resolve, reject) => {
    const docRef = doc(db, "polls", "superadmin_polls", "poll", id);

    try {
      if (pollImage) {
        const pollImageUrl = await fbUploadPollImage(pollImage, docRef);
        data.pollImage = pollImageUrl || "";
      }

      if (sponsorImage) {
        const sponsorImageUrl = await fbUploadSponsorImage(
          sponsorImage,
          docRef
        );
        data.sponsorImage = sponsorImageUrl || "";
      }

      await setDoc(docRef, { ...data }, { merge: true });
      resolve();
    } catch (e) {
      reject({
        message: "Error uploading images or updating the poll, contact admin",
      });
    }
  });
}

export function getPoll(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(db, "polls", "superadmin_polls", "poll", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        resolve({ ...docSnap.data(), id: docSnap.id });
      } else {
        reject({ message: "Poll not found" });
      }
    } catch (err) {
      reject(err);
    }
  });
}

function fbUploadPollImage(image, ref) {
  let path = `/polls/superadmin/${ref.id}/pollImage`;
  return uploadImage(image, path);
}

function fbUploadSponsorImage(image, ref) {
  let path = `/polls/superadmin/${ref.id}/sponsorImage`;
  return uploadImage(image, path);
}

async function uploadImage(image, path) {
  const storageRef = ref(storage, path);
  await uploadBytes(storageRef, image);
  return await getDownloadURL(storageRef);
}

async function getAllMembers() {
  let groups = await fbGetGroups();
  let memberPromises = groups.map((group) => {
    return getDocs(collection(db, "userGroup", group.id, "members")).then(
      (snap) => {
        let res = [];
        snap.docs.forEach((doc) => {
          res.push({
            ...doc.data(),
            id: doc.id,
            country: group.supportersCountry,
            region: group.supportersRegion,
            groupName: group.groupName,
            groupId: group.id,
            leagueId: group.leagueId,
            clubId: group.clubId,
          });
        });
        return res;
      }
    );
  });

  let members = await Promise.all(memberPromises)
    .then((membersArrays) => membersArrays.flat())
    .catch((err) => {
      logger.error(err);
    });

  return members;
}

export async function postLive(id) {
  const docRef = doc(db, "polls", "superadmin_polls", "poll", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const docData = docSnap.data();
    const data = {
      isActive: true,
      postedOn: moment().unix(),
      closesOn:
        moment().unix() +
        docData.duration.days * 24 * 60 * 60 +
        docData.duration.hours * 60 * 60,
    };

    await updateDoc(docRef, { ...data });
  } else {
    throw new Error("Poll not found");
  }
}

export async function deletePolls(id) {
  await deleteDoc(doc(db, "polls", "superadmin_polls", "poll", id));
}
