import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import GroupRegFormConfiguration from "./PackageSetup/GroupRegFormConfiguration";
import PackagesTable from "./PackagesTable/PackagesTable";
import ResponsiveMobileTab from "../../../../CommonComponents/ResponsiveMobileTab/ResponsiveMobileTab";

const Packages = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getCurrentTab = () => {
    if (location.pathname.includes("/setup")) {
      return "setup";
    }
    return "list";
  };

  const menuItems = [
    {
      key: "list",
      label: "Packages",
    },
    {
      key: "setup",
      label: "Setup",
    },
  ];

  const handleTabChange = (e) => {
    const tabKey = e.target.name;
    switch (tabKey) {
      case "list":
        navigate("/admin/members/packages");
        break;
      case "setup":
        navigate("/admin/members/packages/setup");
        break;
      default:
        navigate("/admin/members/packages");
    }
  };

  const renderContent = () => {
    if (location.pathname.includes("/setup")) {
      return <GroupRegFormConfiguration />;
    }
    return <PackagesTable />;
  };

  return (
    <div className="packages-container">
      <nav className="packages-nav">
        <ul
          style={{
            listStyle: "none",
            padding: "2rem 2rem 0rem",
            margin: 0,
            display: "flex",
          }}
        >
          <ResponsiveMobileTab
            menuItems={menuItems}
            param={getCurrentTab()}
            changeTab={handleTabChange}
            primaryColor="var(--primary-background)"
            tabIndex={0}
          />
        </ul>
      </nav>

      <div className="packages-content" style={{ marginTop: "24px" }}>
        {renderContent()}
      </div>
    </div>
  );
};

export default Packages;
