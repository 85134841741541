import { Table } from "antd";
import React from "react";
import { fbGetAllGroupDetails } from "../../../services/firebaseService/endPoints/group";
import { getDeactivatedUsers } from "../../../services/firebaseService/endPoints/user";

class Deactivated extends React.Component {
  state = {
    groupIdDetailsMap: {},
    users: [],
  };

  changeHeaderColor = () => {
    let elements = document.querySelectorAll("th");
    elements.forEach((elem) => {
      elem.style.backgroundColor = "#000000";
    });
  };

  render() {
    const columns = [
      {
        title: "Group Name",
        key: "groupName",
        className: "header-white",
        dataIndex: "groupName",
      },
      {
        title: "UserId",
        key: "userId",
        className: "header-white",
        dataIndex: "id",
      },
      {
        title: "Full Name",
        key: "fullName",
        className: "header-white",
        dataIndex: "name",
      },
      {
        title: "Email",
        key: "email",
        className: "header-white",
        dataIndex: "email",
      },
    ];
    const rootEl = document.querySelector(":root");
    rootEl.style.setProperty("--main-color", "#000000");
    return (
      <div className="container p-5">
        <Table
          columns={columns}
          dataSource={this.state.users}
          className="table-backdrop mb-3"
          bordered
          bodyStyle={{
            fontSize: window.screen.width > 500 ? "18px" : "12px",
            backgroundColor: "#ffffff",
          }}
        />
      </div>
    );
  }

  fetchGroups = () => {
    return fbGetAllGroupDetails().then((groups) => {
      let groupIdDetailsMap = {};

      groups.forEach((group) => {
        groupIdDetailsMap[group.id] = group;
      });

      this.setState({
        groupIdDetailsMap,
      });
    });
  };

  fetchDeactivatedUsers = () => {
    getDeactivatedUsers().then((users) => {
      let tableData = [];
      users.forEach((user) => {
        let groupIds = user.groupIds;
        groupIds.forEach((groupId) => {
          let groupName = this.state?.groupIdDetailsMap?.[groupId].groupName;
          tableData.push({
            groupName,
            ...user,
          });
        });
      });

      this.setState({
        users: tableData,
      });
    });
  };

  componentDidMount() {
    this.fetchGroups().then(() => {
      this.fetchDeactivatedUsers();
    });
    this.changeHeaderColor();
  }
}

export default Deactivated;
