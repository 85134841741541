import useGlobalStore from "../../store/store";
import {
  fbGetFormLayout,
  getAllPackages,
} from "../firebaseService/endPoints/admin/members";

export const fetchPackages = async (groupId) => {
  const { setPackagesList, setPackagesLoading } =
    useGlobalStore.getState().packages;

  try {
    setPackagesLoading(true);

    const data = await getAllPackages(groupId);

    setPackagesList(groupId, data);
  } catch (error) {
    console.error("Error fetching packages:", error);
  } finally {
    setPackagesLoading(false);
  }
};

export const fetchFormLayout = async (groupId) => {
  const { setFormLayout, setFormLayoutLoading } =
    useGlobalStore.getState().formLayout;
  const { setShowAddMember } = useGlobalStore.getState().members;

  try {
    setFormLayoutLoading(true);
    const data = await fbGetFormLayout(groupId);
    setFormLayout(groupId, data);
    if (data) {
      setShowAddMember(true);
    }
  } catch (error) {
    setFormLayout(groupId, {});
  } finally {
    setFormLayoutLoading(false);
  }
};
