import React from "react";
import "../GettingStarted.css";
import "./MaximizedView.css";
import { Progress } from "antd";
import Accordion from "../../../CommonComponents/Accordion/Accordion";
import Timeline from "../../../CommonComponents/Timeline/Timeline";
import useMaximizedView from "./hooks/useMaximizedView";
import {
  selectCurrentGroupData,
  selectGettingStartedExpanded,
} from "../../../redux/selectors/adminData";
import { useSelector } from "react-redux";

const MaximizedView = () => {
  const adminData = useSelector(selectCurrentGroupData);
  const expanded = useSelector(selectGettingStartedExpanded);

  const {
    mainColor,
    detailsOpen,
    guideData,
    currentDetails,
    totalProgress,
    gettingStartedItemStatus,
    handleTitleClick,
    handleTimelineItemClick,
  } = useMaximizedView(adminData);

  return (
    <div
      className={`get-started-maximized-container ${!expanded ? "get-started-maximized-container--hidden" : ""}  ${detailsOpen ? "get-started-maximized-container--detailed" : ""}`}
    >
      <div className="get-started-maximized">
        <div className="get-started-maximized-header">
          <div>
            <p
              style={{ fontWeight: 700, fontSize: "20px", marginBottom: "4px" }}
            >
              Getting Started
            </p>
            <p style={{ fontWeight: 400, fontSize: "14px", opacity: 0.8 }}>
              Enable these features to get the most from Chant.
            </p>
          </div>
          <div className="flex justify-content-center align-items-center">
            <Progress
              type="circle"
              percent={totalProgress || 0}
              strokeColor={mainColor}
              size={80}
              strokeWidth={8}
              format={(percent) => (
                <p
                  style={{ color: "#999999", marginBottom: 0 }}
                >{`${percent}%`}</p>
              )}
            />
          </div>
        </div>

        <div className="get-started-maximized-tasks">
          {guideData?.map((data) => (
            <Accordion
              title={data?.title}
              isComplete={
                data?.expandedData?.length ===
                gettingStartedItemStatus?.[data.id]?.completedItems
              }
              // expanded={
              //   data?.expandedData?.length !==
              //   gettingStartedItemStatus?.[data.id]?.completedItems
              // }
              statusText={`${gettingStartedItemStatus?.[data?.id]?.completedItems || 0}/${data?.expandedData?.length}`}
            >
              <Timeline
                accordionId={data.id}
                onItemClick={(itemId) =>
                  handleTimelineItemClick(data.id, itemId)
                }
                onTitleClick={(itemId) => handleTitleClick(data.id, itemId)}
                timelineItems={data?.expandedData}
                timelineItemStatus={
                  gettingStartedItemStatus?.[data?.id]?.topics
                }
              />
            </Accordion>
          ))}
        </div>
      </div>

      <div className="divider"></div>

      <div className="maximized-view-details">{currentDetails}</div>
    </div>
  );
};

export default MaximizedView;
