import { Alert, Checkbox } from "antd";
import React from "react";
import { connect } from "react-redux";
import { getGroups } from "../../../services/firebaseService/endPoints/clubAdmin/groups";
import LoadingModal from "../../commons/LoadingModal";
import Search from "../../commons/Search";
import { continents, countries } from "countries-list";
import { FInput } from "../../commons/formFields/FInput";
import FUpload from "../../commons/formFields/FUpload";
import { FSelect } from "../../commons/formFields/FSelect";
import Giveaway from "../../../models/clubadmin/Giveaway";
import {
  createGiveaway,
  fbGetGiveaway,
  updateGiveaway,
} from "../../../services/firebaseService/endPoints/clubAdmin/giveaway";
import moment from "moment";
import { withRouter } from "../../../utils/helper";

let countrtyNameCodeMap = {};
Object.keys(countries).forEach((key) => {
  countrtyNameCodeMap[key] = countries[key].name;
});

class CreateGiveaway extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      loadingTitle: "",
      loadingMessage: "",
      errorInForm: {
        isError: false,
        visible: false,
        message: "",
      },
      allGroups: [],
      count: "0 groups",
      itemName: "",
      itemDesc: "",
      imageList: [],
      imageFile: undefined,
      editConfigurations: {
        imageChanged: false,
        proceedsImageChanged: false,
        donatedByImageChanged: false,
      },
      proceedSupportImageList: [],
      proceedsImageFile: undefined,
      donatedByImageList: [],
      donatedByImage: undefined,
      acceptedTerms: false,
      quantity: "",
      goal: "",
      proceedsSupportName: "",
      donatedByText: "",
      paypalEmailId: "",
      editMode: !!props.router.params.id,
      groups: [],
      params: [],
    };
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleImageChange = (editConfigKey, imageFileKey, imageListKey, fileList) => {
    let result = {};
    if (fileList.length === 0) {
      result[imageFileKey] = {};
    } else {
      result[imageFileKey] = fileList[0].originFileObj;
    }
    result[imageListKey] = fileList;

    this.setState((prevState) => ({
      ...result,
      editConfigurations: {
        ...prevState.editConfigurations,
        [editConfigKey]: true,
      },
    }));
  };

  uploadAction = (editConfigKey, key, file) => {
    this.setState((prevState) => ({
      [key]: file,
      editConfigurations: {
        ...prevState.editConfigurations,
        [editConfigKey]: true,
      },
    }));
  };

  onCheckChange = (e) => {
    this.setState({
      acceptedTerms: true,
    });
  };

  handleSearchParamChange = (list) => {
    let params = [];
    list.forEach((param) => {
      if (
        Object.values(continents).includes(param) ||
        Object.values(countrtyNameCodeMap).includes(param)
      ) {
        params.push(param);
      } else {
        let group = this.state.allGroups.find(
          (group) => group.groupName === param
        );
        if (group) {
          params.push(group.id);
        }
      }
    });
    this.setState((state) => {
      let groupCount =
        list.length > 0
          ? list.includes("global")
            ? this.state.allGroups.length
            : this.state.allGroups.filter(
                (group) =>
                  params.includes(group.id) ||
                  params.includes(
                    countrtyNameCodeMap[group.supportersCountry]
                  ) ||
                  params.includes(continents[group.supportersRegion])
              ).length
          : this.state.allGroups.length;
      return { groups: params, count: `${groupCount} groups` };
    });
  };

  handleOptionChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleErrorMessageClose = () => {
    this.setState((prevState) => {
      const errorInForm = {
        ...prevState.errorInForm,
      };
      errorInForm.visible = false;
      return {
        errorInForm,
      };
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.state.acceptedTerms) {
      alert("Accept the Terms & Conditions");
      return;
    }

    this.setState({
      isLoading: true,
    });

    let data = new Giveaway();
    data.toDataSet({ ...this.state });

    data = JSON.parse(JSON.stringify(data));
    data.groupId = this.props.currentGroup;

    if (data.hasError) {
      window.scrollTo(0, 0);
      this.setState({
        errorInForm: {
          isError: true,
          visible: true,
          message: data.hasError.message,
        },
        isLoading: false,
      });
      window.scrollTo(0, 0);
      setTimeout(this.handleErrorMessageClose, 5000);
    } else {
      let promise;
      if (!this.state.editMode) {
        data.addedOn = moment().unix();
        promise = createGiveaway(
          data,
          this.state.imageFile,
          this.state.proceedsImageFile,
          this.state.donatedByImage
        );
      } else {
        data.updateOn = moment().unix();
        promise = updateGiveaway(
          data,
          this.state.editConfigurations.imageChanged && this.state.imageFile,
          this.state.editConfigurations.proceedsImageChanged &&
            this.state.proceedsImageFile,
          this.state.editConfigurations.donatedByImageChanged &&
            this.state.donatedByImage
        );
      }

      promise.then(() => {
        this.setState({
          isLoading: false,
        });
        this.props.router.navigate("/clubadmin/giveaways");
      });
    }
  };

  render() {
    let { loadingTitle, loadingMessage } = this.state;

    let winnerList = Array.from({ length: 5 }, (_, index) => index + 1);

    let primaryColor = "";
    if (
      this.props.groupData.data.configuration &&
      this.props.groupData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.groupData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <div>
        <LoadingModal
          title={loadingTitle}
          message={loadingMessage}
          // percentage={this.state.loadingPercentage}
          loading={this.state.isLoading}
        />
        {this.state.errorInForm.isError && this.state.errorInForm.visible && (
          <div className="mx-5 mt-2">
            <Alert
              message="Error"
              description={this.state.errorInForm.message}
              type="error"
              closable
              // className="mb-3"
              afterClose={this.handleErrorMessageClose}
            />
          </div>
        )}

        <div className="mx-md-5 p-2 border-box">
          <div
            style={{
              width: "100%",
              paddingTop: 30,
            }}
          >
            <ul
              className="nav nav-tabs"
              style={{ color: "#ffffff", position: "relative" }}
            >
              <li
                className="nav-item"
                style={{ borderBottom: `4px solid ${primaryColor}` }}
              >
                <a
                  style={{
                    height: "100%",
                    fontWeight: "bold",
                    fontSize: "22px",
                  }}
                  name="current"
                >
                  {!this.state.editMode
                    ? " New Giveaway "
                    : " Update Giveaway "}
                </a>
              </li>
            </ul>
          </div>

          <form
            onSubmit={this.handleSubmit}
            className="border rounded p-3 px-3 mt-2"
            style={{
              background: "#ffffff",
            }}
          >
            <div className="row">
              <div>
                <div className="row mb-2">
                  <div className={`form-group`}>
                    <div
                      style={{
                        margin: "-5px 0px -5px 45px",
                      }}
                    >
                      <Search
                        options={[
                          ...this.state.allGroups.map(
                            (group) => group.groupName
                          ),
                          ...Array.from(
                            new Set(
                              this.state.allGroups.map(
                                (group) => group.supportersRegion
                              )
                            )
                          ).map((code) => continents[code]),
                          ...Array.from(
                            new Set(
                              this.state.allGroups.map(
                                (group) => group.supportersCountry
                              )
                            )
                          ).map((code) => countrtyNameCodeMap[code]),
                        ]}
                        params={this.state.editMode && this.state.params}
                        count={this.state.count}
                        onSearchParamsChange={this.handleSearchParamChange}
                        label="Select Groups"
                        placeholder={
                          !(
                            Array.isArray(this.state.groups) &&
                            this.state.groups.length > 0
                          ) &&
                          "Default is 'worldwide'. Select groups by region (Europe) and/or name (Atlanta) to target"
                        }
                        placeholderStyleClass="default"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="border rounded p-3 px-3">
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 24,
                      marginBottom: "1rem",
                    }}
                  >
                    Item<span style={{ color: "#ff0000" }}>*</span>
                  </div>
                  <div className="col-md-8" style={{ marginLeft: "auto" }}>
                    <FInput
                      label=""
                      name="itemName"
                      display="col"
                      onChange={this.handleInputChange}
                      value={this.state.itemName}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 24,
                      marginBottom: "1rem",
                    }}
                  >
                    Details
                  </div>
                  <div className="col-md-8" style={{ marginLeft: "auto" }}>
                    <FInput
                      label=""
                      name="itemDesc"
                      display="col"
                      onChange={this.handleInputChange}
                      value={this.state.itemDesc}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 24,
                      marginBottom: "1rem",
                    }}
                  >
                    Image
                  </div>
                  <div className="col-md-8" style={{ marginLeft: "auto" }}>
                    <FUpload
                      action={(file) => {
                        this.uploadAction("imageChanged", "imageFile", file);
                      }}
                      fileList={this.state.imageList}
                      onChange={({ fileList }) => {
                        this.handleImageChange(
                          "imageChanged",
                          "imageFile",
                          "imageList",
                          fileList
                        );
                      }}
                      display="col"
                      isAvatar="false"
                      membershipCard={true}
                      aspectRatio={16 / 9}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 24,
                      marginBottom: "1rem",
                    }}
                  >
                    # of Winners
                  </div>
                  <div className="col-md-8" style={{ marginLeft: "auto" }}>
                    <FSelect
                      label=""
                      name="quantity"
                      onChange={this.handleOptionChange}
                      value={this.state.quantity}
                      dataList={winnerList}
                      dataOnlyValue
                      placeholder="Count"
                      display="col"
                      tabIndex="1"
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 24,
                      marginBottom: "1rem",
                    }}
                  >
                    Goal
                  </div>
                  <div className="col-md-8" style={{ marginLeft: "auto" }}>
                    <FInput
                      label=""
                      name="goal"
                      display="col"
                      onChange={this.handleInputChange}
                      value={this.state.goal}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 24,
                      marginBottom: "1rem",
                    }}
                  >
                    Funds directed to
                  </div>
                  <div className="col-md-8" style={{ marginLeft: "auto" }}>
                    <FInput
                      label=""
                      name="paypalEmailId"
                      display="col"
                      onChange={this.handleInputChange}
                      value={this.state.paypalEmailId}
                      placeholder="Set paypal business email"
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 24,
                      marginBottom: "1rem",
                    }}
                  >
                    Proceeds support
                  </div>
                  <div className="col-md-8" style={{ marginLeft: "auto" }}>
                    <FUpload
                      action={(file) => {
                        this.uploadAction(
                          "proceedsImageChanged",
                          "proceedsImageFile",
                          file
                        );
                      }}
                      fileList={this.state.proceedSupportImageList}
                      onChange={({ fileList }) => {
                        this.handleImageChange(
                          "proceedsImageChanged",
                          "proceedsImageFile",
                          "proceedSupportImageList",
                          fileList
                        );
                      }}
                      display="col"
                      isAvatar="false"
                      membershipCard={true}
                      aspectRatio={16 / 9}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 24,
                      marginBottom: "1rem",
                      marginLeft: "auto",
                    }}
                  >
                    Or
                  </div>
                  <div className="col-md-8" style={{ marginLeft: "auto" }}>
                    <FInput
                      label=""
                      name="proceedsSupportName"
                      display="col"
                      onChange={this.handleInputChange}
                      value={this.state.funds}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 24,
                      marginBottom: "1rem",
                    }}
                  >
                    Donated by{" "}
                  </div>
                  <div className="col-md-8" style={{ marginLeft: "auto" }}>
                    <FUpload
                      action={(file) => {
                        this.uploadAction(
                          "donatedByImageChanges",
                          "donatedByImage",
                          file
                        );
                      }}
                      fileList={this.state.donatedByImageList}
                      onChange={({ fileList }) => {
                        this.handleImageChange(
                          "donatedByImageChanges",
                          "donatedByImage",
                          "donatedByImageList",
                          fileList
                        );
                      }}
                      display="col"
                      isAvatar="false"
                      membershipCard={true}
                      aspectRatio={16 / 9}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 24,
                      marginBottom: "1rem",
                      marginLeft: "auto",
                    }}
                  >
                    Or
                  </div>
                  <div className="col-md-8" style={{ marginLeft: "auto" }}>
                    <FInput
                      label=""
                      name="donatedByText"
                      display="col"
                      onChange={this.handleInputChange}
                      value={this.state.funds}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Checkbox
                style={{ marginLeft: "18px", marginTop: "1px" }}
                checked={this.state.acceptedTerms}
                onChange={this.onCheckChange}
                value="secondary"
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <span
                style={{
                  marginLeft: "15px",
                  fontsize: "50px",
                  color: "#FF0000",
                }}
              >
                *{" "}
              </span>
              <p style={{ marginLeft: "5px" }}>
                I understand and am in compliance with all local, state and
                national laws governing this giveaway.
              </p>
            </div>

            <div className="d-flex justify-content-end">
              <button
                className="btn btn-success mr-3"
                tabIndex="14"
                type="submit"
              >
                Submit
              </button>
              <button
                className="btn btn-sm btn-outline-secondary"
                tabIndex="14"
                onClick={this.handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  handleEditMode() {
    fbGetGiveaway(this.props.router.params.id).then((result) => {
      let data = new Giveaway();
      data.fromDataSet(result);

      let groupCount = data.groups.includes("global")
        ? this.state.allGroups.length
        : this.state.allGroups.filter(
            (group) =>
              data.groups.includes(group.id) ||
              data.groups.includes(
                countrtyNameCodeMap[group.supportersCountry]
              ) ||
              data.groups.includes(continents[group.supportersRegion])
          ).length;
      let params = [];
      data.groups.forEach((param) => {
        let group = this.state.allGroups.find((group) => group.id === param);
        if (group) {
          params.push(group.groupName);
        } else {
          params.push(param);
        }
      });

      this.setState({
        ...data,
        params,
        count: `${groupCount} groups`,
        isLoading: false,
      });
    });
  }

  componentDidMount() {
    if (this.props.router.params.id) {
      if (this.props.router.params.group.includes(this.props.clubId)) {
        getGroups().then((groups) => {
          this.setState(
            { allGroups: groups, count: `${groups.length} groups` },
            () => {
              this.handleEditMode();
            }
          );
        });
      }
    } else {
      getGroups().then((groups) => {
        this.setState({
          allGroups: groups,
          count: `${groups.length} groups`,
          isLoading: false,
        });
      });
    }
  }
}

const mapStateToProps = (state) => {
  let currentGroup = Object.keys(state.user.userRoles).find(
    (key) => state.user.userRoles[key] === "parentAdmin"
  );
  let { clubId } = state.adminData.allGroups[currentGroup].data;
  return {
    user: state.user,
    currentGroup,
    groupData: state.adminData.allGroups[currentGroup],
    clubId,
  };
};

export default connect(mapStateToProps)(withRouter(CreateGiveaway));
