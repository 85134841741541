const initialState = {
  "primary-background": "rgb(0, 0, 0)",
  "primary-background-dark": "rgb(0, 0, 0)",
  "primary-text-color": "rgb(255, 255, 255)",
};

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_THEME_COLORS":
      return { ...state, ...action.payload };

    case "RESET_THEME_COLORS":
      return { ...state, ...initialState };
    default:
      return state;
  }
};

export default themeReducer;
