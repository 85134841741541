const createFormLayoutStore = (set) => ({
  formLayout: {},
  formLayoutLoading: false,
  setFormLayout: (groupId, data) => {
    set((state) => ({
      formLayout: {
        ...state.formLayout,
        formLayout: {
          ...state.formLayout.formLayout,
          [groupId]: data,
        },
      },
    }));
  },

  setFormLayoutLoading: (formLayoutLoading) =>
    set((state) => ({
      formLayout: {
        ...state.formLayout,
        formLayoutLoading,
      },
    })),

  reset: () => set({ formLayout: {} }),
});

export default createFormLayoutStore;
