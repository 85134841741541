import React from "react";
import "../GettingStarted.css";
import "./MinimizedView.css";
import { useDispatch, useSelector } from "react-redux";
import { setGettingStartedExpanded } from "../../../redux/actions/adminData";
import { selectGettingStartedExpanded } from "../../../redux/selectors/adminData";
import closeIcon from "../../../images/close-icon.svg";

const MinimizedView = () => {
  const dispatch = useDispatch();

  const expanded = useSelector(selectGettingStartedExpanded);

  const handleExpand = (value) => {
    dispatch(setGettingStartedExpanded(value));
  };

  return (
    <div
      className="get-started-minimized"
      onClick={() => handleExpand(!expanded)}
    >
      <p className="get-started-minimized-text">
        <p
          className={`get-started-minimized-text--expanded ${expanded ? "visible" : ""}`}
        >
          <img
            height={28}
            width={28}
            alt="Close Getting Started"
            src={closeIcon}
          />
        </p>
        <p
          className={`get-started-minimized-text--minimized ${expanded ? "" : "visible"}`}
        >
          Get Started
        </p>
      </p>
    </div>
  );
};

export default MinimizedView;
