import React, { useState } from "react";
import "./MaximizedViewDetails.css";
import VideoPlayer from "../../../../CommonComponents/VideoPlayer/VideoPlayer";
import { Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentGroupData,
  selectGettingStartedItemStatus,
} from "../../../../redux/selectors/adminData";
import { setGettingStartedItemStatus } from "../../../../redux/actions/adminData";
import chevronLeft from "../../../../images/chevron-left.svg";

const MaximizedViewDetails = ({
  guideId,
  topicId,
  openDetails,
  title,
  details,
  footerDetails,
  videoUrl,
  customComponent,
  list,
}) => {
  const dispatch = useDispatch();
  const gettingStartedItemStatus = useSelector(selectGettingStartedItemStatus);
  const adminData = useSelector(selectCurrentGroupData);

  const handleMarkComplete = (checked) => {
    dispatch(
      setGettingStartedItemStatus(adminData.id, guideId, topicId, checked)
    );
  };

  return (
    <div>
      <div className="maximized-view-header">
        <div
          style={{
            display: "flex",
            gap: "8px",
          }}
        >
          <div className="flex-center">
            <img
              className="maximized-view-back-button"
              style={{ cursor: "pointer" }}
              onClick={() => openDetails(false)}
              height={25}
              width={25}
              src={chevronLeft}
            />
          </div>

          <div className="flex-center">
            <p style={{ fontSize: "16px", fontWeight: 700, marginBottom: 0 }}>
              {title}
            </p>
          </div>
        </div>

        <div className="maximized-view-header-mark-complete">
          <p>Mark as Complete</p>
          <div className="flex-center">
            <Checkbox
              className="maximized-view-details-checkbox"
              checked={
                gettingStartedItemStatus?.[guideId]?.topics?.[topicId] || false
              }
              onChange={(e) => handleMarkComplete(e.target.checked)}
              value="secondary"
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
              tabIndex="2"
            />
          </div>
        </div>
      </div>

      <div className="maximized-view-content-details">
        {details && (
          <p style={{ fontSize: "14px", fontWeight: 400 }}>{details}</p>
        )}

        {list &&
          list?.length > 0 &&
          list.map((item) => (
            <div style={{ display: "flex", gap: "8px" }}>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "14px",
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {`• `}
              </div>
              <div>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#333333",
                  }}
                >
                  {item}
                </p>
              </div>
            </div>
          ))}

        {customComponent && customComponent}

        {footerDetails && (
          <p style={{ fontSize: "14px", fontWeight: 400 }}>{footerDetails}</p>
        )}

        {videoUrl && <VideoPlayer videoUrl={videoUrl} />}
      </div>
    </div>
  );
};

export default MaximizedViewDetails;
