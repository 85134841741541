import React from "react";

import { Result, Button, Modal } from "antd";

import { connect } from "react-redux";
import { fbLogoutUser } from "../../services/firebaseService/endPoints/user";
import { loadingUser } from "../../redux/actions/user";
import { resetError } from "../../redux/actions/error";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      errorCode: "500",
      message: "",
      error: "",
      errorHandlers: {
        403: {
          title: "403",
          message: "Sorry, you are not authorized to access this page.",
          buttonText: "Back Home",
          buttonAction: this.action403,
        },
        404: {
          title: "404",
          message: "This page is not found",
          buttonText: "Back Home",
          buttonAction: this.action500,
        },
        500: {
          title: "500",
          message: "Internal server error.",
          buttonText: "Back Home",
          buttonAction: this.action500,
        },
        loadingTimeout: {
          status: "warning",
          title: "Timeout",
          message: "Loading timed out",
          buttonText: "Go Back",
          buttonAction: this.actionGoBack,
        },
        "api-football": {
          status: "warning",
          message: "API Error",
          buttonText: "Go Back",
          buttonAction: this.actionGoBack,
        },
        firebase: {
          status: "error",
          message: "Server database error",
          buttonText: "Go Back",
          buttonAction: this.actionGoBack,
        },
        indexDb: {
          status: "error",
          message: "Local database error",
          buttonText: "Back Home",
          buttonAction: this.action500,
        },
      },
    };
  }

  action403 = () => {
    this.props.dispatch(loadingUser());
    fbLogoutUser();
    this.setState({ isError: false, errorCode: "", message: "" });
    window.location.href = "/";
  };

  actionGoBack = () => {
    window.history.back();
    this.props.dispatch(resetError());
  };

  action500 = () => {
    window.location.href = "/";
  };

  static getDerivedStateFromError(error) {
    // render error
    return { errorCode: "500", isError: true };
  }

  handleCancel = () => {
    this.props.dispatch(resetError());
  };

  render() {
    let errorHandlers = this.state.errorHandlers;
    let errorCode = errorHandlers[this.state.errorCode]
      ? this.state.errorCode
      : "500";
    if (this.state.isError && !this.state.showModal) {
      return (
        <Result
          status={
            errorHandlers[errorCode].status
              ? errorHandlers[errorCode].status
              : errorCode
          }
          title={errorHandlers[errorCode].title}
          subTitle={this.state.message || errorHandlers[errorCode].message}
          extra={
            <Button
              type="primary"
              onClick={errorHandlers[errorCode].buttonAction}
            >
              {errorHandlers[errorCode].buttonText}
            </Button>
          }
        />
      );
    } else {
      return (
        // this.state.isError && this.state.showModal && <></>
        <>
          {this.state.isError && this.state.showModal && (
            <Modal
              open={this.props.error.show}
              title="Error"
              onOk={this.handleReport}
              onCancel={this.handleCancel}
              footer={[
                <Button key="back" onClick={this.handleCancel}>
                  Return
                </Button>,
              ]}
            >
              <p>{this.state.message || errorHandlers[errorCode].message}</p>
            </Modal>
          )}
          {this.props.children}
        </>
      );
    }
  }

  componentDidUpdate() {
    if (this.props.error.show !== this.state.isError) {
      this.setState({
        isError: !!this.props.error.show,
        error: this.props.error.err,
        errorCode: this.props.error.errorCode,
        message: this.props.error.message,
        showModal:
          this.props.error.errorCode &&
          this.props.error.errorCode.includes("modal"),
      });
    }
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.error,
  };
};

export default connect(mapStateToProps)(ErrorBoundary);
