import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { store } from "../../../..";
import { db } from "../../connection";
import { getMyId } from "../admin/members";

export function getDefaultClub() {
  let currentGroup = store.getState().adminData.currentGroup;
  let currentClub =
    store.getState().adminData.allGroups[currentGroup].data.clubId;
  return currentClub;
}

export function getCurrentGroup() {
  return store.getState().adminData.currentGroup;
}

export function getChildrenGroupIds() {
  let currentGroup = store.getState().adminData.currentGroup;
  let childrenGroups =
    store.getState().adminData.allGroups[currentGroup].data.children || [];
  return childrenGroups;
}

export async function getIsParentAdmin() {
  try {
    const myId = getMyId();
    const currentGroup = getCurrentGroup();

    if (!myId || !currentGroup) {
      console.error("Missing required identifiers: myId or currentGroup.");
      return false;
    }

    const memberDocRef = doc(db, "userGroup", currentGroup, "members", myId);

    const memberDoc = await getDoc(memberDocRef);

    if (!memberDoc.exists()) {
      console.warn("Document does not exist for the given user and group.");
      return false;
    }

    const userData = memberDoc.data();

    if (userData?.userRole === "parentAdmin") {
      return true;
    }

    return false;
  } catch (error) {
    console.error("Error while checking if user is Parent Admin:", error);
    return false;
  }
}

export async function getGroups() {
  let isParentAdmin = await getIsParentAdmin();

  if (isParentAdmin) {
    let clubId = getDefaultClub();
    let currentGroup = getCurrentGroup();
    let childrenGroups = getChildrenGroupIds();
    let groupQuery = getDocs(
      query(collection(db, "group"), where("clubId", "==", clubId))
    );

    if (Array.isArray(childrenGroups)) {
      if (!childrenGroups.includes("All")) {
        if (childrenGroups.includes("Official Groups")) {
          groupQuery = getDocs(
            query(
              collection(db, "group"),
              where("clubId", "==", clubId),
              where("groupType", "==", "Official Supporters Group")
            )
          );
        } else if (childrenGroups.length > 0) {
          childrenGroups.push(currentGroup);
          childrenGroups = Array.from(new Set(childrenGroups));
          let promises = childrenGroups.map((groupId) => {
            return getDoc(doc(db, "group", groupId));
          });
          groupQuery = Promise.all(promises).then((docs) => {
            let snap = {
              docs: docs,
            };
            return snap;
          });
        }
      }

      let groupData = await groupQuery.then((snap) => {
        let groups = [];
        snap.docs.forEach((doc) => {
          let data = doc.data();
          let groupData = {
            ...data,
            id: doc.id,
          };
          delete groupData.children;
          groups.push(groupData);
        });

        return groups;
      });

      let adminPromises = groupData.map((group) => {
        return getDocs(collection(db, "userGroup", group.id, "members")).then(
          (snap) => {
            let admins = [];
            snap.docs.forEach((doc) => {
              if (doc.data().userRole === "admin") {
                admins.push({
                  ...doc.data(),
                });
              }
            });
            return { ...group, admins, registeredMembers: snap.docs.length };
          }
        );
      });

      let invitePromises = groupData.map((group) => {
        return getDoc(doc(db, "invite_emails", group.id)).then((doc) => {
          if (doc.exists) {
            return { id: group.id, preRegistered: doc.data().emails.length };
          }
        });
      });

      let preRegCounts = await Promise.all(invitePromises);

      return await Promise.all(adminPromises).then((groups) => {
        let res = groups.map((groupData) => {
          let preRegForGroupArr = preRegCounts.filter((data) => {
            return data && data.id === groupData.id;
          });
          let preRegForGroup =
            preRegForGroupArr.length > 0
              ? preRegForGroupArr[0].preRegistered
              : 0;
          return {
            ...groupData,
            totalMembers: groupData.registeredMembers + preRegForGroup,
          };
        });

        return res;
      });
    }
    return [];
  } else {
    window.location.href = "/admin/members";
    return [];
  }
}
