import { Alert, Input, Steps, Modal, Tooltip, Checkbox } from "antd";
import React from "react";
import { connect } from "react-redux";
import {
  fbGetFormLayout,
  fbSaveFormLayout,
  publishOrUnpublishForm,
  uploadImage,
} from "../../../../../services/firebaseService/endPoints/admin/members";
import { FInput } from "../../../../commons/formFields/FInput";
import FUpload from "../../../../commons/formFields/FUpload";
import RegFormDetails from "../../../../../models/admin/RegFormDetails";
import LoadingModal from "../../../../commons/LoadingModal";
import moment from "moment";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { fbUpdateGroup } from "../../../../../services/firebaseService/endPoints/group";
import { fbGetGroup } from "../../../../../services/firebaseService/endPoints/user";
import { FSelect } from "../../../../commons/formFields/FSelect";
import { fbGetPartners } from "../../../../../services/firebaseService/endPoints/admin/partners";
import {
  generateQR,
  postUpdateCDNUtil,
} from "../../../../../helperFunctions/util";
import logger from "../../../../../utils/logger";
import { AutoRenewalIcon } from "../../../../../utils/icons";
import "./Setup.css";

const packageTemplate = {
  name: "",
  desc: "",
  price: {
    unit: "$",
    value: "",
  },
  image: undefined,
  imageList: [],
  familyMembership: false,
  editConfiguration: {
    imageChanged: false,
  },
  includesTshirt: false,
  sizes: [],
  isDeleted: false,
  hasNoOfChildren: false,
  costPerChild: "",
  hasChildTshirtSizes: false,
  shippingOption: undefined,
  shippingCharge: "",
  pickupNote: "",
  shippingNote: "",
};
const ProcessingFeeTypes = {
  groupPays: "Group pays (Fees absorbed by group)",
  memberPays: "Member pays (Fees added to total)",
  optional: "Ask Member to pay (Request member pay fee but not required)",
};

const SHIPPING_OPTIONS = [
  "Select shipping option",
  "Show Pickup",
  "Show Shipping",
  "Offer choice of pickup or shipping",
];

class GroupRegFormConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      title: "",
      intro: "",
      benefits: EditorState.createEmpty(),
      groupImageFile: undefined,
      groupImageWideList: [],
      groupImageRegularList: [],
      groupImageType: "wide",
      groupTermsURL: "",
      thankYouNote: "",
      renewalDate: "",
      supportEmail: "",
      processingFee: "",
      requireMembershipPackage: false,
      basicsStep: "process",
      formFieldStep: "wait",
      // membershipPackagesStep: 'wait',
      donationStep: "wait",
      previewStep: "wait",
      formFields: {
        fullName: true,
        firstName: true,
        lastName: true,
        email: true,
        phone: "",
        address: "",
        birthday: "",
        joinMailingList: "",
        shareVolunteerOpportunities: "",
        officialClubMember: "",
        seasonTicketHolder: "",
        customFieldCount: 0,
      },
      customFieldDetails: {},
      editableFieldName: "",
      showChapterModal: false,
      chapters: [],
      includeNone: false,
      packages: [],
      hasDonations: false,
      donationTitle: "",
      donationDesc: "",
      donationImageList: [],
      donationImage: "",
      amount1: "",
      amount2: "",
      amount3: "",
      partnerId: "",
      isPublished: false,
      donationAutorenewal: false,
      editConfiguration: {
        groupImageChanged: false,
        donationImageChanged: false,
      },
      errorInForm: {
        visible: false,
        isError: false,
        message: "",
      },
      isTransitioning: false,
      previewVisible: "false",
      isPublishing: false,
      addOrEditPackageModal: false,
      allPartners: [],
      joinSVG: "",
    };
  }

  handleEditorStateChange = (editorState) => {
    this.setState({ benefits: editorState });
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  changeSelect = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  uploadAction = (file, name) => {
    this.setState({ [name]: file });
  };

  handleImageChange = (fileList, fileNameKey, fileListKey, type) => {
    let result = {};
    if (fileList.length === 0) {
      result[fileNameKey] = null;
    } else {
      result[fileNameKey] = fileList[0].originFileObj;
    }

    result[fileListKey] = fileList;
    if (this.state.basicsStep === "process") {
      result.groupImageType = type;
    }
    result.editConfiguration =
      this.state.basicsStep === "process"
        ? {
            groupImageChanged: true,
            donationImageChanged: false,
          }
        : {
            groupImageChanged: false,
            donationImageChanged: true,
          };

    this.setState(() => result);
  };

  handleDateChange = (name, value) => {
    if (!Array.isArray(value)) value = moment(value).isValid() ? value : "";
    let hours = 0,
      minutes = 0,
      seconds = 59;
    // return;
    if (value) {
      this.setState({
        [name]: moment(value)
          .set({
            h: hours,
            m: minutes,
            second: seconds,
          })
          .unix(),
      });
    }
  };

  handleCheckChange = (e) => {
    this.setState((prevState) => ({
      formFields: {
        ...prevState.formFields,
        [e.target.id]: e.target.checked,
      },
    }));
  };

  handleErrorMessageClose = () => {
    this.setState((prevState) => {
      const errorInForm = {
        ...prevState.errorInForm,
      };
      errorInForm.visible = false;
      return {
        errorInForm,
      };
    });
  };

  handleNext = (current, next) => {
    this.setState({ loading: true, isTransitioning: true });
    let packages = this.state.packages;
    let promise;
    if (
      current === "basicsStep" &&
      this.state.editConfiguration.groupImageChanged &&
      this.state.groupImageFile
    ) {
      let groupImageUploadPromise = uploadImage(
        this.state.groupImageFile,
        `/regForm/${this.props.currentGroup}/groupImage`
      ).then(({ downloadUrl }) => downloadUrl);
      promise = groupImageUploadPromise;
    }

    if (promise) {
      promise.then((res) => {
        let data = new RegFormDetails();
        data.toDataSet(
          {
            ...this.state,
            benefits: draftToHtml(
              convertToRaw(this.state.benefits.getCurrentContent())
            ),
          },
          current
        );

        data = JSON.parse(JSON.stringify(data));
        if (current === "basicsStep") {
          data.groupImageFile = res;
        }

        if (data.hasError) {
          window.scrollTo(0, 0);
          this.setState({
            errorInForm: {
              isError: true,
              visible: true,
              message: data.hasError.message,
            },
            loading: false,
            isTransitioning: false,
          });
          window.scrollTo(0, 0);
          setTimeout(this.handleErrorMessageClose, 5000);
        } else {
          delete data.packages;
          if (current === "basicsStep") {
            let requireMembershipPackage = data.requireMembershipPackage;

            let groupData = { requireMembershipPackage };

            // if (data.requireMembershipPackage) {
            let packageIdExpiryMap = {};
            let packageExpiryMap = {};
            for (let pack of packages) {
              packageIdExpiryMap[pack.id] = pack.packageExpiryDate;
              packageExpiryMap[pack.id] = {
                expiryDate: pack.packageExpiryDate || 0,
                name: pack.name || "",
              };
            }

            groupData = { ...groupData, packageIdExpiryMap, packageExpiryMap };
            // }

            fbUpdateGroup({ ...groupData }, this.props.currentGroup, null);
          }
          if (current === "membershipPackagesStep") {
            this.setState({
              [current]: "finish",
              [next]: "process",
              loading: false,
              isTransitioning: false,
              ...data,
            });
          } else {
            data.groupName = this.props.groupName;
            fbGetGroup(this.props.currentGroup).then((docData) => {
              data.configuration = docData.configuration || {};
              data.paymentDetails = docData.paymentDetails || {};

              fbSaveFormLayout(data, this.props.currentGroup).then(() => {
                this.setState({
                  [current]: "finish",
                  [next]: "process",
                  loading: false,
                  isTransitioning: false,
                });
              });
            });
          }
        }
      });
    } else {
      let data = new RegFormDetails();
      data.toDataSet(
        {
          ...this.state,
          benefits: draftToHtml(
            convertToRaw(this.state.benefits.getCurrentContent())
          ),
        },
        current
      );

      data = JSON.parse(JSON.stringify(data, current));

      let hasError = false;

      if (hasError) {
        data.hasError = { message: "Image is required" };
      }

      if (data.hasError) {
        window.scrollTo(0, 0);
        this.setState({
          errorInForm: {
            isError: true,
            visible: true,
            message: data.hasError.message,
          },
          loading: false,
          isTransitioning: false,
        });
        window.scrollTo(0, 0);
        setTimeout(this.handleErrorMessageClose, 5000);
      } else {
        delete data.packages;
        if (current === "basicsStep") {
          let requireMembershipPackage = data.requireMembershipPackage;

          let groupData = { requireMembershipPackage };

          // if (data.requireMembershipPackage) {
          let packageIdExpiryMap = {};
          let packageExpiryMap = {};
          for (let pack of packages) {
            packageIdExpiryMap[pack.id] = pack.packageExpiryDate;
            packageExpiryMap[pack.id] = {
              expiryDate: pack.packageExpiryDate || 0,
              name: pack.name || "",
            };
          }
          groupData = { ...groupData, packageIdExpiryMap, packageExpiryMap };
          // }

          fbUpdateGroup({ ...groupData }, this.props.currentGroup, null);
        }
        if (current === "membershipPackagesStep") {
          fbGetFormLayout(this.props.currentGroup).then((res) => {
            let data = new RegFormDetails();
            data.fromDataSet(res);
            let benefits = htmlToDraft(data.benefits);
            if (benefits) {
              const contentState = ContentState.createFromBlockArray(
                benefits.contentBlocks
              );
              const editorState = EditorState.createWithContent(contentState);
              data.benefits = editorState;
            }
            this.setState({
              [current]: "finish",
              [next]: "process",
              loading: false,
              isTransitioning: false,
              ...data,
            });
          });
          // });
        } else {
          data.groupName = this.props.groupName;
          fbGetGroup(this.props.currentGroup).then((docData) => {
            data.configuration = docData.configuration || {};
            data.paymentDetails = docData.paymentDetails || {};

            fbSaveFormLayout(data, this.props.currentGroup).then(() => {
              this.setState({
                [current]: "finish",
                [next]: "process",
                loading: false,
                isTransitioning: false,
              });
            });
          });
        }
      }
    }

    postUpdateCDNUtil(this.props.currentGroup, "join");
  };

  handlePackageInputChange = (name, value, index) => {
    let packages = this.state.packages;
    if (name.includes("price")) {
      name = name.split(".")[1];
      packages[index]["price"][name] = value;
    } else {
      packages[index][name] = value;
    }
    this.setState({ packages });
  };

  handlePackageImageUploadAction = (file, index) => {
    let packages = this.state.packages;
    packages[index].image = file;
    this.setState({ packages });
  };

  handlePackageImageChange = (fileList, index) => {
    let result = {};
    if (fileList.length === 0) {
      result.image = null;
    } else {
      result.image = fileList[0].originFileObj;
    }

    result.imageList = fileList;

    let packages = this.state.packages;
    packages[index] = {
      ...packages[index],
      ...result,
      editConfiguration: {
        imageChanged: true,
      },
    };
    this.setState({ packages });
  };

  handlePackageCheckChange = (name, checked, index) => {
    let packages = this.state.packages;
    packages[index][name] = checked;
    this.setState({ packages });
  };

  publishOrUnpublishForm = () => {
    this.setState({
      isPublishing: true,
    });
    publishOrUnpublishForm(
      !this.state.isPublished,
      this.props.currentGroup,
      this.props.groupName
    ).then((isPublished) => {
      this.setState({
        isPublishing: false,
        isPublished,
      });
    });
  };

  handleUpdateTshirtSizes = (index, sizes) => {
    let packages = this.state.packages;
    packages[index].sizes = sizes;

    this.setState({
      packages,
    });
  };

  handleAddRowForSize = (index) => {
    let packages = this.state.packages;
    if (!packages[index].sizes) {
      packages[index].sizes = [];
    }
    packages[index].sizes.push("");

    this.setState({
      packages,
    });
  };

  handlePackageSelectChange = (name, value, index) => {
    let packages = this.state.packages;
    packages[index][name] = value;

    this.setState({
      packages,
    });
  };

  render() {
    const groupImageList = this.state?.groupImageWideList?.filter((item) => {
      return (
        (item?.url && item?.url?.length > 0) ||
        (item?.uid && item?.uid?.length > 0 && item?.originFileObj)
      );
    });

    let loadingTitle = "Loading Document";
    let loadingMessage = "Fetching data";

    let customFieldCount = this.state.formFields.customFieldCount;
    let customFieldKeys = Object.keys(this.state.customFieldDetails);

    let backgroundColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      backgroundColor = `rgb(${r}, ${g}, ${b})`;
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0rem 3rem",
        }}
      >
        <LoadingModal
          title={loadingTitle}
          message={!this.state.isTransitioning && loadingMessage}
          // percentage={this.state.loadingPercentage}
          loading={this.state.loading}
        />

        {this.state.editableFieldName.length > 0 && (
          <EditCustomFieldModal
            data={this.state.customFieldDetails[this.state.editableFieldName]}
            showModal={this.state.editableFieldName.length > 0}
            onUpdateField={(data) => {
              let editableFieldName = this.state.editableFieldName;
              let customFieldDetails = this.state.customFieldDetails;
              this.setState({
                customFieldDetails: {
                  ...customFieldDetails,
                  [editableFieldName]: data,
                },
                editableFieldName: "",
              });
            }}
            onClose={() => {
              this.setState({
                editableFieldName: "",
              });
            }}
          />
        )}

        {this.state.showChapterModal > 0 && (
          <EditChapterModal
            data={{
              chapters: this.state.chapters,
              includeNone: this.state.includeNone,
            }}
            showModal={this.state.showChapterModal}
            onUpdateField={(data) => {
              this.setState({
                ...data,
                showChapterModal: "",
              });
            }}
            onClose={() => {
              this.setState({
                showChapterModal: "",
              });
            }}
          />
        )}

        {this.state.errorInForm.isError && this.state.errorInForm.visible && (
          <div className="mx-5 mt-2">
            <Alert
              message="Error"
              description={this.state.errorInForm.message}
              type="error"
              closable
              // className="mb-3"
              afterClose={this.handleErrorMessageClose}
            />
          </div>
        )}

        <div style={{ width: "100%", display: "flex" }}>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              className="border rounded p-3 px-3 mx-auto"
              style={{
                maxWidth: "100%",
                backgroundColor: "#ffffff",
                // boxShadow: "0px 3px 10px 1px",
              }}
            >
              <Steps>
                <Steps.Step
                  status={this.state.basicsStep}
                  title="General Setup"
                />
                <Steps.Step status={this.state.formFieldStep} title="Form" />
                {/* <Steps.Step
                                    status={this.state.membershipPackagesStep}
                                    title="Packages"
                                /> */}
                <Steps.Step
                  status={this.state.donationStep}
                  title="Donations"
                />
                <Steps.Step status={this.state.previewStep} title="Complete" />
              </Steps>
              {this.state.basicsStep === "process" && (
                <>
                  <div style={{ marginTop: 40 }}>
                    <p style={{ fontSize: 15, color: "rgb(85, 85, 85)" }}>
                      Set up the registration flow including member benefits,
                      registration form fields and donations.
                    </p>
                  </div>
                  <div>
                    <p style={{ fontSize: 15, color: "rgb(85, 85, 85)" }}>
                      Important: If ‘Require Membership Package’ is checked,
                      accounts will be locked after the ‘Package Expiration
                      Date’ for members without a current package. Provide a
                      grace period to allow members time to renew.
                    </p>
                  </div>
                  <div>
                    <p style={{ fontSize: 15, color: "rgb(85, 85, 85)" }}>
                      Once published, your registration URL will be:{" "}
                      <a
                        href={`https://group.chant.fan/${this.props.groupName
                          .replace(/ /g, "")
                          .replace("#", "%23")
                          .toLowerCase()}/join`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        https://group.chant.fan/
                        {this.props.groupName
                          .replace(/ /g, "")
                          .replace("#", "%23")
                          .toLowerCase()}
                        /join
                      </a>{" "}
                      and the QR code is.{" "}
                      {this.state.joinSVG && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: `${this.state.joinSVG}`,
                          }}
                        ></span>
                      )}
                    </p>
                  </div>
                  <div style={{ display: "flex", gap: "30px" }}>
                    <div className="col-md-12">
                      {/* <p style={{ fontSize: 15, color: 'rgb(85, 85, 85)' }}>When a members joins your group they are automatically pre-registered for Chant. Members who do not renew the following season will lose Chant access on the 'Package Expiration Date'.</p> */}
                      <div
                        style={{ display: "flex", flexWrap: "wrap" }}
                        className="mb-2"
                      >
                        <div className="col-md-6">
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <div
                              className="col-md-3"
                              style={{
                                display: "flex",
                              }}
                            >
                              <span
                                style={{ color: "#000000", fontWeight: 600 }}
                              >
                                Title
                                <span style={{ color: "#ff0000" }}>*</span>
                              </span>
                            </div>
                            <div
                              className="col-md-9"
                              style={{ padding: "0 30px" }}
                            >
                              <FInput
                                maxLength={"40"}
                                value={this.state.title}
                                onChange={this.handleInputChange}
                                name="title"
                                display="col"
                                tabIndex="2"
                                postTip={`Max ${this.state.title.length}/40 characters`}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-6 hide-for-mobile"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                          }}
                        >
                          {/* <div style={{display: "flex"}} style={{ border: '1px solid #000000', backgroundColor: 'rgba(255, 0, 0, 0.7)', width: '70%', height: 'fit-content', textAlign: 'center', position: 'absolute' }}>
                                                        <div className="row justify-content-center" style={{ width: '100%', marginTop: 15 }}>
                                                            <span style={{ fontWeight: 'bold' }}>
                                                                Registration Web Address
                                                            </span>
                                                        </div>
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                                                            <p>https://group.chant.fan/{this.props.groupName.replace(/ /g, '').replace('#', '%23').toLowerCase()}/join</p>
                                                        </div>
                                                        <p style={{ width: '100%', textAlign: 'center' }}>Copy this URL to use as the ‘Join’ link on your website and share in social channels.</p>
                                                        <p style={{ width: '100%', textAlign: 'center' }}>Questions? Contact <a href='mailto:support@chant.fan' target='_blank'>support@chant.fan</a>.</p>
                                                    </div> */}
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", flexWrap: "wrap" }}
                        className="mb-4"
                      >
                        <div className="col-md-6">
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <div
                              className="col-md-3"
                              style={{
                                display: "flex",
                              }}
                            >
                              <span
                                style={{ color: "#000000", fontWeight: 600 }}
                              >
                                Brief Introduction
                                <span style={{ color: "#ff0000" }}>*</span>
                              </span>
                            </div>
                            <div
                              className="col-md-9"
                              style={{ padding: "0px 30px" }}
                            >
                              <Input.TextArea
                                tabIndex="2"
                                style={{
                                  fontSize: `18px`,
                                }}
                                value={this.state.intro}
                                name="intro"
                                onChange={this.handleInputChange}
                                rows={5}
                                maxLength={"250"}
                              />
                              <p>{`Max ${this.state.intro.length}/250 characters`}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-6">
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <div
                              className="col-md-3"
                              style={{
                                display: "flex",
                              }}
                            >
                              <span
                                style={{ color: "#000000", fontWeight: 600 }}
                              >
                                Member Benefits
                              </span>
                            </div>
                            <div
                              className="col-md-9"
                              style={{ padding: "0px 30px" }}
                            >
                              <div
                                style={{
                                  border: "1px solid #d9d9d9",
                                  borderRadius: 5,
                                  padding: 5,
                                  height: 270,
                                  overflow: "scroll",
                                }}
                              >
                                <Editor
                                  editorState={this.state.benefits}
                                  onEditorStateChange={
                                    this.handleEditorStateChange
                                  }
                                  toolbar={{
                                    options: ["inline", "list", "link"],
                                    inline: {
                                      inDropdown: false,
                                      options: ["bold", "italic", "underline"],
                                    },
                                    list: {
                                      inDropdown: false,
                                      options: ["unordered", "ordered"],
                                    },
                                    link: {
                                      inDropdown: false,
                                      defaultTargetOption: "_self",
                                      options: ["link", "unlink"],
                                      linkCallback: undefined,
                                    },
                                  }}
                                  stripPastedStyles={true}
                                  tabIndex="2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          paddingTop: "16px",
                          gap: "10px",
                        }}
                      >
                        <div className="col-md-6">
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <div
                              className="col-md-3"
                              style={{
                                display: "flex",
                              }}
                            >
                              <span
                                style={{ color: "#000000", fontWeight: 600 }}
                              >
                                Group Image
                                <span style={{ color: "#ff0000" }}>*</span>
                              </span>
                            </div>
                            <div className="col-md-9">
                              <div
                                style={{
                                  position: "relative",
                                  padding: "0 30px",
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    position: "relative",
                                  }}
                                >
                                  <FUpload
                                    action={(file) =>
                                      this.uploadAction(file, "groupImageFile")
                                    }
                                    fileList={groupImageList}
                                    onChange={({ fileList }) =>
                                      this.handleImageChange(
                                        fileList,
                                        "groupImageFile",
                                        "groupImageWideList",
                                        "wide"
                                      )
                                    }
                                    display="col"
                                    isAvatar="false"
                                    aspectRatio={16 / 9}
                                  />
                                </div>

                                {/* <div style={{ display: 'flex', alignItems: 'center', height: '100%', margin: 'auto 0px' }}>or</div>
                                                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
                                                                    <FUpload
                                                                        action={(file) =>
                                                                            this.uploadAction(file, 'groupImageFile')
                                                                        }
                                                                        fileList={
                                                                            this.state.groupImageRegularList
                                                                        }
                                                                        onChange={({ fileList }) =>
                                                                            this.handleImageChange(fileList, 'groupImageFile', 'groupImageRegularList', 'regular')
                                                                        }
                                                                        display="col"
                                                                        isAvatar="false"
                                                                        aspectRatio={2 / 1}
                                                                    />
                                                                    <span style={{ fontSize: 14, bottom: 10, position: 'absolute', margin: '0px auto' }}>Portrait</span>
                                                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-6 hide-for-mobile"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#000000aa",
                          }}
                        >
                          Upload a landscape (16:9) image for page header. Image
                          should be 500KB minimum for best quality.
                        </div>
                      </div>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <div className="col-md-6">
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <div
                              className="col-md-3"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{ color: "#000000", fontWeight: 600 }}
                              >
                                Group Terms (URL)
                              </span>
                            </div>
                            <div
                              className="col-md-9"
                              style={{ padding: "0 30px" }}
                            >
                              <FInput
                                value={this.state.groupTermsURL}
                                onChange={this.handleInputChange}
                                name="groupTermsURL"
                                display="col"
                                tabIndex="2"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-6 hide-for-mobile"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#000000aa",
                          }}
                        >
                          Members required to accept group and Chant terms
                        </div>
                      </div>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <div className="col-md-6">
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <div
                              className="col-md-3"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{ color: "#000000", fontWeight: 600 }}
                              >
                                Thank you message
                                <span style={{ color: "#ff0000" }}>*</span>
                              </span>
                            </div>
                            <div
                              className="col-md-9"
                              style={{ padding: "0 30px" }}
                            >
                              <FInput
                                value={this.state.thankYouNote}
                                onChange={this.handleInputChange}
                                name="thankYouNote"
                                display="col"
                                tabIndex="2"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-6 hide-for-mobile"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#000000aa",
                          }}
                        >
                          Large header on confirmation thank you page. Enter
                          very brief message like 'Welcome to{" "}
                          {this.props.groupName}'
                        </div>
                      </div>
                      {/* <div style={{display: "flex"}}>
                                                <div className="col-md-6">
                                                    <div style={{display: "flex"}}>
                                                        <div className="col-md-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <span style={{ color: '#000000', fontWeight: 600 }}>Renewal Date<span style={{ color: "#ff0000" }}>*</span></span>
                                                        </div>
                                                        <div className="col-md-9" style={{ padding: '0px 30px' }}>
                                                            <DatePicker
                                                                tabIndex="2"
                                                                value={this.state.renewalDate ? moment.unix(this.state.renewalDate) : ''}
                                                                onChange={
                                                                    (value) => {
                                                                        this.handleDateChange('renewalDate', value);
                                                                    }
                                                                }
                                                                className="form-control form-control-sm"
                                                                size={
                                                                    10
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 hide-for-mobile" style={{ display: 'flex', alignItems: 'center', color: '#000000aa' }}>
                                                    Displayed on registration thank you page
                                                </div>
                                            </div> */}

                      <div style={{ display: "flex", gap: "10px" }}>
                        <div className="col-md-6">
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <div
                              className="col-md-3"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{ color: "#000000", fontWeight: 600 }}
                              >
                                Support Email
                                <span style={{ color: "#ff0000" }}>*</span>
                              </span>
                            </div>
                            <div
                              className="col-md-9"
                              style={{ padding: "0 30px" }}
                            >
                              <FInput
                                value={this.state.supportEmail}
                                onChange={this.handleInputChange}
                                name="supportEmail"
                                display="col"
                                tabIndex="2"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-6 hide-for-mobile"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#000000aa",
                          }}
                        >
                          Displayed on registration thank you page
                        </div>
                      </div>

                      <div style={{ display: "flex", gap: "10px" }}>
                        <div className="col-md-6">
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <div
                              className="col-md-3"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{ color: "#000000", fontWeight: 600 }}
                              >
                                Require membership package?
                              </span>
                            </div>
                            <div
                              className="col-md-9 d-flex"
                              style={{ alignItems: "center" }}
                            >
                              <div
                                className="row m-0"
                                style={{ paddingLeft: "20px" }}
                              >
                                <div className="col-md-4"></div>
                                <div className="col-md-8">
                                  <input
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                    }}
                                    type="checkbox"
                                    className="form-control-input cursor-pointer"
                                    id="requireMembershipPackage"
                                    checked={
                                      this.state.requireMembershipPackage
                                    }
                                    onChange={(e) => {
                                      this.setState(
                                        {
                                          [e.target.id]: e.target.checked,
                                        },
                                        () => {
                                          logger.log(this.state);
                                        }
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-6 hide-for-mobile"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#000000aa",
                          }}
                        >
                          <span>
                            Selecting this field will use the package expiration
                            date (set for each membership package you create) to
                            lock members who do not purchase a package or renew
                            next season. Reach out to{" "}
                            <a href="mailto:support@chant.fan">
                              support@chant.fan
                            </a>{" "}
                            if you have questions.
                          </span>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          padding: "12px 0px",
                          gap: "10px",
                        }}
                      >
                        <div className="col-md-6">
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <div
                              className="col-md-3"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{ color: "#000000", fontWeight: 600 }}
                              >
                                Setup auto-renewal email in Stripe
                              </span>
                              <Tooltip
                                placement="topLeft"
                                title={() => (
                                  <div>
                                    <img
                                      style={{
                                        height: 400,
                                        width: 600,
                                        objectFit: "contain",
                                      }}
                                      src={AutoRenewalIcon}
                                      alt="stripe screenshot"
                                      height={15}
                                    />
                                  </div>
                                )}
                              >
                                <span className="border-0">
                                  <i className="fa fa-question-circle-o"></i>
                                </span>
                              </Tooltip>
                            </div>
                            <div
                              className="col-md-9 d-flex"
                              style={{
                                alignItems: "center",
                                padding: "0 30px",
                              }}
                            >
                              <div className="row m-0">
                                <div className="col-md">
                                  <a
                                    href="https://dashboard.stripe.com/settings/emails"
                                    target="#"
                                  >
                                    Stripe Dashboard
                                  </a>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-6 hide-for-mobile"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#000000aa",
                          }}
                        >
                          <span>
                            If offering auto-renewal you must enable 'Payments /
                            Successful Payments' in Settings/Emails of the
                            Stripe Dashboard for subscribers to receive an email
                            receipt with 'manage subscription' link.
                          </span>
                        </div>
                      </div>

                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="col-md-6">
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <div className="col-md-3">
                              <span
                                style={{ color: "#000000", fontWeight: 600 }}
                              >
                                Processing Fee
                                <span style={{ color: "#ff0000" }}>*</span>
                              </span>
                              <Tooltip
                                placement="topLeft"
                                title={() => (
                                  <div style={{ width: 400 }}>
                                    <p style={{ fontWeight: "bold" }}>Fees</p>
                                    <p style={{ marginBottom: 0 }}>
                                      Stripe card processing fee: 2.9% + $0.30
                                      (can vary)
                                    </p>
                                    <p>Chant fee: 3%</p>

                                    <p style={{ fontWeight: "bold" }}>
                                      Member Pay
                                    </p>
                                    <p>
                                      Stripe collects the fee on the entire
                                      purchase amount. So, if a member covers
                                      fees that is added to the total charged
                                      ($10 item + $0.59 fee paid by member for
                                      $10.59) stripe will collect a fee on the
                                      entire $10.59.
                                    </p>

                                    <p>
                                      To account for this we add 3% + $.30 (.1%
                                      more) to the original item price for
                                      Stripe so your net is close to the item
                                      price - it may be off very slightly.
                                    </p>

                                    <p>
                                      Stripe fees can vary based on member
                                      payment method but are not known until
                                      after the transaction is complete (foreign
                                      card, etc). Your net proceeds may not
                                      cover the full item amount but should be
                                      close.
                                    </p>

                                    <p style={{ fontWeight: "bold" }}>
                                      Member Pay (legal Limitations)
                                    </p>
                                    <p>
                                      Not all states/countries allow the fee to
                                      be passed to the member. Chant is not
                                      responsible for determining your local
                                      laws. Please confirm this for your
                                      location.
                                    </p>
                                  </div>
                                )}
                              >
                                <span className="border-0">
                                  <i className="fa fa-question-circle-o"></i>
                                </span>
                              </Tooltip>
                            </div>
                            <div
                              className="col-md-9"
                              style={{ padding: "0 30px" }}
                            >
                              <FSelect
                                label=""
                                name="processingFee"
                                display="col"
                                tabIndex="5"
                                bottomHint="Stripe (2.9% + $.030) + Chant (3%). See ‘?’ for additional details."
                                tooltip={
                                  <div>
                                    <p style={{ fontWeight: "bold" }}>Fees</p>
                                    <p style={{ marginBottom: 0 }}>
                                      Stripe card processing fee: 2.9% + $0.30
                                      (can vary)
                                    </p>
                                    <p>Chant fee: 3%</p>

                                    <p style={{ fontWeight: "bold" }}>
                                      Member Pay
                                    </p>
                                    <p>
                                      Stripe collects the fee on the entire
                                      purchase amount. So, if a member covers
                                      fees that is added to the total charged
                                      ($10 item + $0.59 fee paid by member for
                                      $10.59) stripe will collect a fee on the
                                      entire $10.59.
                                    </p>

                                    <p>
                                      To account for this we add 3% + $.30 (.1%
                                      more) to the original item price for
                                      Stripe so your net is close to the item
                                      price - it may be off very slightly.
                                    </p>

                                    <p>
                                      Stripe fees can vary based on member
                                      payment method but are not known until
                                      after the transaction is complete (foreign
                                      card, etc). Your net proceeds may not
                                      cover the full item amount but should be
                                      close.
                                    </p>

                                    <p style={{ fontWeight: "bold" }}>
                                      Member Pay (legal Limitations)
                                    </p>
                                    <p>
                                      Not all states/countries allow the fee to
                                      be passed to the member. Chant is not
                                      responsible for determining your local
                                      laws. Please confirm this for your
                                      location.
                                    </p>
                                  </div>
                                }
                                value={this.state.processingFee}
                                onChange={(e) =>
                                  this.changeSelect(
                                    "processingFee",
                                    e.target.value
                                  )
                                }
                                placeholder="--Select--"
                                dataKey="key"
                                dataValue="value"
                                dataList={Object.keys(
                                  ProcessingFeeTypes
                                ).reduce((acc, val) => {
                                  let obj = {
                                    key: val,
                                    value: ProcessingFeeTypes[val],
                                  };
                                  acc.push(obj);
                                  return acc;
                                }, [])}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      className="btn btn-primary m-2 ml-auto default-text-color default-border"
                      type="button"
                      tabIndex="2"
                      style={{
                        backgroundColor: backgroundColor,
                        width: "5rem",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleNext("basicsStep", "formFieldStep");
                      }}
                    >
                      Next
                    </button>
                  </div>
                </>
              )}

              {this.state.formFieldStep === "process" && (
                <>
                  <p style={{ marginTop: 40 }}>
                    Select fields to include in the registration form including
                    any 'custom fields'
                  </p>
                  <div className="col-md-12 mt-2">
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div className="col-md-11">
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <div
                            className="col-md-4"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label
                              className="form-check-label ml-0"
                              for="firstName"
                            >
                              Full Name&nbsp;
                            </label>
                            <input
                              style={{ height: "20px", width: "20px" }}
                              type="checkbox"
                              className="form-control-input cursor-pointer"
                              id="fullName"
                              checked={this.state.formFields.fullName}
                              disabled={true}
                              onChange={this.handleCheckChange}
                            />
                          </div>

                          <div
                            className="hide-for-mobile"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: 20,
                            }}
                          >
                            Required
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div style={{display: "flex"}}>
                                            <div className="col-md-6">
                                                <div style={{display: "flex"}}>
                                                    <div className="col-md-6" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <label className="form-check-label ml-0" for="firstName">
                                                            Last Name&nbsp;
                                                        </label>
                                                        <input
                                                            style={{ height: "20px", width: "20px" }}
                                                            type="checkbox"
                                                            className="form-control-input cursor-pointer"
                                                            id="lastName"
                                                            checked={this.state.formFields.lastName}
                                                            disabled={true}
                                                            onChange={this.handleCheckChange}
                                                        />
                                                    </div>

                                                    <div className="hide-for-mobile" style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>Required</div>
                                                </div>
                                            </div>
                                        </div> */}
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div className="col-md-11">
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <div
                            className="col-md-4"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label
                              className="form-check-label ml-0"
                              for="firstName"
                            >
                              Email&nbsp;
                            </label>
                            <input
                              style={{ height: "20px", width: "20px" }}
                              type="checkbox"
                              className="form-control-input cursor-pointer"
                              id="email"
                              checked={this.state.formFields.email}
                              disabled={true}
                              onChange={this.handleCheckChange}
                            />
                          </div>

                          <div
                            className="hide-for-mobile"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: 20,
                            }}
                          >
                            Required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div className="col-md-11">
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <div
                            className="col-md-4"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label
                              className="form-check-label ml-0"
                              for="firstName"
                            >
                              Phone&nbsp;
                            </label>
                            <input
                              style={{ height: "20px", width: "20px" }}
                              type="checkbox"
                              className="form-control-input cursor-pointer"
                              id="phone"
                              checked={this.state.formFields.phone}
                              onChange={this.handleCheckChange}
                            />
                          </div>

                          <div
                            className="hide-for-mobile"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: 20,
                            }}
                          >
                            Required if selected
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div className="col-md-11">
                        <div style={{ display: "flex", flexWrap: "nowrap" }}>
                          <div
                            className="col-md-4"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label
                              className="form-check-label ml-0"
                              for="firstName"
                            >
                              Address&nbsp;
                            </label>
                            <input
                              style={{ height: "20px", width: "20px" }}
                              type="checkbox"
                              className="form-control-input cursor-pointer"
                              id="address"
                              checked={this.state.formFields.address}
                              onChange={this.handleCheckChange}
                            />
                          </div>

                          <div
                            className="hide-for-mobile"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: 20,
                            }}
                          >
                            <p className="m-0">
                              Required if selected. Must use field if shipping
                              is offered for registration or store.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-md-11">
                        <div style={{ display: "flex", flexWrap: "nowrap" }}>
                          <div
                            className="col-md-4"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <label
                              className="form-check-label ml-0"
                              for="firstName"
                            >
                              Birthday&nbsp;
                            </label>
                            <select
                              style={{
                                height: "30px",
                                width: "150px",
                                fontWeight: "normal",
                              }}
                              className="form-control-input cursor-pointer"
                              id="birthday"
                              value={this.state.formFields.birthday}
                              onChange={(e) => {
                                let formFields = this.state.formFields;
                                formFields.birthday = e.target.value;
                                this.setState({
                                  formFields,
                                });
                              }}
                            >
                              <option value={""} key="none">
                                --Select--
                              </option>
                              <option
                                value={"month and day"}
                                key="month and day"
                              >
                                Month and day
                              </option>
                              <option
                                value={"month, day and year"}
                                key="month, day and year"
                              >
                                Month, day and year
                              </option>
                            </select>
                          </div>

                          <div
                            className="hide-for-mobile"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: 20,
                            }}
                          >
                            <p className="m-0">Required if selected. </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div className="col-md-11">
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <div
                            className="col-md-4"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label
                              className="form-check-label ml-0"
                              for="firstName"
                            >
                              Join mailing list?&nbsp;
                            </label>
                            <input
                              style={{ height: "20px", width: "20px" }}
                              type="checkbox"
                              className="form-control-input cursor-pointer"
                              id="joinMailingList"
                              checked={this.state.formFields.joinMailingList}
                              onChange={this.handleCheckChange}
                            />
                          </div>

                          <div
                            className="hide-for-mobile"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: 20,
                            }}
                          >
                            Checkbox
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div className="col-md-11">
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <div
                            className="col-md-4"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label
                              className="form-check-label ml-0"
                              for="firstName"
                            >
                              Get notified of volunteer opportunities?&nbsp;
                            </label>
                            <input
                              style={{
                                height: "20px",
                                width: "20px",
                                minWidth: 20,
                              }}
                              type="checkbox"
                              className="form-control-input cursor-pointer"
                              id="shareVolunteerOpportunities"
                              checked={
                                this.state.formFields
                                  .shareVolunteerOpportunities
                              }
                              onChange={this.handleCheckChange}
                            />
                          </div>

                          <div
                            className="hide-for-mobile"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: 20,
                            }}
                          >
                            Checkbox
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div className="col-md-11">
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <div
                            className="col-md-4"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label
                              className="form-check-label ml-0"
                              for="firstName"
                            >
                              Official Club Member?&nbsp;
                            </label>
                            <input
                              style={{ height: "20px", width: "20px" }}
                              type="checkbox"
                              className="form-control-input cursor-pointer"
                              id="officialClubMember"
                              checked={this.state.formFields.officialClubMember}
                              onChange={this.handleCheckChange}
                            />
                          </div>
                          <div
                            className="hide-for-mobile"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: 20,
                            }}
                          >
                            Will ask for membership number
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div className="col-md-11">
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <div
                            className="col-md-4"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label
                              className="form-check-label ml-0"
                              for="firstName"
                            >
                              Season Ticket Holder?&nbsp;
                            </label>
                            <input
                              style={{ height: "20px", width: "20px" }}
                              type="checkbox"
                              className="form-control-input cursor-pointer"
                              id="seasonTicketHolder"
                              checked={this.state.formFields.seasonTicketHolder}
                              onChange={this.handleCheckChange}
                            />
                          </div>

                          <div
                            className="hide-for-mobile"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: 20,
                            }}
                          >
                            Will ask for section and # tickets
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div className="col-md-11">
                        <div style={{ display: "flex", flexWrap: "nowrap" }}>
                          <div
                            className="col-md-4"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label
                              className="form-check-label ml-0"
                              for="firstName"
                            >
                              Member Referral&nbsp;
                            </label>
                            <input
                              style={{ height: "20px", width: "20px" }}
                              type="checkbox"
                              className="form-control-input cursor-pointer"
                              id="memberReferral"
                              checked={this.state.formFields.memberReferral}
                              onChange={this.handleCheckChange}
                            />
                          </div>

                          <div
                            className="hide-for-mobile"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: 20,
                            }}
                          >
                            <p className="m-0">
                              {" "}
                              Track referrals by allowing person registering to
                              enter referrer’s email address.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div className="col-md-11">
                        <div style={{ display: "flex", flexWrap: "nowrap" }}>
                          <div
                            className="col-md-4"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <label
                              className="form-check-label ml-0"
                              for="firstName"
                            >
                              Chapter&nbsp;
                            </label>
                            <input
                              style={{ height: "20px", width: "20px" }}
                              type="checkbox"
                              className="form-control-input cursor-pointer"
                              id="chapters"
                              checked={this.state.formFields.chapters}
                              onChange={this.handleCheckChange}
                            />
                          </div>
                          <div className="col" style={{ marginLeft: 20 }}>
                            <div
                              style={{ display: "flex", flexWrap: "nowrap" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                                onClick={() => {
                                  this.setState({ showChapterModal: true });
                                }}
                              >
                                <label
                                  className="form-check-label ml-0 span-link"
                                  for="customField"
                                >
                                  Set Chapter&nbsp;
                                </label>
                              </div>
                              <div
                                className="hide-for-mobile ml-2"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <p className="m-0"> Required if selected.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <div
                            className="col-md-6"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            onClick={() => {
                              this.setState((prevState) => {
                                return {
                                  formFields: {
                                    ...prevState.formFields,
                                    customFieldCount:
                                      (prevState.formFields.customFieldCount ||
                                        0) + 1,
                                  },
                                };
                              });
                            }}
                          >
                            <h5
                              style={{
                                fontWeight: "bold",
                                color: "#ff4040",
                              }}
                            >
                              Custom Fields&nbsp;
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    {customFieldCount > 0 ? (
                      customFieldKeys.map((fieldKey, index) => {
                        let customFieldName = "";
                        let customFieldType = "";
                        let fieldDetails =
                          this.state.customFieldDetails[fieldKey];
                        if (fieldDetails && fieldDetails.fieldTitle) {
                          customFieldName = fieldDetails.fieldTitle;
                          customFieldType = fieldDetails.fieldType;
                        }
                        return (
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <div className="col-md-11">
                              <div style={{ display: "flex", gap: "8px" }}>
                                <div
                                  className="col-md-4"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <label
                                    className="form-check-label ml-0"
                                    for="firstName"
                                  >
                                    {customFieldName || `Custom Field `}&nbsp;
                                  </label>
                                  <input
                                    style={{ height: "20px", width: "20px" }}
                                    type="checkbox"
                                    className="form-control-input cursor-pointer"
                                    id={fieldKey}
                                    checked={this.state.formFields[fieldKey]}
                                    onChange={this.handleCheckChange}
                                  />
                                </div>
                                {customFieldType && (
                                  <div className="col-md-4 ml-2 mr-2">
                                    {customFieldType}
                                  </div>
                                )}

                                <div
                                  className="hide-for-mobile span-link"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: 20,
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      editableFieldName: fieldKey,
                                    });
                                  }}
                                >
                                  Edit
                                </div>
                                {!this.state.customFieldDetails[fieldKey]
                                  .isSaved && (
                                  <div
                                    className="hide-for-mobile span-link"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginLeft: 20,
                                    }}
                                    onClick={() => {
                                      this.setState((prevState) => {
                                        let updatedState = { ...prevState };
                                        delete updatedState.customFieldDetails[
                                          fieldKey
                                        ];
                                        delete updatedState.formFields[
                                          fieldKey
                                        ];
                                        updatedState = {
                                          ...updatedState,
                                          formFields: {
                                            ...updatedState.formFields,
                                            customFieldCount:
                                              updatedState.formFields
                                                .customFieldCount - 1,
                                          },
                                        };

                                        return updatedState;
                                      });
                                    }}
                                  >
                                    <i className="fa fa-close"></i>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div></div>
                    )}
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div className="col-md-11">
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <div
                            className="col-md-4"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            onClick={() => {
                              let fieldKey = `customField${this.getKey()}`;
                              this.setState((prevState) => {
                                return {
                                  formFields: {
                                    ...prevState.formFields,
                                    customFieldCount:
                                      (prevState.formFields.customFieldCount ||
                                        0) + 1,
                                  },
                                  customFieldDetails: {
                                    ...prevState.customFieldDetails,
                                    [fieldKey]: {},
                                  },
                                };
                              });
                            }}
                          >
                            <label
                              className="form-check-label ml-0 span-link"
                              for="customField"
                            >
                              + Add Field&nbsp;
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        className="btn btn-primary m-2 ml-auto"
                        type="button"
                        tabIndex="2"
                        style={{
                          backgroundColor: "#999999",
                          borderColor: "#999999",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleNext("formFieldStep", "basicsStep");
                        }}
                      >
                        Back
                      </button>
                      <button
                        className="btn btn-primary m-2 ml-2 default-text-color default-border"
                        type="button"
                        tabIndex="2"
                        style={{ backgroundColor: backgroundColor }}
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleNext("formFieldStep", "donationStep");
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </>
              )}

              {/* {this.state.membershipPackagesStep === 'process' && (
                                <>
                                    <div style={{ marginTop: 40 }}>
                                        <p>Active’ packages will appear on the web registration page. Membership packages should be created per season.</p>
                                        <p>If package includes merchandise (scarf, shirt, etc) first add product to Store then select item in the ‘add package’ form below. Orders will then appear in the ‘Store: Orders’ report to track fulfillment.</p>
                                        <p>Important: If ‘Require Membership Package’ is checked then accounts will be locked after the ‘Package Expiration Date’ for members without a current package. Provide a grace period to allow members time to renew.</p>

                                        <MembershipPackageTable onCloseModal={this.onPackageModalClose} />

                                    </div>
                                    <div style={{display: "flex"}}>
                                        <button className="btn btn-primary m-2 ml-auto" type="button" tabIndex='2' style={{ backgroundColor: "#999999", borderColor: "#999999" }} onClick={(e) => {
                                            e.preventDefault();
                                            this.handleNext('membershipPackagesStep', 'formFieldStep')
                                        }}>Back</button>
                                        <button className="btn btn-primary m-2 ml-2 default-text-color default-border" type="button" tabIndex='2' disabled={this.state.packages.length === 0} style={{ backgroundColor: backgroundColor }} onClick={(e) => {
                                            e.preventDefault();
                                            this.handleNext('membershipPackagesStep', 'donationStep')
                                        }}>Next</button>
                                    </div>
                                </>
                            )} */}

              {this.state.donationStep === "process" && (
                <>
                  <div style={{ display: "flex", margin: "3%", marginTop: 40 }}>
                    <div className="col">
                      <span style={{ color: "#000000" }}>
                        If you wish to request donations select one featured
                        partner to appear during the registration process. See
                        Partners section to set up partners.
                      </span>

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          paddingTop: "2rem",
                        }}
                      >
                        <div className="col-md-8">
                          <FSelect
                            label="Partner (Donations)"
                            name="partnerId"
                            display="row"
                            tabIndex="2"
                            value={
                              this.state.partnerId ? this.state.partnerId : null
                            }
                            onChange={(e) => {
                              if (e.target.value) {
                                let value = e.target.value;
                                let partner = this.state.allPartners.find(
                                  (e) => e.id === value
                                );
                                this.setState({
                                  hasDonations: true,
                                  donationTitle: partner.name,
                                  donationDesc:
                                    ContentState.createFromBlockArray(
                                      htmlToDraft(partner.details || "<p></p>")
                                    ).getPlainText(""),
                                  donationImageList: Array.from(
                                    {
                                      length: partner.galleryImageCount || 0,
                                    },
                                    (_, index) => {
                                      return `https://firebasestorage.googleapis.com/v0/b/chant2019.appspot.com/o/${this.props.currentGroup}%2Fpartner%2F${partner.id}%2Fgallery%2F${index}?alt=media`;
                                    }
                                  ),
                                  donationImage: partner.showLogo
                                    ? `https://firebasestorage.googleapis.com/v0/b/chant2019.appspot.com/o/${this.props.currentGroup}%2Fpartner%2F${partner.id}%2Flogo?alt=media`
                                    : "",
                                  amount1: partner.suggestedAmounts[0],
                                  amount2: partner.suggestedAmounts[1],
                                  amount3: partner.suggestedAmounts[2],
                                  partnerId: partner.id,
                                });
                              } else {
                                this.setState({
                                  hasDonations: false,
                                  donationTitle: "",
                                  donationDesc: "<p></p>",
                                  donationImageList: [],
                                  donationImage: "",
                                  amount1: "",
                                  amount2: "",
                                  amount3: "",
                                  partnerId: "",
                                });
                              }
                            }}
                            placeholder={"Select partner"}
                            dataList={this.state.allPartners}
                            listBuilder={(partner) => {
                              return (
                                <option
                                  key={partner.name}
                                  value={partner.id + ""}
                                >
                                  {partner.name}
                                </option>
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="col-md-8">
                          <div
                            className="mt-2"
                            style={{ display: "flex", flexFlow: "nowrap" }}
                          >
                            <div className="col-md-4">
                              <label>Offer auto-renewal?</label>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                flexFlow: "nowrap",
                              }}
                            >
                              <Checkbox
                                checked={this.state.donationAutorenewal}
                                onChange={(e) => {
                                  this.setState({
                                    donationAutorenewal: e.target.checked,
                                  });
                                }}
                                value="secondary"
                                color="primary"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                                tabIndex="2"
                              />
                              <div className="ml-2">
                                <span>
                                  If offering auto-renewal for member packages
                                  you can offer the same for donations. Consider
                                  setting the supporter group as the ‘partner’
                                  and not changing this from season to season –
                                  so that auto-renewals always go to the same
                                  cause.
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <button
                      className="btn btn-primary m-2 ml-auto"
                      type="button"
                      style={{
                        backgroundColor: "#999999",
                        borderColor: "#999999",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleNext("donationStep", "formFieldStep");
                      }}
                    >
                      Back
                    </button>
                    <button
                      className="btn btn-primary m-2 ml-2 default-text-color default-border"
                      type="button"
                      style={{ backgroundColor: backgroundColor }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleNext("donationStep", "previewStep");
                        // this.handleNext('donationStep', 'basicsStep');
                      }}
                    >
                      {/* Preview */}
                      Submit
                    </button>
                  </div>
                </>
              )}

              {this.state.previewStep === "process" && (
                <>
                  <div
                    className="row justify-content-center"
                    style={{ marginTop: 40 }}
                  >
                    <div
                      style={{
                        borderBottom: "1px solid #000000",
                        width: "80%",
                      }}
                    >
                      {/* <p>Preview</p>
                                            <hr />
                                            <MembershipForm isPreview={true} groupName={this.props.groupName} /> */}
                      <div className="row justify-content-center">
                        <div style={{ display: "grid", placeItems: "center" }}>
                          Your membership package is now live at{" "}
                          <a
                            href={`https://group.chant.fan/${this.props.groupName
                              .replace(/ /g, "")
                              .replace("#", "%23")
                              .toLowerCase()}/join`}
                          >
                            {" "}
                            https://group.chant.fan/
                            {this.props.groupName
                              .replace(/ /g, "")
                              .replace("#", "%23")
                              .toLowerCase()}
                            /join
                          </a>
                          .
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div style={{ display: "grid", placeItems: "center" }}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: `${this.state.joinSVG}`,
                            }}
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div style={{ display: "flex", padding: "30px 0px" }}>
                    <button
                      className="btn btn-primary m-2 mx-auto default-text-color default-border"
                      onClick={() =>
                        this.handleNext("previewStep", "basicsStep")
                      }
                      style={{ backgroundColor: backgroundColor }}
                    >
                      Back to Setup
                    </button>
                    {/* <button className="btn btn-primary m-2 mx-auto" disabled={this.state.isPublishing} onClick={this.publishOrUnpublishForm}><Spin size='small' spinning={this.state.isPublishing} >{this.state.isPublished ? 'Unpublish' : 'Publish'}</Spin> </button> */}
                  </div>
                  {/* <Modal
                                        open={this.state.previewVisible}
                                        title="Preview"
                                        onOk={() => { }}
                                        onCancel={() => { }}
                                        footer={[
                                            <button className="btn btn-primary m-2" onClick={() => { this.handleNext('previewStep', 'basicsStep'); this.setState({ previewVisible: false }) }}>Edit</button>,
                                            <button className="btn btn-primary m-2" onClick={() => { this.publishOrUnpublishForm(); this.setState({ previewVisible: false }) }}>{this.state.isPublished ? 'Unpublish' : 'Publish'}</button>
                                        ]}
                                    >
                                        <div style={{ borderBottom: '1px solid #000000', paddingBottom: 20 }}>
                                            <MembershipForm isPreview={true} groupName={this.props.groupName} />
                                        </div>
                                    </Modal> */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  getKey = () => {
    let length = 6;
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  componentDidMount() {
    let registrationLink = `https://group.chant.fan/${this.props.groupName
      .replace(/ /g, "")
      .replace("#", "%23")
      .toLowerCase()}/join`;
    generateQR(registrationLink, null, 75).then((svg) => {
      this.setState({
        joinSVG: svg,
      });
    });
    fbGetFormLayout(this.props.currentGroup).then((res) => {
      if (res) {
        let data = new RegFormDetails();
        data.fromDataSet(res);
        let benefits = htmlToDraft(data.benefits);
        if (benefits) {
          const contentState = ContentState.createFromBlockArray(
            benefits.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          data.benefits = editorState;
        }
        this.setState({ ...data, loading: false });
      } else {
        this.setState({ loading: false });
      }
    });

    fbGetPartners(this.props.currentGroup).then((partners) => {
      let allPartners = partners.filter(
        (partner) => partner.status === "Active" && partner.collectDonations
      );
      this.setState({
        allPartners: allPartners,
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentGroup !== prevProps.currentGroup) {
      this.setState({
        loading: true,
        basicsStep: "process",
        // membershipPackagesStep: 'wait',
        formFieldStep: "wait",
        previewStep: "wait",
        donationStep: "wait",
      });
    }
    if (this.state.loading && !this.state.isTransitioning) {
      fbGetFormLayout(this.props.currentGroup).then((res) => {
        if (res) {
          let data = new RegFormDetails();
          data.fromDataSet(res);
          let benefits = htmlToDraft(data.benefits);
          if (benefits) {
            const contentState = ContentState.createFromBlockArray(
              benefits.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            data.benefits = editorState;
          }
          this.setState({ ...data, loading: false });
        } else {
          this.setState({ loading: false });
        }
      });
      fbGetPartners(this.props.currentGroup).then((partners) => {
        let allPartners = partners.filter(
          (partner) => partner.status === "Active" && partner.collectDonations
        );
        this.setState({
          allPartners: allPartners,
        });
      });
    }
  }
}

class AddSizes extends React.Component {
  handleInputChange = (value, index) => {
    let { sizes, onAddSize } = this.props;
    sizes.splice(index, 1, value);
    onAddSize(sizes);
  };

  handleAddRow = () => {
    this.props.onAddRow();
  };

  handleDeleteSize = (index) => {
    let { sizes, onAddSize } = this.props;
    sizes.splice(index, 1);
    onAddSize(sizes);
  };

  render() {
    let { sizes } = this.props;

    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div className="col-md-4" style={{ display: "flex", flexWrap: "wrap" }}>
          <span
            style={{
              color: "#000000",
              fontWeight: 600,
              textAlign: "center",
              marginTop: 20,
            }}
          >
            Set size values:
          </span>
        </div>
        <div
          className="col-md-8"
          style={{ padding: "0px 30px", marginTop: 20 }}
        >
          {sizes &&
            sizes.map((size, index) => (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <Input
                  value={size}
                  onChange={(e) =>
                    this.handleInputChange(e.target.value, index)
                  }
                  tabIndex="2"
                  placeholder="Enter value"
                  addonAfter={
                    <div
                      style={{
                        display: "flex",
                        height: "100%",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => this.handleDeleteSize(index)}
                    >
                      &times;
                    </div>
                  }
                  style={{ marginTop: 5 }}
                />
                {/* <div style={{ display: 'flex', height: '100%', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.handleDeleteSize(index)}>
                                &times;
                            </div> */}
              </div>
            ))}
          <button
            style={{
              border: "0px none transparent",
              textDecoration: "underline",
              background: "transparent",
              marginTop: 5,
            }}
            onClick={this.handleAddRow}
          >
            Add row
          </button>
        </div>
      </div>
    );
  }
}

class EditCustomFieldModal extends React.Component {
  state = {
    fieldTitle: "",
    fieldType: "",
    required: false,
    loading: true,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.loading) {
      return { ...props.data, loading: false };
    }
    return null;
  }

  render() {
    return (
      this.props.showModal && (
        <Modal
          title={<h5>Custom Field</h5>}
          width={700}
          centered
          open={this.props.showModal}
          onOk={() => {
            this.props.onUpdateField(this.state);
          }}
          onCancel={() => {
            this.props.onClose();
          }}
          footer={[]}
        >
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="col-md-12">
                <span>Create a custom registration form field.</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div
                    className="col-md-3 d-flex "
                    style={{ alignItems: "center" }}
                  >
                    <label>Field Type</label>
                  </div>
                  <div className="col-md-9 d-flex ">
                    <div className="col">
                      <select
                        style={{ width: "100%" }}
                        value={this.state.fieldType}
                        onChange={(e) => {
                          this.setState({
                            fieldType: e.target.value,
                          });
                        }}
                      >
                        <option key={"select"}>---Select---</option>
                        {Object.keys(FIELD_TYPES).map((label) => {
                          return (
                            <option key={label} value={FIELD_TYPES[label]}>
                              {label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="col">
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div
                    className="col-md-3 d-flex "
                    style={{ alignItems: "center" }}
                  >
                    <label>Field Name</label>
                  </div>
                  <div className="col-md-9 d-flex ">
                    <FInput
                      value={this.state.fieldTitle}
                      onChange={(e) => {
                        this.setState({
                          fieldTitle: e.target.value,
                        });
                      }}
                      name="fieldTitle"
                      display="col"
                      tabIndex="2"
                      maxLenght={50}
                    />
                  </div>
                </div>
              </div>
            </div>

            {this.getOptionalField()}
            <div className="row mt-1">
              <div className="col-md-8">
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div
                    className="col-md-6 d-flex "
                    style={{ alignItems: "center" }}
                  >
                    <label>Required?</label>
                  </div>
                  <div className="col-md-6 d-flex ">
                    <div className="col">
                      <input
                        style={{ height: "20px", width: "20px" }}
                        type="checkbox"
                        className="form-control-input cursor-pointer"
                        id="required"
                        checked={this.state.required}
                        onChange={(e) => {
                          this.setState({
                            required: e.target.checked,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4" style={{ justifyContent: "center" }}>
              <div className="col-md-2">
                <div
                  className="row px-4 py-2"
                  style={{
                    border: "1px solid #000000",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let updateObj = {
                      ...this.state,
                    };

                    delete updateObj.loading;
                    this.props.onUpdateField(updateObj);
                  }}
                >
                  <span>Save</span>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )
    );
  }

  getOptionalField = () => {
    switch (this.state.fieldType) {
      case FIELD_TYPES.Dropdown:
        return (
          <div className="row mt-1">
            <div className="col">
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div className="col-md-3 d-flex ">
                  <label>Dropdown value</label>
                </div>
                <div className="col-md-9 d-flex ">
                  <div className="col">
                    {this.state.dropdownValues &&
                      this.state.dropdownValues.map((value, index) => {
                        return (
                          <div
                            className="row mt-1"
                            style={{
                              alignItems: "center",
                              flexWrap: "nowrap",
                              paddingLeft: 15,
                            }}
                          >
                            <input
                              value={value}
                              onChange={(e) => {
                                let values = this.state.dropdownValues;
                                values[index] = e.target.value;
                                this.setState({
                                  dropdownValues: values,
                                });
                              }}
                            />
                            <div
                              className="ml-2"
                              onClick={() => {
                                let values = this.state.dropdownValues;
                                values.splice(index, 1);
                                this.setState({
                                  dropdownValues: values,
                                });
                              }}
                            >
                              x
                            </div>
                          </div>
                        );
                      })}
                    <div
                      className="span-link"
                      onClick={() => {
                        let values = this.state.dropdownValues || [];
                        values.push("");
                        this.setState({
                          dropdownValues: values,
                        });
                      }}
                    >
                      +Add value
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case FIELD_TYPES.Number:
      case FIELD_TYPES.Text:
        return [
          <div className="row mt-3">
            <div className="col">
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div
                  className="col-md-3 d-flex "
                  style={{ alignItems: "center" }}
                >
                  <label>Help/Instructions</label>
                </div>
                <div className="col-md-9 d-flex ">
                  <FInput
                    value={this.state.fieldHelp}
                    onChange={(e) => {
                      this.setState({
                        fieldHelp: e.target.value,
                      });
                    }}
                    name="fieldHelp"
                    display="col"
                    tabIndex="2"
                  />
                </div>
              </div>
            </div>
          </div>,
          <div className="row mt-3">
            <div className="col">
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div
                  className="col-md-3 d-flex "
                  style={{ alignItems: "center" }}
                >
                  <span>Max Characters</span>
                  <Tooltip
                    placement="topLeft"
                    title={() => <div>50 character limit.</div>}
                  >
                    <span className="border-0">
                      &nbsp;<i className="fa fa-question-circle-o"></i>
                    </span>
                  </Tooltip>
                </div>
                <div className="col-md-9 d-flex ">
                  <div className="col">
                    <input
                      value={this.state.maxChars}
                      onChange={(e) => {
                        let value = e.target.value;
                        let valueAsNumber = parseInt(value.toString());
                        if (valueAsNumber !== NaN && value <= 50) {
                          this.setState({
                            maxChars: e.target.value,
                          });
                        }
                      }}
                      name="fieldTitle"
                      type="number"
                      display="col"
                      max={50}
                      min={0}
                      step={1}
                      tabIndex="2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>,
        ];
      default:
        return <div style={{ display: "flex", flexWrap: "wrap" }}></div>;
    }
  };
}

const FIELD_TYPES = {
  Dropdown: "dropdown",
  Text: "text",
  Number: "number",
  Checkbox: "checkbox",
};

class EditChapterModal extends React.Component {
  state = {
    chapters: [],
    includeNone: false,
    loading: true,
  };
  static getDerivedStateFromProps(props, state) {
    if (state.loading) {
      return { ...props.data, loading: false };
    }
    return null;
  }

  render() {
    return (
      this.props.showModal && (
        <Modal
          title={<h5>Set Chapters</h5>}
          width={700}
          centered
          open={this.props.showModal}
          onOk={() => {
            this.props.onUpdateField(this.state);
          }}
          onCancel={() => {
            this.props.onClose();
          }}
          footer={[]}
        >
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="col-md-12">
                <span>
                  Chapters are currently used only as a way for members to
                  identify themselves. Chapter names will appear in member
                  profiles and in the admin dashboard.
                </span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <span>
                  Reach out to support@chant.fan to discuss options for
                  supporting chapters.
                </span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <span>If selected, this field will be required.</span>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12">
                <div style={{ display: "flex", flexWrap: "nowrap" }}>
                  <div className="d-flex ">
                    <div className="col">
                      <input
                        style={{ height: "20px", width: "20px" }}
                        type="checkbox"
                        className="form-control-input cursor-pointer"
                        id="includeNone"
                        checked={this.state.includeNone}
                        onChange={(e) => {
                          this.setState({
                            includeNone: e.target.checked,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="d-flex " style={{ alignItems: "center" }}>
                    <label>
                      Include ‘None’ as option in form dropdown? Provides option
                      for members who are not part of a chapter.
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-1">
              <div className="col-md-12">
                <div className="col-md-6 d-flex ">
                  <h5>Chapters</h5>
                </div>
                <div className="col-md-6 d-flex ">
                  <div className="col">
                    {this.state.chapters &&
                      this.state.chapters.map((value, index) => {
                        return (
                          <div
                            className="row mt-1"
                            style={{
                              alignItems: "center",
                              flexWrap: "nowrap",
                              paddingLeft: 15,
                            }}
                          >
                            <input
                              value={value}
                              onChange={(e) => {
                                let values = this.state.chapters;
                                values[index] = e.target.value;
                                this.setState({
                                  chapters: values,
                                });
                              }}
                              style={{
                                border: "1px solid #c4c4c4",
                                borderRadius: 0,
                                padding: "5px 8px",
                              }}
                            />
                            <div
                              className="ml-2"
                              onClick={() => {
                                let values = this.state.chapters;
                                values.splice(index, 1);
                                this.setState({
                                  dropdownValues: values,
                                });
                              }}
                            >
                              x
                            </div>
                          </div>
                        );
                      })}
                    <div
                      className="span-link"
                      onClick={() => {
                        let values = this.state.chapters || [];
                        values.push("");
                        this.setState({
                          chapters: values,
                        });
                      }}
                    >
                      +Add Chapter
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4" style={{ justifyContent: "center" }}>
              <div className="col-md-2">
                <div
                  className="row px-4 py-2"
                  style={{
                    border: "1px solid #000000",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let updateObj = {
                      ...this.state,
                    };

                    delete updateObj.loading;
                    this.props.onUpdateField(updateObj);
                  }}
                >
                  <span>Save</span>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )
    );
  }
}

const mapStateToProps = (state) => {
  let currentGroup = state.adminData.currentGroup;
  let { groupName } = state.adminData.allGroups[currentGroup].data;
  return {
    user: state.user,
    currentGroup,
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    groupName,
  };
};

export default connect(mapStateToProps)(GroupRegFormConfiguration);
