import { Button, Dropdown, Menu, Select } from "antd";
import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { adminGroupChange } from "../../../redux/actions/adminData";
import { fbLogoutUser } from "../../../services/firebaseService/endPoints/user";
import { withRouter } from "../../../utils/helper";
import {
  ChantBrandmark,
  ChantBrandmarkTaglineIcon,
} from "../../../utils/icons";

const ImageColor = { black: 1, white: 2 };

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clubGroup: undefined,
      dashboard: "admin",
    };
  }

  handleAdminGroupChange = (value) => {
    const [groupName, dashboard] = value.split("|");

    if (dashboard === "admin") {
      if (window.location.pathname.includes("/admin/content/locations/edit")) {
        window.alert("You cannot change group at this page");
      } else {
        window.localStorage.setItem("currentGroup", groupName);
        let group = Object.keys(this.props.adminData.allGroups).find(
          (group) => {
            return group === groupName;
          }
        );
        this.props.dispatch(adminGroupChange({ group, dashboard: "admin" }));
      }
    } else {
      let group = value;
      this.props.dispatch(adminGroupChange(group));
    }

    if (this.state.dashboard !== dashboard) {
      let history;
      if (dashboard === "clubadmin") {
        history = "/clubadmin/groups";
        let root = document.querySelector(":root");
        root.style.setProperty("--main-color", "#fafafa");
      } else {
        history = "/admin/content";
      }
      this.props.router.navigate(history);
    }

    this.setState({
      dashboard: dashboard,
    });
  };
  handleLogout = () => {
    // window.location.href = "/"

    fbLogoutUser().then(() => {
      this.props.router.navigate("/login");
    });
    // this.props.router.navigate("/")
  };

  getClubAdminSettings = (clubAdminMenu) => {
    try {
      return (
        <Dropdown overlay={clubAdminMenu}>
          <NavLink className="nav-link" to="">
            Settings
          </NavLink>
        </Dropdown>
      );
    } catch (e) {
      return <></>;
    }
  };

  render() {
    const { clubGroup } = this.state;

    const groupNameKeyMap = {};
    const groupIdLogoMap = {};
    this.props.adminData &&
      Object.entries(this.props.adminData.allGroups).forEach(([key, group]) => {
        groupNameKeyMap[group.data.groupName] = key;
        groupIdLogoMap[key] = (group.data.configuration || {}).logo;
      });

    let headerColor = "#000000";
    let color = "#ffffff";
    let prefferedImageColor = ImageColor.white;
    let style = {};

    if (this.props.adminData) {
      let group =
        this.props.adminData.allGroups[this.props.adminData.currentGroup];
      let groupData = group ? group.data : null;

      if (groupData) {
        if (groupData.configuration && groupData.configuration.primaryColor) {
          let { r, g, b } = groupData.configuration.primaryColor;
          headerColor = `rgb(${r}, ${g}, ${b})`;
          if ([r, g, b].filter((e) => `${e}` === "255").length === 3) {
            // let { r, g, b } = groupData.configuration.secondaryColor || {r:0, b:0, g:0};
            color = `rgb(0, 0, 0)`;
            prefferedImageColor = ImageColor.black;
            style = {
              boxShadow: "0px 0px 3px 0px",
            };
          }
        }
      }
    }

    const rootEl = document.querySelector(":root");
    rootEl.style.setProperty("--text-color", color);

    const adminLinks = [
      // <NavLink to="/admin/members/registrationCode" className="nav-link">
      //   Registration Code
      // </NavLink>,
      <NavLink to="/admin/groupSettings" className="nav-link">
        Design Settings
      </NavLink>,
      <NavLink to="/admin/paymentDetails" className="nav-link">
        Payment Details
      </NavLink>,
      <NavLink to="/admin/membershipcard" className="nav-link">
        Membership Card
      </NavLink>,
      // <NavLink to="/admin/faq" className="nav-link">
      //   FAQ
      // </NavLink>,
      <NavLink to="/admin/fanPermission" className="nav-link">
        Fan Permission
      </NavLink>,
      <NavLink to="/admin/appSettings" className="nav-link">
        App Settings
      </NavLink>,
      <Button
        className="btn-info"
        type="dashed"
        block
        onClick={this.handleLogout}
      >
        Logout
      </Button>,
    ];

    const menu = (
      <Menu>
        {adminLinks.map((link) => (
          <Menu.Item>{link}</Menu.Item>
        ))}
      </Menu>
    );

    const clubAdminMenu = (
      <Menu>
        <Menu.Item>
          <NavLink to="/clubadmin/help" className="nav-link">
            Help
          </NavLink>
        </Menu.Item>
        <Menu.Item>
          <NavLink to="/clubadmin/membership" className="nav-link">
            Membership Card
          </NavLink>
        </Menu.Item>
      </Menu>
    );

    let dashboard = this.state.dashboard;
    let path = window.location.pathname.split("/")[1];
    if (path === "superadmin") {
      dashboard = "superadmin";
    }

    style = {
      ...style,
      backgroundColor: headerColor,
    };
    if (!this.props.user.isAuthenticated || dashboard === "superadmin") {
      style.height = 80;
    }

    return (
      // window.location.pathname === "/admin/register" ? <></> :
      <>
        <nav
          className="navbar navbar-expand-xl navbar-dark bg-custom-header navbar-fixed-top"
          style={style}
        >
          <div
            to="/"
            className="logo mr-3 cursor-pointer"
            style={{ maxWidth: 300 }}
          >
            <img
              style={{
                padding: "0",
                margin: "0",
                width: "12%",
                minWidth: "120px",
              }}
              src={
                prefferedImageColor === ImageColor.white
                  ? ChantBrandmark
                  : ChantBrandmarkTaglineIcon
              }
            />
          </div>

          {this.props.user.isAuthenticated && (
            <button
              className="navbar-toggler ml-auto"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                this.props.toggleSideNav();
              }}
            >
              <span className="navbar-toggler-icon" />
            </button>
          )}

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul
              className="navbar-nav w-100"
              style={{ justifyContent: "flex-end" }}
            >
              {/* dashboard links*/}
              {!this.props.user.isAuthenticated && (
                <>
                  <li
                    className="nav-item unauth-header-link"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <a
                      style={{
                        color: "#ffffff",
                        fontWeight: 800,
                        fontSize: 21,
                      }}
                      href="https://chant.fan/#theplatform"
                    >
                      THE PLATFORM
                    </a>
                  </li>
                  <li
                    className="nav-item unauth-header-link"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <a
                      style={{
                        color: "#ffffff",
                        fontWeight: 800,
                        fontSize: 21,
                      }}
                      href="https://chant.fan/#features"
                    >
                      FEATURES
                    </a>
                  </li>
                  <li
                    className="nav-item unauth-header-link"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <a
                      style={{
                        color: "#ffffff",
                        fontWeight: 800,
                        fontSize: 21,
                      }}
                      href="https://chant.fan/#groups"
                    >
                      GROUPS
                    </a>
                  </li>
                  <li
                    className="nav-item unauth-header-link"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <a
                      style={{
                        color: "#ffffff",
                        fontWeight: 800,
                        fontSize: 21,
                      }}
                      href="https://chant.fan/#getstarted"
                    >
                      GET STARTED
                    </a>
                  </li>
                  <li
                    className="nav-item unauth-header-link"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                  >
                    <a
                      style={{
                        color: "#ffffff",
                        fontWeight: 800,
                        fontSize: 21,
                        marginRight: 35,
                      }}
                      href="#"
                    >
                      ADMIN
                    </a>
                  </li>
                </>
              )}
              {/* Members links */}
              {this.props.user.isAuthenticated &&
                this.props.user.role === "member" && (
                  <>
                    <li
                      className="nav-item"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <NavLink to="/membership" className="nav-link">
                        Membership
                      </NavLink>
                    </li>
                  </>
                )}

              {/* Admin links */}
              {this.props.user.isAuthenticated &&
                this.props.user.role === "admin" &&
                this.state.dashboard === "admin" &&
                ["admin", "parentAdmin"].includes(
                  this.props.user.userRoles[this.props.adminData.currentGroup]
                ) && (
                  <>
                    <li className="nav-item mt-2 d-flex">
                      {this.state.dashboard !== "clubadmin" &&
                      groupIdLogoMap[this.props.adminData.currentGroup] ? (
                        <img
                          src={
                            groupIdLogoMap[this.props.adminData.currentGroup]
                          }
                          style={{
                            width: 50,
                            height: 50,
                            borderRadius: 25,
                            marginRight: 10,
                          }}
                        />
                      ) : (
                        <></>
                      )}
                      <div className="flex-center">
                        <Select
                          onChange={this.handleAdminGroupChange}
                          value={
                            this.state.dashboard === "clubadmin"
                              ? this.state.clubGroup
                                ? "".concat(
                                    this.state.clubGroup.data.groupName,
                                    " Parent"
                                  )
                                : ""
                              : this.props.adminData.allGroups[
                                  this.props.adminData.currentGroup
                                ].data.groupName
                          }
                          className="header-group-select"
                          style={{
                            minWidth: 250,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            color: color,
                          }}
                        >
                          {clubGroup !== undefined && (
                            <Select.Option
                              key={`clubGroup-${clubGroup.data.id}`}
                              value={`${clubGroup.data.id}|clubadmin`}
                            >
                              {`${clubGroup.data.groupName} Parent`}
                            </Select.Option>
                          )}
                          {Object.keys(groupNameKeyMap)
                            .sort((name1, name2) => name1.localeCompare(name2))
                            .map((groupName) => {
                              return (
                                <Select.Option
                                  key={groupNameKeyMap[groupName]}
                                  value={`${groupNameKeyMap[groupName]}|admin`}
                                >
                                  {groupName}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </div>
                    </li>
                  </>
                )}

              {/* Super-Admin links */}
              {this.props.user.isAuthenticated &&
                this.props.user.role === "super-admin" && (
                  <>
                    <li
                      className="nav-item"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <NavLink
                        to="/superadmin/groups"
                        exact
                        className="nav-link"
                      >
                        Groups
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <NavLink
                        to="/superadmin/adoption"
                        exact
                        className="nav-link"
                      >
                        Adoption
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <NavLink
                        to="/superadmin/missing-transactions"
                        exact
                        className="nav-link"
                      >
                        Missing Transactions
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <NavLink
                        to="/superadmin/directory"
                        exact
                        className="nav-link"
                      >
                        Directory
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <NavLink
                        to="/superadmin/polls"
                        exact
                        className="nav-link"
                      >
                        Polls
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <NavLink
                        to="/superadmin/report/transactions"
                        className="nav-link"
                      >
                        Transactions
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <NavLink
                        to="/superadmin/status/report"
                        className="nav-link"
                      >
                        Match Day
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <NavLink
                        to="/superadmin/deactivated"
                        exact
                        className="nav-link"
                      >
                        Deactivated
                      </NavLink>
                    </li>
                    <li
                      className="nav-item"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <NavLink to="/superadmin/data" exact className="nav-link">
                        Data
                      </NavLink>
                    </li>

                    <li
                      className="nav-item"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                    >
                      <NavLink
                        to="/superadmin/logos"
                        exact
                        className="nav-link"
                      >
                        Logos
                      </NavLink>
                    </li>
                    <div className="ml-auto">
                      <button
                        className="btn btn-sm btn-outline-info my-2 my-sm-0"
                        data-toggle="collapse"
                        data-target=".navbar-collapse.show"
                        type="submit"
                        onClick={() => {
                          this.props.router.navigate(
                            "/superadmin/groups/create"
                          );
                        }}
                      >
                        Create Group
                      </button>

                      <button
                        className="btn btn-sm btn-outline-info my-2 my-sm-0 ml-2"
                        data-toggle="collapse"
                        data-target=".navbar-collapse.show"
                        type="submit"
                        onClick={this.handleLogout}
                      >
                        Logout
                      </button>
                    </div>
                  </>
                )}

              {/* Club-Admin Links*/}
              {this.props.user.isAuthenticated &&
                this.props.user.role === "admin" &&
                this.state.dashboard === "clubadmin" &&
                ["admin", "parentAdmin"].includes(
                  this.props.user.userRoles[this.props.adminData.currentGroup]
                ) && (
                  <>
                    <li className="nav-item mt-2 d-flex">
                      {groupIdLogoMap[this.props.adminData.currentGroup] ? (
                        <img
                          src={
                            groupIdLogoMap[this.props.adminData.currentGroup]
                          }
                          style={{
                            width: 50,
                            height: 50,
                            borderRadius: 25,
                            marginRight: 10,
                          }}
                        />
                      ) : (
                        <></>
                      )}
                      {Object.keys(this.props.adminData.allGroups).length >
                        0 && (
                        <Select
                          onChange={this.handleAdminGroupChange}
                          value={
                            this.state.dashboard === "clubadmin"
                              ? this.state.clubGroup
                                ? "".concat(
                                    this.state.clubGroup.data.groupName,
                                    " Parent"
                                  )
                                : ""
                              : this.props.adminData.allGroups[
                                  this.props.adminData.currentGroup
                                ].data.groupName
                          }
                          className="header-group-select"
                          style={{
                            minWidth: 250,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            color: "#ffffff",
                          }}
                        >
                          {clubGroup !== undefined && (
                            <Select.Option
                              // key={`clubGroup`}
                              // value={clubGroup.data.id}
                              // value={{
                              //   group: clubGroup.data.id,
                              //   dashboard: "clubadmin",
                              // }}
                              key={`clubGroup`}
                              value={`${clubGroup.data.id}|clubadmin`}
                            >
                              {"".concat(clubGroup.data.groupName, " Parent")}
                            </Select.Option>
                          )}
                          {Object.keys(groupNameKeyMap)
                            .sort((name1, name2) => name1.localeCompare(name2))
                            .map((groupName) => {
                              return (
                                <Select.Option
                                  // key={groupNameKeyMap[groupName]}
                                  // value={{
                                  //   group: groupNameKeyMap[groupName],
                                  //   dashboard: "admin",
                                  // }}
                                  // value={groupNameKeyMap[groupName]}
                                  key={groupNameKeyMap[groupName]}
                                  value={`${groupNameKeyMap[groupName]}|admin`}
                                >
                                  {groupName}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      )}
                    </li>
                  </>
                )}
            </ul>
          </div>
        </nav>
      </>
    );
  }

  componentDidMount() {
    if (this.props.user.isAuthenticated && this.props.user.role === "admin") {
      const currentGroup = window.localStorage.getItem("currentGroup");
      const dashboard = this.props.router.location.pathname
        ? this.props.router.location.pathname.split("/")[1] || "admin"
        : "admin";

      let found;
      if (currentGroup) {
        if (currentGroup !== this.props.adminData.currentGroup) {
          found = Object.keys(this.props.adminData.allGroups).find((group) => {
            return group === currentGroup;
          });
          if (found) {
            this.props.dispatch(
              adminGroupChange({ group: found, dashboardType: dashboard })
            );
          } else {
            if (this.props.adminData.currentGroup === "") {
              let groupNameKeyMap = {};
              this.props.adminData &&
                Object.entries(this.props.adminData.allGroups).forEach(
                  ([key, group]) => {
                    groupNameKeyMap[group.data.groupName] = key;
                  }
                );

              let groupName = Object.keys(groupNameKeyMap).sort(
                (name1, name2) => name1.localeCompare(name2)
              )[0];
              this.props.dispatch(
                adminGroupChange({
                  group: groupNameKeyMap[groupName],
                  dashboardType: dashboard,
                })
              );
            }
            // window.localStorage.setItem('currentGroup', "")
          }
        }
      }
    }

    if (this.props.user.userRoles) {
      let ids = Object.keys(this.props.user.userRoles).filter(
        (id) => this.props.user.userRoles[id] === "parentAdmin"
      );
      if (ids.length > 0) {
        let id = ids[0];
        this.setState({
          clubGroup: this.props.adminData.allGroups[id],
          dashboard: this.props.router.location.pathname
            ? this.props.router.location.pathname.split("/")[1] || "admin"
            : "admin",
        });
      } else {
        this.setState({
          dashboard: "admin",
        });
      }
    }
  }
}

const mapStateToProps = (state) => {
  let result = {};
  if (state.user.role === "admin") {
    result.adminData = state.adminData;
  }
  result.user = state.user;
  return result;
};

export default connect(mapStateToProps)(withRouter(Header));
