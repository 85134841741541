import { NavLink } from "react-router-dom";
import {
  ContentIcon,
  DirectoryIcon,
  GalleryIcon,
  GiveawayIcon,
  HelpIcon,
  MembersIcon,
  PollsIcon,
  SettingsIcon,
  StadiumIcon,
  StoreIcon,
  TicketsIcon,
  WebsiteIcon,
  getIcon,
} from "../../../utils/icons";
import FeatureBadge from "../../commons/FeatureBadge";

const useSideNavLinks = () => {
  const getItem = (label, key, icon, children) => {
    return {
      key,
      icon,
      children,
      label,
    };
  };

  const memberSideNavLinks = getItem(
    "Members",
    "sub1",
    getIcon(22, MembersIcon),
    [
      getItem(
        <NavLink
          to="/admin/members/all"
          className="nav-link default-text-color"
        >
          Members
        </NavLink>,
        "/admin/members/all"
      ),
      getItem(
        <NavLink
          to="/admin/members/purchaseReport"
          className="nav-link default-text-color"
        >
          Purchases
        </NavLink>,
        "/admin/members/purchaseReport"
      ),
      getItem(
        <NavLink
          to="/admin/members/packages"
          className="nav-link default-text-color"
        >
          Packages
        </NavLink>,
        "/admin/members/packages"
      ),
      getItem(
        <NavLink
          to="/admin/members/onboard"
          className="nav-link default-text-color"
        >
          Onboard
        </NavLink>,
        "/admin/members/onboard"
      ),
      getItem(
        <NavLink to="/admin/members/promote" className="nav-link">
          <div className="d-flex" style={{ gap: 5, alignItems: "center" }}>
            Promote
            {/* <FeatureBadge type="new" /> */}
          </div>
        </NavLink>,
        "/admin/members/promote"
      ),
      getItem(
        <NavLink
          to="/admin/membershipcard"
          className="nav-link default-text-color"
        >
          Card
        </NavLink>,
        "/admin/membershipcard"
      ),
    ]
  );

  const contentSideNavLinks = getItem(
    "Content",
    "sub2",
    getIcon(22, ContentIcon),
    [
      getItem(
        <NavLink to="/admin/content" className="nav-link default-text-color">
          News/Events
        </NavLink>,
        "/admin/content"
      ),
      getItem(
        <NavLink to="/admin/welcome" className="nav-link default-text-color">
          Welcome
        </NavLink>,
        "/admin/welcome"
      ),
      getItem(
        <NavLink to="/admin/gallery" className="nav-link default-text-color">
          Gallery
        </NavLink>,
        "/admin/gallery"
      ),
      getItem(
        <NavLink
          to="/admin/partners/list"
          className="nav-link default-text-color"
        >
          Partners
        </NavLink>,
        "/admin/partners/list"
      ),
      getItem(
        <NavLink to="/admin/chants" className="nav-link">
          <div className="d-flex" style={{ gap: 5, alignItems: "center" }}>
            Our Chants
            {/* <FeatureBadge type="new" /> */}
          </div>
        </NavLink>,
        "/admin/chants"
      ),
      getItem(
        <NavLink
          to="/admin/content/leaderboard"
          className="nav-link default-text-color"
        >
          Leaderboard
        </NavLink>,
        "/admin/content/leaderboard"
      ),
      getItem(
        <NavLink
          to="/admin/content/locations"
          className="nav-link default-text-color"
        >
          Locations
        </NavLink>,
        "/admin/content/locations"
      ),
    ]
  );

  const storeSideNavLinks = getItem("Store", "sub3", getIcon(22, StoreIcon), [
    getItem(
      <NavLink to="/admin/store/orders" className="nav-link default-text-color">
        Orders
      </NavLink>,
      "/admin/store/orders"
    ),
    getItem(
      <NavLink to="/admin/store/merch" className="nav-link default-text-color">
        Merchandise
      </NavLink>,
      "/admin/store/merch"
    ),
    getItem(
      <NavLink
        to="/admin/store/settings"
        className="nav-link default-text-color"
      >
        Settings
      </NavLink>,
      "/admin/store/settings"
    ),
  ]);

  const ticketsSideNavLinks = getItem(
    "Tickets",
    "sub4",
    getIcon(22, TicketsIcon),
    [
      getItem(
        <NavLink
          to="/admin/tickets/all"
          className="nav-link default-text-color"
        >
          Tickets
        </NavLink>,
        "/admin/tickets/all"
      ),
      getItem(
        <NavLink
          to="/admin/tickets/update"
          className="nav-link default-text-color"
        >
          Update Matches
        </NavLink>,
        "/admin/tickets/update"
      ),
    ]
  );

  const websiteSideNavLinks = getItem(
    "Website",
    "sub5",
    getIcon(22, WebsiteIcon),
    [
      getItem(
        <NavLink
          to="/admin/website/general"
          className="nav-link default-text-color"
        >
          General Setup
        </NavLink>,
        "/admin/website/general"
      ),
      getItem(
        <NavLink
          to="/admin/website/sections"
          className="nav-link default-text-color"
        >
          Sections
        </NavLink>,
        "/admin/website/sections"
      ),
    ]
  );

  const stadiumSideNavLinks = getItem(
    "Stadium",
    "sub6",
    getIcon(22, StadiumIcon),
    [
      getItem(
        <NavLink
          to="/admin/stadium/setup"
          className="nav-link default-text-color"
        >
          Setup
        </NavLink>,
        "/admin/stadium/setup"
      ),
    ]
  );

  const directorySideNavLinks = getItem(
    "Directory",
    "sub7",
    getIcon(22, DirectoryIcon),
    [
      getItem(
        <NavLink
          to="/admin/directory/groupListing"
          className="nav-link default-text-color"
        >
          Group Listing
        </NavLink>,
        "/admin/directory/groupListing"
      ),
    ]
  );

  const helpSideNavLinks = getItem(
    <NavLink to="/admin/help" className="nav-link default-text-color">
      Help
    </NavLink>,
    "/admin/help",
    getIcon(22, HelpIcon)
  );

  const settingsSideNavLinks = getItem(
    "Settings",
    "sub8",
    getIcon(22, SettingsIcon),
    [
      getItem(
        <NavLink
          to="/admin/groupSettings"
          className="nav-link default-text-color"
        >
          Design
        </NavLink>,
        "/admin/groupSettings"
      ),
      getItem(
        <NavLink
          to="/admin/appSettings"
          className="nav-link default-text-color"
        >
          App
        </NavLink>,
        "/admin/appSettings"
      ),
      getItem(
        <NavLink
          to="/admin/paymentDetails"
          className="nav-link default-text-color"
        >
          Payments
        </NavLink>,
        "/admin/paymentDetails"
      ),
    ]
  );

  const clubAdminGroupsSideNavLinks = getItem(
    "Groups",
    "sub9",
    getIcon(22, MembersIcon),
    [
      getItem(
        <NavLink to="/clubadmin/groups" className="nav-link default-text-color">
          Groups
        </NavLink>,
        "/clubadmin/groups"
      ),
      getItem(
        <NavLink
          to="/clubadmin/members"
          className="nav-link default-text-color"
        >
          Members
        </NavLink>,
        "/clubadmin/members"
      ),
      getItem(
        <NavLink
          to="/clubadmin/membership"
          className="nav-link default-text-color"
        >
          Card
        </NavLink>,
        "/clubadmin/membership"
      ),
    ]
  );

  const clubAdminContentSideNavLinks = getItem(
    "Content",
    "sub10",
    getIcon(22, ContentIcon),
    [
      getItem(
        <NavLink
          to="/clubadmin/content"
          className="nav-link default-text-color"
        >
          News/Events
        </NavLink>,
        "/clubadmin/content"
      ),
      getItem(
        <NavLink to="/clubadmin/chants" className="nav-link default-text-color">
          Chants
        </NavLink>,
        "/clubadmin/chants"
      ),
    ]
  );

  const clubAdminGallerySideNavLinks = getItem(
    <NavLink to="/clubadmin/gallery" className="nav-link default-text-color">
      Gallery
    </NavLink>,
    "/clubadmin/gallery",
    getIcon(22, GalleryIcon)
  );

  const clubAdminPollsSideNavLinks = getItem(
    <NavLink to="/clubadmin/Polls" className="nav-link default-text-color">
      Polls
    </NavLink>,
    "/clubadmin/Polls",
    getIcon(22, PollsIcon)
  );

  const clubAdminGiveawaySideNavLinks = getItem(
    <NavLink to="/clubadmin/giveaways" className="nav-link default-text-color">
      Giveaways
    </NavLink>,
    "/clubadmin/giveaways",
    getIcon(22, GiveawayIcon)
  );

  const clubAdminHelpSideNavLinks = getItem(
    <NavLink to="/clubadmin/help" className="nav-link default-text-color">
      Help
    </NavLink>,
    "/clubadmin/help",
    getIcon(22, HelpIcon)
  );

  const adminLinks = [
    memberSideNavLinks,
    contentSideNavLinks,
    storeSideNavLinks,
    ticketsSideNavLinks,
    websiteSideNavLinks,
    stadiumSideNavLinks,
    directorySideNavLinks,
    settingsSideNavLinks,
    helpSideNavLinks,
  ];

  const clubAdminLinks = [
    clubAdminGroupsSideNavLinks,
    clubAdminContentSideNavLinks,
    clubAdminGallerySideNavLinks,
    clubAdminPollsSideNavLinks,
    clubAdminGiveawaySideNavLinks,
    clubAdminHelpSideNavLinks,
  ];

  return { adminLinks, clubAdminLinks };
};

export default useSideNavLinks;
