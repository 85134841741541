const createPackagesStore = (set) => ({
  packagesList: {},
  packagesLoading: false,
  setPackagesList: (groupId, list) =>
    set((state) => ({
      packages: {
        ...state.packages,
        packagesList: {
          ...state.packages.packagesList,
          [groupId]: list,
        },
      },
    })),
  setPackagesLoading: (packagesLoading) =>
    set((state) => ({
      packages: {
        ...state.packages,
        packagesLoading,
      },
    })),

  reset: (state) => set({ packages: { ...state, packagesList: {} } }),
});

export default createPackagesStore;
