import React from "react";
import useTicketsReportData from "./useTicketsReportData";
import { Table } from "antd";
import { FSelect } from "../../commons/formFields/FSelect";

const TicketsReport = () => {
  const {
    onClubChange,
    clubs,
    columns,
    dataLoading,
    clubsLoading,
    selectedClub,
    allMissingTransactions,
  } = useTicketsReportData();

  return (
    <div className="container p-5">
      <div className="my-row" style={{ marginLeft: "16px", gap: "24px" }}>
        <FSelect
          value={selectedClub}
          onChange={onClubChange}
          dataList={clubs}
          dataKey="id"
          dataValue="label"
          style={{
            backgroundColor: "transparent",
            border: "0px none transparent",
            color: "white",
            borderRadius: 0,
            fontSize: 16,
            width: "200px",
          }}
          name="status"
          display="my-row"
          placeholder="Club"
          tabIndex="2"
        />
      </div>
      <Table
        columns={columns}
        bordered
        dataSource={allMissingTransactions?.[selectedClub] || []}
        loading={dataLoading || clubsLoading}
        bodyStyle={{
          backgroundColor: "#ffffff",
        }}
        scroll={{ x: true }}
        className="superadmin-logos-table table-backdrop mb-3"
      />
    </div>
  );
};

export default TicketsReport;
