import React from "react";
import "./DashboardLinks.css";

const DashboardLinks = () => {
  return (
    <>
      <li
        className="nav-item unauth-header-link"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <a
          style={{
            color: "#ffffff",
            fontWeight: 800,
            fontSize: 21,
          }}
          href="https://chant.fan/#theplatform"
        >
          THE PLATFORM
        </a>
      </li>
      <li
        className="nav-item unauth-header-link"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <a
          style={{
            color: "#ffffff",
            fontWeight: 800,
            fontSize: 21,
          }}
          href="https://chant.fan/#features"
        >
          FEATURES
        </a>
      </li>
      <li
        className="nav-item unauth-header-link"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <a
          style={{
            color: "#ffffff",
            fontWeight: 800,
            fontSize: 21,
          }}
          href="https://chant.fan/#groups"
        >
          GROUPS
        </a>
      </li>
      <li
        className="nav-item unauth-header-link"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <a
          style={{
            color: "#ffffff",
            fontWeight: 800,
            fontSize: 21,
          }}
          href="https://chant.fan/#getstarted"
        >
          GET STARTED
        </a>
      </li>
      <li
        className="nav-item unauth-header-link"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <a
          className="dashboard-admin-link"
          href="/"
          aria-label="Go to Admin Section"
        >
          ADMIN
        </a>
      </li>
    </>
  );
};

export default DashboardLinks;
