import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { fbLogoutUser } from "../../../../services/firebaseService/endPoints/user";

const SuperAdminLinks = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    fbLogoutUser().then(() => {
      navigate("/login");
    });
  };

  const linkStyle = {
    color: "grey",
  };

  const activeLinkStyle = {
    color: "white",
  };

  return (
    <>
      <li
        className="nav-item"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <NavLink
          to="/superadmin/groups"
          exact
          className="nav-link"
          style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
        >
          Groups
        </NavLink>
      </li>
      <li
        className="nav-item"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <NavLink
          to="/superadmin/adoption"
          exact
          className="nav-link"
          style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
        >
          Adoption
        </NavLink>
      </li>
      <li
        className="nav-item"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <NavLink
          to="/superadmin/missing-transactions"
          exact
          className="nav-link"
          style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
        >
          Missing Transactions
        </NavLink>
      </li>
      <li
        className="nav-item"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <NavLink
          to="/superadmin/directory"
          exact
          className="nav-link"
          style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
        >
          Directory
        </NavLink>
      </li>
      <li
        className="nav-item"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <NavLink
          to="/superadmin/polls"
          exact
          className="nav-link"
          style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
        >
          Polls
        </NavLink>
      </li>
      <li
        className="nav-item"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <NavLink
          to="/superadmin/report/transactions"
          className="nav-link"
          style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
        >
          Transactions
        </NavLink>
      </li>
      <li
        className="nav-item"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <NavLink
          to="/superadmin/status/report"
          className="nav-link"
          style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
        >
          Match Day
        </NavLink>
      </li>
      <li
        className="nav-item"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <NavLink
          to="/superadmin/deactivated"
          exact
          className="nav-link"
          style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
        >
          Deactivated
        </NavLink>
      </li>
      <li
        className="nav-item"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <NavLink
          to="/superadmin/data"
          exact
          className="nav-link"
          style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
        >
          Data
        </NavLink>
      </li>

      <li
        className="nav-item"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <NavLink
          to="/superadmin/logos"
          exact
          className="nav-link"
          style={({ isActive }) => (isActive ? activeLinkStyle : linkStyle)}
        >
          Logos
        </NavLink>
      </li>
      <div style={{ display: "flex", gap: "8px" }}>
        <button
          className="btn btn-sm btn-outline-info my-2 my-sm-0"
          data-toggle="collapse"
          data-target=".navbar-collapse.show"
          type="submit"
          onClick={() => {
            navigate("/superadmin/groups/create");
          }}
        >
          Create Group
        </button>

        <button
          className="btn btn-sm btn-outline-info my-2 my-sm-0 ml-2"
          data-toggle="collapse"
          data-target=".navbar-collapse.show"
          type="submit"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
    </>
  );
};

export default SuperAdminLinks;
