import { doc, getDoc, setDoc, updateDoc } from "@firebase/firestore";
import { Modal, Spin, Tooltip } from "antd";
import React from "react";
import { connect } from "react-redux";
import { postUpdateCDNUtil } from "../../../helperFunctions/util";
import { db } from "../../../services/firebaseService/connection";
import { FInput } from "../../commons/formFields/FInput";
import { FSelect } from "../../commons/formFields/FSelect";

const shippingOptions = [
  { value: "pickup", label: "Pick up only" },
  { value: "shipping", label: "Shipping only" },
  { value: "both", label: "Option of shipping or pickup" },
];

const storeFeeOptions = [
  "Add $1 fee per product at checkout",
  "Deduct fee from product price",
];

const countries = [
  "United States",
  "Canada",
  "United Kingdom",
  "France",
  "Germany",
  "Italy",
  "Spain",
];

const ProcessingFeeTypes = [
  "Group pays (Fees absorbed by group)",
  "Member pays (Fees added to total)",
  "Ask Member to pay (Request member pay fee but not required)",
];

class StoreSettings extends React.Component {
  state = {
    deliveryOption: "",
    shippingNote: "",
    pickupNote: "",
    publishStandalone: false,
    // storeFeeOption: 'Add $1 fee per product at checkout',
    storeFeeOption: "",
    storeIntroText: "",
    shippingCountries: [],
    // processingFee: '',
    loading: false,
    showModal: false,
    navigationLabel: "",
    storeUrl: "",
  };

  changeSelect = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    this.setState({
      loading: true,
    });

    let updateObj = this.state;

    let storeUrl = updateObj.storeUrl;
    let navigationLabel = updateObj.navigationLabel;

    if (storeUrl && !(storeUrl || "").startsWith("https://")) {
      storeUrl = `https://${storeUrl}`;
    }

    let store = {
      name: navigationLabel,
      link: storeUrl,
    };

    delete updateObj.loading;
    delete updateObj.showModal;
    delete updateObj.navigationLabel;
    delete updateObj.storeUrl;

    setDoc(doc(db, "store", this.props.currentGroup), updateObj, {
      merge: true,
    })
      .then(() =>
        updateDoc(doc(db, "group", this.props.currentGroup), {
          store,
        })
      )
      .then(() => this.fetchData())
      .then(() => {
        this.setState({
          loading: false,
        });
      });

    postUpdateCDNUtil(this.props.currentGroup);
  };

  fetchData = async () => {
    await getDoc(doc(db, "store", this.props.currentGroup)).then((doc) => {
      if (doc.exists()) {
        let data = doc.data();
        let deliveryOption = data.deliveryOption || "";
        let shippingNote = data.shippingNote || "";
        let pickupNote = data.pickupNote || "";
        let publishStandalone = data.publishStandalone || false;
        let storeFeeOption = data.storeFeeOption || "";
        let processingFee = data.processingFee || "";
        let storeIntroText = data.storeIntroText || "";
        let shippingCountries = data.shippingCountries || [];
        let storeConf = this.props.adminData.data.store || {};
        let navigationLabel =
          storeConf.name || (publishStandalone ? "Store" : "");
        let storeUrl =
          storeConf.link ||
          (publishStandalone
            ? `https://group.chant.fan/${this.props.adminData.data.groupName.toLowerCase().replace(/ /g, "")}/store`
            : "");

        this.setState({
          deliveryOption,
          shippingNote,
          pickupNote,
          publishStandalone,
          storeFeeOption,
          storeIntroText,
          shippingCountries,
          navigationLabel,
          storeUrl,
          processingFee,
        });
      }
    });
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentGroup !== prevProps.currentGroup) {
      this.fetchData();
    }
  }

  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <>
        {this.state.showModal && (
          <ColorCreateModal
            open={this.state.showModal}
            hideModal={() => this.setState({ showModal: false })}
            currentGroup={this.props.currentGroup}
          />
        )}
        <div className="mx-auto mt-3" style={{ width: "100%" }}>
          <p style={{ fontSize: 17 }}>
            Complete the required fields in settings before publishing items in
            the store. If items will be offered in varying colors establish a
            master color list here first.
          </p>
        </div>
        <div
          className="mx-auto mt-3"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#ffffff",
            // boxShadow: "0px 3px 10px 1px",
          }}
        >
          <div className="my-row mt-2 p-3">
            <div className="col-md-8">
              <div className="my-row">
                <div className="col-md-4">
                  <span>Delivery Options</span>
                  <span style={{ color: "#ff0000" }}>*</span>
                  <Tooltip
                    placement="topLeft"
                    title={
                      <div style={{ width: 400 }}>
                        <span>
                          Applied to all products in the Store. Add flat rate
                          shipping cost when creating products. Split orders
                          (part pickup, part shipping) are not allowed.
                        </span>
                      </div>
                    }
                  >
                    <span className="border-0">
                      &nbsp;<i className="fa fa-question-circle-o"></i>
                    </span>
                  </Tooltip>
                </div>
                <div className="col-md-8">
                  <Select
                    placeholder="Select option"
                    dataList={shippingOptions}
                    value={this.state.deliveryOption}
                    onChange={(e) => {
                      this.changeSelect("deliveryOption", e.target.value);
                    }}
                    dataKey="value"
                    dataValue="label"
                  />
                </div>
              </div>

              <div className="my-row mt-4">
                <div className="col-md-1"></div>
                <div className="col-md-3">
                  <span>Shipping note</span>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    onChange={this.handleInputChange}
                    name="shippingNote"
                    value={this.state.shippingNote}
                    style={{
                      border: "1px solid #000000",
                      borderRadius: 3,
                      width: "100%",
                      boxShadow: "0px 0px 1px 1px #cccccc",
                    }}
                  />
                </div>
              </div>

              <div className="my-row mt-4">
                <div className="col-md-1"></div>
                <div className="col-md-3">
                  <span>Pickup note</span>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    onChange={this.handleInputChange}
                    value={this.state.pickupNote}
                    name="pickupNote"
                    style={{
                      border: "1px solid #000000",
                      borderRadius: 3,
                      width: "100%",
                      boxShadow: "0px 0px 1px 1px #cccccc",
                    }}
                  />
                </div>
              </div>

              <div className="my-row mt-4">
                <div className="col-md-4">
                  <span>Country Shipping Options</span>
                  <span style={{ color: "#ff0000" }}>*</span>
                  <Tooltip
                    placement="topLeft"
                    title={
                      <div style={{ width: 400 }}>
                        <span>
                          Select countries where you offer shipping. Note:
                          Shipping is set as flat rate by product.
                        </span>
                      </div>
                    }
                  >
                    <span className="border-0">
                      &nbsp;<i className="fa fa-question-circle-o"></i>
                    </span>
                  </Tooltip>
                </div>
                <div className="col-md-8">
                  <ul style={{ listStyle: "none" }}>
                    {countries.map((country) => {
                      return (
                        <li>
                          <div className="my-row align-items-center">
                            <input
                              type="checkbox"
                              onChange={(e) => {
                                let shippingCountries =
                                  this.state.shippingCountries || [];

                                if (e.target.checked) {
                                  shippingCountries.push(country);
                                } else {
                                  shippingCountries.splice(
                                    shippingCountries.indexOf(country),
                                    1
                                  );
                                }

                                this.setState({
                                  shippingCountries,
                                });
                              }}
                              checked={
                                this.state.shippingCountries &&
                                this.state.shippingCountries.includes(country)
                              }
                            />
                            <span className="ml-3">{country}</span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className="my-row mt-4">
                <div className="col-md-4">
                  <span>Create Product Colors</span>
                  <Tooltip
                    placement="topLeft"
                    title={
                      <div style={{ width: 400 }}>
                        <span>
                          Create a master list of color values here then select
                          colors when adding products.
                        </span>
                      </div>
                    }
                  >
                    <span className="border-0">
                      &nbsp;<i className="fa fa-question-circle-o"></i>
                    </span>
                  </Tooltip>
                </div>
                <div className="col-md-8">
                  <span
                    style={{
                      textDecoration: "underline",
                      color: "blue",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.setState({ showModal: true });
                    }}
                  >
                    Set Colors
                  </span>
                </div>
              </div>

              <div className="my-row mt-4">
                <span
                  className="col"
                  style={{ fontSize: 20, fontWeight: "bold" }}
                >
                  Store (Web)
                </span>
              </div>

              <div className="my-row">
                <div className="col-md-4">
                  <span>Publish standalone store webpage?</span>
                  <Tooltip
                    placement="topLeft"
                    title={
                      <div style={{ width: 400 }}>
                        <span>
                          Select this option to generate a standalone store web
                          page allowing you to link from your own website. If
                          using a Chant website this option is not needed.
                        </span>
                      </div>
                    }
                  >
                    <span className="border-0">
                      &nbsp;<i className="fa fa-question-circle-o"></i>
                    </span>
                  </Tooltip>
                </div>
                <div className="col-md-8">
                  <div className="my-row align-items-center">
                    <input
                      type="checkbox"
                      style={{ marginLeft: "18px", marginTop: "1px" }}
                      onChange={(e) => {
                        let publish = e.target.checked;
                        let updatedState = {
                          publishStandalone: publish,
                        };
                        if (publish) {
                          updatedState = {
                            ...updatedState,
                            storeUrl:
                              "https://group.chant.fan/" +
                              this.props.adminData.data.groupName
                                .replace(/ /g, "")
                                .toLowerCase() +
                              "/store",
                            navigationLabel: "Store",
                          };
                        } else {
                          if (this.state.storeUrl.includes("group.chant.fan")) {
                            updatedState = {
                              ...updatedState,
                              storeUrl: "",
                              navigationLabel: "",
                            };
                          }
                        }
                        this.setState(updatedState);
                      }}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      tabIndex="5"
                      id="publishStandalone"
                      checked={this.state.publishStandalone}
                    />

                    <div className="ml-4">
                      Status:{" "}
                      {this.state.publishStandalone ? (
                        <span>
                          Published (
                          <a
                            href={`https://group.chant.fan/${this.props.adminData.data.groupName.toLowerCase().replace(/ /g, "")}/store`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Store
                          </a>
                          )
                        </span>
                      ) : (
                        "Unpublished"
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-row mt-4">
                <div className="col-md-4">
                  <span>Store Intro Text</span>
                </div>
                <div className="col-md-8">
                  <textarea
                    onChange={this.handleInputChange}
                    name="storeIntroText"
                    value={this.state.storeIntroText}
                    style={{
                      border: "1px solid #000000",
                      borderRadius: 3,
                      width: "100%",
                      boxShadow: "0px 0px 1px 1px #cccccc",
                    }}
                    my-rows={3}
                  />
                </div>
              </div>

              <div className="my-row mt-4">
                <span
                  className="col"
                  style={{ fontSize: 20, fontWeight: "bold" }}
                >
                  Store navigation(App)
                </span>
              </div>
              <div className="my-row">
                <div className="col">
                  <p>
                    Using the Chant Store or an external store? Add navigation
                    name + store url to appear in More section of the app as web
                    link. Note that we will integrate the Store into the app in
                    the coming months – currently web only.
                  </p>
                </div>
              </div>

              <div
                className="col-8"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <FSelect
                  flex
                  label="Navigation name"
                  value={this.state.navigationLabel}
                  onChange={(e) =>
                    this.changeSelect("navigationLabel", e.target.value)
                  }
                  name="type"
                  dataList={["Store", "Merch", "Merchandise"]}
                  dataOnlyValue
                  placeholder="Select"
                  // display="my-row"
                  tabIndex="1"
                />

                <FInput
                  label="Store Url"
                  value={this.state.storeUrl}
                  onChange={this.handleInputChange}
                  name="storeUrl"
                  // display="my-row"
                  placeholder="Store URL"
                  tabIndex="2"
                />

                <FSelect
                  flex
                  label="Processing Fee"
                  name="processingFee"
                  // display="my-row"
                  tabIndex="5"
                  required
                  bottomHint="Stripe (2.9% + $.030) + Chant (3%). See ‘?’ for additional details."
                  tooltip={
                    <div>
                      <p style={{ fontWeight: "bold" }}>Fees</p>
                      <p style={{ marginBottom: 0 }}>
                        Stripe card processing fee: 2.9% + $0.30 (can vary)
                      </p>
                      <p>Chant fee: 3%</p>

                      <p style={{ fontWeight: "bold" }}>Member Pay</p>
                      <p>
                        Stripe collects the fee on the entire purchase amount.
                        So, if a member covers fees that is added to the total
                        charged ($10 item + $0.59 fee paid by member for $10.59)
                        stripe will collect a fee on the entire $10.59.
                      </p>

                      <p>
                        To account for this we add 3% + $.30 (.1% more) to the
                        original item price for Stripe so your net is close to
                        the item price - it may be off very slightly.
                      </p>

                      <p>
                        Stripe fees can vary based on member payment method but
                        are not known until after the transaction is complete
                        (foreign card, etc). Your net proceeds may not cover the
                        full item amount but should be close.
                      </p>

                      <p style={{ fontWeight: "bold" }}>
                        Member Pay (legal Limitations)
                      </p>
                      <p>
                        Not all states/countries allow the fee to be passed to
                        the member. Chant is not responsible for determining
                        your local laws. Please confirm this for your location.
                      </p>
                    </div>
                  }
                  value={this.state.processingFee}
                  onChange={(e) =>
                    this.changeSelect("processingFee", e.target.value)
                  }
                  placeholder="--Select--"
                  dataOnlyValue
                  dataList={ProcessingFeeTypes}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="my-row mt-4 justify-content-center">
          <button
            className="btn btn-success default-text-color"
            tabIndex="14"
            type="success"
            style={{
              backgroundColor: primaryColor,
              borderRadius: 5,
              border: "0px none",
              boxShadow: "transparent 0px 0px 0px",
              outline: "none",
              padding: "5px 10px",
            }}
            onClick={this.handleSubmit}
          >
            {this.state.loading ? (
              <Spin size="small" spinning={true} />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </>
    );
  }
}

class Select extends React.Component {
  render() {
    return (
      <select
        className={`form-control form-control-sm`}
        style={{
          border: "1px solid",
          borderRadius: "3px",
          boxShadow: "0px 0px 1px 1px #cccccc",
        }}
        name={this.props.name}
        onChange={this.props.onChange}
        tabIndex={this.props.tabIndex || 0}
        value={this.props.value}
      >
        <option key="0" value={""}>
          {this.props.placeholder}
        </option>

        {this.props.dataList.length > 0 &&
          this.props.dataList.map((data) => {
            if (this.props.listBuilder) {
              return this.props.listBuilder(data);
            } else {
              return (
                <option
                  key={
                    this.props.dataOnlyValue ? data : data[this.props.dataKey]
                  }
                  value={
                    this.props.dataOnlyValue ? data : data[this.props.dataKey]
                  }
                >
                  {this.props.dataOnlyValue ? data : data[this.props.dataValue]}
                </option>
              );
            }
          })}
      </select>
    );
  }
}

class ColorCreateModal extends React.Component {
  state = {
    colors: [],
  };

  handleSubmit = () => {
    this.setState({
      loading: true,
    });

    let updateObj = this.state;

    delete updateObj.loading;

    setDoc(doc(db, "store", this.props.currentGroup), updateObj, {
      merge: true,
    }).then(() => {
      this.setState({
        loading: false,
      });
      this.props.hideModal();
    });
  };

  fetchData = () => {
    getDoc(doc(db, "store", this.props.currentGroup)).then((doc) => {
      if (doc.exists()) {
        let data = doc.data();

        let colors = data.colors || [];

        this.setState({
          colors,
        });
      }
    });
  };

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <Modal
        width={700}
        onOk={() => {}}
        onCancel={() => {
          this.props.hideModal();
        }}
        open={this.props.visible}
        footer={<></>}
      >
        <div style={{ width: "100%", padding: 30 }}>
          <div className="my-row">
            <span style={{ fontSize: 22 }}>Set Colors</span>
          </div>
          <div className="my-row mt-3">
            <p>
              Create colors that can be applied to any product in inventory.
            </p>
          </div>
          {/* <div className='my-row mt-3'>
                        <p>All products are assigned SKUs (unique codes). Extensions are added to the base SKU for size and color. Create color SKU extensions below and do not change once set.</p>
                    </div> */}
          <div className="my-row mt-5">
            <span style={{ fontSize: 22 }}>Color</span>
          </div>
          <div className="col-md-5 mt-2">
            <div className="my-row">
              <ul
                style={{ listStyle: "none", paddingLeft: "0px", width: "100%" }}
              >
                {this.state.colors.map((color, index) => (
                  <li style={{ marginTop: "5px" }}>
                    <div
                      className="my-row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <input
                        type="text"
                        value={color}
                        onChange={(e) => {
                          let colors = this.state.colors;
                          colors[index] = e.target.value;
                          this.setState({ colors });
                        }}
                        style={{
                          border: "1px solid",
                          boxShadow: "0px 0px",
                          width: "90%",
                        }}
                      />

                      <span
                        style={{
                          cursor: "pointer",
                          height: 20,
                          width: 20,
                          textAlign: "center",
                        }}
                        onClick={() => {
                          let colors = this.state.colors;
                          colors.splice(index, 1);
                          this.setState({ colors });
                        }}
                      >
                        x
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <span
              style={{ textDecoration: "underline" }}
              onClick={() => {
                let colors = this.state.colors;
                colors.push("");
                this.setState({ colors });
              }}
            >
              +Add color
            </span>
          </div>
          <div className="my-row justify-content-center mt-5 mb-2">
            <button
              className="btn btn-success"
              tabIndex="14"
              type="success"
              onClick={this.handleSubmit}
            >
              {this.state.loading ? (
                <Spin size="small" spinning={true} />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  let currentGroup = state.adminData.currentGroup;
  return {
    user: state.user,
    currentGroup,
    adminData: state.adminData.allGroups[currentGroup],
  };
};

export default connect(mapStateToProps)(StoreSettings);
