import { Button, Popconfirm, Table } from "antd";
import React, { useEffect, useState } from "react";
import { fbGetPendingMembers } from "../../../../services/firebaseService/endPoints/admin/members";
import { useSelector } from "react-redux";
import { selectCurrentGroupData } from "../../../../redux/selectors/adminData";
import useGlobalStore from "../../../../store/store";
import logger from "../../../../utils/logger";
import { json2csv } from "json-2-csv";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../../services/firebaseService/connection";

const PendingMembers = () => {
  const groupData = useSelector(selectCurrentGroupData);
  const { packagesList } = useGlobalStore((state) => state.packages);

  const [pendingEmails, setPendingEmails] = useState("");
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "Pre-registered emails",
      key: "email",
      className: "header-white",
      dataIndex: "email",
      render: (email) => <span style={{ fontWeight: "bold" }}>{email}</span>,
    },
    {
      title: "Package",
      key: "package",
      dataIndex: "package",
    },
  ];

  const getPendingMembers = async () => {
    setLoading(true);
    await fbGetPendingMembers(groupData.id)
      .then((doc) => {
        if (doc.exists()) {
          const emails = doc.data().emails;
          const userDetails = doc.data().userDetails || {};
          const emailsWithPackage = [];
          const pendingEmails = Object.values(userDetails).map((detail) => {
            const packageId = detail.package;
            let packageName = "";
            const packageDetails = packagesList?.[groupData?.id].find(
              (pack) => pack.id === packageId
            );
            if (packageDetails) {
              packageName = packageDetails.name;
            }
            emailsWithPackage.push(detail.email);

            return { email: detail.email, package: packageName };
          });

          emails.forEach((email) => {
            if (!emailsWithPackage.includes(email)) {
              pendingEmails.push({
                email,
                package: "",
              });
            }
          });

          setPendingEmails(pendingEmails);
        }
      })
      .catch((e) => logger.error(e))
      .finally(() => setLoading(false));
  };

  const downloadPendingList = () => {
    let groupName = groupData.groupName.replaceAll(" ", "_");

    json2csv(JSON.parse(JSON.stringify(pendingEmails)), (err, csv) => {
      if (err) {
        logger.error(err);
        return;
      }
      var link = document.createElement("a");
      link.setAttribute(
        "href",
        window.URL.createObjectURL(
          new Blob([csv, "Text"], { type: "text/csv" })
        )
      );
      link.setAttribute("download", `${groupName}_pending_list.csv`);
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    });
  };

  const deletePendingList = async () => {
    let docRef = doc(db, "invite_emails", groupData.id);

    let emailsInUserDetails = await getDocs(
      collection(db, docRef.path, "userDetails")
    ).then((snap) => {
      let emails = [];
      snap.docs.forEach((doc) => {
        let user = doc.data() || {};
        emails.push(user.email ?? "");
      });

      emails = emails.filter((email) => email);
      return emails;
    });

    getDoc(docRef)
      .then((doc) => {
        if (doc.exists()) {
          emailsInUserDetails = Array.from(
            new Set(
              emailsInUserDetails.filter((email) => email && email.trim())
            )
          );
          updateDoc(docRef, { emails: emailsInUserDetails }).then(() => {
            getPendingMembers();
          });
        }
      })
      .then(() => {
        logger.log("emails deleted");
      });
  };

  useEffect(() => {
    getPendingMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupData.id]);

  return (
    <div style={{ padding: "0rem 3rem" }}>
      <div className="d-flex" style={{ marginTop: 20 }}>
        <p>
          Pending shows the emails uploaded (Onboard Option #1-2) and from the
          registration form (Onboard Option #3) who have not yet registered in
          Chant.
        </p>
      </div>
      <div style={{ display: "flex", gap: "8px" }}>
        <Popconfirm
          title="Download pending list as CSV?"
          onConfirm={downloadPendingList}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <Button
            style={{
              backgroundColor: "var(--primary-background)",
              padding: "5px 10px",
              color: "var(--primary-text-color)",
            }}
            className="mr-1"
          >
            Download List
          </Button>
        </Popconfirm>

        <Popconfirm
          title={
            <div style={{ width: 400 }}>
              <p>Delete pre-registered emails?</p>

              <p>
                Note: This will not delete records for members who have
                purchased a membership package but not yet joined Chant.
              </p>

              <p>
                Only email addresses manually added by the admin (via Onboard)
                are deleted.
              </p>
            </div>
          }
          onConfirm={deletePendingList}
          okText="Yes"
          cancelText="No"
        >
          <Button
            style={{
              backgroundColor: "#999999",
              padding: "5px 10px",
              color: "#ffffff",
            }}
            className="mr-1"
          >
            Delete List
          </Button>
        </Popconfirm>
      </div>
      <br />
      <div
        style={{
          width: "50%",
        }}
      >
        <p>{pendingEmails?.length} pending</p>
        <Table
          columns={columns}
          className={`table-backdrop`}
          dataSource={pendingEmails}
          bodyStyle={{ backgroundColor: "#ffffff" }}
          bordered
          pagination={
            pendingEmails.length > 50 && {
              position: ["bottomLeft"],
              defaultPageSize: 50,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 50, 100],
            }
          }
          loading={loading}
        />
      </div>
    </div>
  );
};

export default PendingMembers;
