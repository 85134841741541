import { Modal, Tooltip } from "antd";
import React from "react";
import FUpload from "../../commons/formFields/FUpload";
import imageCompression from "browser-image-compression";

class ImageUploadButton extends React.Component {
  state = {
    uploadWindow: false,
    images: [],
    imageList: Array.from({ length: this.props.count || 1 }, (_) => []),
    savingImage: false,
    editConfiguration: {},
  };

  handleClick = () => {
    this.setState({ uploadWindow: true });
  };

  uploadAction = (file, fileList, index) => {
    let images = this.state.images;
    images[index] = file;
    this.setState({ images });
  };

  handleImageChange = async ({ fileList }, index) => {
    this.setState({
      savingImage: true,
    });
    let result = {};
    result.images = this.state.images;
    if (fileList.length === 0) {
      result.images[index] = "";
    } else {
      const options = {
        maxSizeMB: 3,
        maxWidthOrHeight: 2560,
        useWebWorker: true,
        alwaysKeepResolution: true,
        preserveExif: true,
      };
      let compressedImage = await imageCompression(
        fileList[0].originFileObj,
        options
      );
      result.images[index] = compressedImage;
    }

    if (!this.state.editConfiguration.imageChanged) {
      this.setState((prevState) => {
        let editConfiguration = Object.assign({}, prevState.editConfiguration);
        editConfiguration.imageChanged = true;
        return {
          editConfiguration,
        };
      });
    }
    result.imageList = [];
    result.imageList[index] = fileList;

    this.setState(() => result);
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.images &&
      props.images.length > 0 &&
      !state.editConfiguration.imageChanged
    ) {
      let imageList = props.images.map((url, index) => {
        if (url) {
          return [{ uid: -1 * index - 1, url }];
        }
        return null;
      });

      imageList = imageList.filter((image) => image);
      return { imageList };
    }
    return null;
  }

  render() {
    const {
      text,
      onSave,
      count = 1,
      aspectRatio,
      disabled,
      label,
      tooltip,
      required,
      helperText,
    } = this.props;
    return (
      <>
        {this.state.uploadWindow && (
          <Modal
            onCancel={() => {
              this.setState({
                uploadWindow: false,
              });
            }}
            onOk={() => {
              onSave(this.state.images);
              this.setState({ uploadWindow: false });
            }}
            open={this.state.uploadWindow}
            title="Upload Image"
          >
            <div
              className="upload-button"
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {Array.from({ length: count }, (_, index) => {
                return (
                  <FUpload
                    action={(file, fileList) =>
                      this.uploadAction(file, fileList, index)
                    }
                    fileList={this.state.imageList[index] || []}
                    onChange={({ fileList }) =>
                      this.handleImageChange({ fileList }, index)
                    }
                    aspectRatio={aspectRatio}
                    display="col"
                    isAvatar="false"
                    viewMode={false}
                    onRemove={this.props.onDelete}
                  />
                );
              })}
            </div>
          </Modal>
        )}
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {label && (
            <div className="col-md-4">
              <label>
                {label}
                {required && (
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    {" "}
                    *{" "}
                  </span>
                )}
                {tooltip && (
                  <Tooltip
                    placement="topLeft"
                    title={<div style={{ width: 400 }}>{tooltip}</div>}
                  >
                    <span className="border-0 m-0 p-0 ml-2">
                      <i className="fa fa-question-circle-o" />
                    </span>
                  </Tooltip>
                )}
              </label>
            </div>
          )}
          <div className={!label ? "col d-flex" : "d-flex"}>
            {this.props.children ? (
              this.getChildrenComponent(this.props.children)
            ) : (
              <button
                // className="px-auto"
                style={{
                  height: 28,
                  width: 250,
                  minWidth: 250,
                  backgroundColor: "#a0a0a0",
                  borderRadius: 5,
                  color: "#ffffff",
                  boxShadow: "transparent 0px 0px 0px",
                  outline: "none",
                  outlineOffset: "unset",
                  borderWidth: 1,
                }}
                onClick={this.handleClick}
                disabled={disabled}
              >
                <p style={{ fontSize: "15px", color: "white" }}>{text}</p>
              </button>
            )}
            <div className="d-flex">
              {this.state.images.map(({ name }) => name).join(",")}
            </div>
          </div>
          {helperText && (
            <div
              className="flex-center"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                paddingLeft: "8px",
              }}
            >
              {helperText}
            </div>
          )}
        </div>
      </>
    );
  }

  getChildrenComponent = () => {
    return React.Children.map(this.props.children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { onClick: this.handleClick });
      }
      return child;
    });
  };
}

export default ImageUploadButton;
