import React from "react";
import { GithubPicker } from "react-color";

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: true,
    };
  }

  handleColorInputChange = (e) => {
    const prevColor = { rgb: {} };
    prevColor.rgb = { ...this.props.color };
    prevColor.rgb[e.target.name] = Number(e.target.value);
    this.handleChangeRGB(prevColor, this.props.colorType);
  };

  convertToRGBA(color) {
    return `rgba(${color.r},${color.g},${color.b}, ${color.a})`;
  }

  handleChangeRGB = (color, type) => {
    let newValue = Object.assign({}, color.rgb);
    newValue.a = this.props.color.a;
    this.props.handleColorChange(newValue, type);
  };

  render() {
    return (
      <div
        className={`my-row ${window.screen.width < 500 ? "justify-content-end pr-3" : ""}`}
      >
        <div style={{ padding: "10px", backgroundColor: "#ffffff" }}>
          <div
            style={{
              display: window.screen.width > 500 ? "" : "none",
              marginLeft: "-15px !important",
            }}
          >
            <GithubPicker
              className="mt-2"
              colors={this.props.colorOptions}
              width="220px"
              triangle={"hide"}
              onChange={(color) => {
                this.handleChangeRGB(color, this.props.colorType);
                // this.handleChangeAlpha(color, this.props.colorType)
              }}
            />
          </div>

          <div
            className={`my-row mt-2 ${window.screen.width > 500 ? "mx-2" : ""}`}
          >
            <div
              className={`my-row ${window.screen.width < 500 ? "justify-content-end" : ""}`}
            >
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  className=" form-control form-control-sm"
                  name="r"
                  onChange={this.handleColorInputChange}
                  value={this.props.color.r}
                />
                <label htmlFor="r">R</label>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  className=" form-control form-control-sm"
                  name="g"
                  onChange={this.handleColorInputChange}
                  value={this.props.color.g}
                />
                <label htmlFor="g">G</label>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  className=" form-control form-control-sm"
                  name="b"
                  onChange={this.handleColorInputChange}
                  value={this.props.color.b}
                />
                <label htmlFor="b">B</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ColorPicker;
