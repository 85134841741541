import React from "react";

import { connect } from "react-redux";

import { Modal, Button } from "antd";
import { resetError } from "../../redux/actions/error";

class ErrorHandler extends React.Component {
  handleReport = (err) => {
    // this.setState({ show: false })
    this.props.dispatch(resetError());
  };

  handleCancel = () => {
    this.props.dispatch(resetError());
  };

  render() {
    return <></>;
    // return <Modal
    //     open={this.props.error.show}
    //     title="Error"
    //     onOk={this.handleReport}
    //     onCancel={this.handleCancel}
    //     footer={[
    //         <Button key="back" onClick={this.handleCancel}>
    //             Return
    //   </Button>,
    //         <Button key="submit" type="primary" loading={false} onClick={this.handleReport}>
    //             Report Error
    //   </Button>
    //     ]}
    // >
    //     <p>{this.props.error.message}</p>
    // </Modal>
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.error,
  };
};

export default connect(mapStateToProps)(ErrorHandler);
