import React from "react";
import Promotev2 from "../Promotev2";
import ResponsiveMobileTab from "../../../../CommonComponents/ResponsiveMobileTab/ResponsiveMobileTab";

const Promote = () => {
  const menuItems = [
    {
      key: "promote",
      label: "Promote",
    },
  ];

  return (
    <div style={{ padding: "2rem" }}>
      <nav className="promote-nav">
        <ul
          style={{
            listStyle: "none",
            // padding: "2rem 3rem 0rem",
            padding: "0",
            paddingBottom: "1rem",
            margin: 0,
            display: "flex",
          }}
        >
          <ResponsiveMobileTab
            menuItems={menuItems}
            param={"promote"}
            primaryColor="var(--primary-background)"
            tabIndex={0}
          />
        </ul>
      </nav>

      <p>
        Create a post for social media. Download to save and share. Use
        Onboard/Option #4 to generate a custom QR code providing direct group
        access.
      </p>
      <Promotev2 />
    </div>
  );
};

export default Promote;
