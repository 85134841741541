const createAppStore = (set) => ({
  sidenavExpanded: false,
  setSidenavExpanded: (expanded) =>
    set((state) => ({
      app: {
        ...state.app,
        sidenavExpanded: expanded,
      },
    })),
});

export default createAppStore;
