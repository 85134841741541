import moment from "moment";
import { DatePicker } from "antd";
import React from "react";
import { connect } from "react-redux";
import {
  getImages,
  uploadImageSizes,
} from "../../../services/firebaseService/endPoints/admin/gallery";
import ImageViewer from "../../clubAdmin/gallery/ImageViewer";
import { db } from "../../../services/firebaseService/connection";
import { Gallery } from "react-grid-gallery";
import LoadingModal from "../../commons/LoadingModal";
import { switchCollapseHorizontal } from "../../../helperFunctions/util";
import UploadConfirmationModal from "./UploadConfirmationModal";
import { collection, doc, writeBatch } from "@firebase/firestore";

import imageCompression from "browser-image-compression";
import { setGettingStartedItemStatus } from "../../../redux/actions/adminData";
import {
  GUIDE_SETUP,
  TOPIC_UPLOAD_PHOTOS,
} from "../../../constants/gettingStarted";
import logger from "../../../utils/logger";
import dayjs from "dayjs";

const { MonthPicker } = DatePicker;

const max_file_count = 100;

export class MonthRangePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      start: null,
      end: null,
      endOpen: false,
    };
  }

  handleChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  handleStartChange = (value) => {
    if (value) {
      this.handleChange("start", { month: value.month(), year: value.year() });
    } else {
      this.props.onChange(null, null);
    }
  };

  handleEndChange = (value) => {
    if (value) {
      this.handleChange("end", { month: value.month(), year: value.year() });
    } else {
      this.props.onChange(null, null);
    }
  };

  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({
        endOpen: true,
      });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({
      endOpen: open,
    });
  };

  componentDidUpdate() {
    const { start, end } = this.state;

    if (start !== null && end !== null) {
      this.props.onChange(start, end);
      this.setState({
        start: null,
        end: null,
      });
    }
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          // border: "1px solid #bfbfbf",
          gap: "4px",
          borderRadius: 5,
          margin: "5px 15px 50px",
        }}
      >
        <MonthPicker
          placeholder="Start"
          size="default"
          onChange={this.handleStartChange}
          onOpenChange={this.handleStartOpenChange}
        />
        <MonthPicker
          placeholder="End"
          size="default"
          open={this.state.endOpen}
          onChange={this.handleEndChange}
          onOpenChange={this.handleEndOpenChange}
        />
      </div>
    );
  }
}

class AdminGallery extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = null;
    this.state = {
      imageData: [],
      filteredData: [],
      fullViewImage: null,
      dateRange: {
        start: null,
        end: null,
      },
      files: [],
      showUploadModal: false,
      loading: false,
      isUploading: false,
      progress: 0,
    };
  }

  handleImageClick = (data) => {
    this.setState({ fullViewImage: data });
  };

  showNext = () => {
    let nextImageIndex = this.state.filteredData
      .map((image, index) => {
        if (image.src === this.state.fullViewImage.src) {
          return index + 1;
        }
        return null;
      })
      .filter((index) => index !== null)[0];

    this.setState({
      fullViewImage:
        this.state.filteredData[
          nextImageIndex === this.state.filteredData.length ? 0 : nextImageIndex
        ],
    });
  };

  showPrev = () => {
    let prevImageIndex = this.state.filteredData
      .map((image, index) => {
        if (image.src === this.state.fullViewImage.src) {
          return index - 1;
        }
        return null;
      })
      .filter((index) => index !== null)[0];

    this.setState({
      fullViewImage:
        this.state.filteredData[
          prevImageIndex < 0
            ? this.state.filteredData.length - 1
            : prevImageIndex
        ],
    });
  };

  handleKeyPress = (e) => {
    let key = e.keyCode;
    switch (key) {
      case 37:
        this.showPrev();
        break;
      case 39:
        this.showNext();
        break;
      default:
        return;
    }
  };

  renderImages = () => {
    const images = this.state.filteredData.sort((data1, data2) => {
      const date1 = data1.uploadDate || 0;
      const date2 = data2.uploadDate || 0;

      return date2 - date1;
    });

    const imagesFinal = images.map((img) => ({
      src: img.imageUrl,
      width: img.width,
      height: img.height,
      tags: [
        {
          value: dayjs.unix(img.uploadDate).format("Do MMM, YYYY"),
          title: dayjs.unix(img.uploadDate).format("Do MMM, YYYY"),
        },
      ],
    }));

    return (
      <Gallery
        rowHeight={240}
        images={imagesFinal}
        enableImageSelection={false}
        onClick={(_, image) => {
          this.handleImageClick(image);
        }}
      />
    );
  };

  handleReturn = () => {
    this.setState({
      fullViewImage: null,
    });
  };

  handleMonthRangeChange = (start, end) => {
    let data = this.state.filteredData;

    if (start && end) {
      data = data.filter((data) => {
        let date = new Date(data.uploadDate * 1000);
        let month = date.getMonth();
        let year = date.getFullYear();

        let totalMonths = year * 12 + month;

        return (
          totalMonths >= start.year * 12 + start.month &&
          totalMonths <= end.year * 12 + end.month
        );
      });

      this.setState({
        filteredData: data,
        dateRange: {
          start,
          end,
        },
      });
    } else {
      data = this.state.imageData;
      this.setState({
        filteredData: data,
        dateRange: {
          start: null,
          end: null,
        },
      });
    }
  };

  handleUploadButtonClick = () => {
    this.inputRef.click();
  };

  handleUpload = async (e) => {
    let { files } = e.target;
    let readFiles = [];

    files = Array.from(files);

    if (files.length > max_file_count) {
      files = files.slice(0, max_file_count);
    }

    let event = new Event("loaddone");
    for (let file of files) {
      let reader = new FileReader();

      reader.addEventListener("loadend", (e) => {
        readFiles.push({ file: file, data: reader.result });
        if (readFiles.length === files.length) {
          document.dispatchEvent(event);
        }
      });
      reader.readAsDataURL(file);
    }

    document.addEventListener("loaddone", () => {
      this.setState({ files: readFiles, showUploadModal: true });
    });
  };

  handleUploadImages = async () => {
    this.setState({
      isUploading: true,
    });
    let path = `/${this.props.adminData.data.groupName}/gallery`;
    let uploadPromises = [];

    const largeImgOptions = {
      maxSizeMB: 2.5,
      maxWidthOrHeight: 2560,
      useWebWorker: true,
      alwaysKeepResolution: true,
      preserveExif: true,
    };

    const smallImgOptions = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 2560,
      useWebWorker: true,
      alwaysKeepResolution: true,
      preserveExif: true,
    };

    for (var i = 0; i < this.state.files.length; i++) {
      await new Promise((done) => setTimeout(() => done(), 10));
      let file = this.state.files[i];
      let originalImage = file.file;

      let lgCompressedImage = await imageCompression(
        originalImage,
        largeImgOptions
      );
      let smCompressedImage = await imageCompression(
        originalImage,
        smallImgOptions
      );

      uploadPromises.push(
        uploadImageSizes(
          smCompressedImage,
          lgCompressedImage,
          `${path}/${moment().valueOf()}`,
          moment().unix(),
          file.file.lastModified || moment().unix()
        )
      );

      let progressPercent = ((i + 1) * 100) / this.state.files.length;
      this.setState({
        progress: progressPercent,
      });
    }

    let batch = writeBatch(db);

    let res = await Promise.all(uploadPromises);

    let imagesDataPromises = res.map(({ downloadUrlSmall }) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          resolve({
            [downloadUrlSmall]: {
              width: img.width,
              height: img.height,
            },
          });
        };
        img.onerror = reject;
        img.src = downloadUrlSmall;
      });
    });

    let imagesData = (await Promise.all(imagesDataPromises)).reduce(
      (acc, val) => {
        return {
          ...acc,
          ...val,
        };
      },
      {}
    );

    res.forEach(
      ({ downloadUrlSmall, downloadUrlLarge, imageDate, timestamp }) => {
        let dimensions = imagesData[downloadUrlSmall];

        let data = {
          favBy: [],
          imageUrl: downloadUrlSmall,
          imageUrlLg: downloadUrlLarge,
          updatedOn: moment().unix(),
          uploadDate: timestamp,
          uploaderName: this.props.user.profile.displayName,
          userDocId: this.props.user.profile.uid,
          dimensions: dimensions || {},
        };

        if (imageDate) {
          data.imageDate = moment(imageDate).unix();
        }

        let docRef = doc(
          collection(db, "gallery", this.props.currentGroup, "images")
        );

        batch.set(docRef, data);
      }
    );

    batch
      .commit()
      .then(() => {
        this.fetchImages();
      })
      .catch((err) => {
        logger.error(err);
      });
  };

  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    return (
      <>
        {!!this.state.fullViewImage ? (
          <div
            className="mx-auto"
            style={{
              width: "95%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              overflow: "scroll",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: 20,
              }}
            >
              <div
                style={{
                  height: 25,
                  width: 25,
                  borderLeft: "2px solid #000000",
                  borderBottom: "2px solid #000000",
                  transform: "rotate( 45deg )",
                  marginRight: 20,
                  cursor: "pointer",
                }}
                onClick={this.showPrev}
              ></div>
            </div>
            <ImageViewer
              image={this.state.fullViewImage}
              onReturn={this.handleReturn}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: 20,
              }}
            >
              <div
                style={{
                  height: 25,
                  width: 25,
                  borderTop: "2px solid #000000",
                  borderRight: "2px solid #000000",
                  transform: "rotate( 45deg )",
                  marginRight: 20,
                  cursor: "pointer",
                }}
                onClick={this.showNext}
              ></div>
            </div>
          </div>
        ) : (
          <div className="col">
            <div style={{ padding: "30px 16px" }}>
              <ul
                className="nav nav-tabs"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <li
                  className="nav-item"
                  style={{
                    fontSize: 22,
                    fontWeight: "bold",
                    borderBottom: `4px solid ${primaryColor}`,
                    color: "black",
                  }}
                >
                  <a>Gallery</a>
                </li>
                <li
                  className="ml-auto align-items-center collapsible-horizontal tab-menu"
                  id="gallery-utilities"
                >
                  <a
                    class="icon"
                    style={{ textAlign: "end", marginTop: 5, fontSize: 18 }}
                    onClick={() =>
                      switchCollapseHorizontal("gallery-utilities")
                    }
                  >
                    <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                  </a>
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-sm mr-1 default-text-color"
                      htmlFor="uploader"
                      style={{
                        backgroundColor: primaryColor,
                        border: `1px solid ${primaryColor}`,
                        height: "fit-content",
                        borderRadius: 5,
                      }}
                      onClick={this.handleUploadButtonClick}
                    >
                      <span style={{ fontWeight: "bold" }}>Upload</span>
                    </button>
                  </div>
                  <div className="show-for-mobile">
                    <div className="menu-list">
                      <div
                        htmlFor="uploader"
                        style={{
                          backgroundColor: "#ffffff",
                          padding: "5px 10px",
                          color: "#000000",
                        }}
                        onClick={this.handleUploadButtonClick}
                      >
                        <span>Upload</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              <UploadConfirmationModal
                showUploadModal={this.state.showUploadModal}
                files={this.state.files}
                isUploading={this.state.isUploading}
                progress={this.state.progress}
                removeImage={(index) => {
                  let files = this.state.files;
                  files = files.splice(index, 1);
                  this.setState({ files });
                }}
                onCancel={() => {
                  this.setState({
                    files: [],
                    showUploadModal: false,
                  });
                }}
                onUploadImages={
                  this.state.isUploading ? () => {} : this.handleUploadImages
                }
              />
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div className="col">
                  <p>
                    Upload up to 100 photos at a time (try fewer if slow
                    connection). Images compressed to max 2.5MB. Video support
                    coming soon.
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MonthRangePicker onChange={this.handleMonthRangeChange} />
                <div>
                  <input
                    ref={(ref) => {
                      this.inputRef = ref;
                    }}
                    type="file"
                    multiple
                    accept="image/*"
                    style={{ height: 0, width: 0 }}
                    onChange={this.handleUpload}
                    onBlur={() => {
                      this.inputRef.blur();
                    }}
                  />
                </div>
              </div>
              <div>
                {this.state.loading ? (
                  <LoadingModal loading={this.state.loading} />
                ) : (
                  this.renderImages()
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  fetchImages = () => {
    this.setState({ loading: true });

    getImages(this.props.currentGroup).then((images) => {
      if (images?.length > 0) {
        this.props.dispatch(
          setGettingStartedItemStatus(
            this.props.currentGroup,
            GUIDE_SETUP,
            TOPIC_UPLOAD_PHOTOS,
            true
          )
        );
      }

      this.setState({
        imageData: images,
        filteredData: images,
        files: [],
        showUploadModal: false,
        loading: false,
        isUploading: false,
      });
    });
  };

  componentDidMount = () => {
    this.fetchImages();
    if (this.state.fullViewImage !== null) {
      window.addEventListener("keydown", this.handleKeyPress);
    } else {
      window.addEventListener("keydown", () => {});
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.currentGroup !== prevProps.currentGroup) {
      this.fetchImages();
    }
  };
}

const mapStateToProps = (state) => {
  let currentGroup = state.adminData.currentGroup;
  return {
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    currentGroup,
    user: state.user,
  };
};

export default connect(mapStateToProps)(AdminGallery);
