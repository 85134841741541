import { Avatar, Table } from "antd";
import React from "react";
import { afGetCountries } from "../../../services/apiFootballService/endPoints/country";
import {
  fetchLeagues,
  fetchPlayersData,
} from "../../../services/apiFootballService/endPoints/players";
import { afGetSeasons } from "../../../services/apiFootballService/endPoints/seasons";
import { FSelect } from "../../commons/formFields/FSelect";

class Data extends React.Component {
  state = {
    tableData: [],
    country: "",
    league: "",
    season: "",
    countries: [],
    leagues: [],
    seasons: [],
    isLoading: false,
  };

  handleAPIParamChange = (e) => {
    this.setState(
      (_) => {
        let updateObject = {
          [e.target.name]: e.target.value,
        };
        if (["country", "season"].includes(e.target.name)) {
          updateObject.league = "";
        }
        return updateObject;
      },
      () => {
        if (
          ["country", "season"].includes(e.target.name) &&
          this.state.country &&
          this.state.season
        ) {
          fetchLeagues(this.state.country, this.state.season).then(
            (leagues) => {
              this.setState({
                leagues,
              });
            }
          );
        }
        if (this.state.country && this.state.season && this.state.league) {
          this.setState({
            isLoading: true,
          });
          fetchPlayersData(
            this.state.country,
            this.state.season,
            this.state.league
          ).then((data) => {
            this.setState({
              tableData: data,
              isLoading: false,
            });
          });
        }
      }
    );
  };

  componentDidMount() {
    Promise.all([afGetCountries(), afGetSeasons()]).then(
      ([countries, seasons]) => {
        this.setState({
          countries: countries.map((country) => country.name),
          seasons,
        });
      }
    );
  }

  render() {
    const columns = [
      {
        title: "Club Name",
        key: "clubName",
        className: "header-white",
        dataIndex: "clubName",
        render: (data, record) => {
          return (
            <div className="d-flex">
              {record.logo ? (
                <Avatar size="large" src={record.logo} />
              ) : (
                <Avatar size="large" style={{ backgroundColor: "#ff4040" }}>
                  {`${data}`.charAt(0).toUpperCase()}
                </Avatar>
              )}
              <span style={{ marginLeft: 30, fontWeight: "bold" }}>{data}</span>
            </div>
          );
        },
      },
      {
        title: "Id",
        key: "clubId",
        className: "header-white",
        dataIndex: "clubId",
      },
    ];
    const rootEl = document.querySelector(":root");
    rootEl.style.setProperty("--main-color", "#000000");
    return (
      <div className="container">
        <div
          style={{
            marginLeft: "16px",
            gap: "24px",
            backgroundColor: "white",
            borderRadius: "12px",
            padding: "1rem 2rem",
            marginBottom: "24px",
            display: "flex",
            marginTop: "24px",
          }}
        >
          <div>
            <FSelect
              value={this.state.country}
              onChange={this.handleAPIParamChange}
              dataList={this.state.countries}
              dataOnlyValue={true}
              style={{
                color: "#ffffff",
                fontSize: 16,
                width: "200px",
              }}
              name="country"
              display="my-row"
              placeholder="Country"
              tabIndex="2"
            />
          </div>
          <div>
            <FSelect
              value={this.state.season}
              onChange={this.handleAPIParamChange}
              dataList={this.state.seasons}
              dataOnlyValue={true}
              style={{
                color: "#ffffff",
                fontSize: 16,
                width: "200px",
              }}
              name="season"
              display="my-row"
              placeholder="Season"
              tabIndex="2"
            />
          </div>
          <div>
            <FSelect
              value={this.state.league}
              onChange={this.handleAPIParamChange}
              dataList={this.state.leagues}
              dataKey="league_id"
              dataValue="name"
              style={{
                color: "#ffffff",
                fontSize: 16,
                width: "200px",
              }}
              name="league"
              display="my-row"
              placeholder="League"
              tabIndex="2"
            />
          </div>
        </div>

        <Table
          bordered
          loading={this.state.isLoading}
          className={`table-backdrop mt-3`}
          columns={columns}
          dataSource={this.state.tableData}
          scroll={{ x: "max-content" }}
          rowKey={(record) => record.clubId}
          defaultExpandAllRows={true}
          expandedRowRender={(record) => {
            const childCols = [
              {
                title: "Name",
                key: "playerName",
                className: "header-white",
                dataIndex: "playerName",
                render: (data, rec) => {
                  return (
                    <div className="d-flex">
                      {rec.photo ? (
                        <Avatar size="large" src={rec.photo} />
                      ) : (
                        <Avatar
                          size="large"
                          style={{ backgroundColor: "#ff4040" }}
                        >
                          {`${data}`.charAt(0).toUpperCase()}
                        </Avatar>
                      )}
                      <span style={{ marginLeft: 30, fontWeight: "bold" }}>
                        {data}
                      </span>
                    </div>
                  );
                },
              },
              {
                title: "ID",
                key: "playerId",
                className: "header-white",
                dataIndex: "playerId",
              },
            ];

            let data = record.players.map((child) => {
              return {
                ...child,
              };
            });

            return (
              <Table
                bordered
                columns={childCols}
                className="table-backdrop"
                bodyStyle={{ backgroundColor: "#ffffff" }}
                dataSource={data}
              />
            );
          }}
          // size="small"
          bodyStyle={{
            fontSize: window.screen.width > 500 ? "18px" : "12px",
            backgroundColor: "#ffffff",
            // boxShadow: "0px 3px 10px 1px",
          }}
        />
      </div>
    );
  }
}

export default Data;
