import { Checkbox, Spin } from "antd";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { connect } from "react-redux";
import ImageUploadButton from "../website/ImageUploaderButton";
import { db } from "../../../services/firebaseService/connection";
import draftToHtml from "draftjs-to-html";
import { startUpdateUser } from "../../../redux/actions/user";
import { uploadImage } from "../../../services/firebaseService/endPoints/admin/members";
import moment from "moment";
import { doc, getDoc, updateDoc } from "@firebase/firestore";
import logger from "../../../utils/logger";

class FanPermission extends React.Component {
  state = {
    // fanJoin: false,
    groupOverview: EditorState.createEmpty(),
    groupPhoto: null,
    permissions: {
      matchPrediction: "",
      playerOfTheMatch: "",
      varPoll: "",
      gallery: "",
      groupChatStream: "",
    },
    republishWelcomeCards: false,
    isSaving: false,
  };

  changeCheck = (e) => {
    this.setState({
      [e.target.id]: e.target.checked,
    });
  };

  changeSelect = (name, value) => {
    let permissions = this.state.permissions;
    permissions[name] = value;
    this.setState({
      permissions,
    });
  };

  handleEditorStateChange = (state, name) => {
    this.setState({
      [name]: state,
    });
  };

  saveToFirebase = async () => {
    this.setState({
      isSaving: true,
    });
    // let fanJoin = this.state.fanJoin;
    let groupPermission = this.state.permissions;
    let groupOverview = draftToHtml(
      convertToRaw(this.state.groupOverview.getCurrentContent())
    );
    let groupPhoto = "";
    if (
      this.state.groupPhoto &&
      typeof this.state.groupPhoto === "object" &&
      !this.state.groupPhoto.url
    ) {
      let path = `${this.props.adminData.data.groupName}/fanCardGroupPhoto`;
      await uploadImage(this.state.groupPhoto, path)
        .then(({ downloadUrl }) => downloadUrl)
        .then((downloadUrl) => {
          groupPhoto = downloadUrl;
        });
    } else {
      groupPhoto = this.state.groupPhoto || "";
    }
    let updateObj = {
      // fanJoin,
      groupOverview,
      groupPermission,
      groupPhoto,
    };

    if (this.state.republishWelcomeCards) {
      updateObj.welcomeCardRepublishTimestampInSec = moment().unix();
    }
    await updateDoc(doc(db, "group", this.props.currentGroup), updateObj);

    this.props.dispatch(startUpdateUser());
    this.fetchData();
  };

  fetchData = () => {
    getDoc(doc(db, "group", this.props.currentGroup)).then((doc) => {
      if (doc.exists()) {
        let data = doc.data() || {};
        // let fanJoin = data.fanJoin;
        let permissions = data.groupPermission || {
          matchPrediction: "",
          playerOfTheMatch: "",
          varPoll: "",
          gallery: "",
          groupChatStrem: "",
        };
        let groupPhoto = data.groupPhoto || "";
        let content = htmlToDraft(data.groupOverview || "");
        let groupOverview = EditorState.createEmpty();
        if (content) {
          let contentState = ContentState.createFromBlockArray(
            content.contentBlocks
          );
          groupOverview = EditorState.createWithContent(contentState);
        }

        this.setState(
          {
            // fanJoin,
            permissions,
            groupPhoto,
            groupOverview,
            isSaving: false,
          },
          () => {
            logger.log(this.state);
          }
        );
      }
    });
  };

  render() {
    let primaryColor = "";
    let color = "#ffffff";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
      if ([r, g, b].filter((e) => `${e}` === "255").length === 3) {
        color = `rgb(0, 0, 0)`;
      }
    }
    return (
      <div className="pb-4" style={{ padding: "0rem 3rem", height: "100%" }}>
        <div
          className="mx-auto"
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <div className="row">
            <div className="col">
              <p>
                Groups can provide non-members limited app access. Enable via
                Onboard (Option #4) and access your web link / QR code there.
                Set fan permissions below.
              </p>
            </div>
          </div>
          <div
            className="mt-3 p-2"
            style={{
              backgroundColor: "#ffffff",
              boxShadow: "0px 0px 100px 5px #dddddd",
            }}
          >
            <span
              style={{
                fontSize: 19,
                fontWeight: "bold",
              }}
            >
              Create Fan Welcome Card
            </span>
            <p style={{ color: "#525252" }}>
              A welcome card will show for Fans in the home feed of the app.
              Think of this as an ad – be brief and call them to action. You can
              share a link to your registration webpage (Join Now!). The card
              appears until the fan clicks ‘x’ to close.
            </p>
            <p style={{ color: "#525252" }}>
              To create a welcome card first submit the form below then go to
              the Content / Welcome section of the dashboard.
            </p>
            <div className="row">
              <div className="col-md-2">
                <div
                  onClick={() => {
                    this.props.goToPath(`/admin/welcome`);
                  }}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    paddingTop: 5,
                    paddingBottom: 5,
                    cursor: "pointer",
                    borderRadius: 5,
                    backgroundColor: primaryColor,
                    color: color,
                  }}
                >
                  Create card
                </div>
              </div>
            </div>
          </div>

          <div
            className="mt-3 p-2"
            style={{
              backgroundColor: "#ffffff",
              boxShadow: "0px 0px 100px 5px #dddddd",
            }}
          >
            <div>
              <span style={{ fontSize: 18, fontWeight: "bold" }}>
                Permissions
              </span>
            </div>
            <div className=" mt-2">
              <span style={{ fontSize: 20, fontWeight: "bold" }}>
                Set by Feature
              </span>
            </div>
            <div className="row mt-2 flex align-items-center">
              <div className="col-md-3">Match Predictions (enter)</div>
              <div className="col-md-4">
                <Select
                  placeholder="Select option"
                  dataList={[
                    { key: "full", value: "Allow" },
                    { key: "none", value: "Restrict" },
                  ]}
                  value={this.state.permissions.matchPrediction}
                  onChange={(e) => {
                    this.changeSelect("matchPrediction", e.target.value);
                  }}
                  dataKey="key"
                  dataValue="value"
                />
              </div>
              <div className="col-md-5">
                Predictions card is visible to all. Set fan's ability to enter.
                Fans not eligible to win a prediction giveaway.
              </div>
            </div>

            <div className="row mt-1 flex align-items-center">
              <div className="col-md-3">Player of the Match (vote)</div>
              <div className="col-md-4">
                <Select
                  placeholder="Select option"
                  dataList={[
                    { key: "full", value: "Allow" },
                    { key: "none", value: "Restrict" },
                  ]}
                  value={this.state.permissions.playerOfTheMatch}
                  onChange={(e) => {
                    this.changeSelect("playerOfTheMatch", e.target.value);
                  }}
                  dataKey="key"
                  dataValue="value"
                />
              </div>
              <div className="col-md-5">
                Player of match is visible to all. Set fan’s ability to vote.
              </div>
            </div>

            <div className="row mt-1 flex align-items-center">
              <div className="col-md-3">VAR Poll (vote/comment)</div>
              <div className="col-md-4">
                <Select
                  placeholder="Select option"
                  dataList={[
                    { key: "full", value: "Allow" },
                    { key: "none", value: "Restrict" },
                  ]}
                  value={this.state.permissions.varPoll}
                  onChange={(e) => {
                    this.changeSelect("varPoll", e.target.value);
                  }}
                  dataKey="key"
                  dataValue="value"
                />
              </div>
              <div className="col-md-5">
                VAR Poll is visible to all. Sets fan’s ability to vote/comment.
              </div>
            </div>

            <div className="row mt-1 flex align-items-center">
              <div className="col-md-3">Gallery (Add/Download photos)</div>
              <div className="col-md-4">
                <Select
                  placeholder="Select option"
                  dataList={[
                    { key: "full", value: "Allow" },
                    { key: "none", value: "Restrict" },
                  ]}
                  value={this.state.permissions.gallery}
                  onChange={(e) => {
                    this.changeSelect("gallery", e.target.value);
                  }}
                  dataKey="key"
                  dataValue="value"
                />
              </div>
              <div className="col-md-5">
                Set permission for fans to upload photos to gallery or view only
                (no share/download).
              </div>
            </div>

            <div className="row mt-1 flex align-items-center">
              <div className="col-md-3">Chat Stream</div>
              <div className="col-md-4">
                <Select
                  placeholder="Select option"
                  dataList={[
                    { key: "full", value: "Full Access" },
                    { key: "partial", value: "React Only" },
                    { key: "none", value: "Restrict" },
                  ]}
                  value={this.state.permissions.groupChatStream}
                  onChange={(e) => {
                    this.changeSelect("groupChatStream", e.target.value);
                  }}
                  dataKey="key"
                  dataValue="value"
                />
              </div>
              <div className="col-md-5">
                Set fan’s permission to post, react only or fully restrict
                access. If able to post, group is responsible for monitoring
                behavior and adhering to terms of use.
              </div>
            </div>

            <div className=" mt-2">
              <span style={{ fontSize: 20, fontWeight: "bold" }}>
                Set per Item
              </span>
            </div>

            <div className="row flex align-items-center">
              <div className="col-md-3">Poll (view/answer)</div>
              <div className="col-md-4">
                <Restricted value="Set per poll via ‘Add Poll’" />
              </div>
              <div className="col-md-5">
                Polls restricted to members will not be visible to fans.
              </div>
            </div>

            <div className="row mt-1 flex align-items-center">
              <div className="col-md-3">Giveaway (View / Enter)</div>
              <div className="col-md-4">
                <Restricted value="Set per giveaway via 'Add Giveaway'" />
              </div>
              <div className="col-md-5">
                All giveaways are visible to fans. Sets fan’s ability to enter.
              </div>
            </div>

            <div className="row flex align-items-center mt-1">
              <div className="col-md-3">Content (View / RSVP)</div>
              <div className="col-md-4">
                <Restricted value="Set per news/update via 'Add Content'" />
              </div>
              <div className="col-md-5">
                Set each time you create a new piece of content (watch party,
                news, etc). Fans can only see the RSVP ‘count’, not member
                details.
              </div>
            </div>

            <div className="row flex align-items-center mt-1">
              <div className="col-md-3">Buy Group Tickets</div>
              <div className="col-md-4">
                <Restricted value="Set per ticket via 'Create Group Ticket'" />
              </div>
              <div className="col-md-5">
                Group tickets can be viewed by fans but only purchased if
                allowed by group.
              </div>
            </div>

            <div className="row flex align-items-center mt-1">
              <div className="col-md-3">Buy Individual Tickets</div>
              <div className="col-md-4">
                <Restricted value="Set per ticket by member" />
              </div>
              <div className="col-md-5">
                Members have the option (per ticket post) to offer ticket
                purchase to fans.
              </div>
            </div>

            <div className=" mt-2">
              <span style={{ fontSize: 20, fontWeight: "bold" }}>
                Set by Chant
              </span>
            </div>

            {/* <div className='row flex align-items-center'>
                            <div className='col-md-3'>
                                Global Chat Stream
                            </div>
                            <div className='col-md-4'>
                                <Restricted
                                    value="Restricted by Chant"
                                />
                            </div>
                            <div className='col-md-5'>
                                Fans are not allowed to access global chat streams.
                            </div>
                        </div> */}

            <div className="row flex align-items-center mt-1">
              <div className="col-md-3">Access Channels / DM</div>
              <div className="col-md-4">
                <Restricted value="Restricted by Chant" />
              </div>
              <div className="col-md-5">
                Fans are not allowed to access Channels or DMs.
              </div>
            </div>

            <div className="row flex align-items-center mt-1">
              <div className="col-md-3">View Membership card</div>
              <div className="col-md-4">
                <Restricted value="Restricted by Chant" />
              </div>
              <div className="col-md-5">
                Member cards are not offered for fans.
              </div>
            </div>

            <div className="row flex align-items-center mt-1">
              <div className="col-md-3">Profile (Create / Edit)</div>
              <div className="col-md-4">
                <Restricted value="Restricted by Chant" />
              </div>
              <div className="col-md-5">
                Fan profiles will not appear in the Member List. Fans can add a
                profile photo, birthday and favorite player/moment. They will
                not see ‘member since’. ‘Favorite photos’ will not be added to
                Gallery.
              </div>
            </div>

            <div className="row flex align-items-center mt-1">
              <div className="col-md-3">Cap Me / ‘Who’s Here’</div>
              <div className="col-md-4">
                <Restricted value="Restricted by Chant" />
              </div>
              <div className="col-md-5">
                Cap Me is allowed. Viewing ‘Who’s Here’ is restricted to
                members.
              </div>
            </div>
          </div>

          <div className="flex-center">
            <div className="p-2">
              <button
                style={{
                  backgroundColor: primaryColor,
                  borderRadius: 5,
                  border: "0px none",
                  boxShadow: "transparent 0px 0px 0px",
                  outline: "none",
                  padding: "5px 10px",
                  width: "200px",
                  color: "#ffffff",
                }}
                disabled={this.state.isSaving}
                onClick={this.saveToFirebase}
              >
                {this.state.isSaving ? (
                  <Spin size="small" spinning={true} />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentGroup !== this.props.currentGroup) {
      this.fetchData();
    }
  }
}

class Select extends React.Component {
  render() {
    return (
      <select
        className={`form-control form-control-sm`}
        style={{
          border: "1px solid",
          borderRadius: "3px",
          boxShadow: "0px 0px 1px 1px #cccccc",
        }}
        name={this.props.name}
        onChange={this.props.onChange}
        tabIndex={this.props.tabIndex || 0}
        value={this.props.value}
      >
        <option key="0" value={""}>
          {this.props.placeholder}
        </option>

        {this.props.dataList.length > 0 &&
          this.props.dataList.map((data) => {
            if (this.props.listBuilder) {
              return this.props.listBuilder(data);
            } else {
              return (
                <option
                  key={
                    this.props.dataOnlyValue ? data : data[this.props.dataKey]
                  }
                  value={
                    this.props.dataOnlyValue ? data : data[this.props.dataKey]
                  }
                >
                  {this.props.dataOnlyValue ? data : data[this.props.dataValue]}
                </option>
              );
            }
          })}
      </select>
    );
  }
}

class Restricted extends React.Component {
  render() {
    return (
      <div
        className={`form-control form-control-sm  col`}
        style={{
          border: "1px solid",
          borderRadius: "3px",
          boxShadow: "0px 0px 1px 1px #cccccc",
          backgroundColor: "#dddddd",
        }}
      >
        {this.props.value}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    currentGroup: state.adminData.currentGroup,
  };
};

export default connect(mapStateToProps)(FanPermission);
