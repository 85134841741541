import { Alert, Checkbox, DatePicker, Spin, Tabs } from "antd";
import React from "react";
import { connect } from "react-redux";
import { FInput } from "../../commons/formFields/FInput";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, EditorState } from "draft-js";
import ImageUploadButton from "../website/ImageUploaderButton";
import {
  fbGetGroupDetails,
  saveWelcomeCardDetails,
  sendBareNotification,
} from "../../../services/firebaseService/endPoints/admin/content";
import htmlToDraft from "html-to-draftjs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setGettingStartedItemStatus } from "../../../redux/actions/adminData";
import {
  GUIDE_ONBOARD,
  TOPIC_CREATE_WELCOME_CARD,
} from "../../../constants/gettingStarted";
import logger from "../../../utils/logger";
import dayjs from "dayjs";

const { TabPane } = Tabs;

const KEY_ORDER = {
  both: 0,
  members: 0,
  nonMembers: 1,
};
const CARD_TEMPLATE = {
  title: "",
  image: null,
  button: {
    title: "",
    url: "",
  },
  showEmail: false,
  republishCard: false,
  showCardUntilTimestamp: 0,
  welcomeCardRepublishTimestamp: dayjs().unix(),
  filledKeys: [],
  sendNotification: false,
};

const PageTabs = {
  members: "Members",
  nonMembers: "Non Members",
};

class Welcome extends React.Component {
  state = {
    welcomeCards: {
      key: ["members"],
      values: [
        {
          ...JSON.parse(JSON.stringify(CARD_TEMPLATE)),
          details: EditorState.createEmpty(),
        },
      ],
    },
    errorInForm: {
      isError: false,
      visible: false,
      message: "",
    },
    saving: false,
    currenTab: PageTabs.members,
  };

  handleInputChange = (value, key, index) => {
    let welcomeCards = this.state.welcomeCards;
    if (key.includes(".")) {
      let dataKeys = key.split(".");
      let finalPathValue = welcomeCards.values[index];
      for (let index = 0; index < dataKeys.length - 1; index++) {
        finalPathValue = finalPathValue[dataKeys[index]];
      }
      finalPathValue[dataKeys.pop()] = value;
    } else {
      welcomeCards.values[index][key] = value;
    }
    if (value) {
      welcomeCards.values[index].filledKeys = Array.from(
        new Set([...welcomeCards.values[index].filledKeys, key])
      );
    } else {
      if (welcomeCards.values[index].filledKeys.includes(key)) {
        welcomeCards.values[index].filledKeys.splice(
          welcomeCards.values[index].filledKeys.indexOf(key),
          1
        );
      }
    }
    this.setState({
      welcomeCards,
    });
  };

  handleEditorStateChange = (state, key, index) => {
    let welcomeCards = this.state.welcomeCards;
    welcomeCards.values[index][key] = state;
    this.setState(
      {
        welcomeCards,
      },
      () => {
        let details = state;
        if (details.getCurrentContent().getPlainText("").length > 0) {
          welcomeCards.values[index].filledKeys = Array.from(
            new Set([...welcomeCards.values[index].filledKeys, key])
          );
        } else {
          if (welcomeCards.values[index].filledKeys.includes(key)) {
            welcomeCards.values[index].filledKeys.splice(
              welcomeCards.values[index].filledKeys.indexOf(key),
              1
            );
          }
        }
      }
    );
  };
  handleDateChange = (name, value, index) => {
    let welcomeCards = this.state.welcomeCards;

    if (!Array.isArray(value)) value = dayjs(value).isValid() ? value : null;
    let hours = 0,
      minutes = 0,
      seconds = 59;

    if (value) {
      welcomeCards.values[index][name] = dayjs(value)
        .set({
          h: hours,
          m: minutes,
          second: seconds,
        })
        .unix();
    } else {
      welcomeCards.values[index][name] = null;
    }
    this.setState({
      welcomeCards,
    });
  };

  handleSubmit = () => {
    this.setState({
      saving: true,
    });
    let welcomeCards = this.state.welcomeCards;
    for (let index = 0; index < welcomeCards.key.length; index++) {
      let value = welcomeCards.values[index];

      if (
        value.filledKeys.length > 0 &&
        (!value.title || !value.details.getCurrentContent().getPlainText(""))
      ) {
        this.setState({
          errorInForm: {
            isError: true,
            visible: true,
            message: "Please fill the required fields.",
          },
          saving: false,
        });
        window.scrollTo(0, 0);
        return;
      }
    }

    let updateData = {};
    let index = 0;
    for (let key of welcomeCards.key) {
      let value = welcomeCards.values[index];
      if (value.filledKeys.length > 0) {
        updateData[key] = value;
      }
      index++;
    }

    saveWelcomeCardDetails(updateData, this.props.currentGroup).then(() => {
      for (let key of Object.keys(updateData)) {
        let value = updateData[key];
        let details = ContentState.createFromBlockArray(
          htmlToDraft(value.details || "<p></p>")
        );
        if (value.sendNotification) {
          sendBareNotification(
            `${this.props.adminData.data.groupName} ${value.title}`,
            details.getPlainText(""),
            this.props.currentGroup,
            null,
            key === "nonMembers" || key === "both",
            true,
            key === "members" || key === "both"
          );
        }
      }
      this.getWelcomeCardDetails();

      toast.success("Saved", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };

  createInitialState = () => {
    let regCodeRole = this.props.adminData.data.regCodeRole;
    if (regCodeRole === "fan") {
      let welcomeCards = this.state.welcomeCards;
      welcomeCards.key = ["members", "nonMembers"];
      welcomeCards.values.push({
        ...JSON.parse(JSON.stringify(CARD_TEMPLATE)),
        details: EditorState.createEmpty(),
      });
    }
  };

  getWelcomeCardDetails = async () => {
    let welcomeCardDetails = await fbGetGroupDetails(
      this.props.currentGroup
    ).then((data) => data.welcomeCardDetails);
    if (welcomeCardDetails) {
      this.props.dispatch(
        setGettingStartedItemStatus(
          this.props.currentGroup,
          GUIDE_ONBOARD,
          TOPIC_CREATE_WELCOME_CARD,
          true
        )
      );

      let welcomeCards = {
        key: [],
        values: [],
      };
      let keys = Object.keys(welcomeCardDetails);
      if (keys.includes("both")) {
        welcomeCards.key = ["both"];
      } else {
        let regCodeRole = this.props.adminData.data.regCodeRole;
        if (regCodeRole === "fan") {
          welcomeCards.key = ["members", "nonMembers"];
        } else {
          welcomeCards.key = ["members"];
        }
      }

      welcomeCards.values = Array.from(
        { length: welcomeCards.key.length },
        () => {
          return {
            ...JSON.parse(JSON.stringify(CARD_TEMPLATE)),
            details: EditorState.createEmpty(),
          };
        }
      );
      let index = 0;
      for (var key of Object.keys(welcomeCardDetails)) {
        let value = welcomeCardDetails[key];
        value.filledKeys = ["title", "details"];
        if (value.details) {
          try {
            let blockArray = htmlToDraft(value.details || "<p></p>");
            if (blockArray) {
              let contentState = ContentState.createFromBlockArray(blockArray);
              let editorState = EditorState.createWithContent(contentState);
              value.details = editorState;
            } else {
              value.details = EditorState.createEmpty();
            }
          } catch (e) {
            logger.error(e);
          }
        } else {
          value.details = EditorState.createEmpty();
        }
        if (value.image) {
          value.image = `https://firebasestorage.googleapis.com/v0/b/chant2019.appspot.com/o/${this.props.currentGroup}%2FwelcomeCard%2Fimages%2F${index}?alt=media`;
        } else {
          value.image = null;
        }
        if (value.button) {
          if (value.button.title) {
            value.filledKeys.push("button.title");
          }
          if (value.button.url) {
            value.filledKeys.push("button.url");
          }
        }
        index++;
        welcomeCards.values[KEY_ORDER[key]] = value;
      }
      this.setState({
        welcomeCards,
        saving: false,
      });
    }
  };

  componentDidMount() {
    this.createInitialState();
    this.getWelcomeCardDetails();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.currentGroup !== this.props.currentGroup) {
      this.createInitialState();
      this.getWelcomeCardDetails();
    }
  }

  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <div className="col">
        <ToastContainer />
        {this.state.errorInForm.isError && this.state.errorInForm.visible && (
          <div className="mx-5 mt-2">
            <Alert
              message="Error"
              description={this.state.errorInForm.message}
              type="error"
              closable
              // className="mb-3"
              afterClose={this.handleErrorMessageClose}
            />
          </div>
        )}
        <div className="md-5 border-box" style={{ padding: "30px 16px" }}>
          <ul className="nav nav-tabs">
            <li
              className="nav-item"
              style={{
                fontSize: 22,
                fontWeight: "bold",
                borderBottom: `4px solid ${primaryColor}`,
                color: "black",
              }}
            >
              <a>Welcome</a>
            </li>
          </ul>
          <p>
            Show a custom ‘welcome’ card in the home feed. Set to show for
            members, fans or both. Provide an optional web link. Card can be
            closed by members (click on ‘x’). Republish the card as needed. Also
            note that any chat post can be pinned to the home feed as an
            ‘announcement’.
          </p>
          <div
            className="border rounded p-3 px-5 mt-4"
            style={
              {
                // boxShadow: "0px 3px 10px 1px",
              }
            }
          >
            <Tabs
              onChange={(key) => {
                this.setState({
                  currenTab: PageTabs[key],
                });
              }}
              type="card"
            >
              {Object.keys(PageTabs)
                .filter((key) =>
                  this.props.adminData.data.regCodeRole !== "fan" &&
                  key === "nonMembers"
                    ? false
                    : true
                )
                .map((key) => {
                  return <TabPane tab={PageTabs[key]} key={key}></TabPane>;
                })}
            </Tabs>
            {this.state.currenTab === PageTabs.members &&
              this.drawCardLayout("Members", 0)}
            {this.state.currenTab === PageTabs.nonMembers &&
              this.props.adminData.data.regCodeRole === "fan" && (
                <>
                  <div className="page-break mt-2"></div>
                  <div
                    className=" mt-5"
                    style={{ display: "flex", flexWrap: "nowrap", gap: 20 }}
                  >
                    <div>
                      <label>
                        Show above details to members and non-members?
                      </label>
                    </div>
                    <Checkbox
                      style={{ marginTop: "1px" }}
                      checked={this.state.welcomeCards.key[0] === "both"}
                      onChange={(e) => {
                        let welcomeCards = this.state.welcomeCards;
                        if (e.target.checked) {
                          welcomeCards.key = ["both"];
                          this.setState({
                            welcomeCards,
                          });
                        } else {
                          welcomeCards.key = ["members", "nonMembers"];
                          if (!welcomeCards.values[1]) {
                            welcomeCards.values.push({
                              ...JSON.parse(JSON.stringify(CARD_TEMPLATE)),
                              details: EditorState.createEmpty(),
                            });
                          }
                          this.setState({
                            welcomeCards,
                          });
                        }
                      }}
                      value="secondary"
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      tabIndex="5"
                    />
                  </div>
                  {this.state.welcomeCards.key.includes("nonMembers") &&
                    this.drawCardLayout("Non-Members (Fans)", 1)}
                </>
              )}
            <div
              style={{ display: "flex", flexWrap: "wrap" }}
              className=" justify-content-center"
            >
              <button
                className="btn btn-success"
                tabIndex="14"
                type="success"
                style={{
                  backgroundColor: "var(--primary-background)",
                  color: "var(--primary-text-color)",
                  borderColor: primaryColor,
                  padding: "3px 30px",
                }}
                onClick={this.handleSubmit}
              >
                {this.state.saving ? (
                  <Spin size="small" spinning={true} />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  drawCardLayout = (title, index) => {
    let showCardUntilTimestamp = "";
    if (this.state.welcomeCards.values[index].showCardUntilTimestamp) {
      showCardUntilTimestamp = dayjs.unix(
        this.state.welcomeCards.values[index].showCardUntilTimestamp
      );
    }
    return (
      <>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div className="col">
            <span
              style={{
                paddingLeft: -8,
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              {title}
            </span>
          </div>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div className="col-md-8">
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="form-group col">
                <FInput
                  value={this.state.welcomeCards.values[index].title}
                  label="Card Title"
                  onChange={(e) =>
                    this.handleInputChange(e.target.value, "title", index)
                  }
                  name="title"
                  display="row"
                  placeholder="Examples: Welcome, Renew Membership, Update, Important, etc"
                  tabIndex="2"
                  maxLength={25}
                  required
                />
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="col-md-4">
                <label>
                  Details
                  <span style={{ color: "#ff0000" }}>*</span>
                </label>
              </div>
              <div
                style={{
                  border: "1px solid #ced4da",
                  borderRadius: 5,
                  width: "66%",
                }}
              >
                <Editor
                  editorState={
                    typeof this.state.welcomeCards.values[index].details ===
                    "object"
                      ? this.state.welcomeCards.values[index].details
                      : undefined
                  }
                  onEditorStateChange={(state) =>
                    this.handleEditorStateChange(state, "details", index)
                  }
                  toolbar={{
                    options: ["inline", "list", "link"],
                    inline: {
                      inDropdown: false,
                      options: ["bold", "italic", "underline"],
                    },
                    list: {
                      inDropdown: false,
                      options: ["unordered", "ordered"],
                    },
                  }}
                  stripPastedStyles={true}
                  tabIndex="2"
                />
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }} className="mt-4">
              <div className="col-md-4">Image</div>
              <div className="form-group">
                <ImageUploadButton
                  text="Upload"
                  images={[this.state.welcomeCards.values[index].image]}
                  onSave={(images) => {
                    let image = images[0];
                    let welcomeCards = this.state.welcomeCards;
                    welcomeCards.values[index].image = image;
                    this.setState({
                      welcomeCards,
                    });
                  }}
                />
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }} className="mt-3">
              <div className="col-md-4">
                <label>Show Button / Web URL?</label>
              </div>
              <div style={{ width: "10rem" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "24px",
                  }}
                >
                  <input
                    className={`form-control form-control-sm col-md-4`}
                    value={this.state.welcomeCards.values[index].button.title}
                    onChange={(e) =>
                      this.handleInputChange(
                        e.target.value,
                        "button.title",
                        index
                      )
                    }
                    name="button.title"
                    display="row"
                    placeholder="Enter button name"
                    tabIndex="2"
                    maxLength={25}
                    required
                  />
                  <input
                    className={`form-control form-control-sm col-md-7`}
                    value={this.state.welcomeCards.values[index].button.url}
                    onChange={(e) =>
                      this.handleInputChange(
                        e.target.value,
                        "button.url",
                        index
                      )
                    }
                    name="button.url"
                    display="row"
                    placeholder="Enter detination url"
                    tabIndex="2"
                    required
                  />
                </div>
              </div>
            </div>
            <div
              style={{ display: "flex", marginBottom: "16px" }}
              className=" mt-4"
            >
              <div className="col-md-4">
                <label>Show member email they used to register?</label>
              </div>
              <div className="col-md-8">
                <div
                  style={{
                    display: "flex",
                    alignItems: "start",
                    gap: 20,
                  }}
                >
                  <Checkbox
                    style={{ marginTop: "1px" }}
                    checked={this.state.welcomeCards.values[index].showEmail}
                    onChange={(e) => {
                      let welcomeCards = this.state.welcomeCards;
                      welcomeCards.values[index].showEmail = e.target.checked;
                      this.setState({
                        welcomeCards,
                      });
                    }}
                    value="secondary"
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    tabIndex="5"
                  />
                  <div>
                    <p style={{ fontSize: 13 }}>
                      Can be a helpful reminder during renewal season.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div className="form-group col-12">
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <label className="col-md-4">Show card until</label>
                  <div className="col-md-8">
                    <div>
                      <DatePicker
                        tabIndex="2"
                        value={showCardUntilTimestamp}
                        onChange={(value) => {
                          this.handleDateChange(
                            "showCardUntilTimestamp",
                            value,
                            index
                          );
                        }}
                        disabledDate={(current) => {
                          return current && current < dayjs().startOf("day");
                        }}
                        className="form-control form-control-sm col-4"
                        size={10}
                      />
                      <div>
                        <p style={{ fontSize: 13 }}>
                          If no date select, will remain live for a member until
                          they click ‘x’ to close card.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }} className="mt-2">
              <div className="col-md-4">
                <label>Republish Card</label>
              </div>
              <div className="col-md-8">
                <div
                  style={{
                    display: "flex",
                    gap: 20,
                    flexWrap: "nowrap",
                    alignItems: "start",
                  }}
                >
                  <Checkbox
                    style={{ marginTop: "1px" }}
                    checked={
                      this.state.welcomeCards.values[index].republishCard
                    }
                    onChange={(e) => {
                      let welcomeCards = this.state.welcomeCards;
                      welcomeCards.values[index].republishCard =
                        e.target.checked;
                      if (e.target.checked) {
                        welcomeCards.values[
                          index
                        ].welcomeCardRepublishTimestamp = dayjs().unix();
                      }
                      this.setState({
                        welcomeCards,
                      });
                    }}
                    value="secondary"
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    tabIndex="5"
                  />
                  <div>
                    <p style={{ fontSize: 13 }}>
                      The card can be closed in the app by clicking ‘x’. You can
                      republish the card for all by checking this box and
                      saving. Note: this checkbox will appear unchecked each
                      time you open this page, allowing you to republish as
                      needed.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }} className="mt-2">
              <div className="col-md-4">
                <label>Send notification</label>
              </div>
              <div className="col-md-8">
                <div style={{ display: "flex", gap: 20, flexWrap: "nowrap" }}>
                  <Checkbox
                    style={{ marginTop: "1px" }}
                    checked={
                      this.state.welcomeCards.values[index].sendNotification
                    }
                    onChange={(e) => {
                      let welcomeCards = this.state.welcomeCards;
                      welcomeCards.values[index].sendNotification =
                        e.target.checked;
                      this.setState({
                        welcomeCards,
                      });
                    }}
                    value="secondary"
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    tabIndex="5"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    currentGroup: state.adminData.currentGroup,
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
  };
};

export default connect(mapStateToProps)(Welcome);
