import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { doc, setDoc } from "firebase/firestore";
import { Editor } from "react-draft-wysiwyg";

import React, { useEffect, useState } from "react";
import { db } from "../../../services/firebaseService/connection";
import htmlToDraft from "html-to-draftjs";
import {
  getWebsiteConfig,
  uploadImage,
} from "../../../services/firebaseService/endPoints/admin/website";
import { useSelector } from "react-redux";
import { selectCurrentGroupData } from "../../../redux/selectors/adminData";
import { Radio, Spin } from "antd";
import ImageUploadButton from "./ImageUploaderButton";
import { getPrimaryColor } from "../../../utils/helper";
import { postUpdateCDNUtil } from "../../../helperFunctions/util";

const WebsiteMembership = () => {
  const [membershipSummary, setMembershipSummary] = useState(
    EditorState.createEmpty()
  );
  const [registrationOption, setRegistrationOption] = useState("");
  const [imageFile, setImageFile] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  const adminData = useSelector(selectCurrentGroupData);

  const fontSize = undefined;

  const options = [
    {
      option: "No Registration",
      value: "no-registration",
      texts: [
        <p>
          Summary above will simply serve as a ‘welcome’. No form fields or
          ‘Join’ button. You will be required to manually pre-register person
          for Chant.
        </p>,
      ],
    },
    {
      option: "Name + Email fields",
      value: "name-email",
      texts: [
        <p>
          Registration is free. Visitor enters name/email and clicks ‘Join’.
          Automatically registers new member for Chant app.
        </p>,
      ],
    },
    {
      option: "Full Registration Form",
      value: "full-registration",
      texts: [
        <p>
          Offer a full member registration page with benefits, membership
          packages, t-shirt size. Collect dues and donations. Automatically
          registers new member for Chant app.
        </p>,
        <p>
          Complete the form under{" "}
          <a href="/admin/members/packages/setup">
            Website / Member Registration
          </a>
          . Requires ‘Stripe’ account if collecting dues or donations.
        </p>,
      ],
    },
  ];

  const handleRadioChange = (e) => {
    setRegistrationOption(e.target.value);
  };

  const saveToFirebase = () => {
    const save = async () => {
      try {
        setIsSaving(true);

        const storageDirectory = `${adminData.groupName}/website/appScreenshots`;

        let uploadedImage = "";
        if (imageFile && typeof imageFile === "object") {
          uploadedImage = await uploadImage(
            imageFile,
            `${storageDirectory}/${adminData.id}-app-screenshot.png`
          ).then(({ downloadUrl }) => downloadUrl);
        } else if (typeof imageFile === "string" && imageFile.length > 0) {
          uploadedImage = imageFile;
        }

        const about = {
          membershipSummary: draftToHtml(
            convertToRaw(membershipSummary.getCurrentContent())
          ),
          registrationOption,
        };

        let docRef = doc(db, "website", adminData.id);
        setDoc(
          docRef,
          { about, appScreenshot: uploadedImage },
          { merge: true }
        ).then(() => {
          fetchData();
          postUpdateCDNUtil(adminData.id);
          setIsSaving(false);
        });
      } catch (error) {
        setIsSaving(false);
      }
    };
    save();
  };

  const getState = (websiteConfig) => {
    if (websiteConfig && websiteConfig.about) {
      let membershipSummary = EditorState.createEmpty();

      if (websiteConfig.about.membershipSummary) {
        let membershipSummaryDraft = htmlToDraft(
          websiteConfig.about.membershipSummary
        );
        if (membershipSummaryDraft) {
          let contentState = ContentState.createFromBlockArray(
            membershipSummaryDraft.contentBlocks
          );
          let editorState = EditorState.createWithContent(contentState);
          membershipSummary = editorState;
        }
      }

      return {
        membershipSummary,
        // membershipSummaryImage: websiteConfig.about.membershipSummaryImage,
        registrationOption: websiteConfig.about.registrationOption,
      };
    }
    return {
      membershipSummary: EditorState.createEmpty(),
    };
  };

  const fetchData = () => {
    getWebsiteConfig(adminData.id).then((data) => {
      const refactoredData = getState(data);
      setImageFile(data?.appScreenshot);
      setMembershipSummary(refactoredData?.membershipSummary);
      setRegistrationOption(refactoredData?.registrationOption);
    });
  };

  useEffect(() => {
    if (adminData?.id) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminData.id]);

  let primaryColor = "";
  if (adminData.configuration && adminData.configuration.primaryColor) {
    primaryColor = getPrimaryColor(adminData.configuration.primaryColor);
  }

  return (
    <div
      className="border rounded"
      style={{
        width: "100%",
        backgroundColor: "#ffffff",
        // boxShadow: "0px 3px 10px 1px",
        marginTop: 30,
        padding: "15px 30px",
      }}
    >
      <div className="mx-auto" style={{ width: "90%" }}>
        <div className="d-flex">
          <div style={{ flex: 1 }}>
            <p style={{ marginBottom: 0, marginTop: 27 }}>Summary</p>
            <p style={{ fontSize: 12 }}>(1000 max characters)</p>
          </div>
          <div style={{ flex: 3 }}>
            <div>
              <div className="form-group col">
                <div>
                  <label></label>
                </div>
                <div style={{ border: "1px solid #ced4da", borderRadius: 5 }}>
                  <Editor
                    editorState={membershipSummary}
                    onEditorStateChange={(state) => setMembershipSummary(state)}
                    toolbar={{
                      options: ["inline", "list", "link", "fontSize"],
                      inline: {
                        inDropdown: false,
                        options: ["bold", "italic", "underline"],
                      },
                      fontSize: {
                        icon: fontSize,
                        options: [
                          8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72,
                          96,
                        ],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      list: {
                        inDropdown: false,
                        options: ["unordered", "ordered"],
                      },
                      link: {
                        inDropdown: false,
                        defaultTargetOption: "_self",
                        options: ["link", "unlink"],
                        linkCallback: undefined,
                      },
                    }}
                    stripPastedStyles={true}
                    tabIndex="2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div style={{ flex: 1 }}>
            <p style={{ marginBottom: 0 }}>Registration Form</p>
          </div>
          <div style={{ flex: 3 }}>
            <div className="form-group col">
              <Radio.Group
                onChange={handleRadioChange}
                value={registrationOption}
              >
                {options.map(({ option, value, texts }) => (
                  <Radio value={value} key={value}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
                      <div className="col-md-4">{option}</div>
                      <div className="col-m4-8 ml-4">{texts}</div>
                    </div>
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3" style={{ flex: 1 }}>
            <p style={{ marginBottom: 0 }}>App Screenshot</p>
          </div>
          <div className="col-md-9">
            <div className="row" style={{ paddingLeft: "8px" }}>
              <div
                className="col-md-5"
                style={{ flex: 3, paddingLeft: "15px" }}
              >
                <ImageUploadButton
                  text="Upload"
                  images={imageFile ? [imageFile] : []}
                  tooltip="If no image uploaded, default image will appear. Image dimensions are 800x450 (16:9 ratio)"
                  onSave={(images) => setImageFile(images[0])}
                />
              </div>
              <div className="col-md-7">
                <p style={{ fontSize: "14px", color: "#6d6d6d" }}>
                  Populate the app then take a screenshot to appear in the
                  Membership section. Consider a matchday or when you have
                  populated other content in the home feed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-2">
        <div className="p-2">
          <button
            style={{
              backgroundColor: primaryColor,
              borderRadius: 5,
              border: "0px none",
              boxShadow: "transparent 0px 0px 0px",
              outline: "none",
              padding: "5px 10px",
              width: "200px",
            }}
            className="default-text-color"
            disabled={isSaving}
            onClick={saveToFirebase}
          >
            {isSaving ? <Spin size="small" spinning={true} /> : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default WebsiteMembership;
