import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/main/App";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/jquery/dist/jquery.min.js";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";

import "./main.css";

import { Provider } from "react-redux";
import configureStore from "./redux/configureStore";
import ErrorHandler from "./components/main/ErrorHandler";
import { initFacebookSdk } from "./services/facebook/util";

export const store = configureStore();
store.subscribe(() => {});

function startApp() {
  const app = (
    <Provider store={store}>
      <App />
      <ErrorHandler />
    </Provider>
  );

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(app);
}

initFacebookSdk().then(() => startApp());
