import React from "react";
import { connect } from "react-redux";
import NavigationSetup from "./NavigationSetup";
import IntroSection from "./IntroSection";
import { FInput } from "../../commons/formFields/FInput";
import { db } from "../../../services/firebaseService/connection";
import {
  getWebsiteConfig,
  uploadImage,
} from "../../../services/firebaseService/endPoints/admin/website";
import { fbGetGroup } from "../../../services/firebaseService/endPoints/group";
import { Spin, Steps } from "antd";
import { doc, getDoc, setDoc } from "@firebase/firestore";
import { postUpdateCDNUtil } from "../../../helperFunctions/util";

class Setup extends React.Component {
  state = {
    social: {
      facebook: "",
      insta: "",
      twitter: "",
    },
    galleryIntro: "",
    save: false,
    updatedComponents: [],
    dataUpdated: false,
    gallery: false,
    navigationStep: "process",
    introStep: "wait",
    socialStep: "wait",
    current: "",
    next: "",
    saveToFirebase: false,
    isSaving: false,
  };

  handlePublish = () => {
    this.setState({
      saveToFirebase: true,
      isSaving: true,
    });
  };

  handleInputChange = (e) => {
    let social = this.state.social;
    social[e.target.name] = e.target.value;
    this.setState({ social });
  };

  handleSaveComponentState = (state) => {
    let updatedComponents = this.state.updatedComponents;
    updatedComponents.push(Object.keys(state)[0]);
    this.setState({ ...state, updatedComponents });
  };

  handleNext = (current, next) => {
    this.setState({
      current,
      next,
      save: true,
    });
  };

  render() {
    let currentData = this.props;
    currentData.adminData.data.websiteConfig = this.state;
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    let websiteUrl = `https://group.chant.fan/${(this.props.adminData.data.groupName || "").split(" ").join("").toLowerCase()}`;

    return (
      <div
        className="border rounded mt-2"
        style={{
          width: "100%",
          padding: "15px 30px",
          backgroundColor: "#ffffff",
          // boxShadow: "0px 3px 10px 1px",
          marginTop: 30,
        }}
      >
        <Steps>
          <Steps.Step status={this.state.navigationStep} title="Navigation" />
          <Steps.Step
            status={this.state.introStep}
            title="Header, Intro & Section Breaks"
          />
          <Steps.Step status={this.state.socialStep} title="Social" />
        </Steps>
        {this.state.navigationStep === "process" && (
          <>
            <div className="mt-3">
              <p
                style={{ fontSize: "20", fontWeight: "bold", marginBottom: 0 }}
              >
                Website Setup
              </p>

              <p>
                Chant offers a hosted, single-page website for your supporters
                group with news, events, tickets, gallery, store, member
                registration, leader bios and more. Use of the website solution
                is $10/month (paid annually).
              </p>

              <p>
                Content like Events, News and Gallery are integrated across the
                app and website (read: low maintenance!). And you can 'Pin to
                website' any chat stream post from the app.
              </p>

              <p>To launch, simply complete the form and click ‘Publish’. </p>

              <p>
                Your URL (when published): <a href={websiteUrl}>{websiteUrl}</a>
              </p>

              {/* <p>Chant offers a hosted, single-page website for your supporters group with news, events, tickets, gallery, store, member registration, leader bios and more. </p>
                            <p>Complete the following form and click ‘Publish’. See ‘Group Links’ page for details on using your own domain address.</p> */}
            </div>

            <NavigationSetup
              {...currentData}
              updated={this.state.dataUpdated}
              onSave={this.handleSaveComponentState}
              save={this.state.save}
              isUpdated={this.state.updatedComponents.includes("navigation")}
            />

            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="btn btn-primary m-2 ml-auto default-text-color default-border"
                type="button"
                tabIndex="2"
                style={{ backgroundColor: primaryColor }}
                onClick={(e) => {
                  e.preventDefault();
                  this.handleNext("navigationStep", "introStep");
                }}
              >
                Next
              </button>
            </div>
          </>
        )}

        {this.state.introStep === "process" && (
          <>
            <IntroSection
              {...currentData}
              updated={this.state.dataUpdated}
              onSave={this.handleSaveComponentState}
              save={this.state.save}
              isUpdated={this.state.updatedComponents.includes("intro")}
            />

            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="btn btn-primary m-2 ml-auto default-text-color  default-border"
                type="button"
                tabIndex="2"
                style={{ backgroundColor: primaryColor }}
                onClick={(e) => {
                  e.preventDefault();
                  this.handleNext("introStep", "socialStep");
                }}
              >
                Next
              </button>
            </div>
          </>
        )}
        {this.state.socialStep === "process" && (
          <>
            {/* <div className="d-flex mt-4">
                            <div style={{ flex: 1 }}>
                                <span style={{ fontSize: '20', fontWeight: 'bold' }}>Gallery</span>
                            </div>
                            <div className="d-flex align-items-center" style={{ flex: 3, padding: '0px 3rem' }}>
                                <div className="form-group col" style={{ flexGrow: 0, marginBottom: 0 }}>
                                    <input type='checkbox' name="gallery" style={{ height: "20px", width: "20px", borderRadius: 5 }} onChange={(e) => {
                                        this.setState({
                                            gallery: e.target.checked
                                        })
                                    }} />
                                </div>
                                <div>
                                    <p style={{ fontSize: 12, marginBottom: 0 }}>Check to show all public photos from the app on your website.</p>
                                    <p style={{ fontSize: 12, marginBottom: 0 }}>Members set individual privacy settings for their photos in the app.</p>
                                </div>
                            </div>

                        </div>
                        <div className="d-flex">
                            <div className="mx-auto" style={{ width: '90%', display: 'flex' }}>
                                <div style={{ flex: 1 }}>
                                    <p style={{ marginBottom: -5 }}>Intro</p >
                                </div>
                                <div style={{ flex: 3, display: 'flex', flexDirection: 'column' }}>

                                    <div className="form-group col">
                                        <textarea
                                            rows={2}
                                            style={{
                                                borderRadius: 5,
                                                width: '100%',
                                                borderColor: '#ced4da'
                                            }}
                                            value={this.state.galleryIntro}
                                            name="galleryIntro"
                                            onChange={(e) => {
                                                this.setState({
                                                    [e.target.name]: e.target.value
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> */}

            <div className="mt-4">
              <div>
                <span style={{ fontSize: "20", fontWeight: "bold" }}>
                  Social
                </span>
              </div>
              <div className="mx-auto" style={{ width: "90%" }}>
                <div className="d-flex">
                  <div style={{ flex: 1 }}>Facebook</div>
                  <div style={{ flex: 3 }}>
                    <FInput
                      value={this.state.social.facebook}
                      onChange={this.handleInputChange}
                      name="facebook"
                      display="col"
                      placeholder="(optional) Enter Facebook Link"
                      tabIndex="2"
                    />
                  </div>
                </div>

                <div className="d-flex">
                  <div style={{ flex: 1 }}>Instagram</div>
                  <div style={{ flex: 3 }}>
                    <FInput
                      value={this.state.social.insta}
                      onChange={this.handleInputChange}
                      name="insta"
                      display="col"
                      placeholder="(optional) Enter Instagram Link"
                      tabIndex="2"
                    />
                  </div>
                </div>

                <div className="d-flex">
                  <div style={{ flex: 1 }}>Twitter</div>
                  <div style={{ flex: 3 }}>
                    <FInput
                      value={this.state.social.twitter}
                      onChange={this.handleInputChange}
                      name="twitter"
                      display="col"
                      placeholder="(optional) Enter Twitter Link"
                      tabIndex="2"
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <div className="px-2">
                    <button
                      style={{
                        backgroundColor: primaryColor,
                        borderRadius: 5,
                        border: "0px none",
                        boxShadow: "transparent 0px 0px 0px",
                        outline: "none",
                        padding: "5px 10px",
                        width: "200px",
                      }}
                      // disabled={true}
                      className="default-text-color"
                      onClick={this.handlePublish}
                    >
                      {this.state.isSaving ? (
                        <Spin size="small" spinning={true} />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }

  fetchData = () => {
    getWebsiteConfig(this.props.currentGroup).then((data) => {
      if (data && Object.keys(data).length > 0) {
        this.setState({ ...data, dataUpdated: true });
      } else {
        this.setState({
          social: {
            facebook: "",
            insta: "",
            twitter: "",
          },
          galleryIntro: "",
          save: false,
          updatedComponents: [],
          dataUpdated: true,
          navigation: undefined,
          about: undefined,
          communityPartners: undefined,
          sections: undefined,
          intro: undefined,
          gallery: false,
        });
      }
    });
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    function delay(delayInms) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(2);
        }, delayInms);
      });
    }

    const save = async () => {
      if (
        this.state.updatedComponents.length === 2 &&
        this.state.saveToFirebase
      ) {
        let currentState = this.state;
        let updatedComponents = JSON.parse(
          JSON.stringify(currentState.updatedComponents)
        );
        let save = currentState.save;
        delete currentState.save;
        delete currentState.updatedComponents;

        let path = `${this.props.adminData.data.groupName}/website/`;
        let imageUploadPromises = [];

        for (let comp of updatedComponents) {
          let imageFileKeys = currentState[comp].imageFileKeys;
          if (imageFileKeys) {
            for (let key of imageFileKeys) {
              if (Array.isArray(currentState[comp][key])) {
                let images = currentState[comp][key];
                let index = 0;
                for (let image of images) {
                  if (image && (typeof image === "object") & !image.url) {
                    imageUploadPromises.push(
                      uploadImage(
                        image,
                        `${path}${comp}/${key}/${index + 1}`,
                        index
                      ).then(async ({ downloadUrl, index }) => {
                        let imageIndex = index;

                        currentState[comp][key][imageIndex] = await downloadUrl;
                      })
                    );
                    await delay(1000);
                  }
                  index++;
                }
              } else if (key.includes(".")) {
                let keyParts = key.split(".");
                let file = currentState[comp][keyParts[0]][keyParts[1]];

                if (file && typeof file === "object" && !file.url) {
                  imageUploadPromises.push(
                    uploadImage(file, `${path}${comp}/${keyParts[0]}`)
                      .then(({ downloadUrl }) => downloadUrl)
                      .then((downloadUrl) => {
                        currentState[comp][keyParts[0]][keyParts[1]] =
                          downloadUrl;
                      })
                  );
                  await delay(1000);
                }
              } else {
                let image = currentState[comp][key];
                if (image && typeof image === "object" && !image.url) {
                  imageUploadPromises.push(
                    uploadImage(image, `${path}${comp}/${key}`)
                      .then(({ downloadUrl }) => downloadUrl)
                      .then((downloadUrl) => {
                        currentState[comp][key] = downloadUrl;
                      })
                  );
                  await delay(1000);
                }
              }
            }
          }
        }

        for (let comp of updatedComponents) {
          delete currentState[comp].imageFileKeys;
        }
        delete currentState.save;
        delete currentState.saveToFirebase;
        delete currentState.navigationStep;
        delete currentState.introStep;
        delete currentState.aboutStep;
        delete currentState.communityStep;
        delete currentState.socialStep;
        delete currentState.sectionsStep;
        delete currentState.current;
        delete currentState.next;

        await Promise.all(imageUploadPromises);

        let docRef = doc(db, "website", this.props.adminData.data.id);
        let groupData = this.props.adminData.data;
        let websiteUpdate = {
          clubName: groupData.clubName || "",
          leagueName: groupData.leagueName || "",
          groupName: groupData.groupName || "",
          country: groupData.country || "",
        };
        if (groupData.clubId) {
          websiteUpdate.clubId = groupData.clubId;
        }
        if (groupData.leagueId) {
          websiteUpdate.leagueId = groupData.leagueId;
        }
        getDoc(docRef).then((doc) => {
          let websiteConfig = doc.data() || {};
          if (!(websiteConfig && typeof websiteConfig === "object")) {
            websiteConfig = {};
          }
          websiteConfig.intro = currentState.intro || {};
          websiteConfig.navigation = currentState.navigation || {};
          websiteConfig.social = currentState.social || {};
          setDoc(
            docRef,
            {
              ...websiteConfig,
              ...websiteUpdate,
              configuration: groupData.configuration || {},
            },
            { merge: true }
          ).then(() => {
            postUpdateCDNUtil(this.props.currentGroup);
            this.setState(
              {
                updatedComponents: [],
                save: false,
                saveToFirebase: false,
                navigationStep: "process",
                socialStep: "wait",
              },
              () => {
                this.fetchData();
                this.setState({
                  saveToFirebase: false,
                  save: false,
                  dataUpdated: false,
                  current: "",
                  next: "",
                  isSaving: false,
                });
              }
            );
          });
        });
      }

      if (
        this.state.updatedComponents &&
        this.state.updatedComponents.length === 5 &&
        this.state.save
      ) {
        this.setState({ save: false });
      }

      if (this.state.save && this.state.current && this.state.next) {
        this.setState({
          save: false,
          [this.state.current]: "finish",
          [this.state.next]: "process",
          current: "",
          next: "",
          dataUpdated: true,
        });
      }
    };

    if (prevProps.currentGroup !== this.props.currentGroup) {
      this.fetchData();
    }

    if (this.state.dataUpdated) {
      this.setState({
        dataUpdated: false,
      });
    }

    save();
  }
}

const mapStateToProps = (state) => {
  return {
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    currentGroup: state.adminData.currentGroup,
    user: state.user,
  };
};

export default connect(mapStateToProps)(Setup);
