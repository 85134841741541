import React from "react";
import "./Accordion.css";
import chevronDown from "../../images/chevron-down.svg";
import checkedTick from "../../images/checked-tick.svg";
import uncheckedTick from "../../images/unchecked-tick.svg";

const AccordionCollapsed = ({
  title,
  isComplete,
  statusText,
  expanded,
  handleAccordionSize,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
      }}
      onClick={() => expanded && handleAccordionSize(false)}
    >
      <div className="flex-center" style={{ gap: "12px" }}>
        <div className="flex-center" style={{ marginLeft: "-2px" }}>
          {isComplete ? (
            <img
              height={28}
              width={28}
              src={checkedTick}
              alt="items-complete"
            />
          ) : (
            <img
              height={28}
              width={28}
              src={uncheckedTick}
              alt="items-incomplete"
            />
          )}
        </div>
        <p
          className={`accordion-title ${expanded ? "accordion-title--expanded" : "accordion-title--collapsed"}`}
        >
          {title}
        </p>
      </div>

      <div className="flex-center" style={{ gap: "12px" }}>
        <span style={{ fontWeight: 400, fontSize: "14px", color: "#808080" }}>
          {statusText}
        </span>

        <div
          className={`flex-center accordion-expand-icon ${expanded ? "accordion-expand-icon--expanded" : "accordion-expand-icon--minimized"}`}
          onClick={() => handleAccordionSize(!expanded)}
        >
          <img src={chevronDown} alt="open-accordion" />
        </div>
      </div>
    </div>
  );
};

export default AccordionCollapsed;
