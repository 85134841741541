// 3rd party design libraries
import { Alert, Button, Popconfirm, Table } from "antd";

// api end points

// common components
import LoadingModal from "../../commons/LoadingModal";
import React from "react";
import Ticket from "../../../models/admin/Ticket";
import axios from "axios";
import { connect } from "react-redux";
import { fbGetTickets } from "../../../services/firebaseService/endPoints/admin/tickets";
// 3rd party helper libraries
import moment from "moment";
import { getUserDetails } from "../../../services/firebaseService/endPoints/member";
import EditMember from "../members/EditMemberModal/EditMember";

import { db } from "../../../services/firebaseService/connection";
import {
  editMemberInLocal,
  getSingleMemberFromLocal,
} from "../../../services/indexedDb/members";
import { deleteField, doc, getDoc, updateDoc } from "firebase/firestore";
import { getCurrencyUnicode } from "../../../helperFunctions/util";
import ViewMoreText from "../../../CommonComponents/ViewMore/ViewMore";
import logger from "../../../utils/logger";
import { withRouter } from "../../../utils/helper";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";

// constants
const timeFormat = "HH:mm";
const options = [
  {
    value: "on submit",
    label: "On Submit",
  },
  {
    value: "day of event",
    label: "Day of Event",
  },
  {
    value: "1 day before event",
    label: "1 day before event",
  },
];
for (let i = 2; i <= 28; i++) {
  options.push({
    value: `${i} day before event`,
    label: `${i} day's before event`,
  });
}

class ViewTicket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventDate: "",
      type: "",
      location: "",
      eventDescription: "",
      errorInForm: {
        isError: false,
        visible: false,
        message: "",
      },
      loadingDocument: !!props.router.params.id,
      downloadedOn: null,
      clubId: props.clubId,
      groupId: props.currentGroup,
      isLoading: false,
      loadingMatches: false,
      eventName: "",
      eventCode: "",
      ticketId: props.router.params.id,
      tickets: [],
      editableRecordId: "",
      editableRecord: null,
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      isLoading: true,
    });
  };

  handleErrorMessageClose = () => {
    this.setState((prevState) => {
      const errorInForm = {
        ...prevState.errorInForm,
      };
      errorInForm.visible = false;
      return {
        errorInForm,
      };
    });
  };

  handleTicketRefresh = () => {
    this.fetchDetail();
  };

  updateDownloadTimestamp = async () => {
    let ts = Math.round(new Date().getTime() / 1000);
    let obj = {
      groupId: this.state.groupId,
      ticketId: this.state.ticketId,
      timestamp: ts,
    };
    await axios({
      method: "post",
      url: "https://us-central1-chant2019.cloudfunctions.net/lastDownloadedOnForTickets",
      data: obj,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        this.setState({
          downloadedOn: Math.round(new Date().getTime() / 1000),
          loading: false,
        });
      })
      .catch((err) => {
        logger.error(err);
      });
  };

  setEditableRecordId = (id) => {
    getSingleMemberFromLocal(this.props.currentGroup, id).then((data) => {
      if (data) {
        this.setState({
          editableRecord: data,
          editableRecordId: id,
        });
      }
    });
  };

  saveDetail = (updatedData, id, email) => {
    if (updatedData.fullName) {
      updatedData = {
        ...updatedData,
        name: updatedData.fullName,
      };
    }
    editMemberInLocal({ id, ...updatedData });

    if (typeof id === "number") {
      let docRef = doc(db, "invite_emails", this.props.groupData.id);

      getDoc(docRef).then((doc) => {
        if (doc.exists()) {
          let userDetails = (doc.data() || {}).userDetails;
          let id = email;
          let filteredDocs = Object.keys(userDetails || {}).filter(
            (key) => userDetails[key] && userDetails[key].email === email
          );
          if (filteredDocs.length > 0) {
            id = filteredDocs[0];
          }
          if (userDetails) {
            let userDetailObj = userDetails[id];
            let updatedUserDetailObj = {
              ...userDetailObj,
              ...updatedData,
            };

            if (!updatedData.tshirtSize) {
              delete updatedUserDetailObj.tshirtSize;
            }

            userDetails[id] = updatedUserDetailObj;

            updateDoc(docRef, { userDetails });
          }
        }
      });
    } else {
      if (!updatedData.tshirtSize) {
        updatedData.tshirtSize = deleteField();
      }

      if (updatedData.hasOwnProperty("hometown")) {
        let homeTown = updatedData.hometown;
        if (!homeTown) {
          homeTown = "";
        }
        updateDoc(doc(db, "users", id), { homeTown });

        delete updatedData.homeTown;
      }

      updateDoc(doc(db, "userGroup", this.props.groupData.id, "members", id), {
        ...updatedData,
      });

      let websiteCollectionRef = doc(db, "website", this.props.groupData.id);
      if (updatedData.title) {
        getDoc(websiteCollectionRef).then((doc) => {
          if (doc.exists() && doc.data().about) {
            let about = doc.data().about;
            let leadership = about.leadership;
            if (leadership && Array.isArray(leadership.leaders)) {
              let requiredDoc = leadership.leaders.find(
                (value) => value.id === id
              );
              if (requiredDoc) {
                requiredDoc.title = updatedData.title;
                updateDoc(websiteCollectionRef, { about });
              }
            }
          }
        });
      }
    }
  };

  changeHeaderColor = () => {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    let elements = document.querySelectorAll("th");
    elements.forEach((elem) => {
      elem.style.backgroundColor = primaryColor;
    });
  };

  render() {
    let groupCurrencyISO = this.props.adminData.data.paymentDetails
      ? this.props.adminData.data.paymentDetails.currency || "USD"
      : "USD";
    let groupCurrency = getCurrencyUnicode(groupCurrencyISO);

    let eventDate = "",
      eventTime = "";

    if (this.state.eventDate) {
      eventDate = moment.unix(this.state.eventDate);
    }

    if (eventDate) {
      eventTime =
        moment.unix(this.state.eventDate).seconds() !== 59
          ? moment(`${eventDate.hours()}:${eventDate.minutes()}`, timeFormat)
          : null;
    }

    let loadingTitle = "",
      loadingMessage = "";
    if (this.state.loadingDocument) {
      loadingTitle = "Loading Document";
      loadingMessage = "Fetching Data";
    } else if (this.state.loadingMatches) {
      loadingTitle = "Loading Matches";
      loadingMessage = "Fetching match details";
    } else if (this.state.isLoading) {
      loadingTitle = this.state.editMode
        ? "Updating Content"
        : "Creating Content";
      loadingMessage = this.state.editMode
        ? "Updating content"
        : "Creating new content";
    }

    const csvColumns = [
      {
        key: "id",
        label: "Serial No.",
      },
      {
        key: "name",
        label: "Member name",
      },
      {
        key: "email",
        label: "Email",
      },
      {
        key: "phone",
        label: "Phone Number",
      },
      {
        key: "membershipNumber",
        label: "Membership Number",
      },
      {
        key: "purchased_date",
        label: "Purchased Date",
      },
      {
        key: "purchased_timestamp",
        label: "Purchased Time",
      },
      {
        key: "quantity",
        label: "Quantity",
      },
      {
        key: "payment_amount",
        label: "Payment Amount($)",
      },
    ];

    const tableDatas = this.state.tableData;
    let dataArray = [];
    let subTicketAttendeesCountMap = {};

    let subTicketIds = this.state.tickets.map((ticketDetail) => {
      subTicketAttendeesCountMap[ticketDetail.id] = 0;
      return ticketDetail.id;
    });

    if (tableDatas) {
      tableDatas.map((data) => {
        let purchasedSubTickets = data.purchasedSubTickets;
        Object.keys(purchasedSubTickets || {}).map((key) => {
          if (
            subTicketAttendeesCountMap[key] <
            (purchasedSubTickets[key].attendees || []).length
          ) {
            subTicketAttendeesCountMap[key] = (
              purchasedSubTickets[key].attendees || []
            ).length;
          }
        });

        let temp = {
          id: data.id,
          name: data.name,
          email: data.email,
          membershipNumber: data.membershipNumber,
          phone: data.phone,
          purchased_date: dayjs(data.purchasedDate).format("Do MMM"),
          purchased_timestamp: dayjs(data.purchasedDate).format("HH:mm:ss"),
          quantity: data.quantity,
          payment_amount: data.paymentAmount,
        };
        subTicketIds.map((key, index) => {
          let subTicketPurchaseData = purchasedSubTickets[key];
          if (subTicketPurchaseData) {
            temp[`subTicket ${index + 1}`] = subTicketPurchaseData.name;
            temp[`ticketBought ${index + 1}`] =
              subTicketPurchaseData.ticketBought;
            (subTicketPurchaseData.attendees || []).forEach(
              (attendee, attendeeIndex) => {
                temp[`subTicket ${index + 1} GuestName ${attendeeIndex + 1}`] =
                  attendee.fullName || "";
                temp[`subTicket ${index + 1} Email ${attendeeIndex + 1}`] =
                  attendee.email || "";
              }
            );
          }
        });
        dataArray.push(temp);
      });
    }

    let tableDataMap = {
      id: "Serial No.",
      name: "Member name",
      email: "Email",
      phone: "Phone Number",
      // guests: "Guest(s)",
      membershipNumber: "Membership Number",
      purchased_date: "Purchased Date",
      purchased_timestamp: "Purchase Timestamp",
      quantity: "Quantity",
      payment_amount: "Payment Amount($)",
    };

    this.state.tickets.forEach((_, index) => {
      tableDataMap[`subTicket ${index + 1}`] = `subTicket ${index + 1}`;
      csvColumns.push({
        id: `subTicket ${index + 1}`,
        displayName: `subTicket ${index + 1}`,
      });
      tableDataMap[`ticketBought ${index + 1}`] = `ticketBought ${index + 1}`;
      csvColumns.push({
        id: `ticketBought ${index + 1}`,
        displayName: `ticketBought ${index + 1}`,
      });
      let key = subTicketIds[index];
      Array.from(
        { length: subTicketAttendeesCountMap[key] },
        (_, subTicketIndex) => {
          tableDataMap[
            `subTicket ${index + 1} GuestName ${subTicketIndex + 1}`
          ] = `subTicket ${index + 1} GuestName ${subTicketIndex + 1}`;
          csvColumns.push({
            id: `subTicket ${index + 1} GuestName ${subTicketIndex + 1}`,
            displayName: `subTicket ${index + 1} GuestName ${subTicketIndex + 1}`,
          });
          tableDataMap[`subTicket ${index + 1} Email ${subTicketIndex + 1}`] =
            `subTicket ${index + 1} Email ${subTicketIndex + 1}`;
          csvColumns.push({
            id: `subTicket ${index + 1} Email ${subTicketIndex + 1}`,
            displayName: `subTicket ${index + 1} Email ${subTicketIndex + 1}`,
          });
        }
      );
    });

    const columns = [
      {
        title: window.screen.width > 500 ? "Number" : "No.",
        width: window.screen.width > 500 ? 100 : 50,
        className: "header-white",
        key: "id",
        dataIndex: "id",
        // render: (text, record, index) => index + 1,
        align: "center",
      },
      {
        title: window.screen.width > 500 ? "Purchaser Name" : "Name",
        width: window.screen.width > 500 ? 300 : 150,
        className: "header-white",
        align: "center",
        key: "name",
        dataIndex: "name",
        render: (data, record) => {
          return (
            <a
              onClick={() => {
                this.setEditableRecordId(record.customerId);
              }}
            >
              {data}
            </a>
          );
        },
        sorter: (a, b) => {
          if (a.name > b.name) {
            return -1;
          }
          if (a.name < b.name) {
            return 1;
          }
          return 0;
        },
        sortDirections: ["ascend", "descend"],
      },
      {
        title: "Ticket",
        width: window.screen.width > 500 ? 300 : 150,
        className: "header-white",
        key: "ticketName",
        dataIndex: "ticketName",
        align: "center",
      },
      {
        title: "Quantity",
        width: 150,
        key: "quantity",
        className: "header-white",
        align: "center",
        dataIndex: "quantity",
        sorter: (a, b) => a.quantity - b.quantity,
        sortDirections: ["ascend", "descend"],
      },
      {
        title: "Checked-in",
        width: 150,
        key: "checkedin",
        className: "header-white",
        align: "center",
        dataIndex: "checkedin",
      },
      {
        title: "Revenue",
        width: 150,
        key: "revenue",
        className: "header-white",
        align: "center",
        dataIndex: "paymentAmount",
        render: (data) => {
          let dataAsFloat = parseFloat((data || "0").toString());
          let dataAsString = dataAsFloat.toFixed(
            Math.round(dataAsFloat) === dataAsFloat ? 0 : 2
          );
          return <span>{data ? `${groupCurrency}${dataAsString}` : "-"}</span>;
        },
      },
      {
        title: "Purchase Date",
        align: "center",
        className: "header-white",
        width: 175,
        key: "purchasedDate",
        dataIndex: "purchasedDate",
        sorter: (a, b) =>
          moment(a.purchasedDate).unix() - moment(b.purchasedDate).unix(),
        sortDirections: ["ascend", "descend"],
        render: (purchasedDate) => {
          let startDate = "";
          if (purchasedDate) {
            startDate = moment.unix(Math.floor(purchasedDate / 1000));
            startDate = startDate.isValid() ? startDate.format("Do MMM") : "";
          }
          return <span>{startDate}</span>;
        },
      },
      {
        title: "Purchase Time",
        align: "center",
        className: "header-white",
        width: 175,
        key: "purchasedTime",
        dataIndex: "purchasedDate",
        sorter: (a, b) =>
          moment(a.purchasedDate).unix() - moment(b.purchasedDate).unix(),
        sortDirections: ["ascend", "descend"],
        render: (purchasedTime) => {
          let startTime = "";
          if (purchasedTime) {
            startTime = moment.unix(Math.floor(purchasedTime / 1000));
            startTime = startTime.isValid() ? startTime.format("HH:mm:ss") : "";
          }
          return <span>{startTime}</span>;
        },
      },
      {
        title: window.screen.width > 500 ? "Purchaser Email" : "Email",
        width: window.screen.width > 500 ? 300 : 150,
        className: "header-white",
        align: "center",
        key: "email",
        dataIndex: "email",
        sorter: (a, b) => {
          if (a.email > b.email) {
            return -1;
          }
          if (a.email < b.email) {
            return 1;
          }
          return 0;
        },
        sortDirections: ["ascend", "descend"],
      },

      {
        title: "Attendee(s)",
        align: "center",
        className: "header-white",
        key: "attendee",
        dataIndex: "attendee",
        width: window.screen.width > 500 ? 300 : 150,
      },

      // {
      //   title: "Payment amount($)",
      //   key: "paymentAmount",
      //   className: "header-white",
      //   width: 125,
      //   align: "left",
      //   dataIndex: "paymentAmount",
      //   render: (data) => {
      //     let dataAsFloat = parseFloat((data || "0").toString());
      //     let dataAsString = dataAsFloat.toFixed(
      //       Math.round(dataAsFloat) === dataAsFloat ? 0 : 2
      //     );
      //     return <span>{dataAsString}</span>;
      //   },
      //   sorter: (a, b) => a.paymentAmount - b.paymentAmount,
      //   sortDirections: ["ascend", "descend"],
      // },
    ];

    const csvData = [
      ["Ticket Type", this.state.type],
      ["Event Code", this.state.eventCode],
      ["Location", this.state.location !== "" ? this.state.location : "-"],
      ["Match", this.state.matchName !== "" ? this.state.matchName : "-"],
      ["Event Name", this.state.eventName !== "" ? this.state.eventName : "-"],
      [
        "Event Date",
        moment.unix(this.state.eventDate).format("Do MMM YYYY HH:MM"),
      ],
      [
        "Event Description",
        this.state.eventDescription !== "" ? this.state.eventDescription : "-",
      ],
      [],
      [],
      Object.values(tableDataMap),
      ...dataArray.map((item) => Object.values(item)),
    ];

    let primaryColor = "";
    if (
      this.props.adminData.data &&
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <div>
        {this.state.editableRecordId && (
          <EditMember
            editableRecord={this.state.editableRecord}
            editableRecordId={this.state.editableRecordId}
            handleModalOpen={() => {
              this.setState({
                editableRecordId: "",
              });
            }}
            groupId={this.props.currentGroup}
            saveDetail={this.saveDetail}
            primaryColor={primaryColor}
            linkedMember={this.state.linkedMember}
          />
        )}
        <LoadingModal
          title={loadingTitle}
          message={loadingMessage}
          loading={
            this.state.loadingDocument ||
            this.state.loadingMatches ||
            this.state.isLoading
          }
        />
        {this.state.errorInForm.isError && this.state.errorInForm.visible && (
          <div className="mx-5 mt-2">
            <Alert
              message="Error"
              description={this.state.errorInForm.message}
              type="error"
              closable
              afterClose={this.handleErrorMessageClose}
            />
          </div>
        )}
        <li
          className="ml-auto align-items-center"
          style={{ listStyleType: "none", marginTop: "20px" }}
        >
          <div
            className="d-flex justify-content-end"
            style={{ paddingInline: "10px" }}
          >
            <Popconfirm
              title="Refresh tickets?"
              onConfirm={() => {
                this.handleTicketRefresh();
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                style={{ backgroundColor: "#999999", borderColor: "#999999" }}
              >
                Refresh
              </Button>
            </Popconfirm>
            &nbsp; &nbsp;
            {Array.isArray(this.state.tableData) &&
              this.state.tableData.length > 0 && (
                <CSVLink data={csvData} filename={"Tickets Purchase List.csv"}>
                  {" "}
                  <Button
                    type="danger"
                    style={{
                      backgroundColor: primaryColor,
                      borderColor: primaryColor,
                      color: "#ffffff",
                    }}
                  >
                    Download CSV
                  </Button>
                </CSVLink>
              )}
            &nbsp; &nbsp;
            <br />
          </div>
          {window.screen.width > 500 && (
            <div
              className="d-flex justify-content-end mx-2"
              style={{ marginTop: "3px", marginRight: "3px" }}
            >
              <i>
                Last downloaded at:{" "}
                {this.state.downloadedOn !== null &&
                  moment.unix(this.state.downloadedOn).format("Do MMM YYYY")}
                {this.state.downloadedOn === null && "NA"}
              </i>
            </div>
          )}
        </li>
        <div className="mx-md-5 p-2 border-box">
          <div class="row">
            <div className="col p-3">
              Ticket Type: {this.state.type}
              <br />
              Event Code: {this.state.eventCode}
              <br />
              {this.state.location !== "" && `Location: ${this.state.location}`}
              {this.state.location !== "" && <br />}
              {this.state.matchName === "" ? "Event Name:" : "Match"}{" "}
              {this.state.matchName === ""
                ? this.state.eventName
                : this.state.matchName}
            </div>
            <div className="col p-3 d-flex flex-column justify-content-end">
              <div>
                Event Date:{" "}
                {moment.unix(this.state.eventDate).format("Do MMM YYYY")}
              </div>

              <div>
                Event Time: {moment.unix(this.state.eventDate).format("HH:MM")}
              </div>
              {this.state.eventDescription !== "" && (
                <div>
                  <p style={{ marginBottom: 0 }}>Event Desc: </p>
                  <ViewMoreText
                    text={this.state.eventDescription}
                    maxLength={100}
                  />
                </div>
              )}
            </div>
          </div>
          <Table
            bordered
            className="table-backdrop"
            columns={columns}
            loading={this.state.isLoading}
            bodyStyle={{
              fontSize: window.screen.width > 500 ? "18px" : "12px",
              backgroundColor: "#ffffff",
            }}
            scroll={{ x: "max-content" }}
            dataSource={this.state.tableData}
            // pagination={this.state.pagination}
            pagination={false}
            onChange={this.handleTableChange}
            rowKey="id"
            key="id"
            size="middle"
            expandedRowRender={(record) => {
              const childColumns = [
                {
                  title: window.screen.width > 500 ? "Number" : "No.",
                  width: window.screen.width > 500 ? 100 : 50,
                  className: "header-white",
                  key: "id",
                  align: "center",
                },
                {
                  title: window.screen.width > 500 ? "Purchaser Name" : "Name",
                  width: window.screen.width > 500 ? 300 : 150,
                  className: "header-white",
                  key: "name",
                  align: "center",
                },
                {
                  title: "Ticket",
                  width: window.screen.width > 500 ? 300 : 150,
                  className: "header-white",
                  key: "ticketName",
                  dataIndex: "ticketName",
                  align: "center",
                },
                {
                  width: 150,
                  title: "Quantity",
                  key: "quantity",
                  className: "header-white",
                  align: "center",
                  dataIndex: "quantity",
                },
                {
                  width: 150,
                  title: "Checked-in",
                  key: "checkedin",
                  className: "header-white",
                  align: "center",
                  dataIndex: "checkedin",
                },
                {
                  width: 150,
                  title: "Revenue",
                  key: "revenue",
                  className: "header-white",
                  align: "center",
                },
                {
                  title: "Purchase Date",
                  align: "center",
                  className: "header-white",
                  width: 175,
                  key: "purchasedDate",
                },
                {
                  title:
                    window.screen.width > 500 ? "Purchaser Email" : "Email",
                  width: window.screen.width > 500 ? 300 : 150,
                  className: "header-white",
                  align: "center",
                  key: "email",
                },
                {
                  title: "Attendee(s)",
                  align: "center",
                  width: window.screen.width > 500 ? 300 : 150,
                  className: "header-white",
                  key: "attendees",
                  dataIndex: "attendees",
                  render: (data) => {
                    return (
                      <div>
                        {data.map((item, index) => {
                          return (
                            <p
                              style={{ fontSize: "12px" }}
                            >{`${index + 1}. ${item.fullName || "-"} ${item.email || ""}`}</p>
                          );
                        })}
                      </div>
                    );
                  },
                },

                // {
                //   title: "Payment amount($)",
                //   key: "paymentAmount",
                //   className: "header-white",
                //   width: 125,
                //   align: "left",
                //   dataIndex: "paymentAmount",
                //   render: (data) => {
                //     let dataAsFloat = parseFloat((data || "0").toString());
                //     let dataAsString = dataAsFloat.toFixed(
                //       Math.round(dataAsFloat) === dataAsFloat ? 0 : 2
                //     );
                //     return <span>{dataAsString}</span>;
                //   },
                //   sorter: (a, b) => a.paymentAmount - b.paymentAmount,
                //   sortDirections: ["ascend", "descend"],
                // },
              ];

              let data = Object.values(record.purchasedSubTickets).map(
                (item) => {
                  return {
                    ticketName: item.name,
                    quantity: item.ticketBought,
                    checkedin: "",
                    attendees: item.attendees,
                  };
                }
              );

              return (
                <Table
                  bordered
                  className="table-backdrop"
                  columns={childColumns}
                  dataSource={data}
                  showHeader={false}
                  pagination={false}
                  bodyStyle={{
                    fontSize: window.screen.width > 500 ? "18px" : "12px",
                    backgroundColor: "#ffffff",
                  }}
                />
              );
            }}
          />
        </div>
      </div>
    );
  }

  // handleEditMode = () => {
  //   let doc = fbGetTickets(
  //     this.props.router.params.id,
  //     this.props.router.params.group
  //   );

  //   const data = new Ticket();
  //   data.fromDataSet(doc);

  //   if (data.type) {
  //     if (data.type==="Bus" || data.type==="Tailgate") {
  //       this.setState({
  //         ...data,
  //         loadingDocument: false,
  //         generateBarCode: true,
  //         checkMatchRequired: true
  //       });
  //     } else if (data.type==="Group Match") {
  //       this.setState({
  //         ...data,
  //         loadingDocument: false,
  //         generateBarCode: false,
  //         checkMatchRequired: true
  //       });
  //     } else {
  //       this.setState({
  //         ...data,
  //         loadingDocument: false,
  //         generateBarCode: true,
  //         checkMatchRequired: false
  //       });
  //     }
  //   } else {
  //     this.setState({
  //       ...data,
  //       loadingDocument: false
  //     });
  //   }
  // };

  // getMatchDate = id => {
  //   let hours = 0,
  //     minutes = 0,
  //     seconds = 59;

  //   let date = null;
  //   this.state.matchList.map(match => {
  //     if (match.fixture_id===id) {
  //       date = match.event_date;
  //     }
  //   });

  //   if (date)
  //     this.setState({
  //       eventDate: moment(date)
  //         .set({
  //           h: hours,
  //           m: minutes,
  //           second: seconds
  //         })
  //         .unix()
  //     });
  // };

  // getMatchName = id => {
  //   let name = null;
  //   this.state.matchList.map(match => {
  //     if (match.fixture_id===id) {
  //       name = match.homeTeam.team_name + " vs " + match.awayTeam.team_name;
  //     }
  //   });

  //   return name;
  // };

  // getMatchList = () => {
  //   if (this.props.clubId && this.state.matchList.length === 0) {
  //     Axios.get(`fixtures/team/${this.props.clubId}`).then(response => {
  //       this.setState({
  //         matchList: response.data.api.fixtures,
  //         loadingMatches: false
  //       });
  //     });
  //   }
  // };

  static getDerivedStateFromProps(props, state) {
    if (state.clubId !== props.clubId || state.groupId !== props.currentGroup) {
      return {
        clubId: props.clubId,
        groupId: props.currentGroup,
      };
    }
  }

  componentDidMount() {
    this.fetchDetail();
    this.changeHeaderColor();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentGroup !== this.props.currentGroup) {
      this.changeHeaderColor();
      this.fetchDetail();
    }
    // this.fetchDetail();
  }

  getEmailForUser = async (userId) => {
    let { email } = await getUserDetails(this.props.currentGroup, userId);
    return email;
  };

  async fetchDetail() {
    const groupId = this.state.groupId;
    const ticketId = this.state.ticketId;

    let doc = await fbGetTickets(
      this.props.router.params.id,
      this.props.router.params.group
    );

    const ticketData = new Ticket();
    ticketData.fromDataSet(doc.data.body.data);

    await axios
      .get(
        "https://us-central1-chant2019.cloudfunctions.net/getSingleGroupTicketPurchases?groupId=" +
          groupId +
          "&ticketId=" +
          ticketId
      )
      .then(async (tickets) => {
        let users = [];
        let i = 1;
        for (var data of tickets.data.body) {
          var user = {};
          user.id = i++;
          user.customerId = data.id;
          user.paymentAmount = data.data.amountPaid;
          user.purchasedSubTickets = data.data.purchasedSubTickets;
          if (
            user.purchasedSubTickets &&
            Object.values(user.purchasedSubTickets).length > 0
          ) {
            user.paymentAmount = Object.values(user.purchasedSubTickets).reduce(
              (acc, val) => {
                acc += val.amountPaid;
                return acc;
              },
              0
            );
          }
          user.paymentAmount += data.data.totalFees || 0;
          let quantity = data.data.noOfTicketsBrought;
          let usersList = [];
          if (!!!quantity && !!data.data.purchasedSubTickets) {
            quantity = 0;
            Object.values(data.data.purchasedSubTickets).forEach(
              (ticketData) => {
                quantity += ticketData.ticketBought;
                usersList.push(...(ticketData.attendees || []));
              }
            );
          }
          user.quantity = quantity || 0;
          user.purchasedDate = data.data.purchasedOn;
          user.name = data.data.purchasedBy
            ? typeof data.data.purchasedBy === "string"
              ? data.data.purchasedBy
              : typeof data.data.purchasedBy === "object"
                ? data.data.purchasedBy.name
                : ""
            : "";

          if (!data.data.usersList) {
            data.data.usersList = [];
          }

          let { membershipNumber, phone } = await getSingleMemberFromLocal(
            this.props.currentGroup,
            user.customerId
          ).then((data) => {
            if (data) {
              return {
                membershipNumber: data.membershipNumber || "",
                phone: data.phone || "",
              };
            }
            return { membershipNumber: "", phone: "" };
          });

          user.membershipNumber = membershipNumber;
          user.phone = phone;

          user.email =
            data.data.purchasedBy && typeof data.data.purchasedBy === "object"
              ? data.data.purchasedBy.email
              : usersList.length > 0
                ? usersList[0].email === ""
                  ? "-"
                  : usersList[0].email
                : "";
          if (
            !user?.email &&
            user?.email?.length === 0 &&
            usersList?.length === 0
          ) {
            user.email = await this.getEmailForUser(data.id).then(
              (email) => email || ""
            );
          }
          var guests = "";
          usersList.map((f) => {
            var isLast = usersList[usersList.length - 1] === f;
            var ifFirst = usersList[0] === f;
            if (ifFirst === false)
              guests =
                guests +
                `${(f.fullName || f.name) + (f.email ? `(${f.email})` : "")}` +
                `${isLast ? "" : " ; "}`;
          });

          user.guestNames = usersList.length > 1 ? guests : "-";
          users.push(user);
        }

        users.sort((a, b) => moment.unix(a.id) - moment.unix(b.id));

        this.setState({
          tableData: users,
          type: ticketData.type,
          matchName: ticketData.matchName,
          eventName: ticketData.eventName,
          eventDate: ticketData.eventDate,
          location:
            ticketData.location !== null ? ticketData.location.name : "",
          eventDescription: ticketData.eventDescription,
          eventCode: ticketData.eventCode,
          isLoading: false,
          loadingDocument: false,
          downloadedOn: ticketData.lastDownloadOn,
          tickets: ticketData.tickets || [],
          // pagination: { total: users.length }
        });
      })
      .catch((error) => {
        logger.error(error);
      });
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    currentGroup: state.adminData.currentGroup,
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    clubId: state.adminData.allGroups[state.adminData.currentGroup].data.clubId,
  };
};

export default connect(mapStateToProps)(withRouter(ViewTicket));
