import React from "react";
import { ReceiptIcon, TransactionIcon } from "../../../utils/icons";

const ReceiptElement = ({ receiptUrl, stripeTransactionUrl }) => {
  return (
    <div>
      {stripeTransactionUrl ? (
        <div className="flex-center" style={{ gap: "6px" }}>
          {receiptUrl && (
            <div className="flex-center">
              <a href={receiptUrl} target="_blank" rel="noopener noreferrer">
                <img
                  style={{ color: "blue" }}
                  height={24}
                  width={24}
                  src={ReceiptIcon}
                  alt="receiptUrl"
                />
              </a>
            </div>
          )}
          {stripeTransactionUrl && (
            <div className="flex-center">
              <a
                href={stripeTransactionUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{ color: "blue" }}
                  height={24}
                  width={24}
                  src={TransactionIcon}
                  alt="transactionUrl"
                />
              </a>
            </div>
          )}
        </div>
      ) : (
        <div className="flex-center">
          <p>Admin</p>
        </div>
      )}
    </div>
  );
};

export default ReceiptElement;
