import { Popconfirm, Table } from "antd";
import moment from "moment";
import React from "react";
import { fbGetGroups } from "../../../services/firebaseService/endPoints/group";
import {
  deletePolls,
  fbGetPolls,
  postLive,
} from "../../../services/firebaseService/endPoints/poll";
import { withRouter } from "../../../utils/helper";

class SuperAdminPolls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      tableData: [],
      allGroups: [],
    };
  }

  postLive = (id) => {
    this.setState({
      isLoading: true,
    });

    postLive(id).then(() => {
      fbGetPolls().then((polls) => {
        this.setState({ tableData: polls, isLoading: false });
      });
    });
  };

  handleEdit = (row) => {
    this.props.router.navigate(`/superadmin/polls/edit/${row.id}`);
  };

  handleDelete = (row) => {
    this.setState({
      isLoading: true,
    });
    deletePolls(row.id).then(() => {
      fbGetPolls().then((polls) => {
        this.setState({ tableData: polls, isLoading: false });
      });
    });
  };

  render() {
    const columns = [
      {
        title: "Action",
        width: window.screen.width > 500 ? 150 : 100,
        key: "action",
        className: "header-white",

        render: (row) => {
          const currentTime = moment().unix();
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {row.isActive && currentTime <= row.closesOn ? (
                <span style={{ color: "red" }}>Live</span>
              ) : currentTime > row.closesOn ? (
                <span>Closed</span>
              ) : (
                <Popconfirm
                  title="Are you sure?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    this.postLive(row.id);
                  }}
                  onCancel={() => {}}
                >
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-danger mt-3 mb-2"
                  >
                    Post Live
                  </button>
                </Popconfirm>
              )}
              {(!row.isActive || currentTime <= row.closesOn) && (
                <>
                  <i
                    className="fa fa-pencil cursor-pointer"
                    onClick={() => {
                      this.handleEdit(row);
                    }}
                  />
                  <Popconfirm
                    title="Are you sure?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => {
                      this.handleDelete(row);
                    }}
                    onCancel={() => {}}
                  >
                    <i className="fa fa-trash cursor-pointer" />
                  </Popconfirm>
                </>
              )}
            </div>
          );
        },
      },
      {
        title: "Date Posted",
        key: "postedOn",
        dataIndex: "postedOn",
        className: "header-white",

        render: (data) => (data ? moment.unix(data).format("MM/DD/YY") : ""),
      },
      {
        title: "Time Remaining",
        key: "timeRemaining",
        dataIndex: "closesOn",
        className: "header-white",

        render: (data) => {
          let timeRemaining = data - moment().unix();
          if (!!!data || timeRemaining < 0) {
            return "";
          }

          if (timeRemaining < 60) {
            return `${timeRemaining} sec`;
          } else if (timeRemaining < 60 * 60) {
            timeRemaining = Math.round(timeRemaining / 60.0);
            return `${timeRemaining} minutes`;
          } else if (timeRemaining < 24 * 60 * 60) {
            timeRemaining = Math.round(timeRemaining / (60.0 * 60.0));
            return `${timeRemaining} hours`;
          } else {
            timeRemaining = Math.round(timeRemaining / (24 * 60 * 60));
            return `${timeRemaining} days`;
          }
        },
      },
      {
        title: "Groups",
        key: "groups",
        className: "header-white",

        dataIndex: "groups",
        render: (groups) => {
          let targets = [];
          groups.forEach((param) => {
            let group = this.state.allGroups.find(
              (group) => group?.id === param
            );
            if (group) {
              targets.push(group?.groupName);
            }
            let groupForLeague = this.state.allGroups.find(
              (group) => group?.leagueId === param
            );
            if (groupForLeague) {
              targets.push(group?.leagueName);
            }
            let groupForClub = this.state.allGroups.find(
              (group) => group?.clubId === param
            );
            if (groupForClub) {
              targets.push(groupForClub.clubName);
            }
          });
          targets = Array.from(new Set(targets));
          return (
            <ul style={{ listStyle: "none" }}>
              {targets.map((target) => {
                return <li>{target}</li>;
              })}
            </ul>
          );
        },
      },
      {
        title: "Question",
        key: "question",
        className: "header-white",

        dataIndex: "desc",
        render: (data) => data || "",
      },
      {
        title: "Responses(%)",
        key: "response",
        className: "header-white",

        render: (data) => {
          let currentTime = moment().unix();
          if (data.isActive || currentTime > data.closesOn) {
            return (
              <ul
                style={{
                  listStyle: "none",
                }}
              >
                {Object.entries(data.stats).map(([key, value]) => {
                  return <li>{`${key}   ${value}%`}</li>;
                })}
              </ul>
            );
          } else {
            return (
              <ul
                style={{
                  listStyle: "none",
                }}
              >
                {Array.from({ length: 5 }, (_, index) => index)
                  .filter(
                    (index) =>
                      data[`op${index + 1}`] && data[`op${index + 1}`] !== null
                  )
                  .map((index) => {
                    return <li>{data[`op${index + 1}`].name}</li>;
                  })}
              </ul>
            );
          }
        },
      },
      {
        title: "#responses",
        key: "entries",
        className: "header-white",

        dataIndex: "noOfPolls",
        render: (entries) => entries || 0,
      },
      {
        title: "Response Rate(%)",
        key: "resRate",
        className: "header-white",

        render: (data) => {
          if (data.targetMembersCount === 0) {
            return 0;
          }

          return (data.noOfPolls * 100) / data.targetMembersCount;
        },
      },
      {
        title: "Sponsor",
        key: "sponsorName",
        className: "header-white",

        dataIndex: "sponsorName",
        render: (name) => name || "",
      },
    ];

    return (
      <div
        className="mx-auto"
        style={{ width: "95%", display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <button
            type="button"
            className="btn btn-sm btn-outline-info mt-3 mb-2"
            style={{ alignSelf: "flex-end" }}
            onClick={() => {
              this.props.router.navigate("/superadmin/polls/create");
            }}
          >
            Create New
          </button>
        </div>

        <Table
          loading={this.state.isLoading}
          columns={columns}
          dataSource={this.state.tableData}
          bordered
          className="superadmin-logos-table table-backdrop mb-3"
        />
      </div>
    );
  }

  componentDidMount() {
    fbGetPolls().then((polls) => {
      this.setState({ isLoading: false, tableData: polls });
    });
    fbGetGroups().then((groups) => {
      this.setState({ allGroups: groups });
    });
  }
}

export default withRouter(SuperAdminPolls);
