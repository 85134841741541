import { doc, getDoc, setDoc, updateDoc } from "@firebase/firestore";
import { store } from "../../../../index";
import { applicationError } from "../../../../redux/actions/error";
import { db } from "../../connection";

export const fbGetGroupRegistrationCode = (groupId) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, "group", groupId);
    getDoc(docRef)
      .then((querySnapshot) => {
        if (querySnapshot.exists()) {
          resolve((querySnapshot.data() || {}).registrationCode);
        }
      })
      .catch((err) => {
        let message = "There was an error fetching the registration code";
        store.dispatch(
          applicationError({
            message,
            intensity: "low",
            err,
            errorCode: "firebase",
          })
        );
        reject({ message });
      });
  });
};

export const fbGetGroupPaymentDetails = (groupId) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, "group", groupId);
    getDoc(docRef)
      .then((querySnapshot) => {
        if (querySnapshot.exists()) {
          resolve((querySnapshot.data() || {}).paymentDetails || {});
        }
      })
      .catch((err) => {
        let message = "There was an error fetching payment details";
        store.dispatch(
          applicationError({
            message,
            intensity: "low",
            err,
            errorCode: "firebase",
          })
        );
        reject({ message });
      });
  });
};

export const fbChangeGroupRegistrationCode = (groupId, registrationCode) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, "group", groupId);
    createRegistrationLink(registrationCode, groupId)
      .then((link) => {
        setDoc(docRef, { registrationCode }, { merge: true })
          .then(() => resolve(link))
          .catch((err) => {
            let message = "There was an error updating the registration code";
            store.dispatch(
              applicationError({
                message,
                intensity: "high",
                err,
                errorCode: "firebase",
              })
            );
            reject({ message });
          });
      })
      .catch(reject);
  });
};

export const fbChangeGroupPaymentDetails = (groupId, paymentDetails) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, "group", groupId);
    setDoc(docRef, { paymentDetails }, { merge: true })
      .then((result) => {
        resolve();
      })
      .catch((err) => {
        let message = "There was an error updating payment details";
        store.dispatch(
          applicationError({
            message,
            intensity: "high",
            err,
            errorCode: "firebase",
          })
        );
        reject({ message });
      });
  });
};

export const fbGetGroupSettings = (groupId) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, "group", groupId);
    getDoc(docRef)
      .then((querySnapshot) => {
        if (querySnapshot.exists()) {
          resolve((querySnapshot.data() || {}).configuration);
        }
      })
      .catch((err) => {
        let message = "There was an error fetching group configurations";
        store.dispatch(
          applicationError({
            message,
            intensity: "low",
            err,
            errorCode: "firebase",
          })
        );
        reject({ message });
      });
  });
};

export async function createRegistrationLink(regCode, groupId, role) {
  const params = `groupId=${groupId}&regCode=${regCode}&role=${role}&type=register`;

  try {
    const encryptedParams = btoa(encodeURIComponent(params));
    const regLink = `https://chant-register.web.app?data=${encryptedParams}`;
    const docRef = doc(db, "group", groupId);
    await updateDoc(docRef, { registrationLink: regLink });
    return regLink;
  } catch (error) {
    throw error;
  }
}
