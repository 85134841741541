import React from "react";
import { FInput } from "../../commons/formFields/FInput";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Input, Checkbox } from "antd";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { connect } from "react-redux";
import {
  fbCreateChant,
  fbUpdateChant,
  fbGetChant,
} from "../../../services/firebaseService/endPoints/admin/ourChants";
import LoadingModal from "../../commons/LoadingModal";
import "./ourChants.css";
import { applicationError } from "../../../redux/actions/error";
import Search from "../../commons/Search";
import { getGroups } from "../../../services/firebaseService/endPoints/clubAdmin/groups";
import { continents, countries } from "countries-list";
import { withRouter } from "../../../utils/helper";

let countrtyNameCodeMap = {};
Object.keys(countries).forEach((key) => {
  countrtyNameCodeMap[key] = countries[key].name;
});

class CreateChant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lyrics: "",
      noteEditorState: EditorState.createEmpty(),
      title: "",
      videoLink: "",
      isLoading: true,
      editMode: false,
      checked: false,
      count: "0 groups",
      params: [],
      groups: [],
      allGroups: [],
    };
  }

  onEditorStateChange = (editorState, name) => {
    this.setState({
      [name]: editorState,
      // html: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    });
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.checked === true) {
      const result = {
        title: this.state.title,
        //   lyrics: draftToHtml(
        //     convertToRaw(this.state.lyricsEditorState.getCurrentContent())
        //   ),
        lyrics: this.state.lyrics,
        specialNote: draftToHtml(
          convertToRaw(this.state.noteEditorState.getCurrentContent())
        ),
        videoLink: this.state.videoLink,
        groups: this.state.groups.length > 0 ? this.state.groups : ["global"],
        type: "global",
      };

      this.setState({ isLoading: true });
      let promise;

      if (this.state.editMode) {
        promise = fbUpdateChant(
          this.props.currentGroup,
          this.props.router.params.id,
          result
        );
      } else {
        promise = fbCreateChant(this.props.currentGroup, result);
      }

      promise.then(() => {
        this.setState({ isLoading: false });
        this.props.router.navigate("/clubadmin/chants", { replace: true });
      });
    } else {
      alert("Accept the Terms & Conditions");
    }
  };

  handleChange = (event) => {
    this.setState({
      checked: event.target.checked,
    });
  };

  handleSearchParamChange = (list) => {
    let params = [];
    list.forEach((param) => {
      if (
        Object.values(continents).includes(param) ||
        Object.values(countrtyNameCodeMap).includes(param)
      ) {
        params.push(param);
      } else {
        let group = this.state.allGroups.find(
          (group) => group.groupName === param
        );
        if (group) {
          params.push(group.id);
        }
      }
    });
    this.setState((state) => {
      let groupCount =
        list.length > 0
          ? this.state.allGroups.filter(
              (group) =>
                params.includes(group.id) ||
                params.includes(countrtyNameCodeMap[group.supportersCountry]) ||
                params.includes(continents[group.supportersRegion])
            ).length
          : this.state.allGroups.length;
      return { groups: params, count: `${groupCount} groups` };
    });
  };

  render() {
    let primaryColor = "";
    if (
      this.props.groupData.data.configuration &&
      this.props.groupData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.groupData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <>
        <LoadingModal loading={this.state.isLoading} />
        <div className="mx-md-5 p-2 border-box">
          <div
            style={{
              width: "100%",
              paddingTop: 30,
            }}
          >
            <ul
              className="nav nav-tabs"
              style={{ color: "#ffffff", position: "relative" }}
            >
              <li
                className="nav-item"
                style={{ borderBottom: `4px solid ${primaryColor}` }}
              >
                <a
                  style={{
                    height: "100%",
                    fontWeight: "bold",
                    fontSize: "22px",
                  }}
                  name="current"
                >
                  Create Chant
                </a>
              </li>
            </ul>
          </div>
          <form
            onSubmit={this.handleSubmit}
            className="border rounded p-3 px-3 mt-2"
            style={{
              background: "#ffffff",
            }}
          >
            <div
              style={{
                margin: "-5px 30px",
              }}
            >
              <Search
                options={[
                  ...this.state.allGroups.map((group) => group.groupName),
                  ...Array.from(
                    new Set(
                      this.state.allGroups.map(
                        (group) => group.supportersRegion
                      )
                    )
                  ).map((code) => continents[code]),
                  ...Array.from(
                    new Set(
                      this.state.allGroups.map(
                        (group) => group.supportersCountry
                      )
                    )
                  ).map((code) => countrtyNameCodeMap[code]),
                ]}
                onSearchParamsChange={this.handleSearchParamChange}
                label="Select Groups"
                count={this.state.count}
                params={this.state.editMode && this.state.params}
                placeholder={
                  !(
                    Array.isArray(this.state.groups) &&
                    this.state.groups.length > 0
                  ) &&
                  "Default is 'worldwide'. Select groups by region (Europe) and/or name (Atlanta) to target"
                }
                placeholderStyleClass="default"
              />
            </div>
            <div className="col-md-6">
              <FInput
                label="Title"
                name="title"
                display="col"
                placeholder="Enter Title"
                onChange={this.handleInputChange}
                value={this.state.title}
                required
              />
            </div>

            {/* <div className="col">
              <div className="form-group col">
                <div>
                  <label>Lyrics</label>
                </div> */}
            {/* <Editor
                  editorState={this.state.lyricsEditorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="border rounded p-3 mt-0"
                  editorClassName="editorClassName"
                  editorStyle={{ minHeight: "200px" }}
                  placeholder={`Enter Lyrics`}
                  onEditorStateChange={editorState => {
                    this.onEditorStateChange(editorState, "lyricsEditorState");
                  }}
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "list",
                      "colorPicker",
                      "link"
                    ],
                    inline: {
                      options: ["bold", "italic", "underline", "strikethrough"]
                    },
                    blockType: {
                      options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"]
                    },
                    link: {
                      defaultTargetOption: "_blank",
                      dropdownClassName: "custom-link-dropdown"
                    }
                  }}
                  required
                  stripPastedStyles={true}
                /> */}

            <div className="col">
              <div className="form-group col">
                <label className="">Lyrics</label>
                {/* <input className="form-control form-control-sm" placeholder="Join us for a season kickoff party at Fado!"></input> */}
                <Input.TextArea
                  //   tabIndex="4"
                  style={{ fontSize: `18px` }}
                  placeholder="Enter Lyrics"
                  value={this.state.lyrics}
                  name="lyrics"
                  onChange={this.handleInputChange}
                  rows={8}
                  //   autosize={true}
                />
              </div>
            </div>

            <div className="col">
              <div className="form-group col">
                <div>
                  <label>Special Note</label>
                </div>
                <Editor
                  editorState={this.state.noteEditorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="border rounded p-3 mt-0"
                  editorClassName="editorClassName"
                  placeholder={`Enter special note`}
                  onEditorStateChange={(editorState) => {
                    this.onEditorStateChange(editorState, "noteEditorState");
                  }}
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "list",
                      "colorPicker",
                      "link",
                    ],
                    inline: {
                      options: ["bold", "italic", "underline", "strikethrough"],
                    },
                    blockType: {
                      options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
                    },
                    link: { defaultTargetOption: "_blank" },
                  }}
                  stripPastedStyles={true}
                />
              </div>
            </div>

            <div className="col">
              <FInput
                label="Video Link"
                name="videoLink"
                display="col"
                placeholder="Enter YouTube link"
                onChange={this.handleInputChange}
                value={this.state.videoLink}
              />
            </div>

            <div
              className="col"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Checkbox
                style={{ marginLeft: "18px", marginTop: "1px" }}
                checked={this.state.checked}
                onChange={this.handleChange}
                value="secondary"
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <span
                style={{
                  marginLeft: "15px",
                  fontsize: "50px",
                  color: "#FF0000",
                }}
              >
                *{" "}
              </span>
              <p style={{ marginLeft: "5px" }}>
                I confirm that this chant does not violate the code of conduct
                of my club, the league or the terms of Chant. This chant is in
                no way racist, homophobic, misogynistic, bigoted or otherwise
                aggressive.
              </p>
            </div>

            <div className="d-flex justify-content-end">
              {this.state.checked === true && this.state.title !== "" && (
                <button
                  className="btn btn-success"
                  tabIndex="14"
                  type="success"
                >
                  Submit
                </button>
              )}
              {(this.state.checked === false || this.state.title === "") && (
                <button
                  className="btn btn-secondary"
                  tabIndex="14"
                  type="success"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </>
    );
  }

  handleEditMode = () => {
    fbGetChant(
      this.props.router.params.group,
      this.props.router.params.id
    ).then((data) => {
      // TODO: current group issues on refresh
      if (data) {
        //   const contentBlockLyrics = htmlToDraft(data.lyrics);
        const contentBlockNote = htmlToDraft(data.specialNote);

        let result = {};
        //   if (contentBlockLyrics) {
        //     const contentState = ContentState.createFromBlockArray(
        //       contentBlockLyrics.contentBlocks
        //     );
        //     result.lyricsEditorState = EditorState.createWithContent(
        //       contentState
        //     );
        //   }

        if (contentBlockNote) {
          const contentState = ContentState.createFromBlockArray(
            contentBlockNote.contentBlocks
          );
          result.noteEditorState = EditorState.createWithContent(contentState);
        }
        let params = [];
        let groupCount = 0;
        if (Array.isArray(data.groups)) {
          groupCount = data.groups.includes("global")
            ? this.state.allGroups.length
            : this.state.allGroups.filter(
                (group) =>
                  data.groups.includes(group.id) ||
                  data.groups.includes(
                    countrtyNameCodeMap[group.supportersCountry]
                  ) ||
                  data.groups.includes(continents[group.supportersRegion])
              ).length;
          data.groups.forEach((param) => {
            let group = this.state.allGroups.find(
              (group) => group.id === param
            );
            if (group) {
              params.push(group.groupName);
            } else {
              params.push(param);
            }
          });
        }

        this.setState({
          title: data.title,
          lyrics: data.lyrics,
          videoLink: data.videoLink,
          isLoading: false,
          editMode: true,
          count: `${groupCount} groups`,
          params,
          ...result,
        });
      } else {
        this.props.dispatch(applicationError({ err: {}, errorCode: "404" }));
        return;
      }
    });
  };

  componentDidMount() {
    if (this.props.router.params.id) {
      if (this.props.currentGroup === this.props.router.params.group) {
        getGroups().then((groups) => {
          this.setState(
            { allGroups: groups, count: `${groups.length} groups` },
            () => {
              this.handleEditMode();
            }
          );
        });
      }
    } else {
      getGroups().then((groups) => {
        this.setState({
          isLoading: false,
          allGroups: groups,
          count: `${groups.length} groups`,
        });
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.currentGroup !== prevProps.currentGroup &&
      this.props.router.params.id &&
      this.state.isLoading
    ) {
      this.handleEditMode();
    }
  }
}

const mapStateToProps = (state) => {
  let currentGroup = Object.keys(state.user.userRoles).find(
    (key) => state.user.userRoles[key] === "parentAdmin"
  );
  return {
    currentGroup,
    groupData: state.adminData.allGroups[state.adminData.currentGroup],
  };
};

export default connect(mapStateToProps)(withRouter(CreateChant));
