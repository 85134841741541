import { Table } from "antd";
import moment from "moment";
import React from "react";
import { fbGetDonationDataForGroup } from "../../../services/firebaseService/endPoints/admin/partners";
import { connect } from "react-redux";
import {
  getCurrencyUnicode,
  switchCollapseHorizontal,
} from "../../../helperFunctions/util";
import { FInput } from "../../commons/formFields/FInput";

class DonationReport extends React.Component {
  state = {
    tableData: [],
    allData: [],
    filters: {
      search: "",
    },
    loading: false,
  };

  fetchData = () => {
    fbGetDonationDataForGroup(this.props.currentGroup).then((allData) => {
      let allTableData = allData.sort((a, b) => (b.date || 0) - (a.date || 0));
      this.setState({
        tableData: allTableData,
        allData: allTableData,
        loading: false,
      });
    });
  };
  handleFilterChange = (e) => {
    let filters = this.state.filters;
    let value = e.target.value;
    if (e.target.value) {
      filters[e.target.name] = e.target.value;
    } else {
      if (filters[e.target.name]) {
        delete filters[e.target.name];
      }
    }
    let tableData = this.state.allData.filter((record) => {
      return (
        record.userName.toLowerCase().includes(value.toLowerCase()) ||
        record.partnerName.toLowerCase().includes(value.toLowerCase()) ||
        record.userEmail.toLowerCase().includes(value.toLowerCase())
      );
    });
    this.setState({
      filters: filters,
      tableData: tableData,
    });
  };

  render() {
    let groupCurrencyISO = this.props.adminData.data.paymentDetails
      ? this.props.adminData.data.paymentDetails.currency || "USD"
      : "USD";
    let groupCurrency = getCurrencyUnicode(groupCurrencyISO);

    const columns = [
      {
        title: "Name",
        className: "header-white",
        key: "userName",
        dataIndex: "userName",
      },
      {
        title: "Amount",
        className: "header-white",
        key: "amount",
        dataIndex: "amount",
        render: (data) =>
          data ? `${groupCurrency}${parseFloat(data).toFixed(2)}` : "-",
      },
      {
        title: "Partner",
        className: "header-white",
        key: "partnerName",
        dataIndex: "partnerName",
      },
      {
        title: "Source",
        className: "header-white",
        key: "source",
        dataIndex: "source",
      },
      {
        title: "Date",
        className: "header-white",
        key: "timestamp",
        dataIndex: "date",
        render: (data) => {
          return data
            ? moment.unix(Math.floor(data / 1000)).format("MM/DD/YYYY")
            : "";
        },
      },
      {
        title: "Email",
        className: "header-white",
        key: "userEmail",
        dataIndex: "userEmail",
      },
    ];

    let totalAmount = this.state.tableData.reduce(
      (acc, val) => acc + val.amount,
      0
    );
    return (
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col filters-bottom-0">
              <div
                className="row mt-2 collapsible-horizontal collapsile-filter"
                id="partner-filter"
              >
                <a
                  class="icon"
                  onClick={() => switchCollapseHorizontal("partner-filter")}
                >
                  Filters
                </a>
                <div className="col-lg-3">
                  <div className="col">
                    <FInput
                      value={this.state.filters.search}
                      onChange={this.handleFilterChange}
                      name="search"
                      display="row"
                      placeholder="Filter by partner, name or email                                        "
                      tabIndex="2"
                      style={{
                        backgroundColor: "transparent",
                        border: "0px none transparent",
                        borderBottom: "2px solid white",
                        color: "white",
                        borderRadius: 0,
                        fontSize: 16,
                      }}
                      className="placeholder-white"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-flex"
            style={{
              justifyContent: "flex-end",
            }}
          >
            Total Amount:{" "}
            {`${groupCurrency}${parseFloat(totalAmount).toFixed(2)}`}
          </div>
          <Table
            bordered
            loading={this.state.loading}
            columns={columns}
            dataSource={this.state.tableData}
            pagination={{
              position: ["bottomLeft"],
              defaultPageSize: 50,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 50, 100],
            }}
            className="table-backdrop mt-2"
            bodyStyle={{
              backgroundColor: "#ffffff",
              borderRadius: 5,
            }}
          />
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentGroup !== prevProps.currentGroup) {
      this.setState({
        loading: true,
      });
      this.fetchData();
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentGroup: state.adminData.currentGroup,
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
  };
};

export default connect(mapStateToProps)(DonationReport);
