import { Modal, Spin } from "antd";
import React from "react";
import { connect } from "react-redux";
import { db } from "../../../services/firebaseService/connection";
import { getWebsiteConfig } from "../../../services/firebaseService/endPoints/admin/website";
import Map from "../../commons/formFields/Map";
import { getLocations } from "../../../services/firebaseService/endPoints/admin/content";
import { FSelect } from "../../commons/formFields/FSelect";
import { doc, setDoc } from "firebase/firestore";
import { postUpdateCDNUtil } from "../../../helperFunctions/util";
import logger from "../../../utils/logger";
import { CloseBorderlessIcon } from "../../../utils/icons";

class Sections extends React.Component {
  state = {
    newsIntro: "",
    eventsIntro: "",
    whereWeMeetIntro: "",
    currentKey: "",
    eventLocations: [{}],
    isSaving: false,
    showModal: false,
    allLocations: [],
  };

  handleInputChange = (e) => {
    let state = this.state;
    let name = e.target.name;
    if (name.includes(".")) {
      let keySections = e.target.name.split(".");
      state[keySections[0]][keySections[1]] = e.target.value;
    } else {
      state[name] = e.target.value;
    }

    this.setState({ ...state });
  };

  saveToFirebase = () => {
    const save = async () => {
      try {
        this.setState({ isSaving: true });

        const {
          newsIntro = "",
          eventsIntro = "",
          whereWeMeetIntro = "",
          eventLocations = [{}],
        } = this.state;

        const updateObj = {
          newsIntro,
          eventsIntro,
          whereWeMeetIntro,
          eventLocations,
        };

        const docRef = doc(db, "website", this.props.adminData.data.id);

        await setDoc(docRef, { sections: updateObj }, { merge: true });

        this.fetchData();
        postUpdateCDNUtil(this.props.currentGroup);
      } catch (error) {
        logger.error("Error saving to Firebase:", error);
      } finally {
        this.setState({ isSaving: false });
      }
    };

    save();
  };

  handleMapOK = (e) => {
    this.state.showModal = !this.state.showModal;
    //setState is called inn below funnction so no need too call above
    this.child.updateFormMapData();
  };
  handleModalChange = (e, currentKey) => {
    this.setState({
      showModal: !this.state.showModal,
      currentKey: currentKey,
    });
  };
  mapUpdate = (e) => {
    this.setState(
      (prevState) => {
        const venueData = {
          ...prevState[this.state.currentKey],
        };
        // placeId: e.placeId,
        venueData.address = (e.address + "").toString();
        venueData.placeId = (e.placeId + "").toString();
        venueData.longitude = e.long;
        venueData.latitude = e.lat;
        return { [this.state.currentKey]: venueData };
      },
      () => {
        logger.log(this.state);
      }
    );
  };

  addEventLocation = () => {
    this.setState({
      eventLocations: [...this.state.eventLocations, {}],
    });
  };

  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    return (
      <div
        className="border rounded"
        style={{
          backgroundColor: "#ffffff",
          // boxShadow: "0px 3px 10px 1px",
          marginTop: 30,
          padding: "15px 30px",
        }}
      >
        {this.state.showModal ? (
          <div>
            <Modal
              open={this.state.showModal}
              // closable={true}
              width="150vh"
              onOk={this.handleMapOK}
              onCancel={this.handleModalChange}
              // onClose={this.handleModalChange}
            >
              {
                <Map
                  onRef={(ref) => (this.child = ref)}
                  mapUpdate={this.mapUpdate}
                  lat={this.state[this.state.currentKey].latitude}
                  long={this.state[this.state.currentKey].longitude}
                  place_id={this.state[this.state.currentKey].placeId}
                />
              }
            </Modal>
          </div>
        ) : (
          <div></div>
        )}
        <p style={{ fontSize: "20", fontWeight: "bold", marginBottom: 0 }}>
          Sections
        </p>
        <p style={{ fontSize: 15 }}>
          Review settings to configure which sections / content will appear on
          your website.
        </p>
        <div className="mx-auto" style={{ width: "90%" }}>
          <div className="row">
            <div style={{ width: "100%" }}>
              <p style={{ marginBottom: -5, fontWeight: "bold" }}>
                News & Announcements
              </p>
            </div>
            <div className="px-5" style={{ width: "100%" }}>
              <div className="row" style={{ width: "100%" }}>
                <div style={{ flex: 1 }}>
                  <p style={{ marginBottom: -5 }}>Intro</p>
                </div>
                <div
                  style={{ flex: 3, display: "flex", flexDirection: "column" }}
                >
                  <div>
                    <textarea
                      maxLength={1000}
                      rows={2}
                      style={{
                        borderRadius: 5,
                        width: "100%",
                        borderColor: "#ced4da",
                      }}
                      value={this.state.newsIntro}
                      name="newsIntro"
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div style={{ flex: 1 }}>
                  <p style={{ marginBottom: -5 }}>News & Updates</p>
                </div>
                <div
                  style={{ flex: 3, display: "flex", flexDirection: "column" }}
                >
                  <div>
                    <p style={{ fontSize: 14 }}>
                      News and Updates are created via the ‘Content’ section of
                      the admin dashboard. Those set as ‘Public on web’ will
                      appear in this section of your website.
                    </p>
                  </div>
                  <div></div>
                </div>
              </div>
              <div className="row">
                <div style={{ flex: 1 }}>
                  <p style={{ marginBottom: -5 }}>Announcements</p>
                </div>
                <div
                  style={{ flex: 3, display: "flex", flexDirection: "column" }}
                >
                  <div>
                    <p style={{ fontSize: 14 }}>
                      Pin announcements from the app by clicking ‘Pin to
                      website’ on any chat post. Unpin by deselecting from the
                      app or by clicking ‘x’ on pinned announcements below.
                    </p>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div style={{ flex: 1 }}>
              <p style={{ marginBottom: -5, fontWeight: "bold" }}>Events</p>
            </div>
            <div style={{ flex: 3, display: "flex", flexDirection: "column" }}>
              <div className="form-group col">
                <p style={{ fontSize: 14 }}>
                  Events are public on the web by default unless ‘Show on web’
                  is deselected when creating the item.
                </p>
              </div>
            </div>
          </div>
          <div className="row" style={{ width: "100%" }}>
            <div className="px-5" style={{ width: "100%" }}>
              <div className="row">
                <div style={{ flex: 1 }}>
                  <p style={{ marginBottom: -5 }}>Intro</p>
                </div>
                <div
                  style={{ flex: 3, display: "flex", flexDirection: "column" }}
                >
                  <div className="form-group col">
                    <textarea
                      maxLength={1000}
                      rows={2}
                      style={{
                        borderRadius: 5,
                        width: "100%",
                        borderColor: "#ced4da",
                      }}
                      value={this.state.eventsIntro}
                      name="eventsIntro"
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <p style={{ marginBottom: -5, fontWeight: "bold" }}>
                Where We Meet
              </p>
            </div>
            <div className="px-5" style={{ width: "100%" }}>
              <div className="row">
                <div style={{ flex: 1 }}>
                  <p style={{ marginBottom: -5 }}>Intro</p>
                </div>
                <div
                  style={{ flex: 3, display: "flex", flexDirection: "column" }}
                >
                  <div className="form-group col">
                    <textarea
                      maxLength={1000}
                      rows={2}
                      style={{
                        borderRadius: 5,
                        width: "100%",
                        borderColor: "#ced4da",
                      }}
                      value={this.state.whereWeMeetIntro}
                      name="whereWeMeetIntro"
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div>
                <div
                  style={{
                    paddingRight: 15,
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {this.state.eventLocations.map((item, index) => (
                    <div className="my-row">
                      <div className="col-10">
                        <FSelect
                          flex
                          label={`Event Location #${index + 1}`}
                          name="eventLocations"
                          display="row"
                          tabIndex="2"
                          value={item?.id || null}
                          onChange={(e) => {
                            if (e.target.value) {
                              let value = e.target.value;
                              let location = this.state.allLocations.find(
                                (e) => e.id === value
                              );
                              let eventLocations = this.state.eventLocations;
                              eventLocations[index] = location;
                              this.setState({
                                eventLocations: eventLocations,
                              });
                            } else {
                              let eventLocations = this.state.eventLocations;
                              eventLocations.splice(index, 1);
                              this.setState({
                                eventLocations: eventLocations,
                              });
                            }
                          }}
                          placeholder={"Select location"}
                          dataList={this.state.allLocations}
                          listBuilder={(location) => {
                            return (
                              <option
                                key={location.name}
                                value={location.id + ""}
                              >
                                {location.type}:{location.name}
                              </option>
                            );
                          }}
                        />
                      </div>
                      {index > 0 && (
                        <div className="col-2" style={{ paddingLeft: "10px" }}>
                          <img
                            onClick={() => {
                              let eventLocations = this.state.eventLocations;
                              eventLocations.splice(index, 1);
                              this.setState({
                                eventLocations: eventLocations,
                              });
                            }}
                            alt="delete location"
                            height={24}
                            width={24}
                            style={{ cursor: "pointer" }}
                            src={CloseBorderlessIcon}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                <div className="my-row mt-1">
                  <button
                    type="button"
                    class="btn btn-link"
                    onClick={this.addEventLocation}
                  >
                    <i className="fa fa-plus"></i> Add Event Location
                  </button>
                </div>
              </div>

              <div className="my-row" style={{ marginTop: -10 }}>
                <div className="col-md-4"></div>
                <div>
                  <p style={{ fontSize: 13 }}>
                    Add Locations in Content / Locations section of the
                    dashboard.
                  </p>
                </div>
              </div>

              {/* <div className="row">
                                <div style={{ flex: 1 }}>
                                    <p style={{ marginTop: 0 }}>Watch Parties</p >
                                </div>
                                <div style={{ flex: 3 }}>
                                    <FInput
                                        value={this.state.watchparty.location}
                                        onChange={this.handleInputChange}
                                        name="watchparty.location"
                                        display="col"
                                        placeholder="(optional) Enter Location Name"
                                        tabIndex="2"
                                    /> 
                                    <FInput
                                        value={this.state.watchparty.locationUrl}
                                        onChange={this.handleInputChange}
                                        name="watchparty.locationUrl"
                                        display="col"
                                        placeholder="(optional) Enter location url"
                                        tabIndex="2"
                                    />
                                    <div style={{ marginLeft: '15px', marginRight: '15px', padding: '5px', borderRadius: '5px', border: '1px solid grey' }}>
                                        <a onClick={(e) => this.handleModalChange(e, 'watchparty')}
                                            tabIndex="5"
                                            style={{ textDecoration: 'none' }}
                                        >
                                            {this.state.watchparty.address}
                                        </a>
                                    </div>
                                    <div className="form-group col">
                                        <div>
                                            <label></label>
                                        </div>
                                        <textarea
                                            className="placeholder-light"
                                            style={{
                                                borderRadius: 5,
                                                borderColor: '#ced4da',
                                                width: '100%'
                                            }}
                                            value={this.state.watchparty.description}
                                            // rows={3}
                                            maxLength={250}
                                            name="watchparty.description"
                                            placeholder="(optional) brief description (max 250 chars)"
                                            tabIndex="2"
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <ImageUploadButton
                                            text="Add image or logo"
                                            onDelete={(file) => {
                                                if (file.url) {
                                                    let path = `${this.props.adminData.data.groupName}/website/`;
                                                    deleteImage(`${path}sections/watchparty`, 'sections.watchparty.image', this.props.currentGroup)
                                                }
                                            }}
                                            images={[this.state.watchparty.image]}
                                            aspectRatio={1}
                                            onSave={(images) => {
                                                let watchparty = this.state.watchparty
                                                watchparty.image = images[0]
                                                this.setState({
                                                    watchparty
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div> */}
              {/* <div className="row">
                                <div style={{ flex: 1 }}>
                                    <p style={{ marginTop: 0 }}>Tailgates</p >
                                </div>
                                <div style={{ flex: 3 }}>
                                    <FInput
                                        value={this.state.tailgate.location}
                                        onChange={this.handleInputChange}
                                        name="tailgate.location"
                                        display="col"
                                        placeholder="(optional) Enter Location Name"
                                        tabIndex="2"
                                    />
                                    <FInput
                                        value={this.state.tailgate.locationUrl}
                                        onChange={this.handleInputChange}
                                        name="tailgate.locationUrl"
                                        display="col"
                                        placeholder="(optional) Enter Location Url"
                                        tabIndex="2"
                                    />
                                    <div style={{ marginLeft: '15px', marginRight: '15px', padding: '5px', borderRadius: '5px', border: '1px solid grey' }}>
                                        <a onClick={(e) => this.handleModalChange(e, 'tailgate')}
                                            tabIndex="5"
                                            style={{ textDecoration: 'none' }}
                                        >
                                            {this.state.tailgate.address}
                                        </a>
                                    </div>
                                    
                                    <div className="form-group col">
                                        <div>
                                            <label></label>
                                        </div>
                                        <textarea
                                            className="placeholder-light"
                                            style={{
                                                borderRadius: 5,
                                                borderColor: '#ced4da',
                                                width: '100%',
                                            }}
                                            value={this.state.tailgate.description}
                                            // rows={3}
                                            maxLength={250}
                                            name="tailgate.description"
                                            placeholder="(optional) brief description (max 250 chars)"
                                            tabIndex="2"
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group col">
                                        <ImageUploadButton
                                            text="Add image or logo"
                                            images={[this.state.tailgate.image]}
                                            aspectRatio={1}
                                            onSave={(images) => {
                                                let tailgate = this.state.tailgate
                                                tailgate.image = images[0]
                                                this.setState({
                                                    tailgate
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
          {/* <div className="row">
                        <div style={{ flex: 1 }}>
                            <p style={{ marginBottom: -10 }}>Join</p >
                        </div>
                        <div style={{ flex: 3, display: 'flex', flexDirection: 'column' }}>
                            <div>
                                <p style={{ fontSize: 14 }}>
                                    (optional) Create a member registration page using the form under Website/Member Registration. If form is published the fields below are required and a ‘Join’ button will appear taking visitors to the form.
                                </p>
                            </div>
                        </div>
                    </div> */}
          {/* <div style={{ width: '100%' }}>
                        <div className="row">
                            <div style={{ flex: 1 }}>
                                <p style={{ paddingLeft: '10%', marginBottom: -10 }}>Brief Summary</p >
                                <span style={{ paddingLeft: '10%', fontSize: 12 }}>(max 350 characters)</span>
                            </div>
                            <div style={{ flex: 3 }}>
                                <textarea
                                    className="placeholder-light"
                                    style={{
                                        borderRadius: 5,
                                        borderColor: '#ced4da',
                                        width: '100%',
                                    }}
                                    value={this.state.join.desc}
                                    // rows={3}
                                    maxLength={350}
                                    name="join.desc"
                                    placeholder="Required only if using member registration form. Enter a very brief summary of membership and benefits here, then enter full details when creating the registration form"
                                    tabIndex="2"
                                    onChange={this.handleInputChange}
                                />
                            </div>
                        </div>
                    </div> */}
        </div>
        <div className="d-flex justify-content-center">
          <div className="p-2">
            <button
              className="default-text-color"
              style={{
                backgroundColor: primaryColor,
                borderRadius: 5,
                border: "0px none",
                boxShadow: "transparent 0px 0px 0px",
                outline: "none",
                padding: "5px 10px",
                width: "200px",
              }}
              disabled={this.state.isSaving}
              onClick={this.saveToFirebase}
            >
              {this.state.isSaving ? (
                <Spin size="small" spinning={true} />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }

  getState(websiteConfig) {
    if (websiteConfig && websiteConfig.sections) {
      let eventLocations;

      if (
        !websiteConfig.sections.eventLocations ||
        websiteConfig.sections.eventLocations?.length === 0
      ) {
        eventLocations = [{}];
      } else {
        eventLocations = websiteConfig.sections.eventLocations;
      }

      return {
        newsIntro: websiteConfig.sections.newsIntro,
        eventsIntro: websiteConfig.sections.eventsIntro,
        whereWeMeetIntro: websiteConfig.sections.whereWeMeetIntro,
        eventLocations: eventLocations,
      };
    } else {
      return {
        newsIntro: "",
        eventsIntro: "",
        whereWeMeetIntro: "",
        watchparty: {
          location: "",
          address: "",
          locationUrl: "",
          placeId: "",
          latitude: "",
          longitude: "",
          description: "",
          image: null,
        },
        tailgate: {
          location: "",
          address: "",
          placeId: "",
          latitude: "",
          longitude: "",
          locationUrl: "",
          description: "",
          image: null,
        },
      };
    }
  }

  fetchData() {
    getWebsiteConfig(this.props.currentGroup).then((data) => {
      this.setState({ ...this.getState(data) });
    });
    getLocations(this.props.currentGroup).then((allLocations) => {
      this.setState({
        allLocations: allLocations,
      });
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentGroup !== this.props.currentGroup) {
      this.fetchData();
    }
  }
}

const mapStateToProps = (state) => {
  return {
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    currentGroup: state.adminData.currentGroup,
  };
};

export default connect(mapStateToProps)(Sections);
