/* eslint-disable jsx-a11y/anchor-is-valid */
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { DatePicker, Table } from "antd";
import dayjs from "dayjs";
import { collection, getDocs, query, where } from "firebase/firestore";
import React from "react";
import { connect } from "react-redux";
import ResponsiveMobileTab from "../../../CommonComponents/ResponsiveMobileTab/ResponsiveMobileTab";
import { getCurrencyUnicode } from "../../../helperFunctions/util";
import { db } from "../../../services/firebaseService/connection";
import { getAllPackages } from "../../../services/firebaseService/endPoints/admin/members";
import { fbGetDonationDataForGroup } from "../../../services/firebaseService/endPoints/admin/partners";
import { parseString } from "../../../services/firebaseService/endPoints/admin/scripts";
import {
  getOrders,
  getStoreItems,
} from "../../../services/firebaseService/endPoints/admin/store";
import { getFilteredMembersFromLocal } from "../../../services/indexedDb/members";
import { convertStringObjectToJson, isMobile } from "../../../utils/helper";
import { FInput } from "../../commons/formFields/FInput";
import { FSelect } from "../../commons/formFields/FSelect";
import "./PurchaseReport.css";
import ReceiptElement from "./ReceiptElement";

const { RangePicker } = DatePicker;

// const defaultStartDate = Date.now() - 30 * 24 * 60 * 60 * 1000;
const defaultEndDate = Date.now();

class PurchaseReport extends React.Component {
  state = {
    loading: false,
    allMembers: [],
    allStoreOrders: [],
    allDonations: [],
    allTableData: [],
    tableData: [],
    allPackages: [],
    filterPackages: [],
    filtersExpanded: false,
    filters: {
      search: "",
      packageId: "",
      type: "",
      autoRenewal: null,
    },
    status: "",
    dateRange: {
      startDate: null,
      endDate: null,
    },
    types: [],
  };

  onFilterChange = () => {
    let tableData = [];
    let filters = this.state.filters;
    for (let data of this.state.allTableData) {
      let predicate = true;
      if (filters.search) {
        if (
          !data.name.toLowerCase().includes(filters.search.toLowerCase()) &&
          !data.email.toLowerCase().includes(filters.search.toLowerCase())
        ) {
          predicate = false;
        }
      }
      if (predicate && filters.packageId) {
        if (data.package !== filters.packageId) {
          predicate = false;
        }
      }
      if (predicate && filters.autoRenewal) {
        if (data.autoRenewal.length === 0) {
          predicate = false;
        }
      }

      if (predicate && filters.type) {
        if (data.type !== filters.type) {
          predicate = false;
        }
      }

      if (predicate) {
        tableData.push(data);
      }
    }

    this.setState({
      tableData,
    });
  };

  render() {
    let groupCurrencyISO = this.props.adminData.data.paymentDetails
      ? this.props.adminData.data.paymentDetails.currency || "USD"
      : "USD";
    let groupCurrency = getCurrencyUnicode(groupCurrencyISO);

    const totalRevenue = this.state.tableData.reduce((acc, curr) => {
      acc = acc + (Number(curr?.amount) || 0);
      return acc;
    }, 0);

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 150,
        className: "header-white",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        className: "header-white",
        width: 200,
        render: (data) => data || "-",
      },
      {
        title: "Item",
        dataIndex: "item",
        key: "item",
        width: 300,
        className: "header-white",
        render: (data) => data || "-",
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        className: "header-white",
        width: 150,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        className: "header-white",
        width: 100,
        render: (data) => (data ? `${groupCurrency}${data}` : "-"),
      },
      {
        title: "Donation",
        dataIndex: "donationIncluded",
        key: "donationIncluded",
        className: "header-white",
        width: 100,
        render: (data) => (data > 0 ? `${groupCurrency}${data}` : "-"),
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        className: "header-white",
        width: 150,
        sorter: (a, b) => dayjs(b.date).unix() - dayjs(a.date).unix(),
        render: (data) =>
          dayjs.unix(data ? Math.floor(data / 1000) : 0).format("MMM DD, YYYY"),
      },
      {
        title: "Receipt",
        dataIndex: "receipt",
        key: "receipt",
        className: "header-white",
        width: 150,
        render: (data) => (
          <ReceiptElement
            stripeTransactionUrl={data.txnUrl}
            receiptUrl={data.receiptUrl}
          />
        ),
      },
      {
        title: "Order #",
        dataIndex: "orderNo",
        key: "orderNo",
        width: 150,
        className: "header-white",
        render: (data) => data || "-",
      },
      {
        title: "Auto-renew",
        dataIndex: "autoRenewal",
        key: "autoRenew",
        width: 150,
        className: "header-white",
        render: (data) => data || "-",
      },
      // {
      //   title: "Other Members",
      //   dataIndex: "otherMembers",
      //   key: "otherMembers",
      //   className: "header-white",
      //   render: (data) => (data || []).join(","),
      // },

      // {
      //   title: "Auto-renew Cancelled?",
      //   dataIndex: "autoRenewCancelled",
      //   key: "autoRenewCancelled",
      //   className: "header-white",
      //   render: (data) => (data ? "Yes" : "No"),
      // },
    ];

    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }

    return (
      <div className="container purchases-container">
        <nav className="promote-nav">
          <ul
            style={{
              listStyle: "none",
              // padding: "2rem 3rem 0rem",
              padding: "0",
              paddingBottom: "1rem",
              margin: 0,
              display: "flex",
            }}
          >
            <ResponsiveMobileTab
              menuItems={[
                {
                  key: "purchases",
                  label: "Purchases",
                },
              ]}
              param={"purchases"}
              primaryColor="var(--primary-background)"
              tabIndex={0}
            />
          </ul>
        </nav>

        <div className="pt-2">
          <p>
            The Purchases report provides an overview of transactions for
            membership packages, store, tickets, donations and giveaways.
            Historical data may be incomplete. The{" "}
            <a
              href="https://dashboard.stripe.com/dashboard"
              target="_blank"
              rel="noopener noreferrer"
            >
              Stripe dashboard
            </a>{" "}
            remains the primary resource to review and manage transactions
            (additional detail, refunds, invoices, fees, etc).
          </p>
        </div>

        <div className="col filters-bottom-0">
          <div id="purchase-filter">
            {isMobile() ? (
              <div
                className="purchase-filters-header"
                onClick={() =>
                  this.setState({
                    filtersExpanded: !this.state.filtersExpanded,
                  })
                }
                style={
                  this.state.filtersExpanded
                    ? { borderRadius: "0%" }
                    : undefined
                }
              >
                <span>Filters</span>
                {this.state.filtersExpanded ? (
                  <UpOutlined style={{ marginLeft: "auto" }} />
                ) : (
                  <DownOutlined style={{ marginLeft: "auto" }} />
                )}
              </div>
            ) : null}

            {(this.state.filtersExpanded || !isMobile()) && (
              <div className="purchases-filter-container">
                <div
                  style={{
                    margin: "0px 16px",
                  }}
                >
                  <FInput
                    value={this.state.filters.search}
                    onChange={(e) => {
                      let filters = this.state.filters;
                      filters.search = e.target.value;
                      this.setState(
                        {
                          filters,
                        },
                        () => {
                          this.onFilterChange();
                        }
                      );
                    }}
                    name="search"
                    display="row"
                    placeholder="Search name / email"
                    tabIndex="2"
                    style={{
                      color: "black",
                      fontSize: 16,
                      padding: "0px 15px",
                    }}
                  />
                </div>
                <div>
                  <FSelect
                    value={this.state.filters.packageId}
                    onChange={(e) => {
                      let filters = this.state.filters;
                      filters.packageId = e.target.value;
                      this.setState(
                        {
                          filters,
                        },
                        () => {
                          this.onFilterChange();
                        }
                      );
                    }}
                    dataList={this.state.filterPackages}
                    dataKey="id"
                    dataValue="name"
                    style={{
                      backgroundColor: "transparent",
                      border: "0px none transparent",
                      color: "white",
                      borderRadius: 0,
                      fontSize: 16,
                      width: "200px",
                    }}
                    name="status"
                    display="row"
                    placeholder="Package"
                    tabIndex="2"
                  />
                </div>
                <div>
                  <FSelect
                    value={this.state.filters.type}
                    onChange={(e) => {
                      let filters = this.state.filters;
                      filters.type = e.target.value;
                      this.setState(
                        {
                          filters,
                        },
                        () => {
                          this.onFilterChange();
                        }
                      );
                    }}
                    dataList={this.state.types}
                    dataKey="key"
                    dataValue="type"
                    style={{
                      backgroundColor: "transparent",
                      border: "0px none transparent",
                      color: "white",
                      borderRadius: 0,
                      fontSize: 16,
                      width: "200px",
                    }}
                    name="Type"
                    display="row"
                    placeholder="Type"
                    tabIndex="3"
                  />
                </div>
                <div>
                  <div>
                    <RangePicker
                      tabIndex="2"
                      disabledDate={(current) =>
                        current && current > dayjs().endOf("day")
                      }
                      onChange={(value) => {
                        if (value) {
                          let dateRange = this.state.dateRange;
                          dateRange.startDate = value[0]?.valueOf();
                          dateRange.endDate = value[1]?.valueOf();

                          const purchases = this.state.allTableData.filter(
                            (item) => {
                              return (
                                item.date >= dateRange.startDate &&
                                item.date <= dateRange.endDate
                              );
                            }
                          );

                          this.setState({
                            dateRange,
                            tableData: purchases,
                          });
                        } else {
                          this.setState({
                            dateRange: { startDate: null, endDate: null },
                            tableData: this.state.allTableData,
                          });
                        }
                      }}
                      className="col"
                      size={15}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              gap: "12px",
              paddingTop: "8px",
            }}
          >
            <p style={{ marginBottom: 0 }}>
              <span style={{ fontWeight: "bold" }}>Purchases:</span>{" "}
              <span style={{ color: "#4e4e4e" }}>
                {this.state.tableData.length}
              </span>
            </p>
            <p style={{ marginBottom: 0 }}>
              <span style={{ fontWeight: "bold" }}>Revenue:</span>{" "}
              <span style={{ color: "#4e4e4e" }}>
                {totalRevenue
                  ? `${groupCurrency}${totalRevenue.toFixed(2)}`
                  : "-"}
              </span>
            </p>
          </div>
        </div>
        <div className="col mt-2 p-0">
          <Table
            bordered
            loading={this.state.loading}
            columns={columns}
            dataSource={this.state.tableData}
            bodyStyle={{
              backgroundColor: "#ffffff",
            }}
            scroll={{ x: 1600 }}
            pagination={{
              position: ["bottomLeft"],
              defaultPageSize: 50,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 50, 100],
            }}
            className="table-backdrop mb-3"
          />
        </div>
      </div>
    );
  }

  fetchData = async () => {
    this.setState({
      loading: true,
    });

    let tableData = [];
    let queryFilters = [];

    if (this.state.dateRange.startDate) {
      queryFilters.push(
        where("timestamp", ">=", this.state.dateRange.startDate)
      );
    }
    if (this.state.dateRange.endDate) {
      queryFilters.push(where("timestamp", "<=", this.state.dateRange.endDate));
    } else {
      queryFilters.push(where("timestamp", "<=", defaultEndDate));
    }
    if (this.state.status) {
      queryFilters.push(where("status", "==", this.state.status));
    }

    let dbQuery = query(
      collection(db, "transaction_record", this.props.currentGroup, "records"),
      ...queryFilters
    );

    let allTransactions = await getDocs(dbQuery).then((snap) => {
      let transactions = [];
      for (let doc of snap.docs) {
        let data = doc.data();
        data.transactionId = doc.id;
        transactions.push(data);
      }

      return transactions;
    });

    let successfulTransactions = allTransactions?.filter(
      (txn) => txn.status === "success"
    );

    const uniqueTypes = [
      ...new Set(
        successfulTransactions.map((item) =>
          item.paymentType ? item.paymentType : this.getTransactionType(item)
        )
      ),
    ];

    const groupTypes = uniqueTypes
      .map((type) => ({ type, key: type }))
      .filter((item) => item.type);

    this.setState({
      types: [...groupTypes],
    });

    let successfulTransactionsSorted = successfulTransactions.sort(
      (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
    );

    const uniqueApiPackageIds = new Set(
      successfulTransactionsSorted
        .map((item) => item?.packageId)
        .filter((id) => id !== undefined && id !== "")
    );

    const filteredPackages = this.state.allPackages.filter((pkg) =>
      uniqueApiPackageIds.has(pkg.id)
    );

    this.setState({
      filterPackages: filteredPackages,
    });

    for (let transaction of successfulTransactionsSorted) {
      let promises = [
        this.fetchUserData(transaction.transactionId),
        this.fetchStoreData(transaction.transactionId),
        this.fetchDonationData(transaction.transactionId),
      ];

      await Promise.all(promises).then(
        ([userData, storeData, donationData]) => {
          tableData.push(
            this.preparePurchaseData(
              transaction,
              userData,
              storeData,
              donationData,
              this.state.allPackages
            )
          );
        }
      );
    }

    this.setState({
      allTableData: tableData,
      tableData: tableData,
      loading: false,
    });
  };

  fetchUserData = async (transactionId) => {
    let data = {};
    let requiredData = this.state.allMembers.filter(
      (data) => data.paymentIntentId === transactionId
    );
    if (requiredData.length > 0) {
      data = requiredData[0];
    }
    return data;
  };

  fetchStoreData = async (transactionId) => {
    let storeData = {};
    let requiredStoreOrder = this.state.allStoreOrders.filter(
      (data) => data.paymentIntentId === transactionId
    );
    if (requiredStoreOrder.length > 0) {
      storeData.orderNo = requiredStoreOrder[0].orderNo;
      storeData.items = requiredStoreOrder[0].items || [];
    }
    return storeData;
  };

  fetchDonationData = async (transactionId) => {
    let purchases = {
      hasDonation: false,
      donationAmount: 0,
    };
    let requiredDonationData = this.state.allDonations.filter(
      (data) => data.paymentIntentId === transactionId
    );
    if (requiredDonationData.length > 0) {
      purchases.hasDonation = true;
      requiredDonationData.forEach((data) => {
        purchases.donationAmount += data.amount;
      });
    }
    return purchases;
  };

  getUserDetailsFromMetadata = (type, transaction) => {
    const userDetails = {
      metaDataEmail: "",
      metaDataName: "",
      metaDataAmount: undefined,
      metaDataPartnerName: "",
      metaDataGiveawayName: "",
    };

    const params = {
      donation: "donationParams",
      giveaway: "giveawayParams",
      tickets: "ticketParams",
      "tickets-web": "ticketParams",
      registration: "subscriptionParams",
    };

    let data;
    try {
      data = JSON.parse(transaction?.[params?.[type]]);
    } catch (error) {
      return userDetails;
    }

    let items = [];

    if (data?.ticketsBuyingData) {
      Object.values(data?.ticketsBuyingData).forEach((item) =>
        items.push(item.name)
      );
    }

    if (type === "registration") {
      userDetails.metaDataEmail = data?.customerEmailId;
      userDetails.metaDataName = data?.customerName;
      userDetails.metaDataAmount = null;
      userDetails.metaDataPartnerName = data?.partnerName;
      userDetails.metaDataGiveawayName = data?.giveawayName;
    } else {
      userDetails.metaDataEmail = data?.userEmail;
      userDetails.metaDataName = data?.userName;
      userDetails.metaDataAmount = Number(data?.totalCost)?.toFixed(2);
      userDetails.metaDataPartnerName = data?.partnerName;
      userDetails.metaDataGiveawayName = data?.giveawayName;
      userDetails.metaDataTicketItems = items;
    }

    return userDetails;
  };

  getSubscriptionMetaData = (data) => {
    const userDetails = {
      metaDataEmail: "",
      metaDataName: "",
    };

    const key = Object.keys(data.metadata).find((item) =>
      item.startsWith("filled")
    );

    let res;

    try {
      if (key) {
        res = parseString(data.metadata[key]);
        userDetails.metaDataEmail = res.email;
        userDetails.metaDataName = res.fullName;
      }
    } catch {
      return userDetails;
    }

    return userDetails;
  };

  getRegistrationPackageName = (data, packages) => {
    const key = Object.keys(data)?.find((item) => item?.startsWith("package-"));

    const parsedObject = convertStringObjectToJson(data?.[key]);

    let packageName = packages?.find(
      (pack) => pack?.id === parsedObject?.id
    )?.name;

    return packageName;
  };

  preparePurchaseData = (
    transaction,
    userData,
    storeData,
    donationData,
    packages
  ) => {
    let type = transaction.paymentType
      ? transaction.paymentType
      : this.getTransactionType(transaction);
    let items = [];

    let metaDataUserDetails;
    let subscriptionUserDetails;
    let autoRenewalFields = [];

    if (
      type === "donation" ||
      type === "giveaway" ||
      type === "tickets" ||
      type === "tickets-web" ||
      type === "registration"
    ) {
      metaDataUserDetails = this.getUserDetailsFromMetadata(type, transaction);
    }

    if (transaction?.subscription) {
      subscriptionUserDetails = this.getSubscriptionMetaData(transaction);
    }

    if (type === "registration" && transaction?.subscription) {
      autoRenewalFields.push(type);
    }

    if (transaction?.metadata?.packageSeason) {
      const packageSeason = transaction?.metadata?.packageSeason;
      const pack = packages?.find((p) => p.packageTimeline[packageSeason]);
      const packageName = pack?.packageTimeline[packageSeason]?.name;
      if (packageName) items.push(packageName);
    } else if (transaction?.packageId) {
      const pack = packages?.find((p) => p.id === transaction.packageId);
      const packageName = Array.isArray(pack?.name)
        ? pack.name.join(", ")
        : pack?.name || "";
      items.push(packageName);
    } else if (transaction?.data || transaction?.metadata) {
      const packageName = this.getRegistrationPackageName(
        transaction?.data || transaction?.metadata,
        packages
      );
      packageName?.length > 0 && items.push(packageName);
    }

    if (storeData.items && storeData.items.length > 0) {
      items.push(...(storeData.items || []).map((item) => item.itemName));
    }
    // if (donationData.hasDonation && donationData.donationAmount) {
    //   items.push("Donation");
    // }
    if (metaDataUserDetails?.metaDataGiveawayName) {
      items.push(metaDataUserDetails.metaDataGiveawayName);
    }

    if (metaDataUserDetails?.metaDataPartnerName) {
      items.push(metaDataUserDetails.metaDataPartnerName);
    }

    if (
      metaDataUserDetails?.metaDataTicketItems &&
      metaDataUserDetails?.metaDataTicketItems?.length > 0
    ) {
      items.push(metaDataUserDetails?.metaDataTicketItems);
    }

    let stripeTransactionUrl;
    if (transaction?.transactionId?.startsWith("pi_")) {
      stripeTransactionUrl = `https://dashboard.stripe.com/connect/accounts/${this.props?.adminData?.data?.paymentDetails?.stripeAccountId}/payments/${transaction?.transactionId}`;
    }

    const autoRenewals =
      transaction?.subscribedProduct?.length > 0
        ? transaction?.subscribedProduct?.join(", ")
        : autoRenewalFields?.length > 0
          ? autoRenewalFields?.join(", ")
          : "";

    if (!type) {
      this.setState((prevState) => {
        const updatedTypes = [
          ...prevState.types,
          { key: "unknown", type: "unknown" },
        ];
        return { types: updatedTypes };
      });
    }

    return {
      name:
        transaction?.name ||
        userData?.name ||
        metaDataUserDetails?.metaDataName ||
        subscriptionUserDetails?.metaDataName ||
        "-",
      email:
        transaction?.email ||
        userData?.email ||
        metaDataUserDetails?.metaDataEmail ||
        subscriptionUserDetails?.metaDataEmail ||
        "-",
      package: transaction.packageId || "",
      item: items?.join(", "),
      type: type || "unknown",
      amount:
        transaction?.amount?.toFixed(2) || metaDataUserDetails?.metaDataAmount,
      donationIncluded: donationData?.donationAmount?.toFixed(2),
      date: transaction?.timestamp,
      receipt: {
        txnUrl: stripeTransactionUrl,
        receiptUrl: transaction?.receiptUrl,
      },
      orderNo: storeData?.orderNo,
      otherMembers: userData?.otherMembers,
      autoRenewal: autoRenewals,
      autoRenewCancelled: false,
    };
  };

  getTransactionType = (transaction) => {
    const selectedData = transaction?.data || transaction?.metadata;

    if (selectedData) {
      let typeKey = Object.keys(selectedData)?.filter((elem) =>
        elem?.startsWith("type")
      );
      if (typeKey.length > 0) {
        return selectedData?.[typeKey[0]];
      }
    }
    return transaction.type;
  };

  initializeState = async (res) => {
    try {
      this.setState({
        loading: true,
      });
      let packages = await getAllPackages(this.props.currentGroup);
      let members = await getFilteredMembersFromLocal(
        this.props.currentGroup,
        {},
        null,
        packages,
        this.props.adminData.data.requireMembershipPackage,
        null,
        "prev"
      );
      let storeOrders = await getStoreItems(this.props.currentGroup)
        .then((storeItems) => {
          return getOrders(this.props.currentGroup).then((orders) => {
            let formatedOrders = orders.map((order) => {
              let obj = {};
              obj.orderNo = order.id;
              let items = order.items || [];
              obj.items = items.map((item, index) => {
                let itemData = {};
                let requiredStoreItem = storeItems.find(
                  (storeItem) => storeItem.id === item.id
                ) || { name: "" };
                itemData.itemName = requiredStoreItem.name;
                itemData.quantity = item.count;
                itemData.color = item.color;
                itemData.size = item.size;
                itemData.status = item.status || "unfulfilled";
                itemData.statusUpdateTimestamp =
                  item.statusUpdateTimestamp || "";
                itemData.price = item.price;
                itemData.itemId = item.id;
                if (item.buyerInfo) {
                  let address = "";
                  if (itemData.shippingOption === "shipping") {
                    let data = item;
                    if (data.buyerInfo.fullName) {
                      itemData.customerName = data.buyerInfo.fullName;
                    }
                    if (data.buyerInfo.address1) {
                      address += data.buyerInfo.address1 + ",";
                      itemData.address1 = data.buyerInfo.address1;
                    }
                    if (data.buyerInfo.address2) {
                      address += data.buyerInfo.address2 + ",";
                      itemData.address2 = data.buyerInfo.address2;
                    }
                    if (data.buyerInfo.city) {
                      address += data.buyerInfo.city + ",";
                      itemData.city = data.buyerInfo.city;
                    }
                    if (data.buyerInfo.state) {
                      address += data.buyerInfo.state + ",";
                      itemData.state = data.buyerInfo.state;
                    }
                    if (data.buyerInfo.country) {
                      address += data.buyerInfo.country + " ";
                      itemData.country = data.buyerInfo.country;
                    }

                    let addressArr = address.split("");
                    addressArr.pop();
                    address = addressArr.join("") + " ";
                    address = address.replace(/,/g, ", ");

                    if (data.buyerInfo.zip) {
                      address += data.buyerInfo.zip;
                      itemData.zip = data.buyerInfo.zip;
                    }
                  }
                  if (address) {
                    itemData.address = address;
                  }
                }
                return itemData;
              });
              obj.paymentIntentId = order.paymentIntentId;

              return obj;
            });

            return formatedOrders;
          });
        })
        .then((formatedOrders) => formatedOrders);

      let donations = await fbGetDonationDataForGroup(this.props.currentGroup);
      this.setState(
        {
          allPackages: packages,
          allMembers: members,
          allStoreOrders: storeOrders,
          allDonations: donations,
        },
        () => {
          res();
        }
      );
      this.setState({
        loading: false,
      });
    } catch {
      this.setState({
        loading: false,
      });
    }
  };

  initState = () => {
    return new Promise(this.initializeState);
  };

  componentDidMount() {
    this.initState().then(() => {
      this.fetchData();
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentGroup !== prevProps.currentGroup) {
      this.initState().then(() => {
        this.fetchData();
      });
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentGroup: state.adminData.currentGroup,
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
  };
};

export default connect(mapStateToProps)(PurchaseReport);
