import React from "react";
import { connect } from "react-redux";
import LoadingModal from "../../commons/LoadingModal";
import StadiumSetup from "./Setup";
import { withRouter } from "../../../utils/helper";

class StadiumContainer extends React.Component {
  render() {
    let primaryColor = "";
    if (
      this.props.adminData.data.configuration &&
      this.props.adminData.data.configuration.primaryColor
    ) {
      let { r, g, b } = this.props.adminData.data.configuration.primaryColor;
      primaryColor = `rgb(${r}, ${g}, ${b})`;
    }
    if (this.props.user.isAuthenticated === undefined) {
      return <LoadingModal />;
    }
    return (
      <div
        className="col"
        style={{ width: "100%", display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            padding: "30px 16px",
          }}
        >
          <ul
            className="nav nav-tabs"
            style={{
              color: "#ffffff",
              fontSize: 22,
              fontWeight: "bold",
            }}
          >
            {this.props.router.params.tab === "setup" && (
              <li
                className="nav-item"
                onClick={this.changeTab}
                style={{
                  borderBottom: `4px solid ${primaryColor}`,
                  color: "black",
                }}
              >
                <a name="setup" id="setup">
                  Setup
                </a>
              </li>
            )}
          </ul>
          {this.props.router.params.tab === "setup" && <StadiumSetup />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adminData: state.adminData.allGroups[state.adminData.currentGroup],
    currentGroup: state.adminData.currentGroup,
    user: state.user,
  };
};

export default connect(mapStateToProps)(withRouter(StadiumContainer));
