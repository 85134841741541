import { useState } from "react";
import "./ReactChipInput.css";
import { CloseBorderlessIcon } from "../../utils/icons";

const ReactChipInput = ({
  chips = [],
  onSubmit,
  onRemove,
  classes = "",
  placeholder = "Type and press Enter...",
  validateEmail = true,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  const isValidEmail = (email) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  const handleKeyDown = (e) => {
    setError("");

    if (e.key === "Enter") {
      e.preventDefault();
      const value = inputValue.trim();

      if (!value) return;

      if (validateEmail && !isValidEmail(value)) {
        setError("Please enter a valid email");
        return;
      }

      if (chips.includes(value)) {
        setError("Already added");
        return;
      }

      onSubmit(value);
      setInputValue("");
    }

    if (e.key === "Backspace" && !inputValue && chips.length > 0) {
      e.preventDefault();
      onRemove(chips.length - 1);
    }
  };

  const baseClasses =
    "min-h-[45px] p-2 border rounded-lg flex flex-wrap gap-2 bg-white focus-within:ring-1 focus-within:ring-blue-500 focus-within:border-blue-500";

  return (
    <div className="w-full max-w-2xl">
      <div
        className={`${baseClasses} ${classes}`}
        style={{ borderRadius: "10px" }}
      >
        <div className="react-chip-input-chips-container">
          {chips.map((chip, index) => (
            <div key={index} className="react-chip-input-chip">
              <span className="text-sm">{chip}</span>
              <div
                onClick={() => onRemove(index)}
                className="react-chip-input-chip-remove"
                aria-label="Remove chip"
              >
                <div className="flex-center">
                  <img
                    alt="close"
                    height={20}
                    width={20}
                    src={CloseBorderlessIcon}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <input
          style={{ border: "none" }}
          type={validateEmail ? "email" : "text"}
          className="react-chip-input-text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={chips.length === 0 ? placeholder : ""}
        />
      </div>
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};

export default ReactChipInput;
